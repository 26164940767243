import { Button, Dropdown, IconButton, Menu, type ButtonProps, type MenuProps } from "@/components";
import { KebabIcon } from "@hopper-ui/icons";
import { type MenuTriggerProps } from "@react-types/menu";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useRef } from "react";
import { useMenuTrigger } from "react-aria";
import { useMenuTriggerState } from "react-stately";
import styles from "./DropdownMenuButton.module.css";

interface Props<T extends object> extends MenuProps<T>, MenuTriggerProps {
  buttonText?: string;
  loading?: boolean;
  isDisabled?: boolean;
  extraButtonProps?: Partial<ButtonProps>;
  className?: string;
  iconButtonClassName?: string;
}

/**
 * @deprecated Use Menu from @workleap/orbiter-ui instead
 */
export const DropdownMenuButton = <T extends object>(props: Props<T>) => {
  const state = useMenuTriggerState(props);
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonText, loading, isDisabled, extraButtonProps = {}, className, iconButtonClassName } = props;

  const { menuTriggerProps, menuProps } = useMenuTrigger<T>({ isDisabled }, state, ref);

  return (
    <div className={classNames(className)}>
      {buttonText && (
        <Button
          loading={loading}
          {...menuTriggerProps}
          {...extraButtonProps}
          forwardedRef={ref}
          isDisabled={isDisabled}
        >
          {buttonText}
        </Button>
      )}
      {
        !buttonText && (
          <IconButton
            loading={loading}
            {...menuTriggerProps}
            variant="ghost"
            icon={<KebabIcon />}
            forwardedRef={ref}
            className={classNames(styles.menuButton, iconButtonClassName)}
            disabled={isDisabled}
          />
        )
      }
      <AnimatePresence>
        {state.isOpen && (
          <Dropdown variant="auto" state={state} triggerRef={ref} placement="bottom left">
            <motion.div
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
            >
              <Menu
                autoFocus={state.focusStrategy || true}
                {...menuProps}
                {...props}
              />
            </motion.div>
          </Dropdown>
        )}
      </AnimatePresence>
    </div>
  );
};
