import { type FetchStatus } from "@tanstack/react-query";
import BeatLoader from "react-spinners/BeatLoader";
import colors from "@/styles/colors";

interface Props {
  fetchStatus: FetchStatus;
}

export const LoadingAnimation = ({ fetchStatus }: Props) => (
  <BeatLoader
    loading={fetchStatus === "fetching"}
    size={7}
    color={colors["primary-surface-strong"]}
    aria-label="Loading Spinner"
  />
);
