import { type BlockerFunction } from "@remix-run/router";
import { ConfirmationModal } from "./ConfirmationModal";
import { type ReactNode } from "react";
import { useTranslation } from "@/hooks/useTranslation";
import ReactRouterPrompt from "react-router-prompt";

interface Props {
  title?: string;
  description?: string;
  confirmButtonContent?: ReactNode;
  cancelButtonContent?: ReactNode;
  when: boolean | BlockerFunction;
}

export const UnsavedChangesModal = ({ when, title, description, cancelButtonContent, confirmButtonContent }: Props) => {
  const { t } = useTranslation();
  
  return (
    <ReactRouterPrompt when={when}>
      {({ onConfirm, onCancel }) => (
        <ConfirmationModal
          title={title ?? t("unsavedChanges.modal.title")}
          onClickCancel={onCancel}
          onClickConfirm={onConfirm}
          confirmButtonContent={confirmButtonContent}
          cancelButtonContent={cancelButtonContent}
        >
          {description ?? t("unsavedChanges.modal.description")}
        </ConfirmationModal>
      )}
    </ReactRouterPrompt>
  );
};