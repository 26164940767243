import { HrisList } from "@/features/hris/components/list/HrisList";
import { ModalDialog } from "@/components";
import { OverlayContainer } from "react-aria";
import { type OverlayTriggerState } from "react-stately";
import { useConnectHris } from "@/features/hris/hooks/useConnectHris";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  overlayState: OverlayTriggerState;
}

export const ConnectHrisModal = ({ overlayState }: Props) => {
  const { t } = useTranslation();
  const { openMergeLink } = useConnectHris();

  const onConfirm = (integration: string, token: string) => {
    overlayState.close();
    
    openMergeLink(integration, token);
  };

  if (!overlayState.isOpen) {
    return null;
  }

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("hrisSettings.connect.title")}
        isOpen
        onClose={overlayState.close}
        isDismissable
      >
        <HrisList
          onConfirm={onConfirm}
          onCancel={overlayState.close}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};