import { type AddCustomMarketSkill } from "@/features/skills/types/common";
import { skillsQueryKeys } from "@/features/skills/types/skillsQueryKeys";
import { toast } from "react-toastify";
import { translate } from "@/lib/i18n";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import { useQueryClient } from "@tanstack/react-query";
import api from "@/lib/http/api";

export const useAddCustomMarketSkillMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutationWithValidationResult<AddCustomMarketSkill>({
    mutationFn: async (requestPayload : AddCustomMarketSkill) => {
      await api.post("/skills/custom-market-skills", requestPayload);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: skillsQueryKeys.all });
      toast.success(translate("skills.customDescriptions.createSuccessMessage"));

      onSuccess?.();
    }
  });
};