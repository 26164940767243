import { ViewContentWrapper } from "@/components";
import { PermissionGuardRoute } from "@/pages/components/PermissionGuardRoute";
import { Navigate, Route, Routes } from "react-router-dom";
import { UsersPage } from "./UsersPage";
import { BulkImportPage } from "./bulk-import/BulkImportPage";
import { EditUserPage } from "./edit/EditUserPage";

export const UsersRoutes = () => (
  <Routes>
    <Route element={<ViewContentWrapper />}>
      <Route path="" element={<UsersPage />} />
      <Route path="bulk-import"
        element={(
          <PermissionGuardRoute
            permission="Permissions.People.Create"
            checkHrisStatus
          >
            <BulkImportPage />
          </PermissionGuardRoute>
        )}
      />
    </Route>
    <Route path="/edit/:employeeId" element={<EditUserPage />} />
    <Route path="*" element={<Navigate to="." />} />
  </Routes>
);
