import { ThemeProvider } from "@workleap/orbiter-ui";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import "react-tooltip/dist/react-tooltip.css";
import App from "./App";
import "./lib/i18n";
import reportWebVitals from "./reportWebVitals";
import { isProd } from "./utils/environment";

const tagManagerArgs = {
  gtmId: "GTM-WH7WFZH"
};

if (isProd) {
  TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <ThemeProvider colorScheme="light">
    <App />
  </ThemeProvider>
);

reportWebVitals();