import { DefaultDisabledExplainer, FormField } from "@/components";
import { type JobOpeningFormInput } from "@/features/jobs/types/jobOpeningFormTypes";
import { useTranslation } from "@/hooks/useTranslation";
import { Item, Select } from "@workleap/orbiter-ui";
import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { type JobOpeningFormMode } from "./JobOpeningFormFlyout";

interface Props {
  mode: JobOpeningFormMode;
  isStatusDisabled?: boolean;
}

export const JobOpeningFormStatus = ({ mode, isStatusDisabled }: Props) => {
  const { t } = useTranslation();
  const { control } = useFormContext<JobOpeningFormInput>();
  const status = useWatch<JobOpeningFormInput, "status">({ name: "status" });

  const helpMessage = useMemo(() => {
    switch (status) {
      case "Draft":
        return t("jobs.form.visibility.status.draft.help");
      case "Open":
        return t("jobs.form.visibility.status.open.help");
      default:
        return;
    }
  }, [status, t]);

  return (
    <FormField 
      control={control}
      name="status"
      label={t("jobs.form.status.label")}
      helpMessage={helpMessage}
      topRightLabel={<DefaultDisabledExplainer isAtsSynced={isStatusDisabled} />}
      required
      render={({ field: { value, onChange, ...rest } }) => (
        <Select {...rest}
          selectedKey={value}
          onSelectionChange={(_, newValue) => newValue && onChange(newValue)}
          disabled={isStatusDisabled}
        >
          <Item key="Open" selected={value === "Open"}>{t("jobs.form.visibility.status.open.label")}</Item>
          <Item key="Draft" selected={value === "Draft"}>{t("jobs.form.visibility.status.draft.label")}</Item>
          <Item key="Closed" disabled={mode === "create"} selected={value === "Closed"}>{t("jobs.form.visibility.status.closed.label")}</Item>
        </Select>
      )}
    />
  );
};