/**
 * Link: https://gsoft-my.sharepoint.com/:w:/r/personal/guillaume_roy_gsoft_com/_layouts/15/Doc.aspx?sourcedoc=%7BE58775C2-51AA-4128-A04A-2F451FF7D316%7D&file=Mixpanel%20Events.docx&action=default&mobileredirect=true
 * 
 * Example:
 * 
 * Premium Limitation Actions 
 * - Name: hover, clicked 
 * - Source: (page name) 
 * - Module: (module name) 
 *
 * AnalyticEvents: Premium Limitation Actions 
 * AnalyticProperties: Name, Source, Module
 * AnalyticValues: page name, module name
 */

export const AnalyticEvents = {
  skillsMapped: "skills-mapped",
  skillsRanked: "skills-ranked",
  filterApplied: "filter-applied",
  onboardingStarted: "onboarding-started",
  reportViewed: "report-viewed",
  setupAction: "setup-action",
  billingAction: "billing-action",
  skillMappingAction: "skill-mapping-action",
  skillRatingAction: "skill-rating-action",
  premiumLimitationAction: "premium-limitation-action",
  hrisAction: "hris-action",
  roleSkillsAction: "role-skills-action",
  collaboratorAction: "collaborator-action",
  jobAction: "job-action",
  roleFormAction: "role-form-action",
  homeAction: "home-action",
  roleCreationAction: "role-creation-action",
  pageViewed: "page-viewed",
  setupCompleted: "setup-completed",
  atsAction: "ats-action",
  atsPositionAction: "ats-position-action",
  progressionPlanCreated: "progression-plan-created",
  progressionPlanActivityAction: "progression-plan-activity-action",
  progressionPlanSkillAction: "progression-plan-skill-action",
  progressionPlanAction: "progression-plan-action",
  mentorAction: "mentor-action",
  demoAction: "demo-action"
} as const;

export const AnalyticProperties = {
  action: "Action",
  rating: "Rating",
  route: "Route",
  source: "Source",
  role: "Role",
  skill: "Skill",
  type: "Type",
  module: "Module",
  value: "Value",
  roleId: "Role ID",
  managerId: "Manager ID",
  departmentId: "Department ID",
  status: "Status",
  internalName: "Internal Name",
  marketId: "Market ID",
  seniorityLevel: "Seniority Level",
  skillCount: "Skill Count",
  softSkillCount: "Soft Skill Count",
  pathCount: "Path Count",
  objective: "Objective",
  timeline: "Timeline",
  roleName: "Role Name",
  isSelfPlan: "Is Self Plan",
  skillGapSize: "Skill Gap Size",
  activityType: "Activity Type",
  activityTitle: "Activity Title",
  skillLevel: "Skill Level",
  skillName: "Skill Name"
} as const;

export const AnalyticValues = {
  actionAdd: "Add",
  actionBack: "Back",
  actionAutoSync: "Sync Automatically",
  actionCollaboratorSync: "Sync Collaborators",
  actionClick: "click",
  actionConnect: "Connect",
  actionDisconnect: "Disconnect",
  actionDeselect: "Deselect",
  actionHover: "hover",
  actionNext: "Next",
  actionRate: "Rate",
  actionRefine: "Refine",
  actionReconnect: "Reconnect",
  actionRemove: "Remove",
  actionStartSync: "Start Sync",
  actionSelect: "Select",
  actionSkip: "Skip",
  actionUpdate: "Update",
  actionContactUs: "Contact Us",
  actionFixErrors: "Fix Errors",
  actionEdit: "Edit",
  actionSave: "Save",
  actionReset: "Reset",
  sourceCloud: "Cloud",
  sourceRecommended: "Recommended",
  sourceSearch: "Search",
  sourceManual: "manual",
  typeUser: "User",
  typeTenant: "Tenant",
  actionBundle: "Bundle",
  actionCustomerPortal: "Customer Portal",
  actionViewReport: "View Report",
  actionNewPosition: "New Position",
  actionInterested: "Interested",
  actionNotInterested: "Not Interested",
  actionSkillGap: "Skill Gap",
  actionViewOpenings: "View Openings",
  actionUpdateSkills: "Update Skills",
  actionPathAdded: "Path Added",
  actionPathRemoved: "Path Removed",
  actionDescriptionGenerated: "Description Generated",
  delete: "Delete",
  publish: "Publish",
  archive: "Archive",
  launch: "Launch",
  exit: "Exit",
  closeCard: "Close Card"
} as const;