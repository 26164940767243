import { translate } from "@/lib/i18n";
import { redirectToHome } from "@/utils";
import { type AxiosDefaults, AxiosError, type AxiosRequestConfig } from "axios";
import { type Next } from "middleware-axios";
import { toast } from "react-toastify";

export const ErrorMiddleware = async <T>(config: AxiosRequestConfig, next: Next<T>, _: AxiosDefaults): Promise<void> => {
  try {
    await next(config);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      if (e.response?.status === 500) {
        toast.error(translate("common.messages.pageErrorRetry"));
      } else if (e.response?.status === 403 || e.response?.status === 404) {
        redirectToHome("error=not-found");
      }
    } else {
      throw e;
    }
  }
};
