import { AnimatePresence, motion } from "framer-motion";
import { type AriaSwitchProps, VisuallyHidden, useFocusRing, useSwitch } from "react-aria";
import { useRef } from "react";
import { useToggleState } from "react-stately";
import classNames from "classnames";
import styles from "./Switch.module.css";

interface Props extends AriaSwitchProps {
  variant?: "small" | "medium";
}

export const Switch = (props: Props) => {
  const { children, variant = "medium", id } = props;
  const state = useToggleState(props);
  const ref = useRef(null);
  const { inputProps } = useSwitch(props, state, ref);
  const { focusProps } = useFocusRing();

  const classname = classNames(styles.container, {
    [styles.selected]: !!state.isSelected,
    [styles.disabled]: props.isDisabled,
    [styles.small]: variant === "small"
  });

  return (
    <label className={styles.switch}>
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <div className={classname} id={id}>
        <motion.div layout className={styles.handle}>
          <AnimatePresence initial={false}>
            <motion.i
              initial={{ y: -30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 30, opacity: 0 }} 
            />
          </AnimatePresence>
        </motion.div>
      </div>
      {children}
    </label>
  );
};