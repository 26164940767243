import { AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { Button } from "@/components";
import { ConnectHrisModal } from "@/features/hris/components/modal/ConnectHrisModal";
import { NewTabIcon } from "@hopper-ui/icons";
import { useOverlayTriggerState } from "react-stately";
import { useTrackHrisAction } from "@/features/hris/hooks/useTrackHrisAction";
import { useTranslation } from "@/hooks/useTranslation";

export const ConnectHrisButton = () => {
  const { t } = useTranslation();
  const hrisConnectModalState = useOverlayTriggerState({});
  const { trackHrisAction } = useTrackHrisAction();

  const onConnectHrisClick = () => {
    trackHrisAction(AnalyticValues.actionConnect);
    hrisConnectModalState.open();
  };

  return (
    <>      
      <Button
        size="small"
        iconPosition="right"
        icon={<NewTabIcon size="sm" />}
        variant="primary"
        onClick={onConnectHrisClick}
      >
        {t("hrisSettings.connect.button")}
      </Button>
      <ConnectHrisModal overlayState={hrisConnectModalState} />
    </>
  );
};