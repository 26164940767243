import { useAnalytics } from "@/features/analytics/hooks/useAnalytics";
import { AnalyticEvents } from "@/features/analytics/types/analyticEvents";
import { useGetSetup } from "@/features/setup/hooks/useGetSetupQuery";
import { type SetupDto } from "@/features/setup/types/index";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const redirectPaths: Record<SetupDto["type"], string> = {
  Tenant: "/setup-tenant",
  User: "/setup-user"
};

export const SetupRouteGuard = () => {
  const { trackEvent } = useAnalytics();
  const { data: setupQueryData, isLoading: isSetupQueryLoading } = useGetSetup();
  const location = useLocation();

  if (isSetupQueryLoading || !setupQueryData) {
    return null;
  }

  if (setupQueryData.isCompleted) {
    return <Outlet />;
  }

  trackEvent(AnalyticEvents.onboardingStarted, {
    "Type": setupQueryData.type
  });


  if (location.pathname === redirectPaths[setupQueryData.type]) {
    return <Outlet />;
  }

  const redirectPath = redirectPaths[setupQueryData.type];

  return <Navigate to={redirectPath} replace />;
};
