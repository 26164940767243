import { useConfiguration } from "@/lib/core/ConfigurationProvider";
// eslint-disable-next-line no-restricted-imports
import { type IntercomProps, useIntercom as useReactIntercom } from "react-use-intercom";

export const useIntercom = () => {
  const { intercomAppId } = useConfiguration();
  const { 
    trackEvent: intercomTrackEvent, 
    update: intercomUpdate, 
    boot: intercomBoot,
    shutdown: intercomShutdown
  } = useReactIntercom();

  const trackEvent = (event: string, metaData?: object) => {
    intercomAppId && intercomTrackEvent(event, metaData);
  };

  const update = (props?: Partial<IntercomProps>) => {
    intercomAppId && intercomUpdate(props);
  };

  const boot = (props?: IntercomProps) => {
    intercomAppId && intercomBoot(props);
  };

  const shutdown = () => {
    intercomAppId && intercomShutdown();
  };

  return {
    boot,
    trackEvent,
    update,
    shutdown
  };
};