import { HStack, UserMenu } from "@/components";
import { DefaultLayoutContext } from "@/components/layouts/DefaultLayoutContext";
import { HrisAuthErrorButton } from "@/features/hris/components/button/hris-auth-error-button/HrisAuthErrorButton";
import { useGetSetupQuery } from "@/features/setup/hooks/useGetSetupQuery";
import { LanguageToggle } from "@/features/translations";
import { useTranslation } from "@/hooks/useTranslation";
import { useCurrentUser } from "@/lib/auth/AuthProvider";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import { Routes } from "@/types";
import { BarsIcon } from "@hopper-ui/icons";
import { IconButton } from "@workleap/orbiter-ui";
import classNames from "classnames";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { NavigationMobileTopBar } from "./NavigationMobileTopBar";
import { WorkleapNavigationBar } from "./WorkleapNavigationBar";

interface Props {
  className: string;
  hasNavigation?: boolean;
}

export const NavigationBar = ({ className, hasNavigation }: Props) => {
  const mobileMenuState = useContext(DefaultLayoutContext);
  const { toggleMobileMenu } = mobileMenuState;
  const { hasFeature } = useFeatures();
  const { tenant: { isWorkleapTenant } } = useCurrentUser();
  const { t } = useTranslation();
  const { isCompleted } = useGetSetupQuery();

  if (hasFeature("Features.TopNavigation") && isWorkleapTenant) {
    return (
      <WorkleapNavigationBar hasNavigation={hasNavigation} className={className} />
    );
  }

  return (
    <header className={classNames("h-[var(--topbar-height)] flex justify-between gap-5 px-4 items-center bg-neutral-surface border-b border-neutral-border-weak", className)}>
      {mobileMenuState.isMobileMenuOpen ? <NavigationMobileTopBar /> : null}
      {hasNavigation && (
        <IconButton variant="tertiary" aria-label={t("navigation.openMenu")} onClick={toggleMobileMenu} className="block md:hidden">
          <BarsIcon />
        </IconButton>
      )
      }
      <Link to={Routes.Home} className={classNames({ "hidden md:block": !isCompleted })}>
        <HStack align="center" gap="space-inline-md">
          <img src="/skills-logo.svg" alt="Workleap Skills" />
          <span className="font-primary font-410 text-[20px] leading-none mt-1 tracking-[-0.8px]">{t("app.name")}</span>
        </HStack>
      </Link>
      <HStack align="center" gap={6}>
        <LanguageToggle />
        <HrisAuthErrorButton />
        <UserMenu />
      </HStack>
    </header>
  );
};