import { FieldFormHeader, type FieldFormHeaderProps, FormFieldWrapper, type Gap, HStack } from "@/components";
import { type ReactNode } from "react";

import { DatePickerInput } from "./DatePickerInput";
import { type DatePickerSize } from "./types";

interface Props extends FieldFormHeaderProps {
  label?: string | ReactNode;
  errorMessage?: string;
  errorStyle?: string;

  minValue?: Date;
  maxValue?: Date;
  size: DatePickerSize;

  value: [Date?, Date?];

  onChange: (value: [Date?, Date?]) => void;

  inputsGap?: Gap;
}

export const DateRangePicker = ({
  value,
  maxValue,
  minValue,
  size = "md",
  errorMessage,
  errorStyle,
  label,
  onChange,
  inputsGap = 6,
  ...headerProps
}: Props) => {
  const [startDate, endDate] = value;

  const minEndDate = startDate || minValue;
  const maxStartDate = endDate || maxValue;

  const onStartChange = (date: Date) => onChange([date, endDate]);
  const onEndChange = (date: Date) => onChange([startDate, date]);

  return (
    <FormFieldWrapper errorMessage={errorMessage} errorStyle={errorStyle}>
      <FieldFormHeader {...headerProps} label={label} />
      <HStack gap={inputsGap}>
        <DatePickerInput
          isDisabled={headerProps.isDisabled}
          value={startDate}
          minValue={minValue}
          maxValue={maxStartDate}
          onChange={onStartChange}
          label={label}
          size={size}
        />
        <DatePickerInput
          isDisabled={headerProps.isDisabled}
          value={endDate}
          minValue={minEndDate}
          maxValue={maxValue}
          onChange={onEndChange}
          label={label}
          size={size}
        />
      </HStack>
    </FormFieldWrapper>
  );
};
