import { DropdownMenuButton, MenuItemWrapper } from "@/components";
import { fromUnion } from "@/utils/types";
import { debounce } from "lodash";
import { type ComponentProps, type Key, type ReactNode } from "react";
import { Item, Section } from "react-stately";

export interface ActionButtonItem<T> {
  key: T;
  text: ReactNode;
  description?: ReactNode;
  onClick: () => void;
  isDestructive?: boolean;
  isDisabled?: boolean;
  iconButtonClassName?: string;
  isSelected?: boolean;
}

export interface ActionButtonSection<T> {
  key: string;
  actions: ActionButtonItem<T>[];
}

interface MultiSectionActions<T = string> { sections: ActionButtonSection<T>[] }
interface SingleSectionActions<T = string> { actions: ActionButtonItem<T>[] }

type MenuActions<T extends string> = MultiSectionActions<T> | SingleSectionActions<T>;
type Props<T extends string> = Omit<ComponentProps<typeof DropdownMenuButton>, "children"> & MenuActions<T>;

const {
  isOfTypeTwo: isSingleSectionActions
} = fromUnion<MultiSectionActions, SingleSectionActions>();


/**
 * @deprecated Use Menu from @workleap/orbiter-ui instead
 */
export const ActionButtonDropdown = <T extends string>(props: Props<T>) => {
  const sections: ActionButtonSection<T>[] = isSingleSectionActions(props, "actions")
    ? [{ key: "default", actions: props.actions }] : props.sections;
  
  const onAction = debounce((key: Key) => {
    const actions = sections.flatMap(({ actions: sectionActions }) => sectionActions);
    const matchingItem = actions.find(({ key: itemKey }) => itemKey === key);

    matchingItem?.onClick();
  }, 250);

  return (
    <DropdownMenuButton
      {...props}
      onAction={onAction}
      extraButtonProps={{ ...props.extraButtonProps, iconPosition: "right" }}
    >
      {sections.map(({ key, actions }) => (
        <Section key={key}>
          {actions.map(({ key: actionKey, text, isDestructive, ...actionProps }) => (
            <Item key={actionKey} textValue={key}>
              <MenuItemWrapper {...actionProps} variant={isDestructive ? "danger" : "default"}>
                {text}
              </MenuItemWrapper>
            </Item>
          ))}
        </Section>
      ))}
    </DropdownMenuButton>
  );
};


