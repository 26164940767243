import { type Dispatch, type SetStateAction, useMemo, useState } from "react";
import { type UseQueryOptions, type UseQueryResult, keepPreviousData, useQuery } from "@tanstack/react-query";
import { useDebounce } from "use-hooks";

export interface AutoCompleteProps<TEntity> {
  selectedItems: TEntity[];
  extraItems?: TEntity[];
  extraQueryKeys?: string[];
  extraQueryOptions?: Omit<UseQueryOptions<Promise<TEntity[]>, unknown, TEntity[], string[]>, "queryKey">;

  itemKeyGetter: (item: TEntity) => string;
  itemNameGetter: (item: TEntity) => string;
  fetchItemsQuery: (query: string) => Promise<TEntity[]>;
  onItemAdded?: (item: TEntity) => void;
}

type Result<TEntity> = {
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
  filteredItems: TEntity[];
  filteredResults: string[];
  onSelectionChange: (key?: string) => void;
}
& UseQueryResult<TEntity[], unknown>;

export const useAutoComplete = <TEntity>({
  selectedItems,
  extraQueryKeys = [],
  extraQueryOptions = {},
  extraItems = [],
  itemNameGetter,
  fetchItemsQuery,
  onItemAdded
}: AutoCompleteProps<TEntity>): Result<TEntity> => {
  const [query, setQuery] = useState<string>("");
  const debouncedQuery = useDebounce<string>(query.trimEnd(), 300);

  const queryResult = useQuery({
    queryKey: [...extraQueryKeys, debouncedQuery],
    queryFn: () => fetchItemsQuery(debouncedQuery),
    enabled: !!debouncedQuery,
    placeholderData: keepPreviousData,
    ...extraQueryOptions
  });

  const { data } = queryResult;

  const itemsLookupMap = useMemo(() => {
    if (!data) {
      return new Map<string | undefined, TEntity>([]);
    }

    return new Map<string | undefined, TEntity>(
      data.map(item => [itemNameGetter(item), item])
    );
  }, [data]);

  const onSelectionChange = (key?: string) => {
    const matchedItem = itemsLookupMap.get(key);
    if (matchedItem) {
      onItemAdded?.(matchedItem);
      setQuery("");
    }
  };

  const filteredItems = useMemo(() => {
    if (!data) {
      return [];
    }

    const currentKeys = [...extraItems, ...selectedItems].map(item => itemNameGetter(item));

    return data.filter(d => !currentKeys.includes(itemNameGetter(d)));
  }, [data, extraItems, selectedItems]);

  const filteredResults = useMemo(() =>
    [...filteredItems].map(x => itemNameGetter(x)),
  [filteredItems]);

  return {
    setQuery,
    query,
    filteredItems,
    filteredResults,
    onSelectionChange,
    ...queryResult
  };
};
