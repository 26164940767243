import { type AxiosError } from "axios";
import { type ValidationErrors } from "@/models/error";
import { departmentQueryKeys } from "./departmentQueryKeys";
import { toast } from "react-toastify";
import { translate } from "@/lib/i18n";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "@/lib/http/api";

export const useDeleteDepartmentMutation = (departmentId: string) => {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError<{ errors?: ValidationErrors }>, void, unknown>({
    mutationFn: async () => {
      await api.delete(`/departments/${departmentId}`);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: departmentQueryKeys.lists() });

      toast.success(translate("departments.delete.successMessage"));
    }
  });
};