import { directoryQueryKeys } from "@/features/directory/types/directoryQueryKeys";
import { reportQueryKeys } from "@/features/reports/types/reportQueryKeys";
import { roleQueryKeys } from "@/features/roles/types/roleQueryKeys";
import { trainingQueryKeys } from "@/features/training/types/trainingQueryKeys";
import { useTranslation } from "@/hooks/useTranslation";
import api from "@/lib/http/api";
import { type ValidationErrors } from "@/models/error";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { toast } from "react-toastify";

export const useDeleteSkillMutation = (onSuccess?: () => void) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError<{ errors?: ValidationErrors }>, string, unknown>({
    mutationFn: async (skillName: string) => {
      await api.post("/skills/delete", { skillName });
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: directoryQueryKeys.all }),
        queryClient.invalidateQueries({ queryKey: roleQueryKeys.all }),
        queryClient.invalidateQueries({ queryKey: trainingQueryKeys.all }),
        queryClient.invalidateQueries({ queryKey: reportQueryKeys.all })
      ]);

      toast.success(t("common.messages.changesSaved"));
      onSuccess?.();
    }
  });
};