import classNames from "classnames";
import { Stack, type StackProps } from "./Stack";

interface Props extends Omit<StackProps, "flow"> {
  justify?: "start" | "end" | "center" | "between" | "around" | "evenly";
  align?: "start" | "end" | "center" | "baseline" | "stretch";
}

/**
 * @deprecated Use Grid or Flex from @workleap/orbiter-ui instead
 */
export const HStack = ({ as = "div", gap, children, className, divider, justify = "start", align = "start" }: Props) => {
  const classes = classNames(className,
    `justify-${justify}`,
    `items-${align}`
  );

  return (
    <Stack flow="row" className={classes} gap={gap} divider={divider} as={as}>{children}</Stack>
  );
};