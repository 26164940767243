import { getFullName } from "@/features/employees/utils";
import { useSearchEmployeesQuery } from "@/features/search/hooks/useSearchEmployeesQuery";
import { type AutocompleteSelection, type SearchResultItem } from "@/features/search/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { SearchIcon } from "@hopper-ui/icons";
import { Autocomplete, type AutocompleteProps, Item } from "@workleap/orbiter-ui";
import { forwardRef, type SyntheticEvent, useCallback, useState } from "react";

interface Props extends Omit<AutocompleteProps, "value" | "onSelectionChange" | "children"> {
  value: SearchResultItem | null;
  onSelectionChange: (employee: SearchResultItem | null) => void;
}

export const SearchEmployees = forwardRef<HTMLInputElement, Props>(({ value, onSelectionChange, minCharacters = 3, ...rest }: Props, ref) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");

  const { data: employees, isFetching } = useSearchEmployeesQuery(query,
    { enabled: query.length >= minCharacters }
  );

  const handleSelectionChange = useCallback((_: SyntheticEvent, selection: AutocompleteSelection) => {
    const selectedEmployee = employees?.find(x => x.id === selection?.key) || null;
    
    if (selectedEmployee) {
      const { id, firstName, lastName } = selectedEmployee;
      onSelectionChange({ id, name: getFullName(firstName, lastName) });
    } else {
      onSelectionChange(null);
    }
  }, [employees, onSelectionChange]);
  
  return (
    <Autocomplete
      {...rest}
      ref={ref}
      aria-label={t("search.employees")}
      value={value?.name || null} 
      onSearch={(_, searchTerm) => setQuery(searchTerm)}
      onSelectionChange={handleSelectionChange}
      zIndex={Number.MAX_SAFE_INTEGER}
      loading={isFetching}
      minCharacters={minCharacters}
      icon={<SearchIcon />}
    >
      {employees?.map(({ id, name }) => (
        <Item key={id} value={id}>
          {name}
        </Item>
      ))}
    </Autocomplete>
  );
});