import { directoryQueryKeys } from "@/features/directory/types/directoryQueryKeys";
import { employeesQueryKeys } from "@/features/employees/types/employeesQueryKeys";
import { roleQueryKeys } from "@/features/roles/types/roleQueryKeys";
import { toast } from "react-toastify";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "@/hooks/useTranslation";
import api from "@/lib/http/api";

export const useArchiveRoleMutation = (roleId: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  
  return useMutationWithValidationResult<void>({
    mutationFn: async () => {
      await api.post(`roles/${roleId}/archive`);
    },
    onSuccess: () => {
      toast.success(t("common.messages.changesSaved"));
      queryClient.invalidateQueries({ queryKey: roleQueryKeys.all });
      queryClient.invalidateQueries({ queryKey: employeesQueryKeys.all });
      queryClient.invalidateQueries({ queryKey: directoryQueryKeys.all });
    }
  });
};
