import { Switch } from "@/components";
import { useGetAtsSettings } from "@/features/ats/hooks/useGetAtsSettings";
import { useTranslation } from "@/hooks/useTranslation";
import { useUpdateAtsImportSkillsMutation } from "@/features/ats/hooks/useUpdateAtsImportSkillsMutation";

export const AtsActivateImportSkillsToggle = () => {
  const { t } = useTranslation();
  const { data } = useGetAtsSettings();
  const { mutate, isPending } = useUpdateAtsImportSkillsMutation();
  const isDisabled = data?.isScheduledOrRunning || isPending;

  const handleChange = (isImportSkillsEnabled: boolean) => {
    mutate({ isImportSkillsEnabled });
  };
  
  const onAtsAutoSyncChange = (isSelected: boolean) => {
    handleChange(isSelected);
  };

  return (
    <Switch 
      isSelected={data?.isImportSkillsEnabled} 
      isDisabled={isDisabled} 
      onChange={onAtsAutoSyncChange}
    >
      {t("ats.sync.importSkills.label")}
    </Switch>
  );
};