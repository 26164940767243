import { useTranslation } from "@/hooks/useTranslation";
import { Button, Heading, Text } from "@workleap/orbiter-ui";
import classNames from "classnames";
import styles from "./AppErrorBoundary.module.css";
import { ReactComponent as ErrorIllustration } from "./assets/error-illustration.svg";

export const AppErrorBoundary = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="max-w-[560px] mx-auto pt-[--hop-space-480] pb-6 px-8">
        <ErrorIllustration className={classNames(styles.illustration, "w-[232px] mt-12 mx-auto")} />
        <div className="flex flex-col gap-[--hop-space-stack-md] items-center text-center">
          <Heading size="lg" color="status-neutral">
            {t("common.phrases.oops")}
          </Heading>
          <Text size="md" color="neutral-weak" className="text-balance">
            {t("common.messages.pageErrorRefreshRetry")}
          </Text>
          <Button variant="primary" size="md" onClick={() => window.location.assign(window.location.origin)}>
            {t("common.phrases.refresh")}
          </Button>
        </div>
      </div>
    </div>
  );
};