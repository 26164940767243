import { isRichContentEmpty } from "@/features/rich-content/rich-content.utils";
import { type operations } from "@/features/types/client";
import { type Model } from "@/features/types/clientTypes";
import { type FormRichContent, type RichContent } from "@/features/types/common";
import { translate } from "@/lib/i18n";
import { minLength, object, special, string, toTrimmed, unknown, type Output } from "valibot";

export type SkillsSearchDto = Model<"Skills.Queries.SearchSkillsQuery.SearchSkillsDto">;

export type SkillCategory = Model<"Skills.SkillCategory">;

export type GetNewSkillQueryDto = Model<"Skills.Queries.GetNewSkillsQueryDto">;
export type GetSkillsInventoryQueryDto = Model<"Skills.Queries.GetSkillsInventoryQueryDto">;
export type GetCustomMarketSkillDto = Model<"Skills.Queries.GetCustomMarketSkillByNameDto">;
export type CustomMarketSkillDto = Model<"Skills.Models.CustomMarketSkillDto">;
export type ListCustomMarketSkillsDto = Model<"Skills.Queries.GetCustomMarketSkillsDto">;

export type GetCustomMarketSkillsQueryParams = NonNullable<operations["Skills_GetCustomMarketSkills"]["parameters"]>["query"];

export type AddCustomMarketSkill = Model<"Skills.Commands.AddCustomMarketSkillCommand">;
export type UpdateCustomMarketSkill = Model<"Skills.Commands.UpdateCustomMarketSkillCommand">;

export type AddCustomSkill = Model<"Skills.Commands.AddCustomSkillCommand">;
export type SimilarSkillDto = Model<"Skills.Models.SkillDto">;
export type AddCustomSkillResult = Required<NonNullable<Model<"Skills.Commands.AddCustomSkillResult">>>;
export type UpdateCustomSkill = Model<"Skills.Commands.UpdateCustomSkillCommand">;
export type ListCustomSkillsDto = Model<"Skills.Queries.GetCustomSkillsDto">;
export type CustomSkillDto = Model<"Skills.Models.CustomSkillDto">;
export type GetCustomSkillDto = Model<"Skills.Queries.GetCustomSkillByIdDto">;
export type GetCustomSkillsQueryParams = NonNullable<operations["Skills_GetCustomSkills"]["parameters"]>["query"];
export type GetCustomSkillSettingsDto = Model<"Skills.Queries.GetCustomSkillSettingsDto">;
export type UpdateCustomSkillSettingsCommand = Model<"Skills.Commands.UpdateCustomSkillSettingsCommand">;

export type GetSkillEmployeesQuery = Model<"Skills.Queries.GetSkillEmployeesQuery">;
export type GetSkillEmployeesQueryDto = Model<"Skills.Queries.GetSkillEmployeesQueryDto">;
export type EmployeeSkillMatchDto = Model<"Skills.Queries.GetSkillEmployeesQueryDto.EmployeeSkillMatchDto">;

export type RatingsDescription = {
  [key in keyof AddCustomMarketSkill["ratingsDescriptions"]]?: FormRichContent
};

export const ratingsDescriptionSchema = special<RatingsDescription>(val => {
  const ratings = val as RatingsDescription;
  const keys = Object.keys(ratings);

  return keys.every(key => !isRichContentEmpty(ratings[key as keyof RatingsDescription] as RichContent));
}, translate("skills.form.ratingsDescriptions.error"));

export const addSkillSchema = object({
  name: string([toTrimmed(), minLength(1, translate("skills.form.name.error"))]),
  ratingsDescriptions: ratingsDescriptionSchema
}, unknown());

export interface CustomSkillFormState extends Pick<Output<typeof addSkillSchema>, "name">, Pick<Model<"Skills.Commands.AddCustomSkillCommand">, "similarSkills" | "category"> {
  ratingsDescriptions: RatingsDescription;
}

export const editMarketSkillSchema = object({
  ratingsDescriptions: ratingsDescriptionSchema
}, unknown());

export interface EditMarketSkillFormState extends Pick<Model<"Skills.Commands.AddCustomSkillCommand">, "similarSkills"> {
  ratingsDescriptions: RatingsDescription;
}