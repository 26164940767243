import { Navigation } from "@/components";
import { NavigationBar } from "@/components/navigation/NavigationBar";
import { DemoBanner, DemoCard } from "@/features/demo";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styles from "./DefaultLayout.module.css";
import { DefaultLayoutContext } from "./DefaultLayoutContext";
import { useFeatures } from "@/lib/features/hooks/useFeatures";

const useMobileMenuState = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return useMemo(() => ({
    isMobileMenuOpen: isMobileMenuOpen,
    openMobileMenu: () => setIsMobileMenuOpen(true),
    closeMobileMenu: () => setIsMobileMenuOpen(false),
    toggleMobileMenu: () => setIsMobileMenuOpen(!isMobileMenuOpen)
  }), [isMobileMenuOpen]);
};

export const DefaultLayout = () => {
  const mobileMenuState = useMobileMenuState();
  const { closeMobileMenu, isMobileMenuOpen } = mobileMenuState;
  const { pathname } = useLocation();
  const { hasFeature } = useFeatures();
  
  useEffect(() => {
    // Closes mobile menu on route change
    closeMobileMenu();
    // Scroll main-layout to the top on route change
    document.getElementById("main-layout")?.scrollTo(0, 0);
  }, [pathname]);

  return (
    <DefaultLayoutContext.Provider value={mobileMenuState}>
      <article className={styles.root}>
        <div className={styles.layout}>
          <NavigationBar hasNavigation className={classNames(styles.header, "relative z-50")} />
          <DemoBanner />
          <aside className={classNames(styles.sidebar, { "!flex": isMobileMenuOpen })}>
            <Navigation />
            <DemoCard />
          </aside>
          <main className={styles.main} id="main-layout">
            <Outlet />
          </main>
        </div>
      </article>
      {hasFeature("Features.WorkleapAiWidgets") && (
        <div className="absolute z-10">
          {/* @ts-expect-error: Allow custom element <wl-ai-search-bar> */}
          <wl-ai-assistant></wl-ai-assistant>
        </div>
      )}
    </DefaultLayoutContext.Provider>
  );
};