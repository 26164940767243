import { Navigate, Outlet } from "react-router";
import { type PropsWithChildren } from "react";
import { Routes } from "@/types";

interface Props extends PropsWithChildren {
  isAllowed: boolean;
  redirectPath?: string;
}

export const ProtectedRoute = ({
  isAllowed,
  redirectPath = Routes.Home,
  children
}: Props) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children
    ? <>{children}</>
    : <Outlet />;
};