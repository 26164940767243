import { type DepartmentDto, type UpdateDepartmentCommandDto } from "@/features/departments/types/common";
import { DepartmentForm } from "./DepartmentForm";
import { ModalDialog } from "@/components";
import { OverlayContainer } from "react-aria";
import { type OverlayTriggerState } from "react-stately";
import { useForm } from "react-hook-form";
import { useTranslation } from "@/hooks/useTranslation";
import { useUpdateDepartmentMutation } from "@/features/departments/api/useUpdateDepartmentMutation";

export interface Props {
  department: DepartmentDto;
  overlayState: OverlayTriggerState;
}

export const EditDepartmentModal = ({ overlayState, department }: Props) => {
  const { t } = useTranslation();

  const form = useForm<UpdateDepartmentCommandDto>({ defaultValues: { name: department.name } });

  const mutation = useUpdateDepartmentMutation(department.id);

  if (!overlayState.isOpen) {
    return null;
  }

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("departments.edit.title")}
        isOpen
        onClose={overlayState.close}
        isDismissable
      >
        <DepartmentForm 
          {...form}
          {...mutation}
          close={overlayState.close} 
          submitButtonText={t("common.form.update")}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};