import { Button, ButtonGroup, HStack, PageHeader } from "@/components";

interface Props {
  onBack: () => void;
  isLoading?: boolean;
  isDirty?: boolean;
  title: string;
  discardButtonLabel: string;
  saveButtonLabel: string;
  canSubmit?: boolean;
}

export const FormHeader = ({
  title,
  discardButtonLabel,
  saveButtonLabel,
  onBack,
  isLoading,
  isDirty,
  canSubmit = true
}: Props) => (
  <HStack justify="between" align="center">
    <PageHeader title={title} />
    <ButtonGroup gap={2}>
      <Button isDisabled={isLoading} variant="secondary" onClick={onBack}>{discardButtonLabel}</Button>
      <Button
        loading={isLoading}
        type="submit"
        isDisabled={!isDirty || !canSubmit}
      >
        {saveButtonLabel}
      </Button>
    </ButtonGroup>
  </HStack>
);