import { AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { Button, HStack, SelectableCard, SelectableCardGroup, VStack } from "@/components";
import { HrisListItem } from "./HrisListItem";
import { useCreateHrisLinkTokenMutation } from "@/features/hris/hooks/useCreateHrisLinkTokenMutation";
import { useGetHRISIntegrations } from "@/features/setup/hooks/useGetHRISIntegrations";
import { useState } from "react";
import { useTrackHrisAction } from "@/features/hris/hooks/useTrackHrisAction";
import { useTranslation } from "@/hooks/useTranslation";
import styles from "./HrisList.module.css";

interface Props {
  onConfirm: (token: string, integration: string) => void;
  onCancel: () => void;
}

export const HrisList = ({ onConfirm, onCancel }: Props) => {
  const { t } = useTranslation();
  const [selectedIntegration, setSelectedIntegration] = useState("");
  const { data: integrations } = useGetHRISIntegrations();
  const { mutate, isPending } = useCreateHrisLinkTokenMutation(({ linkToken }) => onConfirm(selectedIntegration, linkToken));
  const { trackHrisAction } = useTrackHrisAction();
  
  const createLinkToken = () => {
    trackHrisAction(AnalyticValues.actionSelect, selectedIntegration);
    mutate({ integration: selectedIntegration });
  };

  return (
    <VStack gap={6}>
      <SelectableCardGroup
        label="HRIS Providers"
        value={selectedIntegration}
        onChange={setSelectedIntegration}
      >
        <div className={styles.hrisGrid}>
          {integrations?.map(x => (
            <SelectableCard key={x.slug} value={x.slug} title={x.name}>
              <HrisListItem data={x} />
            </SelectableCard>
          ))}
        </div>
      </SelectableCardGroup>
      <HStack align="center" justify="end">
        <Button variant="secondary" onClick={onCancel} isDisabled={isPending}>
          {t("common.form.cancel")}
        </Button>
        <Button
          variant="primary"
          onClick={createLinkToken}
          loading={isPending}
          isDisabled={!selectedIntegration}
        >
          {t("common.form.confirm")}
        </Button>
      </HStack>
    </VStack>
  );
};
