import React from "react";

import { type AccordionDetailsProps } from "./AccordionDetails";
import { type AccordionSummaryProps } from "./AccordionSummary";

export interface AccordionItemProps {
  itemId: string;
  children: [React.ReactElement<AccordionSummaryProps>, React.ReactElement<AccordionDetailsProps>];
  isCollapsed?: boolean;
  onToggleCollapsed?: (itemId: string) => void;
  className?: string;
}

export const AccordionItem = ({
  itemId,
  children,
  isCollapsed,
  className,
  onToggleCollapsed
}: AccordionItemProps) => {
  const [summary, details] = children;
  const onClickSummary = () => onToggleCollapsed?.(itemId);

  return (
    <div className={className}>
      {React.cloneElement(summary, {
        isCollapsed,
        onClick: onClickSummary
      })}
      {React.cloneElement(details, {
        itemId,
        isCollapsed
      })}
    </div>
  );
};
