import { type AriaTextFieldOptions, useTextField } from "@react-aria/textfield";
import { FieldFormHeader, type FieldFormHeaderProps, FormFieldWrapper } from "@/components";
import { useRef } from "react";
import classNames from "classnames";

interface Props extends AriaTextFieldOptions<"textarea">, FieldFormHeaderProps {
  description?: string;
  errorMessage?: string;
  value?: string;
  errorStyle?: string;
  className?: string;
  variant?: "default" | "large" | "small";
}

export const TextArea = ({ variant = "default", ...props }: Props) => {
  const { label, errorMessage, description, errorStyle, className, ...formHeaderProps } = props;
  const ref = useRef(null);
  const { labelProps, inputProps, errorMessageProps } = useTextField(props, ref);

  return (
    <FormFieldWrapper
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      errorStyle={errorStyle}
    >
      <FieldFormHeader {...formHeaderProps} label={label} labelProps={labelProps} />
      <div className="w-full">
        <textarea 
          className={classNames("input", className, {
            "py-2": variant === "small",
            "py-7": variant === "large"
          })}
          {...inputProps}
          ref={ref}
          placeholder={description}
        />
      </div>
    </FormFieldWrapper>
  );
};