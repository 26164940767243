import { Button, type FlyoutContentChildren, type SelectItem } from "@/components";
import { type SkillCategory } from "@/features/filter/types/common";
import { useSkillFlyoutState } from "@/features/skill/hooks/useSkillFlyoutState";
import { type SkillFlyoutDto } from "@/features/skill/types/skillTypes";
import { getSkillsRatingsWithErrors } from "@/features/skill/utils/skills.utils";
import { useUpdateCustomSkillMutation } from "@/features/skills/hooks/useUpdateCustomSkillMutation";
import { addSkillSchema, type CustomSkillFormState } from "@/features/skills/types/common";
import { toMetadataCustomDescriptions } from "@/features/skills/utils/customSkills";
import { useTranslation } from "@/hooks/useTranslation";
import { useAuth } from "@/lib/auth/AuthProvider";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { Flex, Grid, Heading, minmax, repeat } from "@workleap/orbiter-ui";
import { isEmpty } from "lodash";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type OverlayTriggerState } from "react-stately";
import { DeleteSkillConfirmationModal } from "./DeleteSkillConfirmationModal";
import { SkillFlyoutFormConfirmationModal } from "./SkillFlyoutFormConfirmationModal";
import { SkillFlyoutFormDescriptions } from "./SkillFlyoutFormDescriptions";
import { SkillFlyoutFormDetails } from "./SkillFlyoutFormDetails";
import { SkillFlyoutFormSimilarSkills } from "./SkillFlyoutFormSimilarSkills";

interface Props {
  customSkillId: string;
  skill: SkillFlyoutDto;
  overlayState: OverlayTriggerState;
  children: FlyoutContentChildren;
}

export const SkillFlyoutEditModeCustom = ({ overlayState, skill, customSkillId, children }: Props) => {
  const {
    closeFlyout,
    setIsDirty,
    forceCloseFlyout,
    confirmationOverlayState,
    deleteOverlayState
  } = useSkillFlyoutState(overlayState);
  const { t } = useTranslation();
  const { handleSubmit, formState: { errors, isDirty, isSubmitting }, watch, setValue } = useForm<CustomSkillFormState>({
    defaultValues: { ...skill, name: skill.skillName, category: skill.skillCategory },
    resolver: valibotResolver(addSkillSchema)
  });
  const { currentUserHasPermission } = useAuth();

  const { mutate, isPending } = useUpdateCustomSkillMutation(customSkillId, forceCloseFlyout);

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty]);

  const onSubmitForm = (formData: CustomSkillFormState) => {
    mutate({ ...formData, ratingsDescriptions: toMetadataCustomDescriptions(formData.ratingsDescriptions) });
  };

  const onSkillTypeSelectionChange = (selected?: SelectItem<SkillCategory>) => {
    if (selected?.id) {
      setValue("category", selected?.id, { shouldDirty: true });
    }
  };

  const ratingsWithErrors = useMemo(() => getSkillsRatingsWithErrors(watch("ratingsDescriptions")), [isSubmitting]);

  return (
    <>
      {children({
        header: <Heading size="xl">{isEmpty(watch("name")) ? skill.skillName : watch("name")}</Heading>,
        content: (
          <Flex gap="32px" direction="column">
            <SkillFlyoutFormDetails
              skillName={watch("name")}
              category={watch("category")}
              errorMessage={errors?.name?.message}
              onChangeName={value => setValue("name", value, { shouldDirty: true })}
              onChangeCategory={onSkillTypeSelectionChange}
            />

            <SkillFlyoutFormDescriptions
              ratingsWithErrors={ratingsWithErrors}
              skillName={watch("name")}
              ratingsDescription={watch("ratingsDescriptions")}
              onChangeRatingDescription={ratingsDescription => setValue("ratingsDescriptions", ratingsDescription, { shouldDirty: true })}
              errorMessage={errors?.ratingsDescriptions?.message}
            />

            <SkillFlyoutFormSimilarSkills
              skillName={watch("name")}
              similarSkills={watch("similarSkills")}
              onSelectSimilarSkill={item => setValue("similarSkills", [...watch("similarSkills"), item], { shouldDirty: true })}
              onRemoveSimilarSkill={item => setValue("similarSkills", watch("similarSkills").filter(x => x.name !== item.name), { shouldDirty: true })}
            />
          </Flex>
        ),
        footer: (
          <Flex alignItems="center" justifyContent="space-between">
            {currentUserHasPermission("Permissions.Skills.DeleteSkill") && (
              <Button className="min-w-[140px]" variant="danger" onClick={deleteOverlayState.open}>
                {t("common.actions.delete")}
              </Button>
            )}
            <Grid gap="inline-md" templateColumns={[repeat(2, minmax("140px", "min-content"))]}>
              <Button variant="secondary" onClick={closeFlyout} fluid>{t("common.form.cancel")}</Button>
              <Button type="submit" onClick={handleSubmit(onSubmitForm)} fluid isDisabled={!isDirty} loading={isPending}>{t("common.form.save")}</Button>
            </Grid>
          </Flex>
        ),
        modals: (
          <>
            <SkillFlyoutFormConfirmationModal overlayState={confirmationOverlayState} onClickConfirm={forceCloseFlyout} />
            <DeleteSkillConfirmationModal
              skillName={skill.skillName}
              overlayState={deleteOverlayState}
              onDeleteSuccess={forceCloseFlyout}
            />
          </>
        )
      })}
    </>
  );
};