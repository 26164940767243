import { type RoleDto } from "@/features/roles/types/common";
import { type Model } from "@/features/types/clientTypes";
import api from "@/lib/http/api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useDebounce } from "use-hooks";

type SearchRoleThenJobQueryDto = Model<"Search.Queries.SearchRoleThenJobQuery.SearchRoleThenJobDto">;


export interface SearchRoleThenJobResponseItem extends RoleDto {
  position: number;
}

export const useSearchRoleThenJob = () => {
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce<string>(query, 300);

  const { data, fetchStatus } = useQuery({
    queryKey: ["SearchRoleThenJob", debouncedQuery],
    queryFn: async () => {
      const res = await api.get<SearchRoleThenJobQueryDto>("search/roles-jobs", {
        params: {
          search: debouncedQuery
        }
      });

      return res.data.roles.map((x, position) => ({ ...x, position }));
    },
    enabled: !!debouncedQuery,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false
  });

  return {
    data,
    setQuery,
    query,
    fetchStatus
  };
};
