import { Card, Switch, Text, VStack, useProgressiveLoading } from "@/components";
import { SparklesIcon } from "@hopper-ui/icons";
import { useGetCustomSkillSettings } from "@/features/skills/hooks/useGetCustomSkillSettings";
import { useTranslation } from "@/hooks/useTranslation";
import { useUpdateCustomSkillSettingsMutation } from "@/features/skills/hooks/useUpdateCustomSkillSettingsMutation";

export const GenerateSkillsDescriptionAutomaticallyBanner = () => {
  const { t } = useTranslation();
  const { data: customSkillSettings, isLoading: isGetLoading } = useGetCustomSkillSettings();
  const { showLoading } = useProgressiveLoading({ isLoading: isGetLoading });
  const { mutate } = useUpdateCustomSkillSettingsMutation();

  const onToggleChange = (selected: boolean) => {
    mutate({ isCustomDescriptionsAutoGenerationEnabled: selected });
  };

  return (
    <Card className="!py-4 !px-6 text-primary-text !border-0 ring-2 ring-primary-surface-strong">
      <div className="flex items-center gap-4">
        <SparklesIcon size="lg" />
        <VStack gap={0} className="flex-1">
          <Text color="inherit">
            {t("skills.customDescriptions.generateSkillsBanner.title")}
          </Text>
          <Text size="xs">
            {t("skills.customDescriptions.generateSkillsBanner.description")}
          </Text>
        </VStack>
        {!showLoading && (
          <Switch
            aria-label={t("skills.customDescriptions.generateSkillsBanner.ariaLabel")}
            isSelected={customSkillSettings?.isCustomDescriptionsAutoGenerationEnabled} 
            onChange={onToggleChange}
          />
        )}
      </div>
    </Card>
  );
};