import { Heading, Text, VStack } from "@/components";

interface Props {
  title: string;
  subtitle?: string;
}

export const PageHeader = ({ title, subtitle }: Props) => (
  <VStack gap={1.5}>
    <Heading size="3xl" className="text-status-neutral-text">
      {title}
    </Heading>
    {subtitle && <Text>{subtitle}</Text>}
  </VStack>
);