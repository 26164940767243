import { AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { useTrackAtsPositionAction } from "@/features/ats/hooks/useTrackAtsPositionAction";
import { type AtsOpenJobDto } from "@/features/ats/types/common";
import { UpdateJobOpeningFlyout } from "@/features/jobs/components/update-job-opening/UpdateJobOpeningFlyout";
import { type UpdateJobOpening } from "@/features/jobs/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { Button } from "@workleap/orbiter-ui";
import { useOverlayTriggerState } from "react-stately";
import { AtsImportJobOpeningFlyout } from "./AtsImportJobOpeningFlyout";

interface Props {
  job: AtsOpenJobDto;
}

export const AtsOpenPositionActions = ({ job }: Props) => {
  if (job.isSynced) {
    return <AtsUpdateJobOpeningAction job={job} />;
  }

  return <AtsImportJobOpeningAction job={job} />;
};

const AtsImportJobOpeningAction = ({ job }: Props) => {
  const jobOpeningFlyout = useOverlayTriggerState({});
  const { t } = useTranslation();
  const { trackAtsPositionAction } = useTrackAtsPositionAction();

  const onClick = () => {
    trackAtsPositionAction(AnalyticValues.actionFixErrors);
    jobOpeningFlyout.open();
  };

  return (
    <>
      <Button onClick={onClick}>{t("ats.openPositions.actions.link")}</Button>
      <AtsImportJobOpeningFlyout overlayState={jobOpeningFlyout} job={job} />
    </>
  );
};

const AtsUpdateJobOpeningAction = ({ job }: Props) => {
  const { t } = useTranslation();
  const { trackAtsPositionAction } = useTrackAtsPositionAction();
  const jobOpeningFlyout = useOverlayTriggerState({});

  const onClick = () => {
    trackAtsPositionAction(AnalyticValues.actionEdit);
    jobOpeningFlyout.open();
  };

  const { jobId, roleId, roleName, hiringManager } = job;
  const jobOpening: UpdateJobOpening = {
    ...job,
    id: jobId!,
    roleId: roleId!,
    roleName: roleName!,
    hiringManagerId: hiringManager?.id,
    hiringManagerFirstName: hiringManager?.firstName,
    hiringManagerLastName: hiringManager?.lastName
  };

  return (
    <>
      <Button variant="secondary" onClick={onClick}>{t("ats.openPositions.actions.edit")}</Button>
      <UpdateJobOpeningFlyout overlayState={jobOpeningFlyout} jobOpening={jobOpening} />
    </>
  );
};