import { useTranslation } from "@/hooks/useTranslation";
import { Tag, Text } from "@workleap/orbiter-ui";

export const CurrentRoleChip = () => {
  const { t } = useTranslation();

  return (
    <Tag 
      size="sm"
      border="none"
      style={{
        backgroundColor: "var(--hop-status-option3-surface)",
        color: "var(--hop-status-option3-text)",
        padding: "2px var(--hop-space-inset-sm)",
        minHeight: "0px"
      }}
    >
      <Text size="xs">{t("employee.employment.currentRole")}</Text>
    </Tag>
  );
};