import { BillingPageContent } from "./BillingPageContent";
import { Heading, Text, VStack } from "@/components";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "@/hooks/useTranslation";

export const BillingPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("pages.settingsBilling")}</title>
      </Helmet>
      <VStack gap={3}>
        <VStack gap={1}>
          <Heading size="xl">
            {t("settings.billing.title")}
          </Heading>
          <Text>{t("settings.billing.description")}</Text>
        </VStack>
        <BillingPageContent />
      </VStack>
    </>
  );
};