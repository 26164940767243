import { type SearchResultItem } from "@/features/search/types/common";
import { type Model } from "@/features/types/clientTypes";
import { type Feature } from "@/lib/features/types/feature";
import { fromUnion } from "@/utils/types";
import { type ReactElement, type ReactNode } from "react";

export interface ListFilterItem<T = string> {
  name: string;
  id: T;
}

export interface FiltersFormProps<T> {
  values: T;
  onChange: (newValues: Partial<T>) => void;
  hideDefaultFilters?: boolean;
  hideDraftRoleStatusFilterOption?: boolean;
}

export const filtersByIds: string[] = [
  "employeeIds",
  "managerIds",
  "roleIds",
  "departmentIds",
  "seniorityLevels",
  "genders",
  "ethnicities",
  "teamIds",
  "segmentIds"
];

export const listFilterKeys = [
  "employeeIds", 
  "managerIds", 
  "departmentIds", 
  "genders", 
  "ethnicities", 
  "roleIds", 
  "skillsNames", 
  "skillsCategories",
  "seniorityLevels",
  "roleStatuses",
  "teamIds",
  "segmentIds"
] as const;

export const dateFilterKeys = [
  "birthdayFrom",
  "birthdayTo",
  "fromHireDate",
  "toHireDate"
] as const;

export const initialFilterKeys = [
  ...listFilterKeys,
  ...dateFilterKeys
] as const;

export type ListFilterQueryParamKeys = typeof listFilterKeys[number];
export type DateFilterQueryParamKeys = typeof dateFilterKeys[number];

export type InitialFilterQueryParamKeys = typeof initialFilterKeys[number];

export type InitialFilters = {
  [key in ListFilterQueryParamKeys]: ListFilterItem[];
} & {
  [key in DateFilterQueryParamKeys]: Date
};

export interface AutocompleteField {
  field: InitialFilterQueryParamKeys;
  label: ReactNode;
  blockedFeature?: Feature;
  itemDisplay?: (item: string) => ReactElement;
  autoCompleteProps: {
    fetchItemsQuery: (search: string) => Promise<SearchResultItem[]>;
    selectedItems: ListFilterItem[];
  };
}

export interface CustomField {
  id: string;
  content: ReactNode;
}

export type FilterField = AutocompleteField | CustomField;

export const {
  isOfTypeOne: isAutocompleteField,
  isOfTypeTwo: isCustomField
} = fromUnion<AutocompleteField, CustomField>();


export interface Section {
  name: string;
  fields: FilterField[];
  numberOfSelectedFilters: number;
}

export type SkillCategory = Model<"Skills.SkillCategory">;
export const skillsCategories: SkillCategory[] = ["Hard", "Soft"];