import { ConfirmationModal, useProgressiveLoading } from "@/components";
import { useGetSkillReferences } from "@/features/skill/hooks/useGetSkillReferences";
import { useTranslation } from "@/hooks/useTranslation";
import { Flex } from "@workleap/orbiter-ui";
import { noop } from "lodash";
import Skeleton from "react-loading-skeleton";
import { type OverlayTriggerState } from "react-stately";
import { NoImpactSkillDeletionModal } from "./NoImpactSkillDeletionModal";
import { SkillDeletionWithRiskModal } from "./SkillDeletionWithRiskModal";

interface Props {
  skillName: string;
  onDeleteSuccess?: () => void;
  overlayState: OverlayTriggerState;
}

const LoadingSkeleton = () => (
  <Flex direction="column" gap="stack-md">
    <Skeleton width={450} height={70} />
    <Skeleton width={450} height={120} />
  </Flex>
);

export const DeleteSkillConfirmationModal = (props: Props) => {
  const { overlayState, skillName } = props;
  const { t } = useTranslation();
  const { isFetching, data: skillReferencesData } = useGetSkillReferences(skillName, {
    enabled: overlayState.isOpen
  });
  const { showLoading } = useProgressiveLoading({ isLoading: isFetching, showAfterInitialLoading: true });

  if (!overlayState.isOpen) {
    return null;
  }

  if (showLoading) {
    return (
      <ConfirmationModal
        title={t("skills.delete.title")}
        onClickConfirm={noop}
        overlayState={overlayState}
        cancelButtonContent={t("common.form.cancel")}
        confirmButtonContent={t("skills.actions.delete")}
        confirmButtonProps={{ isDisabled: true }}
        cancelButtonProps={{ isDisabled: true }}
      >
        <LoadingSkeleton />
      </ConfirmationModal>
    );
  }

  if (!skillReferencesData) {
    return null;
  }

  if (skillReferencesData.totalActiveReferences === 0) {
    return (
      <NoImpactSkillDeletionModal {...props} />
    );
  }

  return (
    <SkillDeletionWithRiskModal
      {...props}
      skillReferencesData={skillReferencesData}
    />
  );
};