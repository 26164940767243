import { Card, CardHeader, HStack, IconText, VStack } from "@/components";
import { ConnectedHrisIntegration } from "./ConnectedHrisIntegration";
import { DisconnectHrisButton } from "@/features/hris/components/button/DisconnectHrisButton";
import { ReauthHrisButton } from "@/features/hris/components/button/ReauthHrisButton";
import { WarningIcon } from "@hopper-ui/icons";
import { useTranslation } from "@/hooks/useTranslation";

export const HrisUnauthenticatedSettings = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <VStack gap={6}>  
        <CardHeader title={t("hrisSettings.connect.title")} />
        <ConnectedHrisIntegration />
        <HStack align="center" gap={6}>
          <HStack gap={4}>
            <DisconnectHrisButton />
            <ReauthHrisButton />
          </HStack>
          <IconText icon={<WarningIcon size="lg" />} color="danger-icon">{t("hrisSettings.sync.authError")}</IconText>
        </HStack>
      </VStack>
    </Card>
  );
};