import { ValidationErrorResult } from "@/models/error";
import { isISODateFormat, parseOptionalDate } from "@/utils/dates";
import { AxiosError } from "axios";
import { create } from "middleware-axios";
import qs from "qs";
import { AuthMiddleware } from "./auth-middleware";
import { ErrorMiddleware } from "./error-middleware";

const api = create({
  baseURL: "/api",
  paramsSerializer: (params: unknown) => qs.stringify(params, { allowDots: true }),
  transformResponse: (data: string) => {
    if (!data) {
      return data;
    }

    return JSON.parse(data, (_, value) => !value || !isISODateFormat(value)
      ? value
      : parseOptionalDate(value));
  }
});

export const apiRaw = create({
  baseURL: "/api",
  paramsSerializer: (params: unknown) => qs.stringify(params, { allowDots: true })
});

api.use(AuthMiddleware);
api.use(ErrorMiddleware);

apiRaw.use(AuthMiddleware);
apiRaw.use(ErrorMiddleware);

export interface ApiResponseWithBody<TResult> {
  success: boolean;
  status: number;
  data?: TResult;
  error?: ValidationErrorResult;
}

export const apiPost = async <TResult>(url: string, data?: unknown) => {
  const response = {} as ApiResponseWithBody<TResult>;
  try {
    const res = await api.post(url, data || {});
    response.success = true;
    response.status = res.status;
    response.data = res.data;
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      if (e.response?.status === 400) {
        response.error = new ValidationErrorResult(e.response?.data?.errors);
      }
    }
  }

  return response;
};

export default api;