import { useGetRoleWatchers } from "@/features/roles/api/useGetRoleWatchers";
import { RoleEmployeeList } from "@/features/roles/components/role-employee-list/RoleEmployeeList";
import { useTranslation } from "@/hooks/useTranslation";
import { SendIcon } from "@hopper-ui/icons";
import { Flex, Text } from "@workleap/orbiter-ui";
import { isEmpty } from "lodash";
import { Suspense } from "react";
import Skeleton from "react-loading-skeleton";

interface Props {
  roleId: string;
}

export const JobOpeningFormWatchers = (props: Props) => {
  return (
    <Suspense fallback={<Skeleton height={48} />}>
      <JobOpeningFormWatchersContent {...props} />
    </Suspense>
  );
};

const JobOpeningFormWatchersContent = ({ roleId }: Props) => {
  const { data: watchers } = useGetRoleWatchers(roleId);
  
  const { t } = useTranslation();

  if (isEmpty(watchers)) {
    return null;
  }

  return (
    <Flex 
      backgroundColor="information"
      paddingX="inset-md"
      paddingY="inset-sm"
      gap="inline-md"
      alignItems="center"
      borderRadius="rounded-md"
      justifyContent="space-between"
    >
      <Flex gap="inline-md">
        <SendIcon color="information" />
        <Text color="information">{t("jobs.form.visibility.watchers.description", { count: watchers.length })}</Text>
      </Flex>
      {watchers && <RoleEmployeeList roleEmployees={watchers} count={10} />}
    </Flex>
  );
};