import { type CheckboxGroupState } from "react-stately";
import { createContext, useContext } from "react";

export const CheckboxGroupContext = createContext<CheckboxGroupState | null>(null);

export const useCheckboxGroupContext = () => {
  const context = useContext(CheckboxGroupContext);
  
  if (!context) {
    throw new Error("CheckboxGroup compound components cannot be rendered outside the CheckboxGroup component");
  }
  
  return context;
};
