import { DefaultLayoutContext } from "@/components/layouts/DefaultLayoutContext";
import { useAnalytics } from "@/features/analytics/hooks/useAnalytics";
import { useTranslation } from "@/hooks/useTranslation";
import { useAuth } from "@/lib/auth/AuthProvider";
import { useConfiguration } from "@/lib/core";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import i18n from "@/lib/i18n";
import { WorkleapHeader } from "@workleap-nav/react";
import { buildTrackingFunction } from "@workleap/tracking";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { NavigationMobileTopBar } from "./NavigationMobileTopBar";

interface Props {
  className?: string;
  hasNavigation?: boolean;
}

export const WorkleapNavigationBar = ({ className, hasNavigation }: Props) => {
  const mobileMenuState = useContext(DefaultLayoutContext);
  const { isMobileMenuOpen, toggleMobileMenu } = mobileMenuState;
  const { t } = useTranslation();
  const { currentUserHasPermission } = useAuth();
  const { logout } = useAnalytics();
  const {
    workleapNavigationApiUrl,
    workleapManagementAppUrl,
    workleapApiLogoutUrl,
    workleapFrontendLogoutUrl
  } = useConfiguration();
  const [searchBarSlotValue, setSearchBarSlotValue] = useState("header-center");
  const { hasFeature } = useFeatures();

  useEffect(() => {
    if (hasFeature("Features.WorkleapAiWidgets")) {
      const mediaQuery = window.matchMedia("(max-width: 767px)");

      const handleMediaQueryChange = (e: MediaQueryListEvent) => {
        setSearchBarSlotValue(e.matches ? "header-left" : "header-center");
      };

      // Initial check
      setSearchBarSlotValue(mediaQuery.matches ? "header-left" : "header-center");

      // Add listener
      mediaQuery.addEventListener("change", handleMediaQueryChange);

      // Cleanup listener on unmount
      return () => {
        mediaQuery.removeEventListener("change", handleMediaQueryChange);
      };
    }
  }, []);

  const fetchHeaderData = async() => {
    return axios
      .get(`${workleapNavigationApiUrl}/header/data`, { withCredentials: true })
      .then(response => response.data);
  };

  const onLogoutClick = async () => {
    logout();
    await axios.post(workleapApiLogoutUrl, {}, { withCredentials: true });
    window.location.assign(workleapFrontendLogoutUrl);
  };

  const handleOrganizationChange = async (selectedOrganizationId: string) => {
    await axios.post(`${workleapNavigationApiUrl}/header/setOrganization`, {
      selectedOrganizationId
    }, {
      withCredentials: true
    });

    await onLogoutClick();
  };

  const track = buildTrackingFunction("Skills", workleapNavigationApiUrl);

  return (
    <header className={className}>
      {isMobileMenuOpen && hasNavigation ? <NavigationMobileTopBar /> : null}
      <WorkleapHeader
        track={track}
        language={i18n.language}
        getData={fetchHeaderData}
        onOrganizationChange={handleOrganizationChange}
        title={t("app.name")}
        icon={{
          src: "/skills-logo.svg",
          alt: "Workleap Skills logo"
        }}
        hamburger={hasNavigation ? {
          onClick: toggleMobileMenu
        } : undefined}
        links={{
          help: {
            href: "https://help.skills.workleap.com/",
            target: "_blank"
          },
          profile: {
            href: `${workleapManagementAppUrl}/profile`,
            target: "_blank"
          },
          settings: currentUserHasPermission("Permissions.Settings.ViewPlatformSettings") ? {
            href: `${workleapManagementAppUrl}/settings`
          } : undefined,
          signOut: {
            onClick: onLogoutClick
          }
        }}
      >
        {hasFeature("Features.WorkleapAiWidgets") && (
          <div slot={searchBarSlotValue}>
            {/* @ts-expect-error: Allow custom element <wl-ai-search-bar> */}
            <wl-ai-search-bar></wl-ai-search-bar>
          </div>
        )}
      </WorkleapHeader>
    </header>
  );
};