import { useTranslation } from "@/hooks/useTranslation";
import { BoldIcon, ItalicIcon, OrderedListIcon, UnderlineIcon, UnorderedListIcon } from "@hopper-ui/icons";
import { type Editor as TipTapEditor } from "@tiptap/react";
import { Flex, IconButton, type ButtonVariant, type ResponsiveProp } from "@workleap/orbiter-ui";

export interface EditorToolbarProps {
  editor: TipTapEditor | null;
}

const commonButtonProps = {
  variant: "tertiary" as ButtonVariant,
  size: "sm" as ResponsiveProp<"sm" | "xs" | "md" | "2xs">
};

export const EditorToolbar = ({ editor }: EditorToolbarProps) => { 
  const { t } = useTranslation();

  if (!editor) {
    return null;
  }

  return (
    <Flex gap="inline-xs" paddingX="inset-sm" paddingY="inset-xs" borderBottom="neutral">
      <IconButton 
        onClick={() => editor.chain().focus().toggleBold().run()}
        aria-label={t("editor.bold")}
        title={t("editor.bold")}
        active={editor.isActive("bold")}
        {...commonButtonProps}
      >
        <BoldIcon />
      </IconButton>
      <IconButton
        onClick={() => editor.chain().focus().toggleItalic().run()}
        aria-label={t("editor.italic")}
        title={t("editor.italic")}
        active={editor.isActive("italic")}
        {...commonButtonProps}
      >
        <ItalicIcon />
      </IconButton>
      <IconButton
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        aria-label={t("editor.underline")}
        title={t("editor.underline")}
        active={editor.isActive("underline")}
        {...commonButtonProps}
      >
        <UnderlineIcon />
      </IconButton>
      <IconButton
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        aria-label={t("editor.orderedList")}
        title={t("editor.orderedList")}
        active={editor.isActive("orderedList")}
        {...commonButtonProps}
      >
        <OrderedListIcon />
      </IconButton>
      <IconButton
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        aria-label={t("editor.bulletList")}
        title={t("editor.bulletList")}
        active={editor.isActive("bulletList")}
        {...commonButtonProps}
      >
        <UnorderedListIcon />
      </IconButton>
    </Flex>
  );
};