export const useSupport = () => {
  const contactSupport = () => {
    if (window.zE) {
      window.zE?.("webWidget", "toggle");
    } else {
      window.open("mailto:support@skills.workleap.com", "_blank");
    }
  };

  return {
    contactSupport
  };
};