import { EmptyList, HStack, Heading, TooltipWrapper, VStack } from "@/components";
import { type SimilarSkillDto } from "@/features/skills/types/common";
import { useMarketContentTranslation, useTranslation } from "@/hooks/useTranslation";
import { removeTextBetweenParenthesis } from "@/lib/utils";
import { InfoIcon } from "@hopper-ui/icons";
import { Flex, Tag, Text } from "@workleap/orbiter-ui";
import { isEmpty } from "lodash";
import { SearchSimilarSkill } from "./SearchSimilarSkills";

interface ManageSimilarSkillsProps {
  onSelectSimilarSkill: (item: SimilarSkillDto) => void;
  onChangeSimilarSkills: (newSkills: SimilarSkillDto[]) => void;
  similarSkills: SimilarSkillDto[];
  currentSkillName: string;
}

export const ManageSimilarSkills = ({ currentSkillName, onSelectSimilarSkill, onChangeSimilarSkills, similarSkills }: ManageSimilarSkillsProps) => {
  const { t } = useTranslation();
  
  return (
    <VStack gap={6}>
      <VStack gap={3}>
        <HStack align="center">
          <Heading size="xl">{t("similarSkills.manage.title")}</Heading>
          <TooltipWrapper tooltip={t("similarSkills.manage.tooltip")} anchorSelector="form-help-icon">
            <InfoIcon className="text-neutral-text-weak" />
          </TooltipWrapper>
        </HStack>
        <SearchSimilarSkill
          currentSkillName={currentSkillName}
          selectedSkills={similarSkills}
          onSelect={onSelectSimilarSkill}
        />
      </VStack>
      <SimilarSkillListing skills={similarSkills} onChangeSimilarSkills={onChangeSimilarSkills} />
    </VStack>
  );
};

interface SimilarSkillListingProps {
  skills: SimilarSkillDto[];
  onChangeSimilarSkills: (newSkills: SimilarSkillDto[]) => void;
}

const SimilarSkillListing = ({ skills, onChangeSimilarSkills }: SimilarSkillListingProps) => {
  const { t } = useTranslation();
  const { translateMarketContent } = useMarketContentTranslation();

  if (isEmpty(skills)) {
    return (
      <EmptyList
        title={t("similarSkills.emptyState.title")}
        description={t("similarSkills.emptyState.description")}
        variant="secondary"
        showImage
      />
    );
  }

  const onRemoveSkill = (skillName: string) => {
    onChangeSimilarSkills(skills.filter(({ name }) => skillName !== name));
  };

  return (
    <Flex wrap="wrap" gap="inline-sm">
      {skills.sort().map(skill => (
        <Tag 
          key={skill.name}
          paddingY="6px"
          paddingX="10px"
          borderRadius="rounded-md"
          border="none"
          backgroundColor="decorative-option5-weakest"
          onRemove={() => onRemoveSkill(skill.name)}
        >
          <Text size="sm" color="information" whiteSpace="nowrap" paddingX="inset-xs">
            {removeTextBetweenParenthesis(translateMarketContent(skill.name))}
          </Text>
        </Tag>
      ))}
    </Flex>
  );
};