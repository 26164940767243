import { createContext, useContext } from "react";

interface ProgressionPlanState {
  planId: string;
  hideCompletedActivities: boolean;
  setHideCompletedActivities: (hideCompleted: boolean) => void;
}

export const ProgressionPlanContext = createContext<ProgressionPlanState | null>(null);

export const useProgressionPlanContext = () => {
  const context = useContext(ProgressionPlanContext);
  
  if (!context) {
    throw new Error("Progression plan compound components cannot be rendered outside the ProgressionPlanContext component");
  }
  
  return context;
};
