import { type BulkImportResult } from "@/features/users/types/BulkImportResult";
import { Heading } from "@/components";
import { useTranslation } from "@/hooks/useTranslation";
import classNames from "classnames";

interface BulkImportSuccessProps {
  result: BulkImportResult;
}

interface ErrorListProps {
  errors: string[];
}

const CONTENT_CLASSES = "w-full p-3 rounded-lg";
const DETAILS_CLASSES = "mt-1 overflow-y-auto max-h-[160px]";

const ErrorList = ({ errors }: ErrorListProps) => (
  <ul>
    {errors.map(error => (<li key={error}>{error}</li>))}
  </ul>
);

export const BulkImportSuccess = ({ result }: BulkImportSuccessProps) => {
  const { t } = useTranslation();

  const { errors } = result;

  if (result.isSuccess) {
    return (
      <div className={classNames(CONTENT_CLASSES, "bg-decorative-option2-surface")}>
        <Heading size="xl">{t("users.bulkImport.successTitle")}</Heading>
        <div className={DETAILS_CLASSES}>
          {errors && (<ErrorList errors={errors} />)}
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(CONTENT_CLASSES, "bg-danger-icon-weak")}>
      <Heading size="xl">{t("users.bulkImport.errorTitle")}</Heading>
      <div className={DETAILS_CLASSES}>
        {errors && (<ErrorList errors={errors} />)}
        {!errors && (<ul><li>{t("users.bulkImport.errors.unexpected")}</li></ul>)}
      </div>
    </div>
  );
};