import { ModalDialog } from "@/components";
import { type NewUserFormData } from "@/features/users/types/common";
import { type ValidationErrorResult } from "@/models/error";
import { OverlayContainer } from "react-aria";
import { type OverlayTriggerState } from "react-stately";
import { InviteNewUserForm } from "./NewUserForm";

interface Props {
  title: string;
  overlayState: OverlayTriggerState;
  submitBtnLabel?: string;
  isSubmitting?: boolean;
  onSubmit: (formData: NewUserFormData) => void;
  validationErrors?: ValidationErrorResult;
}

export const CreateUserModal = ({
  overlayState,
  title,
  isSubmitting,
  submitBtnLabel,
  validationErrors,
  onSubmit
}: Props) => {
  if (!overlayState.isOpen) {
    return null;
  }

  return (
    <OverlayContainer>
      <ModalDialog
        title={title}
        isOpen
        onClose={overlayState.close}
        isDismissable
      >
        <InviteNewUserForm
          onCancel={() => overlayState.close()}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          submitBtnLabel={submitBtnLabel}
          validationErrors={validationErrors}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};