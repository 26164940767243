import { type ReportRequest } from "./common";
import { type SkillsVariationReportRequest } from "./skillsVariation";

const makeReportRequestKeys = ({ filters, page, pageSize, sortBy }: ReportRequest) =>
  [filters, page, pageSize, sortBy];

export const reportQueryKeys = {
  all: [{ scope: "reports" }] as const,
  employeeParticipation: (params: ReportRequest) => [{
    ...reportQueryKeys.all[0],
    entity: "employeeParticipation"
  }, ...makeReportRequestKeys(params)] as const,
  skillsGap: (params: ReportRequest) => [{
    ...reportQueryKeys.all[0],
    entity: "skillsGap"
  }, ...makeReportRequestKeys(params)] as const,
  skillsInventory: (params: ReportRequest) => [{
    ...reportQueryKeys.all[0],
    entity: "skillsInventory"
  }, ...makeReportRequestKeys(params)] as const,
  skillsVariation: (params: SkillsVariationReportRequest) => [{
    ...reportQueryKeys.all[0],
    entity: "skillsVariation"
  }, ...makeReportRequestKeys(params), params.granularity] as const
};