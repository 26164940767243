import { type TranslationKey } from "./types";
import { initReactI18next } from "react-i18next";
import errorsEN from "./locales/en/errors.json";
import errorsFR from "./locales/fr/errors.json";
import i18n, { type TOptions } from "i18next";
import translationCrowdin from "./locales/bal/translation.json";
import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";

const resources = {
  en: {
    translation: { ...translationEN, ...errorsEN }
  },
  fr: {
    translation: { ...translationFR, ...errorsFR }
  },
  bal: {
    // TODO PRT-2842: Add errors.json to CrowdIn
    translation: translationCrowdin
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",
    lng: "en",
    detection: {
      //  use two letter code
      convertDetectedLanguage: "Iso15897"
    },
    supportedLngs: ["en", "fr", "bal"],
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

// custom wrapper to i18n - this function must be named `translate`
// so that the i18n-next parser tool can use it while
// auto-generating the locales file
export const translate = (key: TranslationKey, options?: TOptions) =>
  i18n.t(key, options);

export default i18n;