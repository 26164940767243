import { PremiumBadge } from "@/features/billing/components/PremiumBadge";
import { type Feature } from "@/lib/features/types/feature";
import colors from "@/styles/colors";
import { useButton } from "@react-aria/button";
import { type AriaButtonProps } from "@react-types/button";
import { Div, Flex } from "@workleap/orbiter-ui";
import classNames from "classnames";
import { AnimatePresence } from "framer-motion";
import { useRef, type MouseEventHandler, type ReactElement, type RefObject } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import styles from "./Button.module.css";

export type ButtonVariant = "primary" | "secondary" | "upsell" | "danger";

const loadingColors: Record<ButtonVariant, string> = {
  "primary": colors["neutral-text-disabled"],
  "secondary": colors["neutral-text-disabled"],
  "upsell": colors["neutral-text-disabled"],
  "danger": colors["neutral-text-disabled"]
};

export interface ButtonProps extends AriaButtonProps<"button"> {
  variant?: ButtonVariant;
  fluid?: boolean;
  loading?: boolean;
  autoFocus?: boolean;
  dataTest?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: "button" | "submit" | "reset";
  icon?: ReactElement;
  forwardedRef?: RefObject<HTMLButtonElement> | null;
  className?: string;
  iconPosition?: "left" | "right";
  size?: "small" | "medium";
  blockedFeature?: Feature;
}

/**
 * @deprecated Use Button from @workleap/orbiter-ui instead
 */
export const Button = ({
  children,
  variant = "primary",
  size = "medium",
  icon,
  iconPosition = "left",
  fluid = false,
  isDisabled = false,
  loading = false,
  autoFocus = false,
  dataTest,
  type = "button",
  forwardedRef = null,
  className,
  onClick,
  blockedFeature,
  ...props
}: ButtonProps) => {
  const ref = useRef(null);
  const buttonRef = forwardedRef ?? ref;
  const { buttonProps } = useButton(props, ref);
  const isSmall = size === "small";

  const hasPremiumBadge = isDisabled && blockedFeature;

  const buttonClasses = classNames(
    styles.button, styles[`button--${variant}`], className, {
      [styles["button--fluid"]]: fluid,
      [styles["button--icon"]]: icon,
      [styles["button--small"]]: isSmall,
      [styles["button--loading"]]: loading
    });

  return (
    <button
      {...buttonProps}
      className={buttonClasses}
      disabled={isDisabled || loading}
      autoFocus={autoFocus}
      onClick={onClick}
      ref={buttonRef}
      type={type}
      data-test={dataTest}
      data-testid={dataTest}
    >
      <Flex alignItems="center" justifyContent="center" width="100%" gap="inline-xs">
        <Div flex={1} display="flex" alignItems="center" justifyContent="center" gap="inline-xs">
          <AnimatePresence>
            {!loading && iconPosition === "left" && icon}
            {loading && (
              <BeatLoader 
                loading={loading}
                size={isSmall
                  ? 5
                  : 7}
                color={loadingColors[variant]}
                aria-label="Loading Spinner"
                className={classNames({
                  "mr-2": iconPosition !== "left" || !icon
                })}
              />
            )}
          </AnimatePresence>
          {children}
          {iconPosition === "right" && icon}
        </Div>
        {hasPremiumBadge && (<PremiumBadge feature={blockedFeature} />)}
      </Flex>
    </button>
  );
};
