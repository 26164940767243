import { Routes as AppRoutes } from "@/types";
import { CustomSkillsRoutes } from "./custom-skills/CustomSkillsRoutes";
import { FeatureGuardRoute } from "@/pages/components/FeatureGuardRoute";
import { Navigate, Route, Routes } from "react-router-dom";
import { SkillCustomDescriptionsRoutes } from "./custom-descriptions/SkillsCustomDescriptionsRoutes";

export const SettingsSkillsRoutes = () => (
  <Routes>
    <Route path="custom-descriptions/*"
      element={(
        <FeatureGuardRoute redirectPath={AppRoutes.Settings} feature="Features.CustomizeSkills">
          <SkillCustomDescriptionsRoutes />
        </FeatureGuardRoute>
      )}
    />
    <Route path="custom-skills/*"
      element={(
        <FeatureGuardRoute redirectPath={AppRoutes.Settings} feature="Features.CustomizeSkills">
          <CustomSkillsRoutes />
        </FeatureGuardRoute>
      )}
    />
    <Route path="*" element={<Navigate to="." />} />
  </Routes>
);
