import { useProgressiveLoading } from "@/components";
import { type RoleEmployee } from "@/features/roles/types/role";
import { UserAvatarList, UserAvatarListLoading } from "@/features/users/components/UserAvatarList";
import { useTranslation } from "@/hooks/useTranslation";
import { Inline, Text } from "@workleap/orbiter-ui";
import { isEmpty } from "lodash";

interface EmployeeListProps {
  roleEmployees: RoleEmployee[];
  count: number;
  isLoading?: boolean;
  notShownAvatarsDisplayVariant?: "counter-avatar" | "label";
}

export const RoleEmployeeList = ({
  roleEmployees,
  count,
  isLoading = false,
  notShownAvatarsDisplayVariant = "counter-avatar"
}: EmployeeListProps) => {
  const { showLoading } = useProgressiveLoading({ isLoading });
  const { t } = useTranslation();

  if (showLoading) {
    return <UserAvatarListLoading />;
  }

  if (notShownAvatarsDisplayVariant === "counter-avatar") {
    return <UserAvatarList userAvatars={roleEmployees} count={count} />;
  }

  const hasNonVisibleAvatars = roleEmployees.length > count;
  const avatarsLabel = hasNonVisibleAvatars
    ? t("role.employeeList.additionalEmployeesInRole", { count: roleEmployees.length - count })
    : t("role.employeeList.inThisRole", { count: roleEmployees.length });

  return (
    <Inline gap="inline-sm" alignY="center">
      <UserAvatarList
        userAvatars={roleEmployees}
        count={count}
        hideNumberOfUserAvatarsNotShown
        shiftLeft
      />
      {!isEmpty(roleEmployees) && <Text color="neutral-weak" size="sm">{avatarsLabel}</Text>}
    </Inline>
  );
};