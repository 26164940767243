import { Select, type SelectInputProps, type SelectItem } from "@/components";
import { type SkillCategory } from "@/features/skills/types/common";
import { useTranslation } from "@/hooks/useTranslation";

interface Props extends Omit<SelectInputProps<SkillCategory>, "items" | "value"> {
  value?: SkillCategory;
}

export const SelectSkillType = ({ value, ...otherProps }: Props) => {
  const { t } = useTranslation();
  const selectItems: SelectItem<SkillCategory>[] = [
    { id: "Hard", text: t("skills.types.hard") },
    { id: "Soft", text: t("skills.types.soft") },
    { id: "Certification", text: t("skills.types.certification") }
  ];

  return (
    <Select
      value={value
        ? selectItems.find(({ id }) => id === value)
        : undefined}
      items={selectItems}
      {...otherProps}
    />
  );
};