import { useTranslation } from "@/hooks/useTranslation";
import { useCurrentUser } from "@/lib/auth/AuthProvider";

export const useSkillFlyoutFormDescriptionsNote = () => {
  const { language: userLanguage, tenant: { language } } = useCurrentUser();
  const { t } = useTranslation();

  return {
    note: userLanguage !== language ? t("skills.form.description.note") : null
  };
};