import { Button, Card, CardHeader, Editor, FormFieldWrapper, PageHeader, TextField, UnsavedChangesModal, VStack, useEditor } from "@/components";
import { FormLayout } from "@/components/layouts/FormLayout";
import { SkillRatingContent } from "@/features/skill-rating/components/SkillRatingContent";
import { type SkillRatingForm } from "@/features/skill-rating/types/skillRatingForm";
import { useCreateTrainingMutation } from "@/features/training/api/useCreateTrainingMutation";
import { useUpdateTrainingMutation } from "@/features/training/api/useUpdateTrainingMutation";
import { type RichContent } from "@/features/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { FormProvider, useForm } from "react-hook-form";

interface FormModel extends SkillRatingForm {
  title: string;
  link?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  description?: any;
}

interface Props {
  id?: string;
  defaultValues?: FormModel;
}

export const TrainingForm = ({ id, defaultValues }: Props) => {
  const { t } = useTranslation();
  const form = useForm<FormModel>({ defaultValues });
  const { getValues, setValue, watch, handleSubmit, formState: { isDirty, isSubmitted } } = form;
  const { mutate: createTraining, validationErrors: createValidationErrors, isPending: createLoading } = useCreateTrainingMutation();
  const { mutate: updateTraining, validationErrors: updateValidationErrors, isPending: updateLoading } = useUpdateTrainingMutation();

  const isCreating = !id;

  const editor = useEditor({
    onChange: (value: RichContent) => setValue("description", value, { shouldDirty: true }), 
    content: getValues("description"),
    editable: true,
    placeholder: t("settings.training.form.description.descriptionPlaceholder")
  });

  const submitHandler = (formData: FormModel) => {
    if (formData.link && !formData.link.match(/^https?:\/\//)) {
      formData.link = `https://${formData.link}`;
      setValue("link", formData.link);
    }

    if (isCreating) {
      createTraining(formData);

      return;
    }

    updateTraining({ id, ...formData });
  };

  const commandErrors = createValidationErrors || updateValidationErrors;
  const isSaving = createLoading || updateLoading;

  const title = isCreating ? t("settings.training.heading.addTraining") : t("settings.training.heading.editTraining");

  return (
    <FormLayout onSubmit={handleSubmit(submitHandler)}>
      <FormLayout.Content>
        <VStack gap={4}>
          <PageHeader title={title} />
          <Card>
            <VStack gap={4}>
              <CardHeader title={t("settings.training.form.description.title")} subtitle={t("settings.training.form.description.subtitle")} />
              <TextField
                label={t("settings.training.form.description.titleLabel")}
                value={watch("title")}
                onChange={value => setValue("title", value, { shouldDirty: true })}
                errorMessage={commandErrors?.field("Title")}
                isRequired
              />
              <TextField
                label={t("settings.training.form.description.linkLabel")}
                value={watch("link")}
                onChange={value => setValue("link", value, { shouldDirty: true })}
                errorMessage={commandErrors?.field("Link")}
                description={t("settings.training.form.description.linkPlaceholder")}
              />
              <Editor 
                label={t("settings.training.form.description.descriptionLabel")}
                hasToolbar 
                editor={editor}
              />
            </VStack>
          </Card>
          <Card>
            <VStack gap={4}>
              <FormFieldWrapper errorMessage={commandErrors?.field("Skills")}>
                <CardHeader title={t("settings.training.form.skills.title")} subtitle={t("settings.training.form.skills.subtitle")} />
              </FormFieldWrapper>
              <FormProvider {...form}>
                <SkillRatingContent />
              </FormProvider>
            </VStack>
          </Card>
        </VStack>
        <UnsavedChangesModal when={isDirty && !isSubmitted && !isSaving} />
      </FormLayout.Content>
      <FormLayout.Footer>
        <div className="flex items-center justify-end w-full h-full">
          <Button type="submit">
            {t("common.form.save")}
          </Button>
        </div>
      </FormLayout.Footer>
    </FormLayout>
  );
};