import { type Ethnicity, type Gender } from "@/features/employees/types/common";
import { type SearchRequest, type SearchResponse, type SearchRoleSkillsRequest } from "@/features/search/types/common";
import api from "@/lib/http/api";

export const searchEmployees = async (request: SearchRequest) =>
  await api.get<SearchResponse>("/search/employees", {
    params: request
  });
  
export const getEmployeesNamesByIds = async (ids: string[]) =>
  await api.get<SearchResponse>("/search/employees-names", {
    params: { ids }
  });

export const searchRolesSkills = async (request: SearchRoleSkillsRequest) =>
  await api.get<SearchResponse>("/search/roles-skills", {
    params: request
  });

export const searchRoles = async (request: SearchRequest & { enforceCollaboratorAccess?: boolean }) =>
  await api.get<SearchResponse>("/search/roles", {
    params: request
  });
  
export const getRolesNamesByIds = async (ids: string[]) =>
  await api.get<SearchResponse>("/search/roles-names", {
    params: { ids }
  });

export const searchDepartments = async (request: SearchRequest) =>
  await api.get<SearchResponse>("/search/departments", {
    params: request
  });

export const getDepartmentNamesByIds = async (ids: string[]) =>
  await api.get<SearchResponse>("/search/departments-names", {
    params: { ids }
  });

export const searchGenders = async (request: SearchRequest) =>
  await api.get<SearchResponse<Gender>>("/search/genders", {
    params: request
  });

export const searchEthnicities = async (request: SearchRequest) =>
  await api.get<SearchResponse<Ethnicity>>("/search/ethnicities", {
    params: request
  });