import { directoryQueryKeys } from "@/features/directory/types/directoryQueryKeys";
import { type AddCustomSkill, type AddCustomSkillResult } from "@/features/skills/types/common";
import { skillsQueryKeys } from "@/features/skills/types/skillsQueryKeys";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import api from "@/lib/http/api";
import { translate } from "@/lib/i18n";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useAddCustomSkillMutation = (onSuccess?: (responseData: AddCustomSkillResult["data"]) => void) => {
  const queryClient = useQueryClient();

  return useMutationWithValidationResult<AddCustomSkill, AddCustomSkillResult["data"]>({
    mutationFn: async (requestPayload : AddCustomSkill) => {
      const response = await api.post<AddCustomSkillResult["data"]>("/skills/custom-skills", requestPayload);
      
      return response.data;
    },
    onError: validationErrors => {
      const nameError = validationErrors.field("Name");
      const categoryError = validationErrors.field("Category");

      if (nameError || categoryError) {
        toast.error(nameError || categoryError);
      }
    },
    onSuccess: async responseData => {
      await queryClient.invalidateQueries({ queryKey: skillsQueryKeys.all });
      await queryClient.invalidateQueries({ queryKey: directoryQueryKeys.all });

      toast.success(translate("skills.customSkills.createSuccessMessage"));

      onSuccess?.(responseData);
    }
  });
};