import { CautionRichIcon, InfoRichIcon, SuccessRichIcon, UpsellRichIcon } from "@hopper-ui/icons";
import { Div, Flex, type DivProps, type FlexProps } from "@workleap/orbiter-ui";
import React, { type ReactNode } from "react";
// import { ReactComponent as SuccessIcon } from "./assets/callout-success-icon.svg";
// import { ReactComponent as UpsellIcon } from "./assets/callout-upsell-icon.svg";
// import { ReactComponent as WarningIcon } from "./assets/callout-warning-icon.svg";

export interface CalloutProps extends DivProps {
  type: "info" | "warning" | "upsell" | "success";
  title?: string;
  content: string;
  action?: React.ReactNode;
  variant?: "callout" | "inline" | "compact";
}

const infoStyle: Partial<FlexProps> = {
  border: "0.0625rem solid var(--hop-information-border)",
  color: "var(--hop-information-text)",
  backgroundColor: "var(--hop-information-surface)"
};

const warningStyle: Partial<FlexProps> = {
  border: "0.0625rem solid var(--hop-warning-border)",
  color: "var(--hop-warning-text)",
  backgroundColor: "var(--hop-warning-surface)"
};

const upsellStyle: Partial<FlexProps> = {
  border: "0.0625rem solid var(--hop-upsell-border)",
  color: "var(--hop-upsell-text)",
  backgroundColor: "upsell-weak"
};

const successStyle: Partial<FlexProps> = {
  border: "0.0625rem solid var(--hop-success-border)",
  color: "var(--hop-success-text)",
  backgroundColor: "var(--hop-success-surface)"
};

export const Callout = ({ type, title, content, action, variant, ...rest } : CalloutProps) => {
  const styleVariant = variant ?? "callout";
  let icon: ReactNode;
  let style: Partial<FlexProps>;

  switch (type) {
    case "info":
      icon = <InfoRichIcon variant="option1" overflow="visible" aria-label="" display={{ base: "none", sm: "block" }} />;
      style = infoStyle;
      break;
    case "warning":
      icon = <CautionRichIcon variant="option3" overflow="visible" aria-label="" display={{ base: "none", sm: "block" }} />;
      style = warningStyle;
      break;
    case "upsell":
      icon = <UpsellRichIcon variant="option6" overflow="visible" aria-label="" display={{ base: "none", sm: "block" }} />;
      style = upsellStyle;
      break;
    case "success":
      icon = <SuccessRichIcon variant="option2" overflow="visible" aria-label="" display={{ base: "none", sm: "block" }} />;
      style = successStyle;
      break;
  }

  return (
    <Flex padding="inset-md" gap="inline-md" borderRadius="rounded-md" border={styleVariant !== "callout" ? undefined : style.border} color={style.color} backgroundColor={style.backgroundColor} {...rest}>
      {styleVariant !== "compact" && icon}
      <Div>
        {title && (
          <Div fontSize="body-sm" fontWeight="body-sm-semibold" marginBottom="stack-xs" lineHeight="var(--hop-body-sm-semibold-line-height)">
            {title}
          </Div>
        )}
        <Div fontSize="body-sm" marginBottom="stack-xs" lineHeight="var(--hop-body-sm-line-height)">
          {content}
        </Div>
        {action && (
          <Div marginTop="stack-md">
            {action}
          </Div>
        )}
      </Div>
    </Flex>
  );
};
