import { Text } from "@/components";
import { useTranslation } from "@/hooks/useTranslation";
import { useCurrentUser } from "@/lib/auth/AuthProvider";

export const DemoBanner = () => {
  const { t } = useTranslation();

  const { isDemo } = useCurrentUser();

  if (!isDemo) {
    return null;
  }

  return (
    <div className="relative z-40 [grid-area:demo] pointer-events-none">
      <div className="absolute inset-0 flex justify-center border-t-2 border-t-danger-icon">
        <div className="relative h-6 px-4 rounded-b-lg bg-danger-icon">
          <Text size="xs" color="primary-text-strong">{t("demo.banner.text")}</Text>
        </div>
      </div>
    </div>
  );
};