import { ActivationQueryKeys } from "@/features/activation/types/actionsQueryKeys";
import { type GetMemberSkillActivationStateQueryDto } from "@/features/activation/types/common";
import api from "@/lib/http/api";
import { useSuspenseQuery } from "@tanstack/react-query";

export const useGetMemberActivationStatusSuspenseQuery = () => {
  return useSuspenseQuery<GetMemberSkillActivationStateQueryDto>({
    queryKey: ActivationQueryKeys.ActivationGuard,
    queryFn: async () => {
      const res = await api.get("/workleap/members/getMemberActivationStatus");

      return res.data;
    }
  });
};