import { DefaultLayoutContext } from "@/components/layouts/DefaultLayoutContext";
import { useTranslation } from "@/hooks/useTranslation";
import { CrossButton, Flex, Text } from "@workleap/orbiter-ui";
import { useContext } from "react";

export const NavigationMobileTopBar = () => {
  const { t } = useTranslation();
  const mobileMenuState = useContext(DefaultLayoutContext);
  const { closeMobileMenu } = mobileMenuState;

  return (
    <Flex alignItems="center" justifyContent="space-between" paddingX="inset-md" className="absolute top-0 left-0 w-full h-[71px] bg-[white] z-30 md:!hidden">
      <Flex className="transform-gpu opacity-0 animate-[revealLeftToRight_0.7s_0s_forwards]" alignItems="center" gap="inline-md">
        <img src="/skills-logo.svg" alt={t("app.name")} />
        <Text fontWeight={410} color="neutral" fontSize="1.25rem" fontFamily="var(--hop-font-family-primary)" letterSpacing="-0.05rem" lineHeight="1.2">{t("app.name")}</Text>
      </Flex>
      <CrossButton color="neutral-weak" aria-label={t("navigation.closeMenu")} onClick={closeMobileMenu} />
    </Flex>
  );
};