import { motion } from "framer-motion";
import colors from "@/styles/colors";

interface Props {
  width?: number;
  height?: number;
  strokeWidth?: number;
  stroke?: string;
}

export const LogoAnimation = ({ width = 120, height = 97, strokeWidth = 16, stroke = colors["primary-surface-strong"] }: Props) => (
  <motion.svg width={width} height={height} viewBox="0 0 120 97" fill="none">
    <motion.path
      initial={{ pathLength: 0 }}
      animate={{ pathLength: 1 }}
      transition={{
        duration: 1.5,
        repeat: Infinity,
        repeatType: "reverse"
      }}
      // eslint-disable-next-line max-len
      d="M24.42 11.66C14.43 19.44 8 31.58 8 45.22C8 68.69 27.03 87.72 50.5 87.72C90.3726 87.72 107.264 35.5564 76.58 11.66C64 3.05 48.5 10.55 48.5 24.69C48.5 55.183 88.0125 69.9863 108 48"
      stroke={stroke}
      strokeWidth={`${strokeWidth}`}
    />  
  </motion.svg>
);
