import { type AddDepartmentCommandDto, type UpdateDepartmentCommandDto } from "@/features/departments/types/common";
import { Button, ButtonGroup, TextField, VStack } from "@/components";
import { type UseFormReturn } from "react-hook-form";
import { type UseMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import { useTranslation } from "@/hooks/useTranslation";

type DepartmentFormData = AddDepartmentCommandDto | UpdateDepartmentCommandDto;

type Props = UseFormReturn<DepartmentFormData> & UseMutationWithValidationResult<DepartmentFormData> & {
  close: () => void;
  submitButtonText: string;
};

export const DepartmentForm = ({ close, validationErrors, isPending, handleSubmit, setValue, watch, mutate, formState: { isDirty }, submitButtonText }: Props) => {
  const { t } = useTranslation();

  const submitHandler = async (formData: DepartmentFormData) => {
    mutate(formData);
    close();
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <fieldset disabled={isPending}>
        <VStack className="w-[436px]" gap={6}>
          <TextField
            label={t("common.labels.name")}
            value={watch("name")}
            onChange={value => setValue("name", value, { shouldDirty: true })}
            errorMessage={validationErrors?.field?.("Name")}
          />
          <ButtonGroup align="end">
            <Button variant="secondary" onClick={close}>
              {t("common.form.cancel")}
            </Button>
            <Button type="submit" loading={isPending} isDisabled={!isDirty}>
              {submitButtonText}
            </Button>
          </ButtonGroup>
        </VStack>
      </fieldset>
    </form>
  );
};