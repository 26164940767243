import { AngleLeftIcon, AngleRightIcon } from "@hopper-ui/icons";
import { HStack, IconButton, Text } from "@/components";
import { PaginationLink } from "./PaginationLink";
import { type PaginationProps } from "./types";
import { buildPaginationList } from "./utils";
import { useEffect } from "react";
import { useGetCurrentPageParam } from "./hooks/useGetCurrentPageParam";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import styles from "./Pagination.module.css";

interface Props extends PaginationProps {
  className?: string;
}

const PaginationEllipsis = () => (
  <Text size="sm">
    ...
  </Text>
);

export const Pagination = ({
  currentPage,
  goToNext,
  goToPage,
  goToPrev,
  totalPages,
  hasNext,
  hasPrev,
  className
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activePage = useGetCurrentPageParam();

  const setPageInParams = (page: number) => {
    searchParams.set("page", `${page}`);
    setSearchParams(searchParams, { replace: true });
  };

  const onGoToNext = () => {
    setPageInParams(activePage + 1);
    goToNext();
  };

  const onGoToPage = (pageNumber: number) => {
    setPageInParams(pageNumber);
    goToPage(pageNumber);
  };

  const onGoToPrev = () => {
    setPageInParams(activePage - 1);
    goToPrev();
  };

  useEffect(() => {
    if (activePage) {
      goToPage(activePage);
    }
  }, []);

  if (totalPages < 2) {
    return null;
  }

  const paginationItems = buildPaginationList(totalPages, currentPage);
  const paginationNodes = paginationItems.map(item => typeof item === "number" ?
    (
      <PaginationLink
        key={item}
        onClick={() => onGoToPage(item)}
        isActive={item === currentPage}
      >
        {item}
      </PaginationLink>
    )
    : (
      <PaginationEllipsis key={item} />
    ));

  return (
    <HStack gap="space-inline-xs" className="flex items-center">
      <IconButton
        className={styles.paginationLinkPrev}
        icon={<AngleLeftIcon />}
        variant="ghost"
        onPress={onGoToPrev}
        disabled={!hasPrev}
      />
      <HStack align="center" gap="space-inline-xs" className={classNames(styles.paginationList, className)}>
        {paginationNodes}
      </HStack>
      <IconButton
        className={classNames(styles.paginationLinkNext)}
        icon={<AngleRightIcon />}
        onPress={onGoToNext}
        variant="ghost"
        disabled={!hasNext}
      />
    </HStack>
  );
};
