import classNames from "classnames";

interface Props {
  progress: number;
  size?: "sm" | "lg";
  className?: string;
}

export const Progress = ({ progress, className, size = "sm" }: Props) => (
  <div 
    className={classNames("bg-neutral-surface-weak rounded-full", className, { 
      "h-2.5": size === "sm",
      "h-4": size === "lg" 
    })}
  >
    <div className={classNames("h-2.5 rounded-full", {
      "bg-primary-surface-strong": progress < 100,
      "bg-success-surface-strong": progress === 100,
      "h-2.5": size === "sm",
      "h-4": size === "lg"
    })}
    style={{ "width": `${progress}%` }}
    ></div>
  </div>
);