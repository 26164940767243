import { type AppPermission, type PermissionResultDto, type ResourceType } from "@/lib/auth/types";
import api from "@/lib/http/api";
import { useQuery, type UseQueryOptions } from "@tanstack/react-query";

export const queryKeys = {
  all: [{ scope: "resource-permissions" }] as const,
  resourcePermission: (resourceType: ResourceType, resourceId: string, permissions: AppPermission[]) =>
    ([...queryKeys.all, resourceType, resourceId, ...permissions]) 
};

type QueryOptions = Omit<
UseQueryOptions<Promise<PermissionResultDto[]>, unknown, PermissionResultDto[], ReturnType<typeof queryKeys["resourcePermission"]>>,
"queryKey"| "queryFn">;

export const useGetResourcePermissions = (
  resourceType: ResourceType,
  resourceId: string,
  permissions: AppPermission[],
  options: QueryOptions = {}
) =>
  useQuery({
    queryKey: queryKeys.resourcePermission(resourceType, resourceId, permissions), 
    queryFn: async () => {
      const res = await api.get<PermissionResultDto[]>(`authorization/permissions/${resourceType}/${resourceId}`, {
        params: {
          permissions
        }
      });

      return res.data;
    },
    ...options
  });