import { AtsConnectedIntegration } from "./AtsConnectedIntegration";
import { AtsDisconnectButton } from "./AtsDisconnectButton";
import { AtsReconnectButton } from "./AtsReconnectButton";
import { Card, CardHeader, HStack, IconText, VStack } from "@/components";
import { WarningIcon } from "@hopper-ui/icons";
import { useTranslation } from "@/hooks/useTranslation";

export const AtsAuthError = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <VStack gap={4}>
        <CardHeader title={t("ats.connect.title")} />
        <AtsConnectedIntegration />
        <HStack align="center" gap={6}>
          <HStack gap={4}>
            <AtsDisconnectButton />
            <AtsReconnectButton />
          </HStack>
          <IconText icon={<WarningIcon size="lg" />} color="danger-icon">{t("ats.sync.status.authError")}</IconText>
        </HStack>
      </VStack>
    </Card>
  );
};