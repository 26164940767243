import { NavigationItem } from "@/components";
import { useSettingsSections } from "@/features/tenants/hooks/useSettingsSections";
import { useAuth, useCurrentUser } from "@/lib/auth/AuthProvider";
import { type AppPermission } from "@/lib/auth/types";
import { translate } from "@/lib/i18n";
import { Routes } from "@/types";
import { ChartBarIcon, GraphIcon, HomeIcon, SearchIcon, SettingsIcon } from "@hopper-ui/icons";
import { isEmpty } from "lodash";
import { type ReactElement } from "react";
import { v4 as uuid } from "uuid";

interface NavItemData {
  label: string;
  icon: ReactElement;
  to: string;
  permission?: AppPermission;
}

export const Navigation = () => {
  const { currentUserHasPermission } = useAuth();
  const { canViewReporteesInfo } = useCurrentUser();
  const { sections: settingsSections } = useSettingsSections();

  const items: NavItemData[] = [
    {
      label: translate("navigation.label.home"),
      icon: <HomeIcon size="lg" />,
      to: Routes.Home
    },
    {
      label: translate("navigation.label.directory"),
      icon: <SearchIcon size="lg" />,
      to: Routes.SeekPeopleTab
    },
    {
      label: translate("navigation.label.progression"),
      icon: <GraphIcon size="lg" />,
      to: Routes.Grow
    }
  ];
  
  if (currentUserHasPermission("Permissions.Reports.Read")) {
    items.push({
      label: translate("navigation.label.reports"),
      icon: <ChartBarIcon size="lg" />,
      to: Routes.Reports
    });
  }

  if (!isEmpty(settingsSections)) {
    items.push({
      label: translate("navigation.label.settings"),
      icon: <SettingsIcon size="lg" />,
      to: Routes.Settings
    });
  }

  const navItems = canViewReporteesInfo || currentUserHasPermission("Permissions.Reports.Read")
    ? items
    : items.filter(i => i.to !== Routes.Reports);

  return (
    <ul>
      {navItems.map(({ label, ...rest }) => (
        <li key={uuid()}>
          <NavigationItem {...rest}>
            {label}
          </NavigationItem>
        </li>
      ))}
    </ul>
  );
};
