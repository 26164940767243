import { parseISO } from "date-fns";

// YYYY-MM-DDTHH:mm:ss.sss(+01:00|Z)
// see: https://en.wikipedia.org/wiki/ISO_8601
const ISO_DATE_REGEX = /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?))$/i;

export const parseOptionalDate = (date?: string | null) => date
  ? parseDate(date)
  : undefined;

const parseDate = (date: string) => {
  const invalidDate = "Invalid Date";
  const functions = [parseISO, (d: string) => new Date(d)];

  for (let idx = 0; idx < functions.length; idx++) {
    const result = functions[idx](date);

    if (result.toString() !== invalidDate) {
      return result;
    }
  }

  return undefined;
};

export const isISODateFormat = (value: string) =>
  ISO_DATE_REGEX.test(value);
