import { type UpdateAtsActivationCommand } from "@/features/ats/types/common";
import { atsQueryKeys } from "@/features/ats/types/atsQueryKeys";
import { toast } from "react-toastify";
import { translate } from "@/lib/i18n";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "@/lib/http/api";

export const useUpdateAtsActivationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: UpdateAtsActivationCommand) => {
      await api.post("/ats/activation", payload);
    },
    onSuccess: async (_, { isActive }) => {
      await queryClient.invalidateQueries({ queryKey: atsQueryKeys.settings() });

      toast.success(isActive 
        ? translate("ats.sync.toggle.enabled")
        : translate("ats.sync.toggle.disabled"));
    }
  });
};