
import { type QueryFunctionContext, type UseQueryOptions, keepPreviousData, useQuery } from "@tanstack/react-query";
import { type TrainingDto } from "@/features/training/types/common";
import { trainingQueryKeys } from "@/features/training/types/trainingQueryKeys";
import api from "@/lib/http/api";

const getTrainings = async ({ queryKey: [{ id }] }: QueryFunctionContext<ReturnType<typeof trainingQueryKeys["single"]>>) => {
  const { data } = await api.get(`/trainings/${id}`);

  return data;
};

export const useGetTraining = (
  id: string,
  options?: UseQueryOptions<TrainingDto, unknown, TrainingDto, ReturnType<typeof trainingQueryKeys["single"]>>
) => (
  useQuery({
    queryKey: trainingQueryKeys.single(id), 
    queryFn: getTrainings,
    placeholderData: keepPreviousData,
    ...options
  })
);
