import { useConfiguration } from "@/lib/core";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import { type PropsWithChildren } from "react";

export const WorkleapAiWidgetsProvider = ({ children }: PropsWithChildren) => {
  const { hasFeature } = useFeatures();
  const { workleapAiCdnUrl } = useConfiguration();

  if (hasFeature("Features.WorkleapAiWidgets")) {
    const addCacheBustingParam = (url: string) => `${url}?v=${new Date().getTime()}`;

    const addResource = (tag: string, attributes: React.LinkHTMLAttributes<HTMLLinkElement> | React.ScriptHTMLAttributes<HTMLScriptElement>, onload?: () => void) => {
      const element = document.createElement(tag);
      Object.assign(element, attributes);
      if (onload) {
        element.onload = onload;
      }
      document.head.appendChild(element);
    };

    addResource("link", { rel: "stylesheet", href: addCacheBustingParam(`${workleapAiCdnUrl}index.css`) });

    addResource("script", { type: "module", src: addCacheBustingParam(`${workleapAiCdnUrl}index.js`) }, () => {
      window.WorkleapAiComponents.initialize({ trackingAppIdentifier: "sks" });
    });
  }
  
  return children;
};