import { Button, TooltipWrapper } from "@/components";
import { useGetHrisSettingsQuery } from "@/features/hris/hooks/useGetHrisSettings";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@/hooks/useTranslation";

export const BulkImportButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: settings } = useGetHrisSettingsQuery();
  
  const onClickBulkImport = () => {
    navigate("/settings/users/bulk-import");
  };

  return (
    <TooltipWrapper tooltip={settings?.isConnected
      ? t("common.hris.disabled.message")
      : ""}
    anchorSelector="bulk-import-tooltip"
    >
      <Button size="small" variant="secondary" onClick={onClickBulkImport} isDisabled={settings?.isConnected}>
        {t("users.heading.bulkImport")}
      </Button>
    </TooltipWrapper>
  );
};