export const progressionPlanQueryKeys = {
  all: [{ scope: "progressionPlans" }] as const,
  myCreatedPlans: () => [{ ...progressionPlanQueryKeys.all[0], entity: "myCreatedPlans" }] as const,
  directReporteesPlans: () => [{ ...progressionPlanQueryKeys.all[0], entity: "directReporteesPlans" }] as const,
  employeeProgressionPlans: (employeeId: string) => [{ ...progressionPlanQueryKeys.all[0], entity: "employeeProgressionPlans", employeeId }] as const,
  employeeArchivedPlans: (employeeId: string) => [{ ...progressionPlanQueryKeys.all[0], entity: "employeeArchivedPlans", employeeId }] as const,
  single: (id: string) => [{ ...progressionPlanQueryKeys.all[0], entity: "single", id }] as const,
  singleByEmployeeId: (employeeId: string) => [{ ...progressionPlanQueryKeys.all[0], entity: "single", employeeId }] as const,
  activities: (skillName: string, currentRating: string, targetRating: string) => [{ ...progressionPlanQueryKeys.all[0], entity: "activities", skillName, currentRating, targetRating }] as const,
  menteesPlans: () => [{ ...progressionPlanQueryKeys.all[0], entity: "menteesPlans" }] as const,
  skillGap: (id: string) => [{ ...progressionPlanQueryKeys.all[0], entity: "skillGap", id }] as const,
  potentialNextRoles: (marketId: string) => [{ ...progressionPlanQueryKeys.all[0], entity: "potentialNextRoles", marketId }] as const,
  suggestedNextRoles: (id: string) => [{ ...progressionPlanQueryKeys.all[0], entity: "suggestedNextRoles", id }] as const,
  limitations: () => [{ ...progressionPlanQueryKeys.all[0], entity: "limitations" }] as const,
  skillGapByMarketId: (marketId: string) => [{ ...progressionPlanQueryKeys.all[0], entity: "skillGapByMarketId", marketId }] as const
};
