import { Card, CardHeader, HStack, IconText, Text, VStack } from "@/components";
import { useTranslation } from "@/hooks/useTranslation";
import { RemoveIcon, SuccessIcon } from "@hopper-ui/icons";
import { AtsActivateImportSkillsToggle } from "./AtsActivateImportSkillsToggle";
import { AtsActivateToggle } from "./AtsActivateToggle";
import { AtsStartSyncButton } from "./AtsStartSyncButton";
import { AtsSyncStatus } from "./AtsSyncStatus";

export const AtsSyncCard = () => {
  const { t } = useTranslation();
  
  return (
    <Card>
      <VStack gap={4}>
        <CardHeader title={t("ats.sync.title")} />
        <Text>{t("ats.sync.description")}</Text>
        <ul>
          <li>
            <IconText icon={<SuccessIcon className="text-status-positive-icon" />}>
              {t("ats.sync.list.item1")}
            </IconText>
          </li>
          <li>
            <IconText icon={<SuccessIcon className="text-status-positive-icon" />}>
              {t("ats.sync.list.item2")}
            </IconText>
          </li>
          <li>
            <IconText icon={<RemoveIcon className="text-danger-icon-press" />}>
              {t("ats.sync.list.item3")}
            </IconText>
          </li>
          <li>
            <IconText icon={<RemoveIcon className="text-danger-icon-press" />}>
              {t("ats.sync.list.item4")}
            </IconText>
          </li>
        </ul>
        <HStack align="center" gap={6}>
          <AtsStartSyncButton />
          <AtsSyncStatus />
        </HStack>
        <AtsActivateToggle />
        <AtsActivateImportSkillsToggle />
      </VStack>
    </Card>
  );
};