import api from "@/lib/http/api";
import { localizationQueryKeys } from "@/lib/i18n/localizationQueryKeys";
import { type GetMarketContentTranslationsDto } from "@/lib/i18n/types";
import { keepPreviousData, useQuery, type QueryFunctionContext, type UseQueryOptions } from "@tanstack/react-query";

const getMarketContentTranslations = async (_: QueryFunctionContext<ReturnType<typeof localizationQueryKeys["marketContentTranslations"]>>) => {
  const { data } = await api.get("/localization/market-content-translations");

  return data;
};

export const useGetMarketContentTranslations = (
  options?: Omit<UseQueryOptions<GetMarketContentTranslationsDto, unknown, GetMarketContentTranslationsDto, ReturnType<typeof localizationQueryKeys["marketContentTranslations"]>>, "queryKey">
) => (
  useQuery({
    queryKey: localizationQueryKeys.marketContentTranslations(), 
    queryFn: getMarketContentTranslations,
    placeholderData: keepPreviousData,
    ...options
  })
);