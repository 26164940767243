import { Calendar, Dropdown, DropdownDialog, IconButton } from "@/components";
import { CalendarIcon } from "@hopper-ui/icons";
import classNames from "classnames";
import { useRef } from "react";
import { useDatePicker } from "react-aria";
import { useDatePickerState } from "react-stately";
import { DateField } from "./DateField";
import styles from "./DatePickerInput.module.css";
import { type DatePickerProps, toAriaDatePickerProps } from "./types";

export const DatePickerInput = ({ size = "md", ...props }: DatePickerProps) => {
  const datePickerProps = toAriaDatePickerProps(props);
  const state = useDatePickerState(datePickerProps);
  const ref = useRef<HTMLDivElement>(null);

  const {
    groupProps,
    fieldProps,
    buttonProps,
    dialogProps,
    calendarProps
  } = useDatePicker(datePickerProps, state, ref);

  const { className, children } = props;

  return (
    <div className={classNames(styles.container, className)}>
      <div {...groupProps} ref={ref} className={classNames(styles.datePickerInputWrapper, { [styles.disabled]: datePickerProps.isDisabled, [styles.error]: !!props.errorMessage })}>
        <div className={classNames(styles.datePickerInputField, { [styles.small]: size === "sm" })}>
          <DateField {...fieldProps} size={size} />
        </div>
        <IconButton
          variant="ghost"
          className={styles.datePickerInputBtn}
          icon={<CalendarIcon size="sm" />}
          {...buttonProps}
        />
      </div>
      {state.isOpen && (
        <Dropdown
          variant="auto"
          triggerRef={ref}
          state={state}
          placement="bottom start"
          className={styles.calendarDropdown}
        >
          <DropdownDialog {...dialogProps}>
            <div className="flex flex-col gap-1.5">
              <Calendar {...calendarProps} />
              {children}
            </div>
          </DropdownDialog>
        </Dropdown>
      )}
    </div>
  );
};