import { Link, type LinkProps } from "react-router-dom";
import { type ReactNode } from "react";

interface Props extends LinkProps {
  condition: boolean;
  children?: ReactNode;
}

export const ConditionalLink = ({ children, condition, ...props }: Props) => {
  if (!condition) {
    return <div className={props.className}>{children}</div>;
  }

  return (
    <Link {...props}>
      {children}
    </Link>
  );
};