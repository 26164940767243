import { type AriaCheckboxGroupItemProps, VisuallyHidden, useCheckboxGroupItem } from "react-aria";
import { CheckmarkIcon } from "@hopper-ui/icons";
import { HStack } from "@/components/stack";
import { useCheckboxGroupContext } from "./CheckboxGroupContext";
import { useRef } from "react";
import classNames from "classnames";
import styles from "@/components/checkbox/Checkbox.module.css";

export const CheckboxGroupItem = (props: AriaCheckboxGroupItemProps) => {
  const { children, value } = props;
  const ref = useRef(null);
  const state = useCheckboxGroupContext();
  const { inputProps } = useCheckboxGroupItem(props, state, ref);
  
  const { isDisabled: isDisabledFromState, isSelected } = state;
  const { isDisabled: isDisabledFromProps } = props;
  
  const isDisabled = isDisabledFromState || isDisabledFromProps;
  const isItemSelected = isSelected(value);
  const { onClick } = inputProps;

  return (
    <label className={classNames(styles.checkbox, { [styles.selected]: isItemSelected, [styles.disabled]: isDisabled })}>
      <VisuallyHidden>
        <input {...inputProps} ref={ref} />
      </VisuallyHidden>
      <HStack gap={2}>
        <span className={classNames(styles.checkmark, "block cursor-pointer")} onClick={onClick}>
          {isItemSelected && <CheckmarkIcon size="lg" />}
        </span>
        <div className="cursor-pointer">{children}</div>
      </HStack>
    </label>
  );
};
