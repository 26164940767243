import { useProgressiveLoading } from "@/components";
import { TrainingForm } from "@/features/training";
import { useGetTraining } from "@/features/training/api/useGetTraining";
import { useIdRouteParam } from "@/hooks/routing/useIdRouteParam";
import { useTranslation } from "@/hooks/useTranslation";
import { Spinner } from "@workleap/orbiter-ui";
import { Helmet } from "react-helmet-async";

export const EditTrainingPage = () => {
  const id = useIdRouteParam();
  const { t } = useTranslation();

  const { data, isLoading } = useGetTraining(id);
  const { showLoading } = useProgressiveLoading({ isLoading });

  if (showLoading) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Spinner aria-label="training-loading" size="lg" color="decorative-option1-weak" />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{t("pages.addTraining")}</title>
      </Helmet>
      <TrainingForm 
        id={id}
        defaultValues={{ 
          ...data, 
          uncategorizedSkills: [], 
          skills: data.skills ?? []
        }}
      />
    </>
  );
};
