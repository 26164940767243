import { Heading, IconButton, LogoAnimation, VStack } from "@/components";
import { useTranslation } from "@/hooks/useTranslation";
import { DismissIcon } from "@hopper-ui/icons";
import { type AriaDialogProps } from "@react-types/dialog";
import { type FocusableElement } from "@react-types/shared";
import { Spinner, ThemeProvider } from "@workleap/orbiter-ui";
import classNames from "classnames";
import { AnimatePresence } from "framer-motion";
import { useRef, type DOMAttributes, type ReactElement } from "react";
import {
  FocusScope,
  useDialog,
  useModal,
  useOverlay,
  usePreventScroll,
  type AriaOverlayProps
} from "react-aria";

export interface ModalDialogProps extends Omit<ModalHeaderProps, "titleProps"> {
  children: ReactElement;
  isLoading?: boolean;
  showWorkingOnMagicLoading?: boolean;
}

export const ModalDialog = (props: ModalDialogProps) => {
  const { children, isOpen, onClose, isLoading, showWorkingOnMagicLoading } = props;
  const { t } = useTranslation();
  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const ref = useRef(null);
  const { overlayProps, underlayProps } = useOverlay(props, ref);

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll();
  const { modalProps } = useModal();

  // Get props for the dialog and its title
  const { dialogProps, titleProps } = useDialog(props, ref);

  const loadingBackground = showWorkingOnMagicLoading
    ? "bg-neutral-surface"
    : "bg-neutral-surface/[0.5]";

  return (
    <AnimatePresence>
      {isOpen && (
        <ThemeProvider colorScheme="light">
          <div {...underlayProps} className="fixed inset-0 grid items-center justify-center overflow-y-scroll bg-overlay z-max">
            <FocusScope contain restoreFocus autoFocus>
              <div
                {...overlayProps}
                {...dialogProps}
                {...modalProps}
                ref={ref}
                className="relative p-space-inset-lg bg-neutral-surface shadow-elevation-lifted rounded-shape-rounded-md"
              >
                {isLoading && (
                  <VStack className={classNames("absolute rounded-xl inset-0 bg-neutral-surface z-max", loadingBackground)} justify="center" align="center">
                    {showWorkingOnMagicLoading
                      ? ( 
                        <VStack gap={4} justify="center" align="center">
                          <LogoAnimation />
                          <Heading size="lg">{t("common.longLoadingMessage")}</Heading>
                        </VStack>
                      )
                      : <Spinner aria-label="modal-loading" size="lg" color="decorative-option1-weak" />}
                  </VStack>
                )}
                <VStack gap={6}>
                  <ModalHeader
                    {...props}
                    titleProps={titleProps}
                    onClose={onClose}
                    isDismissable={props.isDismissable}
                  />
                  {children}
                </VStack>
              </div>
            </FocusScope>
          </div>
        </ThemeProvider>
      )}
    </AnimatePresence>
  );
};

interface ModalHeaderProps extends AriaOverlayProps, AriaDialogProps {
  title?: string;
  titleProps: DOMAttributes<FocusableElement>;
  centered?: boolean;
}

const ModalHeader = ({ title, titleProps, onClose, isDismissable, centered = false }: ModalHeaderProps) => {
  if (title) {
    return (
      <div className={classNames("relative",
        {
          "flex items-center justify-center": centered
        })}
      >
        {title && (
          <Heading className="max-w-[90%] text-center" size="lg" {...titleProps}>
            {title}
          </Heading>
        )}
        {onClose && isDismissable && (
          <div className="absolute top-[-8px] right-[-8px]">
            <IconButton
              variant="ghost"
              icon={<DismissIcon />}
              onPress={onClose}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="absolute top-[-8px] right-[-8px]">
      {onClose && isDismissable && (
        <IconButton
          variant="ghost"
          icon={<DismissIcon />}
          onPress={onClose}
        />
      )}
    </div>
  );
};