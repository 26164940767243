import { employeesQueryKeys } from "@/features/employees/types/employeesQueryKeys";
import { type SearchEmployeeResponse } from "@/features/reports/types/search";
import api from "@/lib/http/api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

interface UseSearchRoleQueryOptions {
  enabled: boolean;
}

export const useSearchEmployeesQuery = (query: string, options?: UseSearchRoleQueryOptions) => {
  return useQuery({
    queryKey: employeesQueryKeys.searchEmployees(query), 
    queryFn: async () => {
      const { data } = await api.get<SearchEmployeeResponse>("search/employees", {
        params: {
          search: query
        }
      });

      return data.results;
    },
    placeholderData: keepPreviousData,
    ...options
  });
};