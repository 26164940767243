import { HStack, IconButton, Text, VStack } from "@/components";
import { getFullName } from "@/features/employees/utils";
import { type CollaboratorDto } from "@/features/role/types/index";
import { UserAvatar } from "@/features/users/components/UserAvatar";
import { useTranslation } from "@/hooks/useTranslation";
import { DismissIcon } from "@hopper-ui/icons";
import { format } from "date-fns";
import { useCollaborateContext } from "./Collaborate";

interface Props {
  collaborator: CollaboratorDto;
}

export const Collaborator = ({ collaborator }: Props) => {
  const { t } = useTranslation();
  const { onRemoveCollaboratorClick, activeCollaborators } = useCollaborateContext();

  const { firstName, lastName, avatarUrl, addedOn } = collaborator;


  return (
    <HStack align="center" justify="between" gap={4}>
      <HStack align="center" justify="start" gap={4}>
        <UserAvatar
          firstName={firstName}
          lastName={lastName}
          avatarUrl={avatarUrl}
        />
        <VStack gap={0}>
          <Text>
            {getFullName(firstName, lastName)}
          </Text>
          <Text size="xs" color="neutral-text-weak">{t("collaborate.modal.addedOnDate", { date: format(addedOn, "MM/dd/yy") })}</Text>
        </VStack>
      </HStack>
      <IconButton
        variant="ghost"
        icon={<DismissIcon />}
        onPress={() => onRemoveCollaboratorClick(activeCollaborators, collaborator.id)}
      />
    </HStack>
  );
};
