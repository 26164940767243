import { Children, type MouseEvent, type ReactNode, cloneElement } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  children: ReactNode;
}

export const DemoWrapper = ({ children }: Props) => {
  const { t } = useTranslation();

  const onDemoClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    toast.info(t("demo.toast.text"));
  };
  const childrenList = Array.isArray(children)
    ? children
    : [children];

  const renderChildren = () => Children.map(childrenList, item => cloneElement(item, {
    key: item.key || item.props.itemId,
    onClick: onDemoClick
  }));
    
  return <>{renderChildren()}</>;
};