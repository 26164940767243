import classNames from "classnames";
import { Children, Fragment, isValidElement, useMemo, type ReactElement, type ReactNode } from "react";

type Gaps = 0 | 0.5 | 1 | 1.5 | 2 | 2.5 | 3 | 4 | 5 | 6 | 8 | 10;
type HopperInsetGaps = "space-inset-xs" | "space-inset-sm" | "space-inset-md" | "space-inset-lg" | "space-inset-xl";
type HopperInsetSquishGaps = "space-inset-squish-sm" | "space-inset-squish-md" | "space-inset-squish-lg";
type HopperInsetStretchGaps = "space-inset-stretch-sm" | "space-inset-stretch-md" | "space-inset-stretch-lg";
type HopperStackGaps = "space-stack-xs" | "space-stack-sm" | "space-stack-md" | "space-stack-lg" | "space-stack-xl";
type HopperInlineGaps = "space-inline-xs" | "space-inline-sm" | "space-inline-md" | "space-inline-lg" | "space-inline-xl";

export type Gap = Gaps | HopperInsetGaps | HopperInsetSquishGaps | HopperInsetStretchGaps | HopperStackGaps | HopperInlineGaps;

export interface StackProps {
  as?: "ul" | "div" | "li";
  gap?: Gap;
  children: ReactNode;
  className?: string;
  flow: "row" | "col";
  divider?: ReactElement;
}

// filter out any null or invalid children
const getValidChildren = (children: ReactNode) => (
  Children.toArray(children).filter(child => isValidElement(child)) as ReactElement[]
);

/**
 * @deprecated Use Grid or Flex from @workleap/orbiter-ui instead
 */
export const Stack = ({ children, gap = 2, className, flow, divider, as = "div" }: StackProps) => {
  const classname = classNames("flex", className, `gap-${gap}`, {
    "flex-col": flow === "col",
    "flex-row": flow === "row"
  });
  
  const Container = as || "div";
  const hasDivider = !!divider;

  const clonedChildren = useMemo(() => {
    const validChildren = getValidChildren(children);

    return validChildren.map((child, index) => {
      const key = typeof child.key !== "undefined"
        ? child.key
        : index;

      const isLast = index + 1 === validChildren.length;

      if (!hasDivider) {
        return child;
      }
      
      const _divider = isLast
        ? null
        : divider;

      return (
        <Fragment key={key}>
          {child}
          {_divider}
        </Fragment>
      );
    });
  }, [children, flow]);

  return (
    <Container className={classname}>
      {clonedChildren}
    </Container>
  );
};