import { Cell, Column, Row, TableBody, TableHeader } from "react-stately";
import { type CustomMarketSkillDto } from "@/features/skills/types/common";
import { DEFAULT_LIST_PAGE_SIZE } from "@/lib/utils/constants";
import { SkillCustomDescriptionsListActions } from "./SkillCustomDescriptionsListActions";
import { Table, Text, usePaginationCountSummary, useTableSorting } from "@/components";
import { nameOf } from "@/utils/types";
import { useGetCustomMarketSkills } from "@/features/skills/hooks/useGetCustomMarketSkills";
import { useMemo } from "react";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  page: number;
}

export const SkillCustomDescriptionsList = ({ page }: Props) => {
  const { t } = useTranslation();
  
  const { tableSortProps, sortingFields: sortBy } = useTableSorting<CustomMarketSkillDto>({
    initialSortDescriptor: {
      columns: ["marketId"],
      direction: "Ascending"
    }
  });

  const { data, isLoading } = useGetCustomMarketSkills({ page, pageSize: DEFAULT_LIST_PAGE_SIZE, sortBy });

  const { listItems, total } = useMemo(() => ({
    listItems: data?.items ?? [],
    total: data?.total ?? 0
  }), [data]);

  const summaryLine = usePaginationCountSummary({
    currentPage: page,
    pageSize: DEFAULT_LIST_PAGE_SIZE,
    totalItems: total,
    listItemName: t("skills.customDescriptions.paginationItems")
  });

  return (
    <>
      <Table
        isLoading={isLoading}
        aria-label={t("skills.customDescriptions.table.title") ?? ""}
        emptyListProps={{
          title: t("skills.customDescriptions.table.empty.title"),
          description: t("skills.customDescriptions.table.empty.description"),
          showImage: true
        }}
        pagination={{
          totalItems: total,
          currentPage: page,
          pageSize: DEFAULT_LIST_PAGE_SIZE
        }}
        summary={summaryLine}
        {...tableSortProps}
      >
        <TableHeader>
          <Column
            key={nameOf<CustomMarketSkillDto>("marketId")}
            width="100%"
            allowsSorting
          >
            {t("skills.customDescriptions.table.skillName")}
          </Column>
          <Column>
            {t("departments.table.actions")}
          </Column>
        </TableHeader>
        <TableBody>
          {listItems.map(skillMetadata => {
            const { id, marketId } = skillMetadata;

            return (
              <Row key={id}>
                <Cell>
                  <Text>{marketId}</Text>
                </Cell>
                <Cell>
                  <SkillCustomDescriptionsListActions skill={skillMetadata} />
                </Cell>
              </Row>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};