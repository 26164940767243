import { useIntercom } from "@/features/analytics/hooks";
import { useDirectoryRoutes } from "@/features/directory/hooks/useDirectoryRoutes";
import { useCurrentUser } from "@/lib/auth/AuthProvider";
import { useConfiguration } from "@/lib/core/ConfigurationProvider";
import { Routes } from "@/types";
import { useEffect, type ReactNode } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { IntercomProvider as Intercom } from "react-use-intercom";

const IntercomBootstrapper = ({ children }: { children: ReactNode }) => {
  const { intercomAppId } = useConfiguration();
  const user = useCurrentUser();
  const { boot, update } = useIntercom();
  const location = useLocation();
  const { editPersonSkills } = useDirectoryRoutes();

  useEffect(() => {
    if (intercomAppId) {
      const {
        id,
        email,
        firstName,
        lastName,
        fullName,
        roleName,
        permissionLevel,
        isCollaborator,
        tenant,
        intercomUserHash } = user;

      boot({
        userId: id,
        userHash: intercomUserHash,
        email: email,
        name: fullName,
        company: {
          companyId: tenant.id,          
          name: tenant.name,
          industry: tenant.industry
        },
        customAttributes: {
          "First Name": firstName,
          "Last Name": lastName,
          "Role Name": roleName,
          "Permission Level": permissionLevel,
          "Is Collaborator": isCollaborator ?? false,
          "Is Migrated To Workleap platform": tenant?.isWorkleapTenant ?? false
        }
      });
    }
  }, []);
  
  useEffect(() => {
    const currentPath = location.pathname;
    
    const isOnEditSkills = currentPath.startsWith(editPersonSkills);
    const isDuringSetup = currentPath.startsWith(Routes.Setup);
    const isDuringCareerProgressionSteps = matchPath("/grow/:id/steps/*", currentPath);
    const isInForm = currentPath.startsWith(Routes.AddTraining) || 
                     matchPath(Routes.EditTraining, currentPath) || 
                     currentPath.startsWith(Routes.SeekRoleAdd) ||
                     matchPath(Routes.SeekRoleEdit, currentPath) ||
                     currentPath.startsWith(Routes.SeekPeopleAdd) ||
                     matchPath("/seek/people/edit/:employeeId", currentPath) ||
                     matchPath("/settings/*", currentPath);

    const verticalPadding = isDuringSetup || isOnEditSkills || isDuringCareerProgressionSteps || isInForm
      ? 120
      : 20;

    update({ verticalPadding, horizontalPadding: 24 });
  }, [location.pathname, location.search]);

  return <>{children}</>;
};

export const IntercomProvider = ({ children }: { children: ReactNode }) => {
  const { intercomAppId } = useConfiguration();

  return (
    <Intercom appId={intercomAppId ?? ""}>
      <IntercomBootstrapper>
        {children}
      </IntercomBootstrapper>
    </Intercom>
  );
};