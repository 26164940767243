import { AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { AtsConnectModal } from "./AtsConnectModal";
import { Button } from "@/components";
import { NewTabIcon } from "@hopper-ui/icons";
import { useOverlayTriggerState } from "react-stately";
import { useTrackAtsAction } from "@/features/ats/hooks/useTrackAtsAction";
import { useTranslation } from "@/hooks/useTranslation";

export const AtsReconnectButton = () => {
  const { t } = useTranslation();
  const overlayState = useOverlayTriggerState({});
  const { trackAtsAction } = useTrackAtsAction();

  const onReconnectAtsClick = () => {
    trackAtsAction(AnalyticValues.actionReconnect);
    overlayState.open();
  };

  return (
    <>      
      <Button
        size="small"
        icon={<NewTabIcon />}
        variant="primary"
        onClick={onReconnectAtsClick}
      >
        {t("ats.reconnect.button")}
      </Button>
      <AtsConnectModal overlayState={overlayState} />
    </>
  );
};