import { AddSkillCustomDescriptions } from "@/features/skills/components/AddSkillCustomDescriptions";
import { Helmet } from "react-helmet-async";
import { Routes } from "@/types/routes";
import { useTranslation } from "@/hooks/useTranslation";

import { useNavigate } from "react-router-dom";

export const AddSkillCustomDescriptionsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onBack = () => navigate(Routes.SkillsCustomDescriptions);

  return (
    <>
      <Helmet>
        <title>{t("pages.settingsAddCustomDescription")}</title>
      </Helmet>
      <AddSkillCustomDescriptions onCancelClick={onBack} />
    </>
  );
};
