import { HStack, Text } from "@/components";
import { LockIcon } from "@hopper-ui/icons";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  text?: string;
}

export const HrisLock = ({ text }: Props) => {
  const { t } = useTranslation();

  return (
    <HStack gap={1} align="center" className="ml-4">
      <LockIcon size="sm" className="text-neutral-text-weak" />
      <Text color="neutral-text-weak" size="xs">
        {text ?? t("common.hris.disabled.message")}
      </Text>
    </HStack>
  );
};