import { type ProgressionPlanDto } from "@/features/progression-plan/types/common";
import { type QueryFunctionContext, type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { progressionPlanQueryKeys } from "@/features/progression-plan/types/progressionPlanQueryKeys";
import api from "@/lib/http/api";

const getProgressionPlan = async ({ queryKey: [{ id }] }: QueryFunctionContext<ReturnType<typeof progressionPlanQueryKeys["single"]>>) => {
  const { data } = await api.get<ProgressionPlanDto>(`/progression-plans/${id}`);

  return data;
};

export const useGetProgressionPlan = (
  id: string,
  options?: Omit<UseQueryOptions<ProgressionPlanDto, unknown, ProgressionPlanDto, ReturnType<typeof progressionPlanQueryKeys["single"]>>, "queryKey">
) => (
  useQuery({
    queryKey: progressionPlanQueryKeys.single(id), 
    queryFn: getProgressionPlan, 
    ...options
  })
);