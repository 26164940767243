import { directoryQueryKeys } from "@/features/directory/types/directoryQueryKeys";
import { roleQueryKeys } from "@/features/roles/types/roleQueryKeys";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "@/hooks/useTranslation";
import api from "@/lib/http/api";

export const usePublishRoleMutation = (
  roleId: string,
  onSuccess?: () => void,
  hideToast?: boolean) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async () => await api.post(`roles/${roleId}/publish`),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: roleQueryKeys.all });
      await queryClient.invalidateQueries({ queryKey: directoryQueryKeys.all });

      if (!hideToast) {
        toast.success(t("common.messages.changesSaved"));
      }
      onSuccess?.();
    }
  });
};
