import { type GetCustomMarketSkillsQueryParams, type ListCustomMarketSkillsDto } from "@/features/skills/types/common";
import { type QueryFunctionContext, type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { skillsQueryKeys } from "@/features/skills/types/skillsQueryKeys";
import api from "@/lib/http/api";

const getCustomMarketSkills = async({ queryKey: [, , , params] }: QueryFunctionContext<ReturnType<typeof skillsQueryKeys.listCustomMarketSkills>>) => {
  const { data } = await api.get<ListCustomMarketSkillsDto>("/skills/custom-market-skills", { params });

  return data;
};


export const useGetCustomMarketSkills = (
  queryParams: GetCustomMarketSkillsQueryParams,
  options?: UseQueryOptions<ListCustomMarketSkillsDto, unknown, ListCustomMarketSkillsDto, ReturnType<typeof skillsQueryKeys.listCustomMarketSkills>>) =>
  useQuery({
    queryKey: skillsQueryKeys.listCustomMarketSkills(queryParams), 
    queryFn: getCustomMarketSkills, 
    ...options
  }); 