import { HStack, VStack } from "@/components";
import { getFullName } from "@/features/employees/utils";
import { useGetProgressionPlan } from "@/features/progression-plan/hooks/useGetProgressionPlan";
import { useProgressionPlanContext } from "@/features/progression-plan/hooks/useProgressionPlanContext";
import { useGetSkillMentors } from "@/features/skill/hooks/useGetSkillMentors";
import { type SkillRating } from "@/features/skills/types/Skill";
import { UserAvatar } from "@/features/users/components/UserAvatar";
import { useTranslation } from "@/hooks/useTranslation";
import { Heading, Text } from "@workleap/orbiter-ui";
import { isEmpty } from "lodash";
import { AddSkillMentorButton } from "./AddSkillMentorButton";
import { RemoveSkillMentorButton } from "./RemoveSkllMentorButton";

interface Props {
  skillName: string;
  targetRating: SkillRating;
}

export const RecommendedSkillMentors = ({ skillName, targetRating }: Props) => {
  const { data } = useGetSkillMentors(skillName, targetRating);
  const { planId } = useProgressionPlanContext();
  const { data: progressionPlan } = useGetProgressionPlan(planId);
  const { t } = useTranslation();

  if (isEmpty(data?.mentors) || !data) {
    return null;
  }
 
  const { mentors } = data;

  const isMentorAdded = (id: string) => !!progressionPlan?.mentors.find(mentor => mentor.id === id);

  return (
    <VStack>
      <Heading size="md">{t("progressionPlan.recommendedMentors.title")}</Heading>
      <VStack gap={3}>
        {mentors.map(({ id, roleId, firstName, roleName, lastName, avatar }) => (
          <HStack align="center" justify="between" gap={2} key={roleId}>
            <HStack align="center" className="w-full" gap={3}>
              <UserAvatar
                firstName={firstName}
                lastName={lastName}
                avatarUrl={avatar.avatarUrl}
              />
              <VStack gap={0}>
                <Text size="sm">{isMentorAdded(id)}</Text>
                <Text size="sm">
                  {getFullName(firstName, lastName)} 
                </Text>
                <Text size="xs" color="neutral-text-weak">{roleName}</Text>
              </VStack>
            </HStack>
            {isMentorAdded(id)
              ? <RemoveSkillMentorButton id={id} />
              : <AddSkillMentorButton id={id} />
            }
          </HStack>
        ))}
      </VStack>
    </VStack>
  );
};