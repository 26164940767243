import { Heading, LogoAnimation, ModalDialog, VStack, useProgressiveLoading } from "@/components";
import { OverlayContainer } from "react-aria";
import { type OverlayTriggerState } from "react-stately";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  overlayState: OverlayTriggerState;
}

export const WorkingOnMagicLoading = ({ overlayState }: Props) => {
  const { t } = useTranslation();
  const { showLoading } = useProgressiveLoading({ isLoading: overlayState.isOpen, showAfterInitialLoading: true });

  if (!showLoading) {
    return null;
  }
  
  return (
    <OverlayContainer>
      <ModalDialog
        isOpen={showLoading}
        onClose={overlayState.close}
      >
        <VStack className="w-[600px] h-[256px]" gap={4} justify="center" align="center">
          <LogoAnimation />
          <Heading size="xl">{t("common.longLoadingMessage")}</Heading>
        </VStack>
      </ModalDialog>
    </OverlayContainer>
  );
};
