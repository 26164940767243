import { AngleDownIcon } from "@hopper-ui/icons";
import { DefaultMenuItem, DeleteConfirmationModal, DropdownMenuButton } from "@/components";
import { type Key, useMemo } from "react";
import { type RoleManagementDto } from "@/features/roles/types/common";
import { Section, useOverlayTriggerState } from "react-stately";
import { isEmpty } from "lodash";
import { useBulkArchiveRolesMutation } from "@/features/roles/hooks/useBulkArchiveRolesMutation";
import { useBulkDeleteRolesMutation } from "@/features/roles/hooks/useBulkDeleteRolesMutation";
import { useBulkPublishRolesMutation } from "@/features/roles/hooks/useBulkPublishRolesMutation";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  selectedRoles: RoleManagementDto[];
}

enum Actions {
  PUBLISH = "publish",
  ARCHIVE = "draft",
  DELETE = "delete"
}

export const RoleManagementBulkDropdown = ({ selectedRoles }: Props) => {
  const { t } = useTranslation();
  const deleteRolesModalState = useOverlayTriggerState({});
  const { mutate: bulkPublishRoles, isPending: isPublishLoading } = useBulkPublishRolesMutation();
  const { mutate: bulkArchiveRoles, isPending: isArchiveLoading } = useBulkArchiveRolesMutation();
  const { mutate: bulkDeleteRoles, isPending: isDeleteLoading } = useBulkDeleteRolesMutation();

  const draftRoles = useMemo(() => selectedRoles.filter(({ status }) => status === "Draft"), [selectedRoles]);
  const publishedRoles = useMemo(() => selectedRoles.filter(({ status }) => status === "Published"), [selectedRoles]);
  const archivedRoles = useMemo(() => selectedRoles.filter(({ status }) => status === "Archived"), [selectedRoles]);
  
  const isLoading = useMemo(() => isPublishLoading || isArchiveLoading || isDeleteLoading, [isPublishLoading, isArchiveLoading, isDeleteLoading]);

  const onPublish = () => {
    const ids = [...draftRoles.map(({ id }) => id), ...archivedRoles.map(({ id }) => id)];

    bulkPublishRoles({ ids });
  };

  const onArchive = () => {
    bulkArchiveRoles({ ids: publishedRoles.map(({ id }) => id) });
  };

  
  const onDelete = () => {
    bulkDeleteRoles({ ids: draftRoles.map(({ id }) => id) });
  };

  const onAction = async (key: Key) => {
    switch (key) {
      case Actions.PUBLISH: onPublish(); break;
      case Actions.ARCHIVE: onArchive(); break;
      case Actions.DELETE: deleteRolesModalState.open(); break;
    }
  };

  const items = [];

  if (!isEmpty(draftRoles) || !isEmpty(archivedRoles)) {
    items.push(DefaultMenuItem(Actions.PUBLISH, t("roles.bulkDropdown.publish", { count: draftRoles.length + archivedRoles.length })));
  }

  if (!isEmpty(publishedRoles)) {
    items.push(DefaultMenuItem(Actions.ARCHIVE, t("roles.bulkDropdown.archive", { count: publishedRoles.length })));
  }

  if (!isEmpty(draftRoles)) {
    items.push(DefaultMenuItem(Actions.DELETE, t("roles.bulkDropdown.delete", { count: draftRoles.length }), { variant: "danger" }));
  }

  return (
    <>
      <DropdownMenuButton 
        extraButtonProps={{
          icon: <AngleDownIcon />,
          iconPosition: "right"
        }}
        onAction={onAction}
        loading={isLoading}
        buttonText={t("roles.bulkDropdown.selected", { count: selectedRoles.length })}
      >
        <Section>
          {items}
        </Section>
      </DropdownMenuButton>
      <DeleteConfirmationModal
        title={t("roles.bulkDropdown.delete.title", { count: draftRoles.length })}
        confirmationMessage={t("roles.bulkDropdown.delete.confirmationMessage", { count: draftRoles.length })}
        onDelete={onDelete}
        overlayState={deleteRolesModalState}
      />
    </>
  );
};
