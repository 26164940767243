import { useParams } from "@/hooks/useParams";

interface SkillNameRouteParams {
  skillName: string;
}

export const useSkillNameParams = () => {
  const { skillName } = useParams<SkillNameRouteParams>();

  return skillName;
};