import { Card, ConfirmationModal } from "@/components";
import { type RoleReferencesDto } from "@/features/roles/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { Routes } from "@/types";
import { WarningIcon } from "@hopper-ui/icons";
import { Div, Flex, Text } from "@workleap/orbiter-ui";
import { noop } from "lodash";
import { Trans } from "react-i18next";
import { type OverlayTriggerState } from "react-stately";

interface Props {
  roleId: string;
  overlayState: OverlayTriggerState;
  roleReferencesData: RoleReferencesDto;
}

export const NotDeletableRoleModal = ({ overlayState, roleId, roleReferencesData }: Props) => {
  const { t } = useTranslation();
  const activeEmployeesCount = roleReferencesData.workHistory.filter(({ isActive }) => isActive).length;
  const usersPageLink = `${Routes.Settings}/users?roleIds=${encodeURIComponent(roleId)}`;

  return (
    <ConfirmationModal
      title={t("roles.delete.title")}
      onClickConfirm={noop}
      overlayState={overlayState}
      cancelButtonContent={t("common.form.cancel")}
      confirmButtonContent={t("roles.delete.confirm")}
      confirmButtonProps={{ isDisabled: true }}
    >
      <Flex direction="column" gap="stack-md">
        <Text className="text-center">{t("roles.delete.notDeletable.summary")}</Text>
        <Card className="!bg-danger-surface !border-danger-border-strong">
          <Flex direction="row" gap="inline-md">
            <Div>
              <WarningIcon className="text-danger-text" size="lg" />
            </Div>
            <Flex direction="column">
              <Text className="text-danger-text" size="md" fontWeight={690}>{t("roles.delete.theRoleIsAssigned")}</Text>
              <Div className="text-danger-text">
                <ul className="px-4 list-disc">
                  <li>
                    <Trans
                      count={activeEmployeesCount}
                      i18nKey="roles.delete.warning.impact.activeEmployees"
                      components={{
                        usersLink: <a className="underline" href={usersPageLink} target="_blank" rel="noreferrer" />
                      }}
                    />
                  </li>
                </ul>
              </Div>
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </ConfirmationModal>
  );
};
