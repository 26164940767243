import { useTranslation } from "@/hooks/useTranslation";
import { type SkillRating } from "@/features/skill/types/skillTypes";
import { type SkillCategory } from "@/features/skills/types/common";

export const useSkillTranslations = () => {
  const { t } = useTranslation();

  const getSkillRatingTranslation = (rating: SkillRating) => {
    switch (rating) {
      case "Beginner":
        return t("setup.skills.levelBeginner");
      case "Intermediate":
        return t("setup.skills.levelIntermediate");
      case "Advanced":
        return t("setup.skills.levelAdvanced");
      case "Expert":
        return t("setup.skills.levelExpert");
    }
  };

  const getSkillCategoryTranslation = (category?: SkillCategory) => {
    if (category === "Soft") {
      return t("employee.softSkill");
    }

    if (category === "Hard") {
      return t("employee.hardSkill");
    }

    return null;
  };

  return {
    getSkillRatingTranslation,
    getSkillCategoryTranslation
  };
};