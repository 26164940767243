import { HStack, Text, TooltipWrapper } from "@/components";
import { SkillFlyout } from "@/features/skill/components/SkillFlyout";
import { useInitialSkillFlyoutState } from "@/features/skill/hooks/useInitialSkillFlyoutState";
import { useSkillFlyoutState } from "@/features/skill/hooks/useSkillFlyoutState";
import { type SkillDto, type SkillRating } from "@/features/skills/types/Skill";
import { getSkillLevelsMetadata } from "@/features/skills/types/SkillLevels";
import { type Model } from "@/features/types/clientTypes";
import { useMarketContentTranslation, useTranslation } from "@/hooks/useTranslation";
import { removeTextBetweenParenthesis } from "@/lib/utils";
import { NoteIcon, OrganizationIcon, TeamIcon } from "@hopper-ui/icons";
import { HtmlButton } from "@workleap/orbiter-ui";
import classNames from "classnames";
import { useMemo, type MouseEvent } from "react";
import Skeleton from "react-loading-skeleton";
import { useOverlayTriggerState } from "react-stately";

type SkillSource = Model<"Skills.Models.SkillSourceDto">["name"];
type ExtendedSkill = Omit<SkillDto, "score">;

interface SkillPillProps {
  skill: ExtendedSkill;
  size?: "small" | "large";
  hideRating?: boolean;
  hideDefaultFlyoutActions?: boolean;
  showSource?: boolean;
}

export const SkillPill = ({ skill, size = "small", hideRating, hideDefaultFlyoutActions, showSource = false }: SkillPillProps) => {
  const { name, isConfirmed, rating, source } = skill;
  const overlayState = useOverlayTriggerState({});
  const { openFlyout } = useSkillFlyoutState(overlayState);

  const classes = "bg-decorative-option5-surface-weakest border-decorative-option5-surface-weakest text-information-text transition";
  const hoverClasses = "hover:cursor-pointer hover:bg-decorative-option5-surface hover:border-decorative-option5-surface";
  const unconfirmedClassNames = "border-dashed border-information-border bg-status-neutral-surface";
  
  const className = classNames(classes, hoverClasses, {
    [unconfirmedClassNames]: !isConfirmed
  });

  useInitialSkillFlyoutState(overlayState, name);

  return (
    <>
      <BaseSkillPill
        {...skill}
        size={size}
        onClick={() => openFlyout(name, rating)}
        source={source?.name}
        className={className}
        hideRating={hideRating}
        showSource={showSource}
      />
      <SkillFlyout overlayState={overlayState} hideDefaultFlyoutActions={hideDefaultFlyoutActions} />
    </>
  );
};

type Props = Pick<SkillPillProps, "size" | "hideRating"> & {
  name: string;
  className?: string;
  rating: SkillRating;
  onClick: (skillName: string) => void;
  source?: SkillSource;
  showSource: boolean;
};

const BaseSkillPill = ({ size, source, showSource, className, name, rating, onClick, hideRating }: Props) => {
  const skillLevels = getSkillLevelsMetadata();
  const { translateMarketContent } = useMarketContentTranslation();
  const RatingIcon = size === "large"
    ? skillLevels[rating].IconLg
    : skillLevels[rating].Icon;
  const { t } = useTranslation(); 

  const SourceIcon = useMemo(() => {
    switch (source) {
      case "other-user":
        return TeamIcon;
      case "role":
      case "external-role":
        return OrganizationIcon;
      case "resume":
        return NoteIcon;
      default:
        return () => null;
    }
  }, [source]);

  const sourceTooltipText = useMemo(() => {
    switch (source) {
      case "other-user":
        return t("employee.edit.skills.source.otherUser");
      case "role":
        return t("employee.edit.skills.source.role");
      default:
        return "";
    }
  }, [source]);

  const textSize = useMemo(() => {
    switch (size) {
      case "large":
        return "md";
      default:
        return "sm";
    }
  }, [size]);

  const onClickSkill = (e: MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      e.stopPropagation();
      onClick(name);
    }
  };

  return (
    <HtmlButton type="button" onClick={onClickSkill}>
      <HStack
        align="center"
        gap={2}
        className={classNames("rounded-shape-rounded-md border", className, {
          "p-space-inset-md": size === "large",
          "px-2 py-1.5": size === "small"
        })}
      >
        {!hideRating && <RatingIcon />}
        {showSource && (
          <TooltipWrapper tooltip={sourceTooltipText} anchorSelector="form-help-icon">
            <SourceIcon />
          </TooltipWrapper>
        )}
        <Text size={textSize} color="inherit">
          {removeTextBetweenParenthesis(translateMarketContent(name))}
        </Text>
      </HStack>
    </HtmlButton>
  );
};

export const SkillPillSkeleton = () => (
  <HStack gap={2} align="center" className="px-3 border py-space-inset-sm rounded-shape-rounded-md border-decorative-option5-surface-weakest bg-decorative-option5-surface-weakest">
    <Skeleton height={12} width={90} />
  </HStack>
);
