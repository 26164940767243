import { AtsConnectedIntegrationCard } from "./AtsConnectedIntegrationCard";
import { AtsOpenPositionsCard } from "./AtsOpenPositionsCard";
import { AtsSyncCard } from "./AtsSyncCard";
import { Banner, VStack } from "@/components";
import { useConnectAtsStore } from "@/features/ats/hooks/useConnectAtsStore";
import { useEffect } from "react";
import { useGetAtsSettings } from "@/features/ats/hooks/useGetAtsSettings";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  page: number;
}

export const AtsConnected = ({ page }: Props) => {
  const { t } = useTranslation();
  const { showBanner, setShowBanner } = useConnectAtsStore();
  const { data } = useGetAtsSettings();

  useEffect(() => {
    if (showBanner && !data?.isScheduledOrRunning) {
      setShowBanner(false);
    }
  }, [data?.isScheduledOrRunning]);

  return (
    <VStack gap={6}>
      {showBanner && (
        <Banner 
          title={t("ats.connect.success.title")}
          description={t("ats.connect.success.description")}
          onClose={() => setShowBanner(false)}
        />
      )}
      <AtsConnectedIntegrationCard />
      <AtsSyncCard />
      <AtsOpenPositionsCard page={page} />
    </VStack>
  );
};