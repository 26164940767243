import { type Node } from "@react-types/shared";
import { Divider } from "@workleap/orbiter-ui";
import { useMenuSection, useSeparator } from "react-aria";
import { type TreeState } from "react-stately";
import { MenuItem } from "./MenuItem";

interface MenuSectionProps<T> {
  section: Node<T>;
  state: TreeState<T>;
}

export const MenuSection = <T extends object>({ section, state }: MenuSectionProps<T>) => {
  const { itemProps, groupProps } = useMenuSection({
    heading: section.rendered,
    "aria-label": section["aria-label"]
  });

  const { separatorProps } = useSeparator({
    elementType:"Divider"
  });

  return (
    <>
      {section.key !== state.collection.getFirstKey() && (
        <Divider
          {...separatorProps}
        />
      )}
      <li {...itemProps}>
        <ul {...groupProps}>
          {[...section.childNodes].map(node => (
            <MenuItem
              key={node.key}
              item={node}
              state={state}
            />
          ))}
        </ul>
      </li>
    </>
  );
};