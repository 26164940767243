import { atsQueryKeys } from "@/features/ats/types/atsQueryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "@/lib/http/api";

export const useStartAtsSyncMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await api.post("/ats/sync");
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: atsQueryKeys.all })
  });
};