import { ActionButtonDropdown, type ActionButtonItem, DeleteConfirmationModal } from "@/components";
import { Authorize } from "@/lib/auth";
import { type ListTrainingDto } from "@/features/training/types/common";
import { Routes } from "@/types";
import { generatePath, useNavigate } from "react-router-dom";
import { useDeleteTrainingMutation } from "@/features/training/api/useDeleteTrainingMutation";
import { useOverlayTriggerState } from "react-stately";
import { useTranslation } from "@/hooks/useTranslation";

type Actions = "edit" | "delete";

type Props = Pick<ListTrainingDto, "id" | "title" | "isUsedInProgressionPlans">;

export const TrainingsListMenu = ({ id, title, isUsedInProgressionPlans }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const deleteOverlayState = useOverlayTriggerState({});
  const { mutate: deleteTraining } = useDeleteTrainingMutation(id);

  const onEdit = () => {
    navigate(generatePath(Routes.EditTraining, { id }));
  };

  const onDelete = () => {
    deleteTraining();
  };

  const actions: ActionButtonItem<Actions>[] = [
    {
      key: "edit",
      text: t("trainings.table.actions.edit"),
      onClick: onEdit
    }
  ];

  if (!isUsedInProgressionPlans) {
    actions.push({
      key: "delete",
      text: t("trainings.table.actions.delete"),
      onClick: deleteOverlayState.open,
      isDestructive: true
    });
  }

  return (
    <>
      <Authorize permission={["Permissions.Trainings.Edit", "Permissions.Trainings.Delete"]}>
        <ActionButtonDropdown actions={actions} />
      </Authorize>
      <DeleteConfirmationModal
        title={t("trainings.table.deleteTitle")}
        onDelete={onDelete}
        overlayState={deleteOverlayState}
        name={title}
      />
    </>
  );
};
