import { type Feature } from "@/lib/features/types/feature";
import { type PropsWithChildren } from "react";
import { ProtectedRoute } from "./ProtectedRoute";
import { useFeatures } from "@/lib/features/hooks/useFeatures";

interface Props extends PropsWithChildren {
  feature: Feature;
  redirectPath?: string;
}

export const FeatureGuardRoute = ({
  feature,
  ...rest
}: Props) => {
  const { hasFeature } = useFeatures();
  
  const isAllowed = hasFeature(feature);

  return (
    <ProtectedRoute
      isAllowed={isAllowed}
      {...rest}
    />
  );
};