import { Button, Card, Grid, TextField } from "@/components";
import { type DeleteTenantCommand } from "@/features/tenants/types/common";
import { TenantCardTitle } from "./TenantCardTitle";
import { useDeleteTenantMutation } from "@/features/tenants/hooks/useDeleteTenantMutation";
import { useForm } from "react-hook-form";
import { useTranslation } from "@/hooks/useTranslation";

export const DeleteTenantForm = () => {
  const { t } = useTranslation();
  const { mutate, isPending, validationErrors } = useDeleteTenantMutation();
  const { watch, handleSubmit, setValue } = useForm<DeleteTenantCommand>({
    defaultValues: { confirmDelete: "" }
  });
  const submitHandler = async (formData: DeleteTenantCommand) => {
    mutate(formData);
  };

  return (
    <Card>
      <TenantCardTitle title={t("accountSettings.form.account.title")} />
      <form onSubmit={handleSubmit(submitHandler)}>
        <Grid rows="auto">
          <TextField
            label={t("accountSettings.deletionLabel")}
            description={t("accountSettings.deletionDesc")}
            errorMessage={validationErrors?.field("ConfirmDelete")}
            value={watch("confirmDelete")}
            onChange={value => setValue("confirmDelete", value, { shouldDirty: true })}
          />
          <Button type="submit" variant="danger" isDisabled={isPending}>{t("accountSettings.deleteAccount")}</Button>
        </Grid>
      </form>
    </Card>
  );
};
