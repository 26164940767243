import { Button, ButtonGroup, ClearableDatePicker, DatePicker, Grid, HStack, ModalDialog, VStack } from "@/components";
import { type EmployeeEmploymentFormState } from "@/features/employees/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { type ValidationErrorResult } from "@/models/error";
import { type FormEvent, useMemo, useState } from "react";
import { OverlayContainer } from "react-aria";
import { type OverlayTriggerState } from "react-stately";
import { EmploymentModalSearch } from "./EmploymentModalSearch";

interface Props {
  employment: EmployeeEmploymentFormState;
  commandErrors?: ValidationErrorResult;
  overlayState: OverlayTriggerState;
  onDelete: () => void;
  onEditEmployment: (employment: EmployeeEmploymentFormState) => void;
}

export const EditEmploymentModal = ({
  employment: initialEmployment,
  commandErrors,
  overlayState,
  onDelete,
  onEditEmployment
}: Props) => {
  const { t } = useTranslation();
  const [employment, setEmployment] = useState<EmployeeEmploymentFormState | undefined>(initialEmployment);

  const setCurrentEmploymentDate = (date?: Date) => {
    if (employment) {
      setEmployment({
        ...employment,
        effectiveDate: date
      });
    }
  };

  const setEndDate = (date?: Date) => {
    if (employment) {
      setEmployment({
        ...employment,
        endDate: date
      });
    }
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.stopPropagation();

    if (employment) {
      onEditEmployment(employment);
      overlayState.close();
    }
  };

  const isSubmitDisabled = useMemo(
    () =>
      (!employment?.roleName && !employment?.roleId) ||
      !employment?.effectiveDate,
    [employment]
  );

  if (!overlayState.isOpen) {
    return null;
  }

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("employee.edit.editPosition")}
        isOpen={overlayState.isOpen}
        onClose={overlayState.close}
        isDismissable
      >
        <form className="w-[500px] mt-6" onSubmit={onSubmit}>
          <VStack gap={6}>
            <EmploymentModalSearch
              commandErrors={commandErrors}
              employment={employment}
              onChange={setEmployment}
            />
            <Grid rows={1} cols={2} gap={1.5}>
              <DatePicker
                maxValue={employment?.endDate || new Date()}
                label={t("employee.edit.effectiveDate")}
                value={employment?.effectiveDate}
                onChange={setCurrentEmploymentDate}
                errorMessage={commandErrors?.field(
                  "Body.Employments[0].EffectiveDate"
                )}
                size="sm"
              />
              <ClearableDatePicker
                minValue={employment?.effectiveDate}
                maxValue={new Date()}
                label={t("employee.edit.endDate")}
                value={employment?.endDate}
                onChange={setEndDate}
                onClear={() => setEndDate(undefined)}
                errorMessage={commandErrors?.field(
                  "Body.Employments[0].EndDate"
                )}
                size="sm"
              />
            </Grid>
            <HStack justify="between">
              <Button variant="danger" onClick={onDelete}>
                {t("employee.edit.delete")}
              </Button>
              <ButtonGroup align="end" gap={2}>
                <Button variant="secondary" onClick={overlayState.close}>
                  {t("common.form.cancel")}
                </Button>
                <Button type="submit" isDisabled={isSubmitDisabled}>
                  {t("employee.edit.updateRole")}
                </Button>
              </ButtonGroup>
            </HStack>
          </VStack>
        </form>
      </ModalDialog>
    </OverlayContainer>
  );
};
