import { Heading } from "@/components";
import { type RadioGroupState, useRadioGroupState } from "react-stately";
import { type ReactNode, createContext } from "react";
import { VisuallyHidden, useRadioGroup } from "react-aria";
import type { RadioGroupProps } from "@react-types/radio";

export const CardContext = createContext<null | RadioGroupState>(null);

interface CardGroupProps extends RadioGroupProps {
  label: string;
  children: ReactNode;
}

export const SelectableCardGroup = (props: CardGroupProps) => {
  const { children, label } = props;
  const state = useRadioGroupState(props);
  const { radioGroupProps, labelProps } = useRadioGroup(props, state);

  return (
    <div {...radioGroupProps}>
      <VisuallyHidden>
        <Heading size="xl" {...labelProps}>{label}</Heading>
      </VisuallyHidden>
      <CardContext.Provider value={state}>
        {children}
      </CardContext.Provider>
    </div>
  );
};
