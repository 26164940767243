import { Card, Heading, SelectWithSearch, Text, VStack, WorkingOnMagicLoading } from "@/components";
import { type CustomMarketSkillFormState } from "@/features/skills/types/customSkills";
import { type SearchResultItem } from "@/features/search/types/common";
import { type UseFormReturn } from "react-hook-form";
import { type ValidationErrorResult } from "@/models/error";
import { hasDescriptions, makeDefaultDescriptions } from "@/features/skills/utils/customSkills";
import { useMarketContentTranslation, useTranslation } from "@/hooks/useTranslation";
import { useOverlayTriggerState } from "react-stately";
import { useSearchSkills } from "@/features/search/hooks/useSearchSkills";

interface Props extends Omit<UseFormReturn<CustomMarketSkillFormState>, "handleSubmit"> {
  errors?: ValidationErrorResult;
}

export const AddSkillCustomDescriptionsForm = ({ watch, setValue, errors }: Props) => {
  const { t } = useTranslation();
  
  const workingOnMagicOverlayState = useOverlayTriggerState({});
  const { translateMarketContent } = useMarketContentTranslation();
  
  const {
    data: skillData,
    fetchStatus: skillFetchStatus,
    setQuery: setSkillQuery
  } = useSearchSkills({ exclude: "WithCustomization" });

  const onSkillSelectionChange = (selected: SearchResultItem) => {
    if (!watch("marketId") && !hasDescriptions(watch("ratingsDescriptions"))) {
      setValue("ratingsDescriptions", makeDefaultDescriptions(), { shouldDirty: true });
    }
    setValue("marketId", selected.name, { shouldDirty: true });
  };

  const onClearSkillSelection = () => {
    setValue("marketId", "", { shouldDirty: true });
  };

  return (
    <VStack gap={6}>
      <Card>
        <VStack gap={6}>
          <VStack gap={1.5}>
            <Heading size="xl">{t("skills.customDescriptions.form.skillName")}</Heading>
            <Text>{t("skills.customDescriptions.form.skillNameSubtitle")}</Text>
          </VStack>
          <SelectWithSearch
            description={t("skills.customDescriptions.form.skillNamePlaceholder")}
            setQuery={setSkillQuery}
            results={skillData?.results ?? []}
            fetchStatus={skillFetchStatus}
            onSelect={onSkillSelectionChange}
            onClear={onClearSkillSelection}
            initialValue={watch("marketId")}
            keySelector={item => item.name}
            itemDisplay={item => (
              <div key={item.name}>
                {translateMarketContent(item.name)}
              </div>
            )}
            itemToText={item => item.name}
            errorMessage={errors?.field("Body.SkillName")}
            isRequired
          />
        </VStack>
      </Card>
      <Card className="!bg-neutral-surface-weak">
        <Heading size="xl" className="text-neutral-text-weak">{t("skills.customDescriptions.form.descriptions")}</Heading>
      </Card>
      <WorkingOnMagicLoading overlayState={workingOnMagicOverlayState} />
    </VStack>
  );
};
