import { useSearchParams } from "react-router-dom";

export const useChangePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onChangePage = (pageNumber: number) => {
    searchParams.set("page", pageNumber.toString());
    setSearchParams(searchParams, { replace: true });
  };

  const reset = () => {
    searchParams.set("page", "1");
    setSearchParams(searchParams, { replace: true });
  };

  return { onChangePage, reset };
};