import { Card, CardHeader, Grid, PageHeader, Select, UnsavedChangesModal, VStack } from "@/components";
import { FormLayout } from "@/components/layouts/FormLayout";
import { useLanguages } from "@/hooks/useLanguages";
import { useTranslation } from "@/hooks/useTranslation";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { EmployeeFormButtons } from "./EmployeeFormButtons";
import { EmployeeResume } from "./EmployeeResume";
import { ReportingAttributes } from "./ReportingAttributes";
import { Employment } from "./employment/Employment";
import { ProfileInformation } from "./profile-information/ProfileInformation";
import { type EmployeeFormProps, type EmployeeFormState } from "./types";
import { UserPermissions } from "./user-permissions/UserPermissions";

export const EmployeeForm = ({
  employee,
  title,
  showPermissions,
  isSaving,
  saveErrors,
  saveBtnLabelOverride,
  cancelBtnLabelOverride,
  onBack,
  onSubmit,
  variant
}: EmployeeFormProps) => {
  const { avatarUrl, id, resume, isSynced, reportsTo } = employee;
  const form = useForm<EmployeeFormState>({
    defaultValues: {
      ...employee,
      language: employee.language ?? "en",
      reportsToId: reportsTo?.id,
      resume: resume
        ? {
          name: resume.fileName,
          size: resume.fileSize
        }
        : undefined
    }
  });

  const { hasFeature } = useFeatures();
  const { languages, getLanguage } = useLanguages();
  const { t } = useTranslation();

  const showAvatarUpload = variant === "edit";
  const showResumeUpload = variant === "edit" && hasFeature("Features.ResumeInUserProfile");
  const showLanguage = variant === "edit" && hasFeature("Features.FrenchTranslation");
  
  const { handleSubmit, getValues, reset, formState: { isDirty: isFormDirty, isSubmitted }, setValue, watch } = form; 

  const [newAvatar, setNewAvatar] = useState<File>();
  const onResumeRemoved = () => setValue("resume", undefined, { shouldDirty: true });
  const onResumeFileChanged = (uploadedFile: File) =>
    setValue("resume", { name: uploadedFile.name, size: uploadedFile.size, uploadedFile }, { shouldDirty: true });
  
  const hasAvatar = newAvatar !== undefined;
  
  const submitHandler = (formData: EmployeeFormState) => {
    const data = {
      ...(newAvatar && { 
        avatarFile: newAvatar,
        oldAvatarUrl: avatarUrl 
      }),
      formData,
      oldResume: resume
    };

    onSubmit(data);
  };

  const hasFormChanges = isFormDirty || hasAvatar;

  const onClickBack = () => {
    if (isFormDirty) {
      reset();
    }

    onBack();
  };

  return (
    <>
      <FormLayout onSubmit={handleSubmit(submitHandler)}>
        <FormLayout.Content>
          <VStack gap={4}>
            <PageHeader title={title} />
            <VStack gap={4}>
              <ProfileInformation
                commandErrors={saveErrors}
                employee={employee}
                form={form}
                setNewAvatar={setNewAvatar}
                showAvatarUpload={showAvatarUpload}
              />
              {showResumeUpload && (
                <EmployeeResume
                  employeeId={id}
                  resume={watch("resume")}
                  onFileChanged={onResumeFileChanged}
                  onResumeRemoved={onResumeRemoved}
                />
              )}
              <Employment
                form={form}
                employments={getValues("employments")} 
                isSynced={isSynced}
                commandErrors={saveErrors}
              />
              {showLanguage && ( 
                <Card>
                  <CardHeader title={t("profile.preferences.title")} />
                  <Grid cols={2}>
                    <Select
                      label={t("profile.preferences.language.label")}
                      value={getLanguage(watch("language"))}
                      items={languages}
                      selectItem={item => setValue("language", item?.id, { shouldDirty: true })}
                      errorMessage={saveErrors?.field("Language")}
                    />
                  </Grid>
                </Card>
              )}
              <ReportingAttributes employee={employee} {...form} />
              {showPermissions && (
                <UserPermissions
                  form={form}
                  commandErrors={saveErrors}
                  employee={employee}
                />
              )}
            </VStack>
          </VStack>
        </FormLayout.Content>
        <FormLayout.Footer>
          <EmployeeFormButtons
            onBack={onClickBack}
            isSavingChanges={isSaving}
            hasFormChanges={hasFormChanges}
            cancelBtnLabelOverride={cancelBtnLabelOverride}
            saveBtnLabelOverride={saveBtnLabelOverride}
          />
        </FormLayout.Footer>
      </FormLayout>
      <UnsavedChangesModal when={hasFormChanges && !isSaving && !isSubmitted} />
    </>
  );
};
