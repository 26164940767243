import { useAnalytics } from "@/features/analytics/hooks/useAnalytics";
import { AnalyticEvents, AnalyticProperties, AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { useAuth, useCurrentUser } from "@/lib/auth/AuthProvider";
import { redirectToHome } from "@/utils";
import Cookies from "js-cookie";
import { useDemoStore } from "./useDemoStore";

export const useDemo = () => {
  const { currentUserHasPermission } = useAuth();
  const { isDemo, tenant: { hasSubscription } } = useCurrentUser();
  const { trackEvent } = useAnalytics();

  const { setShowCard, showCard } = useDemoStore();
  
  const launchDemo = () => {
    trackEvent(AnalyticEvents.demoAction, { [AnalyticProperties.action]: AnalyticValues.launch });
    
    Cookies.set("demo", "1");
    redirectToHome();
  };

  const exitDemo = () => {
    trackEvent(AnalyticEvents.demoAction, { [AnalyticProperties.action]: AnalyticValues.exit });

    Cookies.remove("demo");
    redirectToHome();
  };

  const closeDemoCard = () => {
    trackEvent(AnalyticEvents.demoAction, { [AnalyticProperties.action]: AnalyticValues.closeCard });
    
    setShowCard(false);
  };

  const hasDemoViewAccess = currentUserHasPermission("Permissions.Demo.View") && !hasSubscription;
  const showDemoCard = isDemo || (showCard && hasDemoViewAccess);

  return { isDemo, hasDemoViewAccess, showCard: showDemoCard, launchDemo, exitDemo, closeDemoCard };
};