import { DefaultMenuItem, DeleteConfirmationModal, DropdownMenuButton, TooltipWrapper } from "@/components";
import { type DeletableDepartmentDto } from "@/features/departments/types/common";
import { EditDepartmentModal } from "./EditDepartmentModal";
import { type Key } from "react";
import { Section, useOverlayTriggerState } from "react-stately";
import { useDeleteDepartmentMutation } from "@/features/departments/api/useDeleteDepartmentMutation";
import { useGetHrisSettingsQuery } from "@/features/hris/hooks/useGetHrisSettings";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  department: DeletableDepartmentDto;
}

enum ActionKeys {
  EDIT_DEPARTMENT = "editDepartment",
  DELETE_DEPARTMENT = "deleteDepartment"
}

export const DepartmentActionsDropdownMenu = ({ department }: Props) => {
  const { t } = useTranslation();
  const { data: hrisSettings } = useGetHrisSettingsQuery();
  const isHrisConnected = hrisSettings?.isConnected;
  
  const editDepartmentModalState = useOverlayTriggerState({});
  const deleteDepartmentModalState = useOverlayTriggerState({});

  const { mutate } = useDeleteDepartmentMutation(department.id);

  const actionCommands = {
    [ActionKeys.EDIT_DEPARTMENT]: editDepartmentModalState.open,
    [ActionKeys.DELETE_DEPARTMENT]: deleteDepartmentModalState.open
  };

  const onAction = async (key: Key) => {
    const command = actionCommands[key as ActionKeys];

    if (!command) {
      return;
    }

    await command();
  };

  const items = [
    DefaultMenuItem(ActionKeys.EDIT_DEPARTMENT, t("departments.actions.edit"))
  ];

  if (department.isDeletable) {
    items.push(DefaultMenuItem(ActionKeys.DELETE_DEPARTMENT, t("departments.actions.delete"), { variant: "danger" }));
  }

  const tooltip = isHrisConnected
    ? t("common.hris.disabled.message")
    : "";

  return (
    <>
      <TooltipWrapper tooltip={tooltip} anchorSelector="department-action-tooltip">
        <DropdownMenuButton onAction={onAction} isDisabled={isHrisConnected}>
          <Section>
            {items}
          </Section>
        </DropdownMenuButton>
      </TooltipWrapper>
      <EditDepartmentModal overlayState={editDepartmentModalState} department={department} />
      <DeleteConfirmationModal
        title={t("departments.delete.title")}
        onDelete={mutate}
        confirmationMessage={t("departments.delete.confirmationMessage", { department: department.name })}
        overlayState={deleteDepartmentModalState}
      />
    </>
  );
};
