import { AnalyticEvents, AnalyticProperties } from "@/features/analytics/types/analyticEvents";
import { type Dict } from "mixpanel-browser";
import { useMixpanel } from "@/features/analytics/hooks/useMixpanel";

export const useTrackJobAction = () => {
  const { trackEvent } = useMixpanel();

  const trackJobAction = (action: string, role: string, status: string, hiringManagerId?: string, departmentId?: string) => {
    const properties: Dict = {};
    properties[AnalyticProperties.action] = action;
    properties[AnalyticProperties.roleId] = role;
    properties[AnalyticProperties.managerId] = hiringManagerId;
    properties[AnalyticProperties.status] = status;
    properties[AnalyticProperties.departmentId] = departmentId;

    trackEvent(AnalyticEvents.jobAction, properties);
  };

  return {
    trackJobAction
  };
};