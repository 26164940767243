import { AddEmploymentButton } from "./AddEmploymentButton";
import { Card, CardHeader, Grid, VStack } from "@/components";
import { EditEmployment } from "./EditEmployment";
import { type EmployeeEditModel, type EmployeeEmploymentFormState } from "@/features/employees/types/common";
import { type UpdateUserProfileCommand } from "@/features/profile/types/common";
import { type UseFormReturn } from "react-hook-form";
import { type ValidationErrorResult } from "@/models/error";
import { useGetEmployeeIsSynced } from "@/features/employees/hooks/useGetEmployeeIsSynced";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  employments: EmployeeEmploymentFormState[];
  commandErrors?: ValidationErrorResult;
  form: UseFormReturn<EmployeeEditModel | UpdateUserProfileCommand>;
  isSynced?: boolean;
}

export const Employment = (props: Props) => {
  const { employments, isSynced } = props;
  const { t } = useTranslation();
  const { isHrisConnectedAndEmployeeIsSynced } = useGetEmployeeIsSynced(isSynced);

  return (
    <Card>
      <VStack gap={2}>
        <CardHeader title={t("employee.edit.workHistory")} />
        <Grid cols={2}>
          <VStack gap={6}>
            {!isHrisConnectedAndEmployeeIsSynced && <AddEmploymentButton {...props} />}
            {employments.map((employment, index) => (
              <EditEmployment
                // Sometimes the same role name is possible
                // eslint-disable-next-line react/no-array-index-key
                key={`${employment.roleName}-${index}`}
                employment={employment}
                {...props}
              />
            ))}
          </VStack>
        </Grid>
      </VStack>
    </Card>
  );
};
