import { type UpdateAutoAssignManagersAsCollaboratorEnabledCommand } from "@/features/hris/types/common";
import { apiPost } from "@/lib/http/api";
import { hrisQueryKeys } from "@/features/hris/types/hrisQueryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateAutoAssignManagersAsCollaboratorsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ isEnabled }: UpdateAutoAssignManagersAsCollaboratorEnabledCommand) => {
      await apiPost("/hris/settings/auto-assign-managers-as-collaborators", {
        isEnabled
      });
    },
    onSuccess: async _ => {
      await queryClient.invalidateQueries({ queryKey: hrisQueryKeys.settings() });
    }
  });
};