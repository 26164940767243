import { useAuth } from "@/lib/auth/AuthProvider";
import { type ReactNode } from "react";
import { hostRoutes } from "./host";
import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";

interface Route {
  path: string;
  element: ReactNode;
  children?: Route[];
}

export const useGetRoutes = () => {
  const { authenticated, currentUser } = useAuth();

  let routes: Route[] = publicRoutes;

  if (authenticated) {
    routes = currentUser?.isHost
      ? hostRoutes
      : protectedRoutes;
  } else {
    // Clear Mixpanel/Intercom etc from localStorage before redirecting to login
    localStorage.clear();
  }

  return routes;
};
