import { type SetupDto, SetupQueryKeys } from "@/features/setup/types";
import api from "@/lib/http/api";
import { type UseQueryOptions, useQuery, useSuspenseQuery } from "@tanstack/react-query";

export const getSetup = async () => {
  const { data } = await api.get<SetupDto>("/setup", {
    validateStatus: status => (status >= 200 && status < 300) || status === 401
  });

  return data;
};

export const useGetSetup = (
  options?: UseQueryOptions<Promise<SetupDto>, unknown, SetupDto, SetupQueryKeys[]>
) => (
  useQuery({
    queryKey: [SetupQueryKeys.SETUP], 
    queryFn: getSetup, 
    ...options
  })
);

export const useGetSetupQuery = (
  options?: UseQueryOptions<Promise<SetupDto>, unknown, SetupDto, SetupQueryKeys[]>
) => {
  const response = useSuspenseQuery({
    queryKey: [SetupQueryKeys.SETUP], 
    queryFn: getSetup, 
    ...options
  });

  return response.data;
};
