import { Children, type FormEventHandler, type PropsWithChildren, type ReactElement, type ReactNode } from "react";

interface Props {
  children: ReactNode;
  onSubmit: FormEventHandler<HTMLFormElement>;
}

const FormLayout = ({ children, onSubmit }: Props) => {
  const childrens = Children.toArray(children);
  const content = childrens.find(child => (child as ReactElement).type === FormLayout.Content);
  const footer = childrens.find(child => (child as ReactElement).type === FormLayout.Footer);

  if (!content || !footer) {
    throw new Error("FormLayout must have a FormLayout.Content and a FormLayout.Footer");
  }

  if (childrens.filter(c => content !== c && footer !== c).length > 0) {
    throw new Error("FormLayout can only have a FormLayout.Content and a FormLayout.Footer");
  }

  return (
    <form onSubmit={onSubmit} className="w-full h-full">
      <div className="relative flex flex-col w-full h-full overflow-hidden">
        {content}
        {footer}
      </div>
    </form>
  );
};

const Content = ({ children }: PropsWithChildren) => (
  <div className="py-[50px] flex-1 overflow-auto">
    <div className="container">
      {children}
    </div>
  </div>
);

const Footer = ({ children }: PropsWithChildren) => (
  <div className="w-full h-[72px] border-t border-neutral-border-weak bg-neutral-surface px-space-inset-xl">
    {children}
  </div>
);

FormLayout.Content = Content;
FormLayout.Footer = Footer;

export { FormLayout };