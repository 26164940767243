import { isNil } from "lodash";

export const CROWDIN_IS_ACTIVE_LOCAL_STORAGE = "crowdin-is-active";

export const getIsCrowdinToolActive = () => {
  const isActive = localStorage.getItem(CROWDIN_IS_ACTIVE_LOCAL_STORAGE);

  if (isNil(isActive)) {
    return true;
  }

  return isActive === "true";
};