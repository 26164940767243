import { directoryQueryKeys } from "@/features/directory/types/directoryQueryKeys";
import { type UpdateCustomSkill } from "@/features/skills/types/common";
import { skillsQueryKeys } from "@/features/skills/types/skillsQueryKeys";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import api from "@/lib/http/api";
import { translate } from "@/lib/i18n";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useUpdateCustomSkillMutation = (id: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutationWithValidationResult<UpdateCustomSkill>({
    mutationFn: async (requestPayload : UpdateCustomSkill) => {
      await api.post(`/skills/custom-skills/${id}`, requestPayload);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: directoryQueryKeys.all });
      await queryClient.invalidateQueries({ queryKey: skillsQueryKeys.all });
      toast.success(translate("skills.customSkills.updateSuccessMessage"));

      onSuccess?.();
    }
  });
};