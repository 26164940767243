import { type RoleManagementDto } from "@/features/roles/types/common";
import { useAuth } from "@/lib/auth/AuthProvider";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import { useCallback } from "react";

export const useRoleActions = () => {
  const { currentUserHasPermission, currentUser } = useAuth();
  const { hasFeature } = useFeatures();

  const canDeleteRole = useCallback(({ status }: Pick<RoleManagementDto, "status" | "id">) => {
    if (!currentUserHasPermission("Permissions.Roles.Delete")) {
      return false;
    }

    return hasFeature("Features.DeleteNonDraftRole") || status === "Draft";
  }, [currentUser]);

  return {
    canDeleteRole
  };
};