import { removeDiacritics } from "@/utils/strings";

interface Props {
  text?: string;
  searchQuery: string;
}

export const HighlightedQuery = ({ text, searchQuery }: Props) => {
  if (!text) {
    return null;
  }

  const normalizedText = removeDiacritics(text.toLocaleLowerCase());
  const normalizedSearchQuery = removeDiacritics(searchQuery.toLocaleLowerCase());

  const matchIndex = normalizedText.indexOf(normalizedSearchQuery);
  if (matchIndex === -1) {
    return (
      <>{text}</>
    );
  }

  const leftPart = text.substring(0, matchIndex);
  const highlight = text.substring(matchIndex, matchIndex + searchQuery.length);
  const rightPart = text.substring(matchIndex + searchQuery.length, text.length);

  return (
    <div>
      {leftPart}
      <span className="underline">{highlight}</span>
      {rightPart}
    </div>
  );
};

