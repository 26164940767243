import { type Dict } from "mixpanel-browser";
import { useConfiguration } from "@/lib/core/ConfigurationProvider";
import { useMixpanelContext } from "@/features/analytics/components/MixpanelProvider";

export const useMixpanel = () => {
  const { mixpanelProjectToken } = useConfiguration();
  const mixpanel = useMixpanelContext();
  
  const trackEvent = (eventName: string, properties?: Dict) => {
    mixpanelProjectToken && mixpanel.track(eventName, properties);
  };

  const reset = () => mixpanelProjectToken && mixpanel.reset();

  return {
    trackEvent,
    reset
  };
};