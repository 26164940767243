import { CROWDIN_IS_ACTIVE_LOCAL_STORAGE, getIsCrowdinToolActive } from "./tools/CrowdinInContextTool.tools";
import { Helmet } from "react-helmet-async";
import { useConfiguration } from "@/lib/core";
import { useFeatures } from "@/lib/features/hooks/useFeatures";

export const CrowdinInContextTool = () => {
  const { appUrl } = useConfiguration();
  const { hasFeature } = useFeatures();

  if (!hasFeature("Features.CrowdinInContextTool") || !getIsCrowdinToolActive()) {
    return null;
  }

  return (
    <Helmet>
      <script type="text/javascript">
        {`
          var _jipt = [];
          _jipt.push(['project', 'wl-skills']);
          _jipt.push(['escape', function() {
            const i18nextLng = localStorage.getItem("i18nextLng");

            if (i18nextLng !== "en") {
              localStorage.setItem("i18nextLng", "en");
            }
            
            localStorage.setItem("${CROWDIN_IS_ACTIVE_LOCAL_STORAGE}", false);
            window.location.href = "${appUrl}";
          }]);
        `}
      </script>
      <script type="text/javascript" src="//cdn.crowdin.com/jipt/jipt.js"></script>
    </Helmet>
  );
};