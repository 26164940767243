import { useAuth } from "@/lib/auth/AuthProvider";
import { useConfiguration } from "@/lib/core";
import { AppErrorBoundary } from "@/providers/AppErrorBoundary";
import { AppInsightsContext, AppInsightsErrorBoundary, ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { useEffect, useRef, useState, type ReactElement } from "react";

export interface LoggingProps {
  children: ReactElement;
}

const reactPlugin = new ReactPlugin();

const AzureAppInsights = ({ children }: LoggingProps) => {
  const [initialized, setInitialized] = useState(false);
  const { appInsightsConnectionString } = useConfiguration();
  const { currentUser } = useAuth();
  const currentUserRef = useRef(currentUser);

  useEffect(() => {
    if (currentUser) {
      currentUserRef.current = currentUser;
    }
  }, [currentUser]);

  if (!appInsightsConnectionString) {
    return <>{children}</>;
  }

  if (!initialized) {
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: appInsightsConnectionString,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableAjaxPerfTracking: true,
        isBrowserLinkTrackingEnabled: true,
        extensions: [reactPlugin],
        correlationHeaderExcludedDomains: ["crowdin.com", "ekr.zdassets.com"],
        correlationHeaderExcludePatterns: [/^.*workleap(-(dev|stg))?\.com.+\/logout.*$/]
      }
    });

    appInsights.loadAppInsights();

    appInsights.addTelemetryInitializer(telemetryItem => {
      const telemetryData = telemetryItem.data || {};
      telemetryData["Talentscope.TenantId"] = currentUserRef?.current?.tenant.id;
      telemetryData["Talentscope.UserId"] = currentUserRef?.current?.id;

      telemetryItem.data = telemetryData;
    });

    setInitialized(true);
  }

  return (
    <AppInsightsErrorBoundary onError={AppErrorBoundary} appInsights={reactPlugin}>
      <AppInsightsContext.Provider value={reactPlugin}>
        {children}
      </AppInsightsContext.Provider>
    </AppInsightsErrorBoundary>
  );
};

export default withAITracking(reactPlugin, AzureAppInsights);