import { type BulkImportResult } from "@/features/users/types/BulkImportResult";
import { Button, Text, VStack } from "@/components";
import { isEmpty } from "lodash";
import { postImportUsers } from "@/features/users/api/postImportUsers";
import { useFileInput } from "@/hooks/useFileInput";
import { useState } from "react";
import { useTranslation } from "@/hooks/useTranslation";

interface BulkImportStep3Props {
  setResult: (result?: BulkImportResult) => void;
}

export const BulkImportStep3 = ({ setResult }: BulkImportStep3Props) => {
  const { t } = useTranslation();
  
  const { input, handleFileChanged, open } = useFileInput({ onFileChanged: file => onFileChanged(file) });
  const [loading, setLoading] = useState(false);

  const onClickUpload = () => {
    setResult(undefined);
    if (input.current) {
      // switch type to reset current file
      input.current.type = "";
      input.current.type = "file";
    }

    open();
  };

  const onFileChanged = async (file: File) => {
    setLoading(true);

    const result = await postImportUsers(file);

    if (result.success) {
      setResult({
        isSuccess: true,
        errors: result.data?.warnings
      });
    } else {
      let errors: string[] = [];
      const errorSummary = result.error?.summary();
      const fieldErrors = result.error?.fieldErrors("File");
      if (errorSummary) {
        errors.push(errorSummary);
      }

      if (fieldErrors && !isEmpty(fieldErrors)) {
        errors = errors.concat(fieldErrors);
      }

      setResult({
        isSuccess: false,
        errors
      });
    }
    
    setLoading(false);
  };

  return (
    <VStack>
      <input
        aria-label="avatar"
        ref={input}
        type="file"
        accept=".csv" 
        id="file"
        className="hidden"
        onChange={handleFileChanged}
      />
      <Text size="md">{t("users.bulkImport.step3Title")}</Text>
      <VStack gap={4}>
        <Text>{t("users.bulkImport.step3DescriptionLine1")}</Text>
        <VStack>
          <Text>{t("users.bulkImport.step3DescriptionLine2")}</Text>
          <Text>{t("users.bulkImport.step3DescriptionLine3")}</Text>
          <Text>{t("users.bulkImport.step3DescriptionLine4")}</Text>
          <Text>{t("users.bulkImport.step3DescriptionLine5")}</Text>
        </VStack>
        <Button variant="secondary" loading={loading} onClick={onClickUpload}>{t("users.bulkImport.step3UploadButton")}</Button>
      </VStack>
    </VStack>
  );
};