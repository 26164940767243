import { FormLabel, HStack } from "@/components";
import { type FocusableElement } from "@react-types/shared";
import { type DOMAttributes, type LabelHTMLAttributes, type ReactElement, type ReactNode } from "react";

export interface FieldFormHeaderProps {
  label?: string | ReactNode;
  isDisabled?: boolean;
  isRequired?: boolean;
  disabledExplainer?: ReactElement;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement> | DOMAttributes<FocusableElement>;
  helpText?: string;
}

/**
 * @deprecated Use [FormField] with orbiter components and react-hook-form.
 */
export const FieldFormHeader = ({ label, isDisabled, disabledExplainer, isRequired, labelProps, helpText }: FieldFormHeaderProps) => {
  if (!label) {
    return null;
  }

  return (
    <HStack justify="between" align="end" className="mb-1 min-h-[24px]">
      <FormLabel helpText={helpText} isRequired={isRequired} {...labelProps}>
        {label}
      </FormLabel>
      
      {isDisabled && disabledExplainer}
    </HStack>
  );
};