import { type GetHrisSettingsQueryDto } from "@/features/hris/types/common";
import { type UseQueryOptions, keepPreviousData, useQuery } from "@tanstack/react-query";
import { hrisQueryKeys } from "@/features/hris/types/hrisQueryKeys";
import api from "@/lib/http/api";

export const useGetHrisSettingsQuery = (options?: Omit<UseQueryOptions<GetHrisSettingsQueryDto>, "queryKey">) => useQuery<GetHrisSettingsQueryDto>({
  queryKey: hrisQueryKeys.settings(),
  queryFn: async () => {
    const res = await api.get<GetHrisSettingsQueryDto>("/hris/settings");

    return res.data;
  },
  ...options,
  placeholderData: keepPreviousData
});