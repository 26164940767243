import { CardContext } from "./SelectableCardGroup";
import { type RadioProps } from "@react-types/radio";
import {
  VisuallyHidden,
  mergeProps,
  useFocusRing,
  useRadio
} from "react-aria";
import { useContext, useId, useRef } from "react";
import classNames from "classnames";

import { type RadioGroupState } from "react-stately";
import styles from "./selectable-card-group.module.css";

interface CardProps extends RadioProps {
  title: string;
}

export const SelectableCard = (props: CardProps) => {
  const state = useContext(CardContext);

  if (!state) {
    return null;
  }

  return <SelectableCardWithState {...props} state={state} />;
};

interface SelectableCardWithStateProps extends CardProps {
  state: RadioGroupState;
}

const SelectableCardWithState = ({ state, ...props }: SelectableCardWithStateProps) => {
  const ref = useRef(null);
  const { inputProps } = useRadio(props, state, ref);
  const { focusProps, isFocusVisible } = useFocusRing();
  const { children, value } = props;

  const titleId = useId();
  const descriptionId = useId();

  const className = classNames(styles.selectableCard, {
    [styles.isSelected]: state.selectedValue === value,
    [styles.isFocusVisible]: isFocusVisible
  });

  return (
    <label className={className}>
      <VisuallyHidden>
        <input
          {...mergeProps(inputProps, focusProps)}
          ref={ref}
          aria-labelledby={titleId}
          aria-describedby={descriptionId}
        />
      </VisuallyHidden>
      <div id={descriptionId}>{children}</div>
    </label>
  );
};
