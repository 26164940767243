import { type QueryFunctionContext } from "@tanstack/react-query";
import { type userQueryKeys } from "@/features/users/types/userQueryKeys";
import api from "@/lib/http/api";

export const getUsers = async ({ queryKey: [, params] }: QueryFunctionContext<ReturnType<typeof userQueryKeys["list"]>>) => {
  const { data } = await api.get("/users", {
    params
  });

  return data;
};
