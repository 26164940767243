import { Button, type ButtonProps } from "@/components";
import { useTranslation } from "@/hooks/useTranslation";
import { Grid, Text } from "@workleap/orbiter-ui";
import classNames from "classnames";
import { type ReactNode } from "react";
import { OverlayContainer } from "react-aria";
import { type OverlayTriggerState } from "react-stately";
import { ModalDialog } from "./ModalDialog";

export interface ConfirmationModalProps {
  children?: ReactNode;
  className?: string;
  overlayState?: OverlayTriggerState;
  title: string;
  onClickConfirm: () => void;
  onClickCancel?: () => void;
  confirmButtonContent?: ReactNode;
  confirmButtonProps?: Partial<ButtonProps>;
  cancelButtonContent?: ReactNode;
  cancelButtonProps?: Partial<ButtonProps>;
  dontCloseOnConfirm?: boolean;
}

export const ConfirmationModal = ({
  children,
  className,
  title,
  overlayState,
  onClickConfirm: onConfirm,
  onClickCancel: onCancel,
  confirmButtonContent,
  dontCloseOnConfirm,
  confirmButtonProps = {},
  cancelButtonContent,
  cancelButtonProps = {}
}: ConfirmationModalProps) => {
  const { t } = useTranslation();

  if (overlayState && !overlayState.isOpen) {
    return null;
  }

  const onClickConfirm = () => {
    onConfirm();
    if (!dontCloseOnConfirm) {
      overlayState?.close();
    }
  };

  const onClickCancel = () => {
    onCancel?.();
    overlayState?.close();
  };

  return (
    <OverlayContainer>
      <ModalDialog
        title={title}
        onClose={overlayState?.close}
        centered
        isOpen
      >
        <div className={classNames("w-[460px] grid grid-cols-1 gap-space-stack-xl", className)}>
          {typeof children === "string" ? (
            <Text color="neutral-weak" className="flex justify-center text-center">{children}</Text>
          ) : children}
          <Grid gap="inline-md" templateColumns="1fr 1fr">
            <Button
              onPress={onClickCancel}
              variant="secondary"
              fluid
              {...cancelButtonProps}
            >
              {cancelButtonContent || t("common.form.cancel")}
            </Button>

            <Button
              onPress={onClickConfirm}
              variant="danger"
              fluid
              {...confirmButtonProps}
            >
              {confirmButtonContent || t("common.form.ok")}
            </Button>
          </Grid>
        </div>
      </ModalDialog>
    </OverlayContainer>
  );
};