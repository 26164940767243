import { FieldFormHeader, type FieldFormHeaderProps, FormFieldWrapper, HStack, IconButton, Text } from "@/components";
import { EmptyState } from "@/components/search-empty-state/SearchEmptyState";
import { LoadingAnimation } from "@/components/select-with-search/LoadingAnimaton";
import { RemoveIcon, SearchIcon } from "@hopper-ui/icons";
import { type FetchStatus } from "@tanstack/react-query";
import classNames from "classnames";
import Downshift, { type StateChangeOptions } from "downshift";
import { isEmpty } from "lodash";
import { type Key, type ReactElement, type RefObject, useEffect, useRef, useState } from "react";

interface Props<TItem> extends FieldFormHeaderProps {
  description?: string;
  errorMessage?: string;
  resultsTitle?: string;
  results?: TItem[];
  disabledItems?: TItem[];
  setQuery: (query: string) => void;
  query: string;
  fetchStatus: FetchStatus;
  initialValue?: TItem;
  errorStyle?: string;
  onInputChange?: (value?: string) => void;
  onItemSelected?: (key?: Key) => void;
  itemDisplay: (item: TItem) => ReactElement;
  keySelector: (item: TItem) => Key | undefined;
  itemToText: (item: TItem) => string;
  className?: string;
  clearInputOnSelect?: boolean;
  emptyState?: ReactElement;
  inputRef?: RefObject<HTMLInputElement>;
  allowClear?: boolean;
  comparator?: (disabledItems: TItem[], item: TItem) => boolean;
  size?: "sm" | "lg";
  hideIcon?: boolean;
}

const OPTION_CLASSES = "flex items-center list-none px-4 py-1.5 font-410 text-sm cursor-auto";
const BASE_INPUT_CLASSES = "relative input z-10";

const LIGHT_INPUT_CLASSES = "bg-neutral-surface border-neutral-border text-neutral-text";

export const TypeAhead = <T, TItem = T>({
  label,
  description,
  errorMessage,
  onInputChange,
  onItemSelected,
  setQuery,
  query,
  fetchStatus,
  resultsTitle,
  results,
  initialValue,
  errorStyle,
  keySelector,
  itemDisplay,
  itemToText,
  clearInputOnSelect,
  className,
  emptyState,
  inputRef,
  allowClear,
  isDisabled,
  disabledItems = [],
  comparator,
  size = "sm",
  hideIcon,
  ...headerProps
}: Props<TItem>) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [input, setInput] = useState(initialValue
    ? itemToText(initialValue)
    : "");
  const [dropdownWidth, setWidth] = useState(0);
  
  useEffect(() => {
    if (ref?.current?.offsetWidth) {
      setWidth(ref?.current?.offsetWidth);
    }
  }, [ref?.current?.offsetWidth]);
  
  useEffect(() => {
    onInputChange?.(input as string);
  }, [input]);

  const onStateChange = (changes: StateChangeOptions<string>) => {
    if (changes.type === Downshift.stateChangeTypes.clickItem) {
      const matchingItem = results?.find(i => keySelector(i) === changes.selectedItem);
      onInputChange?.(changes.selectedItem ?? undefined);
      onItemSelected?.(changes.selectedItem ?? undefined);

      if (clearInputOnSelect) {
        setInput("");
        setQuery("");
      } else if (matchingItem) {
        setInput(itemToText(matchingItem));
      }
    }
  };

  const onClearInput = () => {
    setInput("");
    setQuery("");
    inputRef?.current?.focus();
  };

  const hasResults = !!results && results.length > 0;
  const showEmptyState = !hasResults && fetchStatus !== "fetching" && !!query.length;
  const canShow = hasResults || showEmptyState;

  const isItemDisabled = (item: TItem) => {
    if (!comparator) {
      return false;
    }

    return !!comparator(disabledItems, item);
  };

  return (
    <FormFieldWrapper
      errorMessage={errorMessage}
      errorStyle={errorStyle}
    >
      <Downshift
        onStateChange={onStateChange}
        selectedItem={input}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
          selectedItem,
          openMenu
        }) => (
          <div
            className={classNames(className, {
              "h-[64px]": label,
              "h-[34px]": !label,
              "h-[70px]": label && size === "lg",
              "h-[40px]": !label && size === "lg"
            })}
          >
            <FieldFormHeader
              {...headerProps}
              isDisabled={isDisabled}
              label={label}
              labelProps={getLabelProps()}
            />
            <div className="relative" ref={ref}>
              {!hideIcon && (
                <SearchIcon
                  size="sm"
                  className={classNames("z-50 absolute top-1/2 -translate-y-1/2 transform left-3 text-neutral-text", {
                    "text-neutral-text-weak": isDisabled
                  })}
                  aria-hidden="true"
                />
              )}
              <input
                {...getInputProps({
                  value: input,
                  onFocus: () => openMenu(),
                  onClick: () => openMenu(),
                  onChange: e => {
                    onInputChange?.(e.target.value);
                    // used for the request
                    setQuery(e.target.value);
                    // used for the displaying the input value
                    setInput(e.target.value);
                  }
                })}
                className={classNames(BASE_INPUT_CLASSES, LIGHT_INPUT_CLASSES, {
                  "bg-neutral-surface pl-5 cursor-not-allowed text-neutral-text-weak border-neutral-surface": isDisabled,
                  "border rounded-shape-rounded-md": !isOpen || !canShow,
                  "border-t border-l border-r rounded-t-shape-rounded-md": isOpen && canShow,
                  "input--large": size === "lg",
                  "pl-9": !hideIcon
                })}
                disabled={isDisabled}
                placeholder={description}
                ref={inputRef}
              />
              <HStack className="absolute z-40 transform -translate-y-1/2 top-1/2 right-3" align="center" justify="center">
                <LoadingAnimation fetchStatus={fetchStatus} />
                {!isEmpty(input) && allowClear && (
                  <IconButton 
                    icon={<RemoveIcon />}
                    onPress={onClearInput}
                    variant="ghost"
                  />
                )}
              </HStack>
            </div>
            <div
              {...getMenuProps()}
              className={classNames("absolute z-[100] overflow-x-hidden shadow-elevation-raised rounded-b-shape-rounded-md bg-neutral-surface border-neutral-border", {
                "border-b border-l border-r": isOpen && canShow
              })}
              style={{ width: `${dropdownWidth}px` }}
            >
              {isOpen && canShow && hasResults && (
                <div className="max-h-[350px] z-[100]">
                  {resultsTitle && (
                    <Text
                      size="xs"
                      className="uppercase p-[18px]"
                      color="neutral-text-weak"
                    >
                      {resultsTitle}
                    </Text>
                  )}
                  {results?.map((item, index) => {
                    const isResultDisabled = 
                      isEmpty(disabledItems)
                        ? false
                        : isItemDisabled(item);

                    const props = isResultDisabled
                      ? { isDisabled: true }
                      : getItemProps({
                        key: keySelector(item),
                        index,
                        item: keySelector(item) as string
                      });

                    return (
                      <div
                        {...props}
                        className={classNames(OPTION_CLASSES, "hover:bg-primary-surface-weak", {
                          "text-neutral-text": highlightedIndex === index || selectedItem === item,
                          "cursor-not-allowed": isItemDisabled(item),
                          "cursor-pointer": !isItemDisabled(item)
                        })}
                      >
                        {itemDisplay(item)} 
                      </div>
                    );
                  })}
                </div>
              )}
              <EmptyState
                isOpen={isOpen}
                showEmptyState={showEmptyState}
                emptyState={emptyState}
              />
            </div>
          </div>
        )}
      </Downshift>
    </FormFieldWrapper>
  );
};