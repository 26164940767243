import { Card, CardHeader, HStack, IconText, Text, VStack } from "@/components";
import { AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { ConnectHrisButton } from "@/features/hris/components/button/ConnectHrisButton";
import { useTrackHrisAction } from "@/features/hris/hooks/useTrackHrisAction";
import { useSupport } from "@/features/support/hooks/useSupport";
import { useTranslation } from "@/hooks/useTranslation";
import { RemoveIcon, SuccessIcon } from "@hopper-ui/icons";
import { Link } from "react-router-dom";

export const HrisDisconnectedSettings = () => {  
  const { t } = useTranslation();
  const { contactSupport } = useSupport();
  const { trackHrisAction } = useTrackHrisAction();

  const onContactUsClick = () => {
    trackHrisAction(AnalyticValues.actionContactUs);
    contactSupport();
  };

  return (
    <VStack gap={6}>
      <Card>
        <VStack gap={4}>
          <CardHeader title={t("hrisSettings.connect.title")} />
          <Text>{t("hrisSettings.connect.subtitle")}</Text>
          <ul>
            <li>
              <IconText icon={<SuccessIcon className="text-status-positive-icon" />}>
                {t("hrisSettings.connect.list.item1")}
              </IconText>
            </li>
            <li>
              <IconText icon={<RemoveIcon className="text-danger-icon-press" />}>
                {t("hrisSettings.connect.list.item2")}
              </IconText>
            </li>
            <li>             
              <IconText icon={<RemoveIcon className="text-danger-icon-press" />}>
                {t("hrisSettings.connect.list.item3")}
              </IconText>
            </li>
          </ul>
          <HStack gap={4} align="center">
            <ConnectHrisButton />
            <Link to="https://help.skills.workleap.com/en/articles/7851814-how-to-connect-your-hr-platform-hris" target="_blank">
              <Text color="inherit" className="underline text-status-progress-text">{t("common.phrases.tellMeMore")}</Text>
            </Link>
          </HStack>
        </VStack>
      </Card>
      <button onClick={onContactUsClick}>
        <Text color="tertiary-text" className="underline">{t("ats.connect.contactUs")}</Text>
      </button>
    </VStack>
  );
};