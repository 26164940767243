import { Card, Switch, Text, VStack } from "@/components";
import { useGetSettingsQuery } from "@/features/tenants/hooks/useGetSettingsQuery";
import { useToggleManagerTeamMemberInfoAccess } from "@/features/tenants/hooks/useToggleManagerTeamMemberInfoAccess";
import { useTranslation } from "@/hooks/useTranslation";

export const PermissionsSettingsForm = () => {
  const { data: settingsQueryData } = useGetSettingsQuery();

  const { t } = useTranslation();
  const { mutate } = useToggleManagerTeamMemberInfoAccess();
  
  const onToggleChange = (selected: boolean) => {
    mutate({ isEnabled: selected });
  };

  return (
    <Card className="!py-4 !px-6 !border-0">
      <div className="flex items-center gap-4">
        <VStack gap={0} className="flex-1">
          <Text color="inherit">
            {t("settings.permissions.managerAccess.title")}
          </Text>
          <Text size="xs">
            {t("settings.permissions.managerAccess.description")}
          </Text>
        </VStack>
        <Switch
          aria-label={t("settings.permissions.managerAccess.ariaLabel")}
          isSelected={settingsQueryData?.enableManagerTeamMemberInfoAccess} 
          onChange={onToggleChange}
        />
      </div>
    </Card>
  );
};
