import { type ComponentProps } from "react";
import { ExternalChip } from "@/features/search/components/search-role-then-job/components/ExternalChip";
import { HStack, Text } from "@/components";
import { SelectWithSearch } from "@/components/select-with-search/SelectWithSearch";
import { useJobTitleSearch } from "@/hooks/useJobTitleSearch";
import { useMarketContentTranslation } from "@/hooks/useTranslation";

export interface JobTitleOptionItem {
  label: string;
  value?: string;
}

type PropsKeysToOmit = "setQuery" | "results" | "fetchStatus" | "itemToText" | "keySelector" | "itemDisplay";
interface Props extends Omit<ComponentProps<typeof SelectWithSearch<JobTitleOptionItem>>, PropsKeysToOmit> {
  showExternalChip?: boolean;
}

interface HookProps extends Pick<Props, "onSelect" | "onClear"> {
  initialValue?: string;
  showExternalChip?: boolean;
}

export const useSearchJobTitles = ({ onSelect, onClear, showExternalChip, initialValue }: HookProps) => {
  const { translateMarketContent } = useMarketContentTranslation();
  const {
    data: jobData,
    fetchStatus: jobFetchStatus,
    setQuery: setJobQuery
  } = useJobTitleSearch();

  const onSelectRole = (item: JobTitleOptionItem) => {
    const { value } = item;
    if (value) {
      setJobQuery(value);
    }

    onSelect(item);
  };

  const onClearRole = () => {
    setJobQuery("");
    onClear?.();
  };

  const roleOptions = jobData?.map(x => ({ label: translateMarketContent(x), value: x })) ?? [];
  
  return {
    setQuery: setJobQuery,
    results: roleOptions,
    fetchStatus: jobFetchStatus,
    itemToText: ({ label }: JobTitleOptionItem) => label,
    keySelector: ({ value }: JobTitleOptionItem) => value,
    itemDisplay: ({ label, value }: JobTitleOptionItem) => showExternalChip ? (
      <HStack gap={2} align="center">
        <Text>
          {label}
        </Text>
        <ExternalChip />
      </HStack>
    ) : (
      <div key={value}>{label}</div>
    ),
    defaultSelectedItem: { label: translateMarketContent(initialValue ?? ""), value: initialValue },
    onSelect: onSelectRole,
    onClear: onClearRole
  };
};

export const SearchJobTitles = (props: Props) => {
  const customProps = useSearchJobTitles(props);

  return (
    <SelectWithSearch {...props} {...customProps} />
  );
};