import { Card, ConditionalLink, HStack, Text, VStack } from "@/components";
import { PremiumBadge } from "@/features/billing/components/PremiumBadge";
import { type Feature } from "@/lib/features/types/feature";

export interface PageLinkProps {
  description: string;
  title: string;
  to: string;
  blockedFeature?: Feature;
}

export const PageLink = ({ description, title, to, blockedFeature }: PageLinkProps) => (
  <ConditionalLink to={to} condition={!blockedFeature}>
    <Card clickable={!blockedFeature}>
      <HStack justify="between" align="center">
        <VStack gap={1.5}>
          <Text weight={690} color={blockedFeature ? "neutral-text-weak" : "neutral-text"}>
            {title}
          </Text>
          <Text size="xs">
            {description}
          </Text>
        </VStack>
        <PremiumBadge feature={blockedFeature} size="sm" />
      </HStack>
    </Card>
  </ConditionalLink>
);