import { type UpdateUserProfileCommand } from "@/features/profile/types/common";
import { currentUserQueryKey } from "@/lib/auth/AuthProvider";
import { employeesQueryKeys } from "@/features/employees/types/employeesQueryKeys";
import { toast } from "react-toastify";
import { translate } from "@/lib/i18n";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import { useQueryClient } from "@tanstack/react-query";
import { userProfileQueryKeys } from "./userProfileQueryKeys";
import { userQueryKeys } from "@/features/users/types/userQueryKeys";
import api from "@/lib/http/api";

interface PostModel {
  avatarFile?: File;
  oldAvatarUrl?: string;
  formData: UpdateUserProfileCommand;
}

export const useUpdateUserProfileMutation = (employeeId: string, onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutationWithValidationResult<PostModel>({
    mutationFn: async ({ avatarFile, oldAvatarUrl, formData }: PostModel) => {
      await api.post("/user-profile", formData);

      if (avatarFile) {
        const avatarData = new FormData();
        avatarData.append("file", avatarFile);
    
        await api.post(`employees/${employeeId}/avatar`, avatarData);
      } else if (oldAvatarUrl) {
        await api.post(`employees/${employeeId}/remove-avatar`);
      }
    },
    onSuccess: async () => {
      const keysToInvalidate = [userProfileQueryKeys.single, employeesQueryKeys.all, userQueryKeys.all, currentUserQueryKey];

      await Promise.all(keysToInvalidate.map(async key => {
        await queryClient.invalidateQueries({ queryKey: [key] });
      }));

      toast.success(translate("common.messages.changesSaved"));
      onSuccess();
    }
  });
};