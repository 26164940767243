import { FieldFormHeader, FormFieldWrapper, type FieldFormHeaderProps } from "@/components";
import { useTextField, type AriaTextFieldOptions } from "@react-aria/textfield";
import classNames from "classnames";
import { useRef, type ReactNode } from "react";

interface Props extends AriaTextFieldOptions<"input">, FieldFormHeaderProps {
  description?: string;
  errorMessage?: string;
  value?: string;
  errorStyle?: string;
  className?: string;
  iconRight?: ReactNode;
  iconLeft?: ReactNode;
  variant?: "small" | "medium" | "large";
}

export const TextField = (props: Props) => {
  const { label, errorMessage, description, errorStyle, className, iconRight, iconLeft, variant = "small", ...formHeaderProps } = props;
  const ref = useRef(null);
  const { labelProps, inputProps, errorMessageProps } = useTextField({ ...props, "aria-label": props["aria-label"] || "text field" }, ref);

  const iconPositioningClasses = "absolute transform translate-y-1/2 bottom-1/2 text-neutral-icon-weak flex items-center";

  return (
    <FormFieldWrapper
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      errorStyle={errorStyle}
      className="flex-1"
    >
      <FieldFormHeader {...formHeaderProps} label={label} labelProps={labelProps} />
      <div className="relative w-full">
        {iconLeft && (
          <div className={classNames(iconPositioningClasses, "left-space-inset-md")}>
            {iconLeft}
          </div>
        )}
        <input
          {...inputProps}
          className={classNames("input", className, {
            "input--medium": variant === "medium",
            "input--large": variant === "large",
            "pr-10": iconRight,
            "pl-10": iconLeft
          })}
          ref={ref}
          placeholder={description}
        />
        {iconRight && (
          <div className={classNames(iconPositioningClasses, "right-space-inset-md")}>
            {iconRight}
          </div>
        )}
      </div>
    </FormFieldWrapper>
  );
};