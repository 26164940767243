import { ActionButtonDropdown, type ActionButtonItem, DeleteConfirmationModal } from "@/components";
import { type CustomMarketSkillDto } from "@/features/skills/types/common";
import { Routes } from "@/types";
import { generatePath, useNavigate } from "react-router-dom";
import { useAuth } from "@/lib/auth/AuthProvider";
import { useDeleteCustomMarketSkillMutation } from "@/features/skills/hooks/useDeleteCustomMarketSkill";
import { useOverlayTriggerState } from "react-stately";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  skill: CustomMarketSkillDto;
}

type ActionItemType = "edit" | "delete";

export const SkillCustomDescriptionsListActions = ({ skill: { marketId, id } }: Props) => {
  const { t } = useTranslation();
  const { currentUserHasPermission } = useAuth();
  const navigate = useNavigate();
  const deleteMetadataModalState = useOverlayTriggerState({});
  const { mutate: onDeleteItem } = useDeleteCustomMarketSkillMutation(id);

  const actionItems: ActionButtonItem<ActionItemType>[] = [];
  const onEditClick = () => navigate(generatePath(Routes.EditSkillCustomDescriptions, { marketId: encodeURIComponent(marketId) }));

  if (currentUserHasPermission("Permissions.Skills.UpdateSkill")) {
    actionItems.push({
      key: "edit",
      text: t("common.actions.edit"),
      onClick: onEditClick
    });
  }

  if (currentUserHasPermission("Permissions.Skills.DeleteSkill")) {
    actionItems.push({
      key: "delete",  
      text: t("common.actions.delete"),
      onClick: deleteMetadataModalState.open,
      isDestructive: true
    });
  }

  return (
    <>
      <ActionButtonDropdown actions={actionItems} />
      <DeleteConfirmationModal
        title={t("skills.customDescriptions.deleteModal.title")}
        confirmationMessage={t("skills.customDescriptions.deleteModal.confirmationMessage", { skillName: marketId })}
        onDelete={onDeleteItem}
        overlayState={deleteMetadataModalState}
      />
    </>
  );
};
