import { type Feature } from "@/lib/features/types/feature";
import { HStack } from "@/components";
import { LayoutGroup } from "framer-motion";
import { PremiumBadge } from "@/features/billing/components/PremiumBadge";
import { type ReactNode, type Ref, createRef, forwardRef, useEffect, useState } from "react";
import { TabUnderline } from "./TabUnderline";
import { useSearchParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import classNames from "classnames";
import styles from "./Tabs.module.css";

export interface TabInterface<TRoute = string> {
  name: string;
  route: TRoute;
  title?: ReactNode;
  blockedFeature?: Feature;
}

export interface Props {
  tabs: TabInterface[];
  className?: string;
  useParams?: boolean;
  searchParamKey?: string;
  onSelect: (route: string) => void;
  initialActiveTab?: string;
  isLoading?: boolean;
}

export const Tabs = ({ useParams, ...rest }: Props) => {
  if (useParams) {
    return <ParamBasedTabs {...rest} />;
  }

  return <StateBasedTabs {...rest} />;
};

const StateBasedTabs = ({ onSelect, initialActiveTab, ...props }: Omit<Props, "useParams">) => {
  const { tabs } = props;
  const [activeTab, setActiveTab] = useState(initialActiveTab);

  useEffect(() => {
    if (!activeTab) {
      const [ firstTab ] = tabs;
      setActiveTab(firstTab.route);
      onSelect(firstTab.route);
    }
  }, [activeTab]);
  
  const onClick = (route: string) => {
    setActiveTab(route);
    onSelect(route);
  };

  return (
    <TabListing
      onClick={onClick}
      activeTab={activeTab || tabs[0].route}
      {...props}
    />
  );
};

const ParamBasedTabs = ({ onSelect, searchParamKey = "tab", ...props }: Omit<Props, "useParams">) => {
  const { tabs } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get(searchParamKey);
  const blockedTabs = tabs.filter(tab => !!tab.blockedFeature);

  const onClick = (route: string) => {
    const page = searchParams.get("page");

    if (page) {
      searchParams.delete("page");
    }

    searchParams.set(searchParamKey, route);
    setSearchParams(searchParams);
    onSelect(route);
  };

  const goToFirstTab = () => {
    onSelect(tabs[0].route);
    searchParams.set(searchParamKey, tabs[0].route);
    setSearchParams(searchParams, { replace: true });
  };

  useEffect(() => {
    if (!activeTab || blockedTabs.find(blockedTab => blockedTab.route === activeTab)) {
      goToFirstTab();

      return;
    }

    onSelect(activeTab);
  }, [activeTab, blockedTabs]);
  
  return (
    <TabListing
      onClick={onClick}
      activeTab={activeTab || tabs[0]?.route}
      {...props}
    />
  );
};

interface TabListingProps extends Omit<Props, "onSelect" | "useParams"> {
  activeTab: string;
  onClick: (route: string) => void;
}

const TabListing = ({ tabs, className, activeTab, ...props }: TabListingProps) => {
  const tabRefs = tabs.reduce((acc: Record<string, Ref<HTMLButtonElement>>, item) => {
    acc[item.route] = createRef();

    return acc;
  }, {});

  return (
    <LayoutGroup>
      <div className={classNames(className, styles.tabsWrapper)}>
        <ul role="tablist" aria-orientation="horizontal" className={styles.tabs}>
          {tabs.map(tab => (
            <Tab
              key={tab.name}
              item={tab}
              ref={tabRefs[tab.route]}
              active={activeTab === tab.route}
              {...props}
            />
          ))}
        </ul>
        <TabUnderline refs={tabRefs} activeTab={activeTab} />
      </div>
    </LayoutGroup>
  );
};

interface TabProps {
  active: boolean;
  item: TabInterface;
  onClick: (route: string) => void;
  isLoading?: boolean;
}

const Tab = forwardRef(
  ({ active, item: { name, route, title, blockedFeature }, onClick, isLoading }: TabProps, ref: Ref<HTMLButtonElement>) => (
    <li key={`tab-${name}`}>
      <HStack gap={3} align="center">
        <PremiumBadge feature={blockedFeature} />
        {isLoading
          ? (
            <Skeleton height={20} width={100} />
          )
          : (
            <button
              className={classNames(styles.tab, {
                [styles.active]: active
              })}
              disabled={!!blockedFeature}
              ref={ref}
              type="button"
              onClick={() => onClick(route)}
            >
              {title || name}
            </button>
          )}
      </HStack>
    </li>
  )
);
