import { HStack } from "@/components";
import { type EmployeeRow } from "@/features/reports/types/search";
import { type CollaboratorsDto } from "@/features/role/types";
import { UserAvatarList } from "@/features/users/components/UserAvatarList";
import { useTranslation } from "@/hooks/useTranslation";
import { AuthorizeResource, type AuthorizeResourceProps } from "@/lib/auth/AuthorizeResource";
import { AddUserIcon } from "@hopper-ui/icons";
import { Button, Div, Text } from "@workleap/orbiter-ui";
import { take } from "lodash";
import { createContext, useContext, useMemo } from "react";
import { useOverlayTriggerState } from "react-stately";
import { CollaborateModal } from "./CollaborateModal";
import { type CollaborateVariant } from "./types";

interface CollaborateContextState {
  onAddCollaboratorClick: (activeCollaborators: CollaboratorsDto, employee: EmployeeRow) => void;
  onRemoveCollaboratorClick: (activeCollaborators: CollaboratorsDto, collaboratorId: string) => void;
  activeCollaborators: CollaboratorsDto;
  activeCollaboratorIds: string[];
}

const CollaborateContext = createContext<CollaborateContextState | null>(null);

export const useCollaborateContext = () => {
  const context = useContext(CollaborateContext);
  
  if (!context) {
    throw new Error("Collaborate compound components cannot be rendered outside the <Collaborate /> component");
  }
  
  return context;
};

interface Props extends AuthorizeResourceProps {
  onAddCollaboratorClick: (activeCollaborators: CollaboratorsDto, employee: EmployeeRow) => void;
  onRemoveCollaboratorClick: (activeCollaborators: CollaboratorsDto, collaboratorId: string) => void;
  activeCollaborators: CollaboratorsDto;
  allowCurrentUserAsCollaborator?: boolean;
  hideMentors?: boolean;
  isDisabled?: boolean;
}

export const Mentors = (props: Props) => <Collaborate variant="mentor" {...props} />;
export const Collaborators = (props: Props) => <Collaborate variant="collaborate" {...props} />;

interface CollaborateProps extends Props, AuthorizeResourceProps {
  variant: CollaborateVariant;
}

const Collaborate = ({ onAddCollaboratorClick, onRemoveCollaboratorClick, activeCollaborators, variant, allowCurrentUserAsCollaborator, resourceId, resourceType, permission, hideMentors, isDisabled }: CollaborateProps) => {
  const overlayState = useOverlayTriggerState({});
  const { t } = useTranslation();

  const activeCollaboratorIds = useMemo(() => activeCollaborators.map(({ id }) => id), [activeCollaborators]);

  return (
    <CollaborateContext.Provider value={{ onAddCollaboratorClick, onRemoveCollaboratorClick, activeCollaborators, activeCollaboratorIds }}>
      <HStack align="center" gap={0}>
        <AuthorizeResource resourceId={resourceId} resourceType={resourceType} permission={permission}>
          <Button
            size="sm"
            variant="secondary"
            onClick={overlayState.open}
            disabled={isDisabled}
          >
            <AddUserIcon size="sm" />
            <Text>
              {variant === "collaborate" ? t("collaborate.view") : t("mentor.view")}
            </Text>
            <Div slot="end-icon">
              {!hideMentors && activeCollaborators.length ? <ListButtonCollaborators /> : null}
            </Div>
          </Button>
        </AuthorizeResource>
      </HStack>
      <CollaborateModal variant={variant} overlayState={overlayState} allowCurrentUserAsCollaborator={allowCurrentUserAsCollaborator} />
    </CollaborateContext.Provider>
  );
};

const ListButtonCollaborators = () => {
  const { activeCollaborators } = useCollaborateContext();

  const shownCollaborators = take(activeCollaborators, 3);

  return (
    <UserAvatarList size="xs" userAvatars={shownCollaborators} />
  );
};
