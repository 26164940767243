import { Text } from "@/components";
import styles from "./HrisListItem.module.css";

interface Props {
  data: {
    name: string;
    slug: string;
    squareImage: string;
  };
}

export const HrisListItem = ({ data }: Props) => (
  <div className={styles.hrisItem}>
    <img src={data.squareImage} width={24} height={24} alt="square" />
    <Text size="xs">{data.name}</Text>
  </div>
);