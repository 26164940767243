import { AnalyticEvents, AnalyticProperties } from "@/features/analytics/types/analyticEvents";
import { type Dict } from "mixpanel-browser";
import { useMixpanel } from "@/features/analytics/hooks/useMixpanel";

export const useTrackAtsPositionAction = () => {  
  const { trackEvent } = useMixpanel();

  const trackAtsPositionAction = (action: string) => {
    const properties: Dict = {};
    properties[AnalyticProperties.action] = action;
    trackEvent(AnalyticEvents.atsPositionAction, properties);
  };
  
  return {
    trackAtsPositionAction
  };
};