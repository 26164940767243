import { BulkActionsDropdownMenu } from "@/features/users/components/BulkActionsDropdownMenu";
import { BulkImportButton } from "./UsersPageBulkImportButton";
import { HStack, Heading, Text, VStack } from "@/components";
import { Helmet } from "react-helmet-async";
import { type InitialFilterQueryParamKeys } from "@/features/filter/types/common";
import { InviteNewUserModal } from "@/features/users/components/InviteNewUserModal";
import { InviteUserButton } from "@/features/users/components/InviteUserButton";
import { type UserDto, type UserFilterQueryParamKeys, type UserFilters, userFilterKeys, userFilterKeysConst } from "@/features/users/types/common";
import { UserList } from "@/features/users/components/user-list/UserList";
import { UsersFilter } from "./UsersPageFilter";
import { useFilters } from "@/features/filter";
import { useInvalidateUsers } from "@/features/users/hooks/useUsers";
import { useNavigate } from "react-router-dom";
import { useOverlayTriggerState } from "react-stately";
import { useState } from "react";
import { useTranslation } from "@/hooks/useTranslation";

export const UsersPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { invalidate } = useInvalidateUsers();
  const inviteNewUserModalState = useOverlayTriggerState({});
  const [usersChecked, setUsersChecked] = useState<UserDto[]>([]);
  const { page, queryFilters, ...otherProps } = useFilters<UserFilters, UserFilterQueryParamKeys & InitialFilterQueryParamKeys>(
    userFilterKeys, 
    "users", { 
      additionalListFilterKeys: Object.values(userFilterKeysConst)
    });

  const onEdit = (employeeId: string) => {
    navigate(`/settings/users/edit/${employeeId}`);
  };

  const onClickOpenInviteModal = () => {
    inviteNewUserModalState.open();
  };

  return (
    <>
      <Helmet>
        <title>{t("pages.settingsUsers")}</title>
      </Helmet>
      <VStack gap={6}>
        <HStack align="center" justify="between">
          <VStack gap={1}>
            <Heading size="xl">
              {t("users.heading.title")}
            </Heading>
            <Text>{t("users.heading.subtitle")}</Text>
          </VStack>
          {usersChecked.length === 0
            ? (
              <HStack>
                <UsersFilter {...otherProps} />
                <BulkImportButton />
                <InviteUserButton onClick={onClickOpenInviteModal} />
              </HStack>
            )
            : (
              <HStack align="center" gap={6}>
                <Text color="tertiary-text">{t("users.heading.selected", { count: usersChecked.length })}</Text>
                <BulkActionsDropdownMenu selectedUsers={usersChecked} />
              </HStack>
            )}
        </HStack>
        <UserList {...{ onEdit, setUsersChecked, page }} filters={queryFilters} />
      </VStack>
      <InviteNewUserModal overlayState={inviteNewUserModalState} onSuccess={invalidate} />
    </>
  );
};
