import { type AppPermission } from "./types";
import { DemoWrapper } from "@/features/demo";
import { type ReactElement } from "react";
import { hasAccess } from "./hasAccess";
import { useAuth } from "./AuthProvider";

export interface AuthorizeProps {
  children: ReactElement;
  permission?: AppPermission | AppPermission[];
}

/**
 * Use for Role-based permissions (admin vs regular employee).
 * Checks current user's role.
 */
export const Authorize = ({ children, permission }: AuthorizeProps) => {
  const { currentUser } = useAuth();

  if (!permission) {
    return <>{children}</>;
  }

  const isAuthorized = Array.isArray(permission)
    ? permission.every(p => hasAccess(p, currentUser))
    : hasAccess(permission, currentUser);
    
  if (!isAuthorized) {
    if (currentUser?.isDemo) {
      return <DemoWrapper>{children}</DemoWrapper>;
    }

    return null;
  }

  return <>{children}</>;
};
