import { type UpdateSettingsCommand } from "@/features/tenants/types/settings";
import { authQueryKeys } from "@/lib/auth/authQueryKeys";
import { settingsQueryKeys } from "@/features/tenants/types/settingsQueryKeys";
import { toast } from "react-toastify";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "@/hooks/useTranslation";
import api from "@/lib/http/api";

export const useUpdateSettingsMutation = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  
  return useMutationWithValidationResult({
    mutationFn: async (payload: UpdateSettingsCommand) => await api.post<UpdateSettingsCommand>("/settings", payload),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: settingsQueryKeys.all });
      queryClient.invalidateQueries({ queryKey: authQueryKeys.currentUser() });
      toast.success(t("common.messages.changesSaved"));
    }
  });
};