import { useConnectHrisStore } from "./useConnectHrisStore";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { useSaveHrisAccountTokenMutation } from "./useSaveHrisAccountTokenMutation";
import { useState } from "react";

export const useConnectHris = (onConnected?: () => void) => {
  const [linkToken, setLinkToken] = useState("");
  const [selectedIntegration, setSelectedIntegration] = useState("");
  const { mutate: saveHrisAccountTokenCommand } = useSaveHrisAccountTokenMutation();
  const { setShowBanner } = useConnectHrisStore();
  
  const onSuccess = (publicToken: string) => {
    saveHrisAccountTokenCommand({ 
      integration: selectedIntegration, 
      publicToken 
    });

    setShowBanner(true);

    onConnected?.();
  };

  const { open } = useMergeLink({
    linkToken: linkToken,
    onSuccess
  });

  const openMergeLink = async (integration: string, token: string) => {
    setSelectedIntegration(integration);
    setLinkToken(token);

    open();
  };

  return {
    openMergeLink
  };
};