import { Button, ButtonGroup, HStack } from "@/components";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  onBack: () => void;
  isSavingChanges?: boolean;
  hasFormChanges?: boolean;
  saveBtnLabelOverride?: string;
  cancelBtnLabelOverride?: string;
}

export const EmployeeFormButtons = ({
  onBack,
  isSavingChanges,
  hasFormChanges,
  saveBtnLabelOverride,
  cancelBtnLabelOverride
}: Props) => {
  const { t } = useTranslation();
  
  return ( 
    <HStack justify="end" align="center" className="w-full h-full">
      <ButtonGroup gap={2}>
        <Button
          isDisabled={isSavingChanges}
          variant="secondary"
          onClick={onBack}
        >
          {cancelBtnLabelOverride || t("common.form.discardChanges")}
        </Button>
        <Button
          loading={isSavingChanges}
          isDisabled={!hasFormChanges}
          type="submit"
        >
          {saveBtnLabelOverride || t("common.form.saveChanges")}
        </Button>
      </ButtonGroup>
    </HStack>
  );
};
