import { type UpdateCustomSkillSettingsCommand } from "@/features/skills/types/common";
import { skillsQueryKeys } from "@/features/skills/types/skillsQueryKeys";
import { toast } from "react-toastify";
import { translate } from "@/lib/i18n";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "@/lib/http/api";

export const useUpdateCustomSkillSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (requestPayload : UpdateCustomSkillSettingsCommand) => {
      await api.post("/skills/custom-skills/settings", requestPayload);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: skillsQueryKeys.customSkillSettings() });
      toast.success(translate("common.messages.changesSaved"));
    }
  });
};