import { useSearchParams } from "react-router-dom";

export const useGetCurrentPageParam = () => {
  const [searchParams] = useSearchParams();
  const queryParamsPage = parseInt(searchParams.get("page") ?? "");
  
  return !isNaN(queryParamsPage)
    ? queryParamsPage
    : 1;
};
