import { type EmployeeEditModel, type EmployeeResumeDto, type GetEditEmployeeModelDto } from "@/features/employees/types/common";
import { type ValidationErrorResult } from "@/models/error";
import { fromUnion } from "@/utils/types";

export interface FormResume {
  name: string;
  size: number;
}

export interface NewResumeUpload extends FormResume {
  uploadedFile: File;
}

export type EmployeeFormResume = FormResume | NewResumeUpload;

export interface EmployeeFormState extends Exclude<EmployeeEditModel, "resume"> {
  resume?: EmployeeFormResume;
}

export interface SubmitData {
  avatarFile?: File;
  resume?: FormResume | NewResumeUpload;
  oldAvatarUrl?: string;
  oldResume?: EmployeeResumeDto;
  formData: EmployeeFormState;
}

export interface EmployeeFormProps {
  title: string;
  employee: GetEditEmployeeModelDto;
  showPermissions?: boolean;
  onBack: () => void;
  onSubmit: (formData: SubmitData) => void;
  isSaving: boolean;
  saveErrors?: ValidationErrorResult;
  variant?: "edit" | "create";
  saveBtnLabelOverride?: string;
  cancelBtnLabelOverride?: string;
}


export const NEW_EMPLOYEE_INITIAL_VALUES: GetEditEmployeeModelDto = {
  firstName: "",
  lastName: "",
  employments: [],
  id: "",
  skills: [],
  uncategorizedSkills: [],
  language: "en"
};

export const { isOfTypeTwo: isNewResumeUpload } = fromUnion<FormResume, NewResumeUpload>();
