import { Button, Card, CardHeader, HStack, PageHeader, Switch, Text, TextField, VStack } from "@/components";
import { FormLayout } from "@/components/layouts/FormLayout";
import { useUpdateGeneralSettingsMutation } from "@/features/tenants/hooks/useUpdateGeneralSettings";
import { updateGeneralSchema, type GeneralFormState, type GeneralSettingsDto } from "@/features/tenants/types/settings";
import { useTranslation } from "@/hooks/useTranslation";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { useForm } from "react-hook-form";
import { SelectIndustryTenant } from "./SelectIndustryTenant";

interface Props {
  defaultValues: GeneralSettingsDto;
}

export const SettingsGeneralForm = ({ defaultValues }: Props) => {
  const { t } = useTranslation();
  const { mutate, isPending: isSaving } = useUpdateGeneralSettingsMutation();
  const { handleSubmit, watch, setValue, formState: { isDirty, errors } } = useForm<GeneralFormState>({
    defaultValues,
    resolver: valibotResolver(updateGeneralSchema)
  });

  const companyWebsiteUrl = watch("companyWebsiteUrl");

  const onSubmit = (data: GeneralFormState) => {
    mutate({
      ...data,
      // OpenAPI's schema coerces null types to T | undefined
      // however we need to allow null on the valibot schema because
      // by default the api returns null for this field
      companyWebsiteUrl: data.companyWebsiteUrl === null ?
        undefined : data.companyWebsiteUrl
    });
  };

  return (
    <FormLayout onSubmit={handleSubmit(onSubmit)}>
      <FormLayout.Content>
        <VStack gap={4}>
          <PageHeader title={t("settings.general.title")} />
          <Card>
            <VStack gap={4} className="w-full xl:w-2/3">
              <CardHeader title={t("settings.general.aiConfiguration.title")} subtitle={t("settings.general.aiConfiguration.subtitle")} />
              <SelectIndustryTenant 
                value={watch("industry")}
                onChange={industry => setValue("industry", industry, { shouldDirty: true })}
              />
              <TextField
                label={t("accountSettings.form.companyWebsite.label")}
                description={t("accountSettings.form.companyWebsite.description")}
                errorMessage={errors.companyWebsiteUrl?.message}
                value={companyWebsiteUrl === null ? undefined : companyWebsiteUrl}
                onChange={value => setValue("companyWebsiteUrl", value, { shouldDirty: true })}
              />
              <HStack gap={4} align="center">
                <Switch
                  aria-label={t("skills.customDescriptions.generateSkillsBanner.ariaLabel")}
                  isSelected={watch("useCompanyClustering")} 
                  onChange={isSelected => setValue("useCompanyClustering", isSelected, { shouldDirty: true })}
                />
                <Text>{t("settings.general.useCompanyClustering")}</Text>
              </HStack>
            </VStack>
          </Card>
          <Card>
            <VStack gap={4} className="w-full xl:w-2/3">
              <CardHeader title={t("settings.general.managerAccess.title")} />
              <HStack gap={4} align="center">
                <Switch
                  aria-label={t("settings.general.managerAccess.title")}
                  isSelected={watch("enableManagerTeamMemberInfoAccess")} 
                  onChange={isSelected => setValue("enableManagerTeamMemberInfoAccess", isSelected, { shouldDirty: true })}
                />
                <Text>{t("settings.general.managerAccess.description")}</Text>
              </HStack>
            </VStack>
          </Card>
        </VStack>
      </FormLayout.Content>
      <FormLayout.Footer>
        <HStack className="flex items-center justify-end w-full h-full">
          <Button type="submit" isDisabled={!isDirty} loading={isSaving}>{t("common.form.update")}</Button>
        </HStack>
      </FormLayout.Footer>
    </FormLayout>
  );
};