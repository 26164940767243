import { AnalyticEvents, AnalyticProperties } from "@/features/analytics/types/analyticEvents";
import { type Dict } from "mixpanel-browser";
import { useMixpanel } from "@/features/analytics/hooks/useMixpanel";

export const useTrackBillingAction = () => {  
  const { trackEvent } = useMixpanel();

  const trackBillingAction = (action: string, value?: string) => {
    const properties: Dict = {};
    properties[AnalyticProperties.action] = action;
    properties[AnalyticProperties.value] = value;
    
    trackEvent(AnalyticEvents.billingAction, properties);
  };
  
  return {
    trackBillingAction
  };
};