import { Breadcrumbs, type BreadcrumbsItem, Card, PageHeader, VStack } from "@/components";
import { type BulkImportResult } from "@/features/users/types/BulkImportResult";
import { BulkImportStep1 } from "@/features/users/components/bulk-import/BulkImportStep1";
import { BulkImportStep2 } from "@/features/users/components/bulk-import/BulkImportStep2";
import { BulkImportStep3 } from "@/features/users/components/bulk-import/BulkImportStep3";
import { BulkImportSuccess } from "@/features/users/components/bulk-import/BulkImportSuccess";
import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { useTranslation } from "@/hooks/useTranslation";

export const BulkImportPage = () => {
  const { t } = useTranslation();
  const [result, setResult] = useState<BulkImportResult>();

  const breadcumbs: BreadcrumbsItem[] = [
    { text: t("common.labels.settings"), to: "/settings" },
    { text: t("users.heading.title"), to: "/settings/users" },
    { text: t("users.bulkImport.title"), to: "/settings/users/bulk-import" }
  ];

  return (
    <>
      <Helmet>
        <title>{t("pages.settingsBulkImport")}</title>
      </Helmet>
      <VStack gap={4}>
        <Breadcrumbs items={breadcumbs} />
        <VStack gap={6}>
          <PageHeader title={t("users.bulkImport.title")} subtitle={t("users.bulkImport.subTitle")} />
          <Card>
            <VStack gap={8}>
              {result && <BulkImportSuccess result={result} />}
              <BulkImportStep1 />
              <BulkImportStep2 />
              <BulkImportStep3 setResult={setResult} />
            </VStack>
          </Card>
        </VStack>
      </VStack>
    </>
  );
};