import { useTranslation } from "@/hooks/useTranslation";
import { Alert, Content, Heading, Overlay } from "@workleap/orbiter-ui";
import { type OverlayTriggerState } from "react-stately";


interface Props {
  overlayState: OverlayTriggerState;
  onClickConfirm: () => void;
}

export const JobOpeningFormConfirmationModal = ({ overlayState, onClickConfirm }: Props) => {
  const { t } = useTranslation();

  const { isOpen, close } = overlayState;

  return (
    <Overlay show={isOpen}>
      <Alert
        primaryButtonLabel={t("common.form.ok")}
        cancelButtonLabel={t("common.form.cancel")}
        variant="destructive"
        onPrimaryButtonClick={onClickConfirm}
        onCancelButtonClick={() => close()}
        onClose={() => close()}
      >
        <Heading size="lg">{t("unsavedChanges.modal.title")}</Heading>
        <Content color="neutral">{t("unsavedChanges.modal.description")}</Content>
      </Alert>
    </Overlay>
  );
};