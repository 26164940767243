import { Card, CardHeader, Table, Text, VStack, usePaginationCountSummary, useProgressiveLoading, useTableSorting } from "@/components";
import { useGetAtsOpenPositions } from "@/features/ats/hooks/useGetAtsOpenPositions";
import { type AtsOpenJobDto } from "@/features/ats/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import { DEFAULT_LIST_PAGE_SIZE } from "@/lib/utils/constants";
import { nameOf } from "@/utils/types";
import { format } from "date-fns";
import { useMemo } from "react";
import { Cell, Column, Row, TableBody, TableHeader } from "react-stately";
import { AtsOpenPositionActions } from "./AtsOpenPositionActions";
import { AtsOpenPositionConditionalLink } from "./AtsOpenPositionConditionalLink";
import { AtsOpenPositionLinkStatus } from "./AtsOpenPositionLinkStatus";

interface Props {
  page: number;
}

export const AtsOpenPositionsCard = ({ page }: Props) => {
  const { t } = useTranslation();
  const { hasFeature } = useFeatures();

  const { tableSortProps, sortingFields: sortBy } = useTableSorting<AtsOpenJobDto>({
    initialSortDescriptor: {
      columns: ["mergeImportDate"],
      direction: "Descending"
    }
  });

  const { data, isLoading } = useGetAtsOpenPositions({ page, pageSize: DEFAULT_LIST_PAGE_SIZE, sortBy });
  const { showLoading } = useProgressiveLoading({ isLoading });

  const { positions, total } = useMemo(() => ({
    positions: data?.items ?? [],
    total: data?.total ?? 0
  }), [data]);

  const summaryLine = usePaginationCountSummary({
    currentPage: page,
    pageSize: DEFAULT_LIST_PAGE_SIZE,
    totalItems: total,
    listItemName: t("ats.openPositions.paginationItems")
  });

  return (
    <Card>
      <VStack gap={6}>
        <VStack gap={1.5}>
          <CardHeader title={t("ats.openPositions.title")} />
          <Text>{t("ats.openPositions.description")}</Text>
        </VStack>
        <Table
          isLoading={showLoading}
          aria-label={t("ats.openPositions.title")}
          emptyListProps={{
            title: t("ats.openPositions.empty.title"),
            description: t("ats.openPositions.empty.description"),
            showImage: true
          }}
          pagination={{
            totalItems: total,
            currentPage: page,
            pageSize: DEFAULT_LIST_PAGE_SIZE
          }}
          summary={summaryLine}
          {...tableSortProps}
        >
          <TableHeader>
            <Column
              key={nameOf<AtsOpenJobDto>("mergeImportDate")}
              allowsSorting
            >
              {t("ats.openPositions.table.importDate")}
            </Column>
            <Column
              key={nameOf<AtsOpenJobDto>("mergeName")}
              allowsSorting
            >
              {t("ats.openPositions.table.position")}
            </Column>
            <Column
              key={nameOf<AtsOpenJobDto>("isSynced")}
              allowsSorting
            >
              {t("ats.openPositions.table.linkStatus")}
            </Column>
            <Column width={115}>
              {t("ats.openPositions.table.actions")}
            </Column>
          </TableHeader>
          <TableBody>
            {positions.map(position => {
              const { mergeJobId, mergeImportDate, isSynced } = position;
              const showActionsButton = isSynced || hasFeature("Features.InternalMobility");

              return (
                <Row key={mergeJobId}>
                  <Cell>
                    <Text>{format(mergeImportDate, t("common.formats.dates.fullDateShort"))}</Text>
                  </Cell>
                  <Cell>
                    <AtsOpenPositionConditionalLink {...position} />
                  </Cell>
                  <Cell>
                    <AtsOpenPositionLinkStatus {...position} />
                  </Cell>
                  <Cell>
                    {showActionsButton && <AtsOpenPositionActions job={position} />}
                  </Cell>
                </Row>
              );
            })}
          </TableBody>
        </Table>
      </VStack>
    </Card>
  );
};