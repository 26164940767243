import { useTranslation } from "@/hooks/useTranslation";

interface UsePaginationCountSummaryProps {
  pageSize: number;
  currentPage: number;
  totalItems: number;
  listItemName?: string;
}

export const usePaginationCountSummary = ({ currentPage, listItemName, pageSize, totalItems }: UsePaginationCountSummaryProps): string => {
  const { t } = useTranslation();
  const listItemNameOrDefault = listItemName ?? t("common.pagination.summaryDefaultListItemName");

  if (totalItems === 0) {
    return t("common.pagination.countSummary", { fromPosition: 0, toPosition: 0, totalItems: 0, listItemName });
  }
  
  const fromPosition = ((currentPage - 1) * pageSize) + 1;
  const toPosition = Math.min(currentPage * pageSize, totalItems);

  return t("common.pagination.countSummary", { fromPosition, toPosition, totalItems, listItemName: listItemNameOrDefault });
};
