import { type ToggleManagerTeamMemberInfoAccessCommand } from "@/features/tenants/types/settings";
import { authQueryKeys } from "@/lib/auth/authQueryKeys";
import { settingsQueryKeys } from "@/features/tenants/types/settingsQueryKeys";
import { toast } from "react-toastify";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "@/hooks/useTranslation";
import api from "@/lib/http/api";

export const useToggleManagerTeamMemberInfoAccess = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  
  return useMutationWithValidationResult({
    mutationFn: async (payload: ToggleManagerTeamMemberInfoAccessCommand) =>
      await api.post<ToggleManagerTeamMemberInfoAccessCommand>("/settings/manager-team-member-info-access", payload),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: settingsQueryKeys.all });
      queryClient.invalidateQueries({ queryKey: authQueryKeys.currentUser() });
      toast.success(t("common.messages.changesSaved"));
    }
  });
};