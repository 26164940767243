import { type AxiosError } from "axios";
import { type ValidationErrors } from "@/models/error";
import { toast } from "react-toastify";
import { trainingQueryKeys } from "@/features/training/types/trainingQueryKeys";
import { translate } from "@/lib/i18n";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "@/lib/http/api";

export const useDeleteTrainingMutation = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError<{ errors?: ValidationErrors }>, void, unknown>({
    mutationFn: async () => {
      await api.delete(`/trainings/${id}`);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: trainingQueryKeys.lists() });
      toast.success(translate("trainings.delete.successMessage"));
    }
  });
};