import classNames from "classnames";
import { Children, type ReactNode } from "react";
import styles from "./ButtonGroup.module.css";

type Gap = 1 | 1.5 | 2 | 3 | 4 | 6 | 8;

export interface ButtonGroupProps {
  children: ReactNode;
  dataTest?: string;
  align?: "start" | "center" | "end" | "stretch";
  gap?: Gap;
  className?: string;
}

export const ButtonGroup = ({ children, align = "start", dataTest, gap = 3, className }: ButtonGroupProps) => {
  let spacingSize = "base";
  const buttonCount = Children.count(children);

  if (buttonCount === 1) {
    console.warn("Improper use of ButtonGroup, do not use with only one Button");
  }
  
  if (buttonCount > 3) {
    console.warn("Too many Button components passed to ButtonGroup (max 3)");
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const countSmallButtons = Children.toArray(children).filter((button: any) => button.props?.small).length;

  /* Do not allow different sized buttons to be set in the same ButtonGroup */
  if (countSmallButtons && countSmallButtons !== buttonCount) {
    console.warn("Inconsistent Button sizing passed to ButtonGroup");
  }

  /* If we have small buttons, adjust the spacing */
  if (countSmallButtons) {
    spacingSize = "sm";
  }

  return (
    <div
      className={
        classNames(`gap-${gap}`, styles["button-group"], styles[`button-group--${align}`], {
          [styles["button-group--small"]]: spacingSize === "sm"
        }, className)
      }
      data-test={dataTest}
    >
      {children}
    </div>
  );
};
