
import { type AriaNumberFieldProps, useLocale, useNumberField } from "react-aria";
import { FormFieldWrapper, FormLabel, VStack } from "@/components";
import { useNumberFieldState } from "react-stately";
import { useRef } from "react";
import classNames from "classnames";

interface Props extends AriaNumberFieldProps {
  name?: string;
  label?: string;
  description?: string;
  errorMessage?: string;
  isDisabled?: boolean;
  errorStyle?: string;
  className?: string;
}

export const NumberField = (props: Props) => {
  const { locale } = useLocale();
  const { label, errorMessage, description, errorStyle, className } = props;
  const state = useNumberFieldState({ ...props, locale });
  const ref = useRef(null);
  const { labelProps, inputProps, errorMessageProps } = useNumberField(props, state, ref);

  return (
    <FormFieldWrapper
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      errorStyle={errorStyle}
    >
      <VStack gap={1.5}>
        {label && <FormLabel {...labelProps}>{label}</FormLabel>}
        <input className={classNames("input", className)} {...inputProps} ref={ref} placeholder={description} />
      </VStack>
    </FormFieldWrapper>
  );
};