import { TooltipWrapper } from "@/components";
import { getFullName } from "@/features/employees/utils";
import { avatarSize, type UserAvatarDto } from "@/features/users/types/common";
import { DismissIcon } from "@hopper-ui/icons";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { useMemo, type MouseEvent, type ReactNode } from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./UserAvatar.module.css";

export interface Props extends UserAvatarDto {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  showTooltip?: boolean;
  editable?: boolean;
  onClick?: (() => void) | ((e: MouseEvent) => void);
  onClickRemove?: () => void;
  className?: string;
}

const getInitials = (size: Props["size"], firstName?: string, lastName?: string) => {
  const firstCharacterFirstName = firstName?.charAt(0).toUpperCase();
  const firstCharacterLastName = lastName?.charAt(0).toUpperCase();

  if (!firstCharacterFirstName) {
    return firstCharacterLastName;
  }

  if (!firstCharacterLastName) {
    return firstCharacterFirstName;
  }

  if (size === "xs") {
    return firstCharacterFirstName;
  }

  return `${firstCharacterFirstName}${firstCharacterLastName}`;
};

export const UserAvatar = ({
  id,
  firstName,
  lastName,
  avatarUrl,
  showTooltip,
  editable,
  onClick,
  onClickRemove,
  size = "md",
  className
}: Props) => {
  const classnames = classNames(styles.avatar, styles[`avatar-${size}`], className, {
    [styles.avatarClickable]: !!onClick
  });

  const renderAvatar = () => {
    if (avatarUrl) {
      return (
        <img width={avatarSize[size]} className={classnames} src={avatarUrl} onClick={onClick} alt={getFullName(firstName, lastName)} />
      );
    }
  
    if (!firstName && !lastName && !editable) {
      return (
        <AnonymousUserAvatar size={size} />
      );
    }
  
    const initials = editable
      ? ""
      : getInitials(size, firstName, lastName);
  
    return (
      <div className={classnames} onClick={onClick}>{initials}</div>
    );
  };

  const hasTooltip = showTooltip && (!!firstName || !!lastName);

  const avatar = useMemo(() => {
    if (onClickRemove && avatarUrl) {
      return (
        <div className={styles.close} onClick={onClickRemove}>
          <DismissIcon size="sm" className={styles.closeIcon} />
        </div>
      );
    }

    if (editable && !avatarUrl) {
      return (
        <AnonymousUserAvatar size={size} />
      );
    }

    if (hasTooltip) {
      return (
        <TooltipAvatar id={id} firstName={firstName} lastName={lastName}>{renderAvatar()}</TooltipAvatar>
      );
    }

    return renderAvatar();
  }, [onClickRemove, avatarUrl]);

  return (
    <div className={styles.container}>
      {avatar}
    </div>
  );
};

export interface TooltipWrapperProps {
  id?: string;
  firstName?: string;
  lastName?: string;
  children: ReactNode;
}

const TooltipAvatar = ({ id, firstName, lastName, children }: TooltipWrapperProps) => {
  const fullName = useMemo(() => getFullName(firstName, lastName), [firstName, lastName]);

  if (isEmpty(fullName)) {
    return null;
  }

  return (
    <TooltipWrapper anchorSelector={`user-avatar-${id}`} tooltip={fullName}>
      {children}
    </TooltipWrapper>
  );
};

const AnonymousUserAvatar = ({ size = "md" }: Pick<Props, "size">) => (
  <svg width={avatarSize[size]} height={avatarSize[size]} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z" fill="#E5DED6" />
    {/* eslint-disable-next-line max-len */}
    <path d="M11.5968 11.3794C11.5908 11.385 11.5851 11.3907 11.5796 11.3967C10.5651 12.4971 9.1351 13.3 7.60015 13.3C4.89395 13.3 2.70015 11.1062 2.70015 8.40002C2.70015 6.86253 3.24952 5.96939 4.23061 4.79208C5.36124 3.43533 6.8601 2.70002 8.40015 2.70002C11.1063 2.70002 13.3001 4.89383 13.3001 7.60002C13.3001 9.14723 12.6866 10.3748 11.5968 11.3794Z" fill="#2A2620" stroke="#2A2620" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    {/* eslint-disable-next-line max-len */}
    <path fillRule="evenodd" clipRule="evenodd" d="M8.40003 12.1334C10.9037 12.1334 12.9334 10.1038 12.9334 7.60008C12.9334 5.09638 10.9037 3.06674 8.40003 3.06674C5.89634 3.06674 3.8667 5.09638 3.8667 7.60008C3.8667 10.1038 5.89634 12.1334 8.40003 12.1334ZM6.39994 7.22599C6.84176 7.22599 7.19993 6.86781 7.19993 6.42599C7.19993 5.98416 6.84176 5.62599 6.39994 5.62599C5.95811 5.62599 5.59993 5.98416 5.59993 6.42599C5.59993 6.86781 5.95811 7.22599 6.39994 7.22599ZM11.1999 6.42599C11.1999 6.86781 10.8418 7.22599 10.3999 7.22599C9.95811 7.22599 9.59994 6.86781 9.59994 6.42599C9.59994 5.98416 9.95811 5.62599 10.3999 5.62599C10.8418 5.62599 11.1999 5.98416 11.1999 6.42599ZM6.16195 8.5915C6.0468 8.44786 5.83702 8.42476 5.69338 8.53991C5.54974 8.65505 5.52664 8.86484 5.64178 9.00848C6.28878 9.81557 7.28405 10.3333 8.40002 10.3333C9.51599 10.3333 10.5113 9.81557 11.1582 9.00848C11.2734 8.86484 11.2503 8.65505 11.1067 8.53991C10.963 8.42476 10.7532 8.44786 10.6381 8.5915C10.1122 9.24754 9.30515 9.66666 8.40002 9.66666C7.49488 9.66666 6.68786 9.24754 6.16195 8.5915Z" fill="white" />
  </svg>
);

export const UserAvatarLoading = ({ size = "md" }: Pick<Props, "size">) => (
  <Skeleton circle width={avatarSize[size]} height={avatarSize[size]} />
);