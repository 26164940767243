import { Card, PageHeader, VStack, useProgressiveLoading } from "@/components";
import { Helmet } from "react-helmet-async";
import { HrisConnectedSettings } from "@/features/hris/components/views/HrisConnectedSettings";
import { HrisDisconnectedSettings } from "@/features/hris/components/views/HrisDisconnectedSettings";
import { HrisUnauthenticatedSettings } from "@/features/hris/components/views/HrisUnauthenticatedSettings";
import { useGetHrisSettingsQuery } from "@/features/hris/hooks/useGetHrisSettings";
import { useTranslation } from "@/hooks/useTranslation";
import Skeleton from "react-loading-skeleton";

export const HrisSettingsPage = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetHrisSettingsQuery();
  const { showLoading } = useProgressiveLoading({ isLoading });

  const renderHrisSettingView = () => {
    if (showLoading) {
      return (
        <Card>
          <VStack gap={4}>
            <Skeleton height={22} width={110} />
            <Skeleton height={48} width={164} borderRadius={100} />
          </VStack>
        </Card>
      );
    }
  
    if (!data) {
      return null;
    }

    if (!data.isConnected) {
      return <HrisDisconnectedSettings />;
    }
    
    if (data.syncStatus === "AuthError") {
      return <HrisUnauthenticatedSettings />;
    }

    return <HrisConnectedSettings />;
  };

  return (
    <>
      <Helmet>
        <title>{t("pages.settingsHris")}</title>
      </Helmet>
      <VStack gap={4}>
        <PageHeader title={t("hrisSettings.title")} />
        {renderHrisSettingView()}
      </VStack>
    </>
  );
};