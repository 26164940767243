import { useMarketContentTranslation } from "@/hooks/useTranslation";
import { removeTextBetweenParenthesis } from "@/lib/utils";
import { GripIcon } from "@hopper-ui/icons";
import { Tag, Text } from "@workleap/orbiter-ui";
import { useDragLayer, type XYCoord } from "react-dnd";

export const DraggableSkillDragLayer = () => {
  const { translateMarketContent } = useMarketContentTranslation();
  const { isDragging, initialOffset, currentOffset, item } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging()
  }));

  if (!isDragging) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-transparent pointer-events-none z-max">
      <Tag 
        paddingY="6px"
        paddingX="10px"
        borderRadius="rounded-md"
        backgroundColor="decorative-option5"
        cursor="grabbing"
        className="border-2 border-decorative-option5-surface"
        style={getItemStyles(initialOffset, currentOffset)}
      >
        <GripIcon size="sm" color="information-weak" />
        <Text size="sm" color="information" paddingX="inset-xs">
          {removeTextBetweenParenthesis(translateMarketContent(item.name))}
        </Text>
      </Tag>
    </div>
  );
};

const getItemStyles = (initialOffset: XYCoord | null, currentOffset: XYCoord | null) => {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none"
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform 
  };
};