import { useMarketContentTranslation, useTranslation } from "@/hooks/useTranslation";
import { type OverlayTriggerState } from "react-stately";
import { ConfirmationModal } from "./ConfirmationModal";

interface Props {
  name?: string;
  title: string;
  confirmationMessage?: string;
  onDelete: () => void;
  overlayState: OverlayTriggerState;
}

/**
 * @deprecated Should not be used in new code.
 * Use [Alert](https://wl-orbiter-website.netlify.app/?path=/docs/alert--default-story) from Orbiter instead.
 */
export const DeleteConfirmationModal = ({
  title,
  name,
  confirmationMessage,
  onDelete,
  overlayState
}: Props) => {
  const { t } = useTranslation();
  const { translateMarketContent } = useMarketContentTranslation();
  const message = confirmationMessage || t("common.messages.deleteConfirmation", { name: translateMarketContent(name ?? "") });
  
  return (
    <ConfirmationModal
      title={title}
      onClickConfirm={onDelete}
      overlayState={overlayState}
      cancelButtonContent={t("modal.delete.cancel")}
      confirmButtonContent={t("modal.delete.confirm")}
    >
      {message}
    </ConfirmationModal>
  );
};