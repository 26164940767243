import { Heading, Text } from "@/components";
import { useTranslation } from "@/hooks/useTranslation";

export const Tenants = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading size="3xl">{t("tenants.title")}</Heading>
      <div className="mt-1 mb-6">
        <Text size="xs" color="neutral-text-weak">Non pellentesque diam eu pharetra etiam sodales purus</Text>
      </div>
    </>
  );
};
