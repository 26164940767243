import { apiPost } from "@/lib/http/api";
import { hrisQueryKeys } from "@/features/hris/types/hrisQueryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateActivationMutation = (onSuccess: (isActive: boolean) => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ isActive }: { isActive: boolean }) => {
      await apiPost("/hris/settings/activation", {
        isActive
      });
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({ queryKey: hrisQueryKeys.settings() });
      onSuccess(variables.isActive);
    }
  });
};