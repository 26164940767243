import { Card, DefaultDisabledExplainer, Grid, HStack, Heading, Select, TextField, UnsavedChangesModal, VStack } from "@/components";
import { CurrentEmployeeRole } from "@/features/employees/components/employee-form/CurrentEmployeeRole";
import { EmployeeFormEditableAvatar } from "@/features/employees/components/employee-form/EmployeeFormEditableAvatar";
import { Employment } from "@/features/employees/components/employee-form/employment/Employment";
import { SelectEthnicity } from "@/features/employees/components/employee-form/profile-information/SelectEthnicity";
import { SelectGender } from "@/features/employees/components/employee-form/profile-information/SelectGender";
import { useGetEmployeeIsSynced } from "@/features/employees/hooks/useGetEmployeeIsSynced";
import { useUpdateUserProfileMutation } from "@/features/profile/api/useUpdateUserProfileMutation";
import { type UpdateUserProfileCommand, type UserProfileDto } from "@/features/profile/types/common";
import { useLanguages } from "@/hooks/useLanguages";
import { useTranslation } from "@/hooks/useTranslation";
import { useCurrentUser } from "@/lib/auth/AuthProvider";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import { changeLanguage } from "i18next";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { UserProfileHeader } from "./components/UserProfileHeader";

interface UserProfileFormProps {
  userProfile: UserProfileDto;
}

export const UserProfileForm = ({ userProfile }: UserProfileFormProps) => {
  const { t } = useTranslation();
  const { hasFeature } = useFeatures();
  const { languages, getLanguage } = useLanguages();
  const { isSuperAdmin } = useCurrentUser();
  const [newAvatar, setNewAvatar] = useState<File>();
  const mutation = useUpdateUserProfileMutation(userProfile?.employeeId, () => onSuccess());
  const form = useForm<UpdateUserProfileCommand>({ defaultValues: userProfile });
  const { handleSubmit, getValues, reset, watch, setValue, formState: { isDirty, isSubmitted } } = form;
  const { isHrisConnectedAndEmployeeIsSynced } = useGetEmployeeIsSynced(userProfile?.isSynced);

  const handleAvatarRemoved = () => {
    setNewAvatar(undefined);
  };

  const { mutate, validationErrors, isPending } = mutation;

  const submitHandler = (formData: UpdateUserProfileCommand) => {
    const data = {
      ...(newAvatar && { 
        avatarFile: newAvatar,
        oldAvatarUrl: userProfile?.avatarUrl 
      }),
      formData
    };

    mutate(data);
  };

  const onSuccess = () => {
    reset(getValues());

    changeLanguage(watch("language"));
  };

  const hasUnsavedChanges = isDirty || !!newAvatar;

  const disabledExplainer = <DefaultDisabledExplainer isHrisConnected={isHrisConnectedAndEmployeeIsSynced} />;

  return (
    <>
      <form onSubmit={handleSubmit(submitHandler)}>
        <VStack gap={6}>
          <UserProfileHeader reset={reset} isDirty={hasUnsavedChanges} isLoading={isPending} />
          <Card>
            <VStack gap={6}>
              <HStack align="center" justify="between">
                <Heading size="xl">{t("employee.edit.profileInformation")}</Heading>
                {disabledExplainer}
              </HStack>
              <EmployeeFormEditableAvatar 
                {...userProfile}
                onFileChanged={setNewAvatar}
                onAvatarRemoved={handleAvatarRemoved}
                errorMessage={validationErrors?.field("File")}
              />
              <Grid cols={2}>
                <TextField
                  label={t("common.labels.firstName")}
                  description={t("common.labelDesc.firstName")}
                  value={watch("firstName")}
                  onChange={value => setValue("firstName", value, { shouldDirty: true })}
                  isDisabled={isHrisConnectedAndEmployeeIsSynced}
                  errorMessage={validationErrors?.field?.("FirstName")}
                />
                <TextField
                  label={t("common.labels.lastName")}
                  description={t("common.labelDesc.lastName")}
                  value={watch("lastName")}
                  onChange={value => setValue("lastName", value, { shouldDirty: true })}
                  isDisabled={isHrisConnectedAndEmployeeIsSynced}
                  errorMessage={validationErrors?.field("LastName")}
                />
              </Grid>
              {isSuperAdmin && (
                <Grid cols={2} rows="auto">
                  <SelectGender
                    value={watch("gender")}
                    selectItem={gender => setValue("gender", gender?.id, { shouldDirty: true })}
                    label={t("common.labels.gender.title")}
                    description={t("common.labels.gender.title")}
                    errorMessage={validationErrors?.field("Body.Gender")}
                    isDisabled={!!isHrisConnectedAndEmployeeIsSynced}
                  />
                  <SelectEthnicity
                    value={watch("ethnicity")}
                    selectItem={ethnicity => setValue("ethnicity", ethnicity?.id, { shouldDirty: true })}
                    description={t("common.labels.ethnicity.title")}
                    errorMessage={validationErrors?.field("Body.Ethnicity")}
                    label={t("common.labels.ethnicity.title")}
                    isDisabled={!!isHrisConnectedAndEmployeeIsSynced}
                  />
                </Grid>
              )}
              <Grid cols={2}>
                <TextField
                  label={t("common.labels.email")}
                  description={t("common.labelDesc.email")}
                  isDisabled
                  value={userProfile.workEmail}
                />
                <CurrentEmployeeRole {...form} isSynced={userProfile.isSynced} />
              </Grid>
            </VStack>
          </Card>
          {hasFeature("Features.FrenchTranslation") && ( 
            <Card>
              <Heading size="xl">{t("profile.preferences.title")}</Heading>
              <Grid cols={2}>
                <Select
                  label={t("profile.preferences.language.label")}
                  value={getLanguage(watch("language"))}
                  items={languages}
                  selectItem={item => setValue("language", item?.id, { shouldDirty: true })}
                  errorMessage={validationErrors?.field("Language")}
                />
              </Grid>
            </Card>
          )}
          <Employment
            form={form}
            employments={getValues("employments")} 
            commandErrors={validationErrors}
            isSynced={userProfile?.isSynced}
          />
        </VStack>
      </form>
      <UnsavedChangesModal when={hasUnsavedChanges && !isSubmitted && !isPending} />
    </>
  );
};
