import { useParams } from "@/hooks/useParams";

interface RouteParamWithId {
  id: string;
}

export const useIdRouteParam = () => {
  const { id } = useParams<RouteParamWithId>();

  return id;
};