import { Card, CardHeader, DefaultDisabledExplainer, Grid, HStack, SelectWithSearch, TextField, VStack, useTypeAheadQuery } from "@/components";
import { CurrentEmployeeRole } from "@/features/employees/components/employee-form/CurrentEmployeeRole";
import { EmployeeFormEditableAvatar } from "@/features/employees/components/employee-form/EmployeeFormEditableAvatar";
import { useGetEmployeeIsSynced } from "@/features/employees/hooks/useGetEmployeeIsSynced";
import { type EmployeeEditModel, type GetEditEmployeeModelDto } from "@/features/employees/types/common";
import { getFullName } from "@/features/employees/utils";
import { type EmployeeRow } from "@/features/reports/types/search";
import { searchDepartments } from "@/features/search/api";
import { type SearchResultItem } from "@/features/search/types/common";
import { useSearchEmployees } from "@/hooks/useSearchEmployees";
import { useTranslation } from "@/hooks/useTranslation";
import { type ValidationErrorResult } from "@/models/error";
import { nameOf } from "@/utils/types";
import { type Dispatch, type SetStateAction } from "react";
import { type UseFormReturn } from "react-hook-form";

interface TabProfileProps {
  commandErrors?: ValidationErrorResult;
  employee?: GetEditEmployeeModelDto;
  setNewAvatar: Dispatch<SetStateAction<File | undefined>>;
  form: UseFormReturn<EmployeeEditModel>;
  showAvatarUpload?: boolean;
}

export const ProfileInformation = (props: TabProfileProps) => {
  const {
    form,
    employee,
    commandErrors,
    setNewAvatar,
    showAvatarUpload
  } = props;
  const { t } = useTranslation();
  const { isHrisConnectedAndEmployeeIsSynced } = useGetEmployeeIsSynced(employee?.isSynced);
  const {
    setValue,
    getValues,
    watch
  } = form;
  
  const {
    setQuery: employeeSetQuery,
    data: employeeData,
    fetchStatus: employeeFetchStatus
  } = useSearchEmployees();

  const {
    setQuery: departmentSetQuery,
    fetchStatus: departmentFetchStatus,
    data: departments
  } = useTypeAheadQuery({
    fetchItemsQuery: async search => (await searchDepartments({ search })).data.results
  });

  const handleAvatarRemoved = () => {
    setNewAvatar(undefined);
  };
 
  const onItemSelectedReportsTo = (row: EmployeeRow) => {
    setValue("reportsToId", row?.id, { shouldDirty: true });
  };

  const onClear = (key: keyof EmployeeEditModel) => {
    setValue(key, undefined, { shouldDirty: true });
  };
  
  const onItemSelectedDepartment = (department: SearchResultItem) => {
    setValue("department", department, { shouldDirty: true });
  };

  const getDirectManager = () => {
    const managerFullName = getFullName(employee?.reportsTo?.firstName, employee?.reportsTo?.lastName);
    
    return managerFullName || undefined;
  };
  
  return (
    <Card>
      <VStack gap={2}>
        <HStack align="center" justify="between">
          <CardHeader title={t("employee.edit.profileInformation")} />
          <DefaultDisabledExplainer isHrisConnected={isHrisConnectedAndEmployeeIsSynced} />
        </HStack>
        {showAvatarUpload && (
          <EmployeeFormEditableAvatar
            firstName={getValues("firstName")}
            lastName={getValues("lastName")}
            avatarUrl={employee?.avatarUrl}
            onFileChanged={setNewAvatar}
            onAvatarRemoved={handleAvatarRemoved}
            errorMessage={commandErrors?.field("File")}
            isSynced={employee?.isSynced}
          />
        )}
        <Grid cols={2} rows="auto">
          <TextField
            name={nameOf<EmployeeEditModel>("firstName")}
            label={t("common.labels.firstName")}
            description={t("common.labelDesc.shortFirstName")}
            errorMessage={commandErrors?.field("Body.FirstName")}
            isDisabled={isHrisConnectedAndEmployeeIsSynced}
            value={watch("firstName")}
            onChange={value => setValue("firstName", value, { shouldDirty: true })}
          />
          <TextField
            name={nameOf<EmployeeEditModel>("lastName")}
            description={t("common.labelDesc.shortLastName")}
            errorMessage={commandErrors?.field("Body.LastName")}
            label={t("common.labels.lastName")}
            isDisabled={isHrisConnectedAndEmployeeIsSynced}
            value={watch("lastName")}
            onChange={value => setValue("lastName", value, { shouldDirty: true })}
          />
        </Grid>
        <Grid cols={2} rows="auto">
          <TextField
            label={t("employee.edit.emailAddress")}
            description={t("employee.edit.emailAddressPlaceholder")}
            errorMessage={commandErrors?.field("Body.Email")}
            isDisabled={!!employee?.workEmail || isHrisConnectedAndEmployeeIsSynced}
            value={watch("workEmail")}
            onChange={value => setValue("workEmail", value, { shouldDirty: true })}
          />
          <CurrentEmployeeRole {...form} isSynced={employee?.isSynced} />
        </Grid>
        <Grid cols={2} rows="auto">
          <SelectWithSearch
            label={t("employee.edit.department")}
            description={t("employee.edit.departmentPlaceholder")}
            setQuery={departmentSetQuery}
            results={departments}
            fetchStatus={departmentFetchStatus}
            onSelect={onItemSelectedDepartment}
            onClear={() => onClear("department")}
            initialValue={employee?.department?.name}
            errorMessage={commandErrors?.field(`Body.${nameOf<EmployeeEditModel>("department")}`)}
            keySelector={({ id }) => id}
            itemDisplay={({ id, name }) => (
              <div key={id}>
                {name}
              </div>
            )}
            itemToText={({ name }) => name}
            isDisabled={isHrisConnectedAndEmployeeIsSynced}
          />
          <SelectWithSearch
            label={t("employee.edit.manager")}
            description={t("employee.edit.managerPlaceholder")}
            setQuery={employeeSetQuery}
            results={employeeData}
            fetchStatus={employeeFetchStatus}
            onSelect={onItemSelectedReportsTo}
            onClear={() => onClear("reportsToId")}
            initialValue={getDirectManager()}
            keySelector={item => item.id}
            itemDisplay={({ id, firstName, lastName }) => (
              <div key={id}>
                {getFullName(firstName, lastName)} 
              </div>
            )}
            itemToText={item => getFullName(item.firstName, item.lastName)}
            disabledItems={employee
              ? [employee?.id]
              : []}
            isDisabled={isHrisConnectedAndEmployeeIsSynced}
          />
        </Grid>
      </VStack>
    </Card>
  );
};