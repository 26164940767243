import { AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { useTranslation } from "@/hooks/useTranslation";
import { useCurrentUser } from "@/lib/auth/AuthProvider";
import { useConfiguration } from "@/lib/core";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import { type Feature } from "@/lib/features/types/feature";
import { Routes } from "@/types";
import { type CursorProp } from "@workleap/orbiter-ui";
import { useMemo, type SyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useTrackPremiumLimitationAction } from "./useTrackPremiumLimitationAction";

export const usePremiumBadge = (feature: Feature) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getPlanNameByFeature } = useFeatures();
  const { isSuperAdmin, isExecutive, tenant: { isWorkleapTenant } } = useCurrentUser();
  const { workleapManagementAppUrl } = useConfiguration();
  const { trackPremiumLimitationAction } = useTrackPremiumLimitationAction();

  const message = useMemo(() => t("freemium.tooltip", { plan: getPlanNameByFeature(feature) }), [feature, getPlanNameByFeature, t]);
  const isClickable = useMemo(() => isSuperAdmin || isExecutive, [isExecutive, isSuperAdmin]);
  const cursor = useMemo<CursorProp>(() => (isClickable ? "pointer" : "default"), [isClickable]);

  const onClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    if (!isClickable) {
      return;
    }

    trackPremiumLimitationAction(AnalyticValues.actionClick, feature);

    if (isWorkleapTenant) {
      window.open(`${workleapManagementAppUrl}/settings/billing/subscriptions`, "_blank");
      
      return;
    }

    navigate(Routes.SettingsBilling);
  };

  const onMouseOver = (_: SyntheticEvent) => {
    trackPremiumLimitationAction(AnalyticValues.actionHover, feature);
  };

  return {
    message,
    cursor,
    onClick,
    onMouseOver
  };
};