import { AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { Switch } from "@/components";
import { useGetAtsSettings } from "@/features/ats/hooks/useGetAtsSettings";
import { useTrackAtsAction } from "@/features/ats/hooks/useTrackAtsAction";
import { useTranslation } from "@/hooks/useTranslation";
import { useUpdateAtsActivationMutation } from "@/features/ats/hooks/useUpdateAtsActivationMutation";

export const AtsActivateToggle = () => {
  const { t } = useTranslation();
  const { data } = useGetAtsSettings();
  const { mutate, isPending } = useUpdateAtsActivationMutation();
  const isDisabled = data?.isScheduledOrRunning || isPending;
  const { trackAtsAction } = useTrackAtsAction();

  const handleChange = (isActive: boolean) => {
    mutate({ isActive });
  };
  
  const onAtsAutoSyncChange = (isSelected: boolean) => {
    trackAtsAction(AnalyticValues.actionAutoSync, isSelected.toString());
    handleChange(isSelected);
  };
  
  return (
    <Switch 
      isSelected={data?.isActive} 
      isDisabled={isDisabled} 
      onChange={onAtsAutoSyncChange}
    >
      {t("ats.sync.toggle.label")}
    </Switch>
  );
};