import { type CustomMarketSkillDto, type SimilarSkillDto } from "@/features/skills/types/common";
import { type CustomMarketSkillFormState } from "@/features/skills/types/customSkills";
import { EditSkillCustomDescriptionsForm } from "./EditSkillCustomDescriptionsForm";
import { FormHeader, UnsavedChangesModal, VStack, useProgressiveLoading } from "@/components";
import { Routes } from "@/types/routes";
import { isCustomMarketSkillFormValid, toMetadataCustomDescriptions } from "@/features/skills/utils/customSkills";
import { useForm } from "react-hook-form";
import { useGetCustomMarketSkillByName } from "@/features/skills/hooks/useGetCustomMarketSkillByName";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@/hooks/useTranslation";
import { useUpdateCustomMarketSkillMutation } from "@/features/skills/hooks/useUpdateCustomMarketSkill";

interface Props {
  skillName: string;
  onCancelClick: () => void;
}

interface FormProps {
  skillMetadata: CustomMarketSkillDto;
  onCancelClick: () => void;
}

const Form = ({ skillMetadata, onCancelClick }: FormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const form = useForm<CustomMarketSkillFormState>({
    // there is an issue on the DefaultValues<TFormValues> type from react-hook-form
    // where [x: string] : unknown is transformed into [x: string] : {} | undefined
    // while the `attrs` field from the RichContent type is not compatible with {} | undefined
    // see: https://github.com/react-hook-form/react-hook-form/issues/10519
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: { ...skillMetadata as any }
  });
  const { formState: { isDirty, isSubmitting, isSubmitted }, handleSubmit, watch, setValue } = form;

  const { mutate, isPending } = useUpdateCustomMarketSkillMutation(skillMetadata.id, () => navigate(Routes.SkillsCustomDescriptions));

  const submitHandler = (formData: CustomMarketSkillFormState) => {
    mutate({
      ...formData,
      ratingsDescriptions: toMetadataCustomDescriptions(formData.ratingsDescriptions)
    });
  };

  const onChangeSimilarSkills = (newSkills: SimilarSkillDto[]) =>
    setValue("similarSkills", newSkills, { shouldDirty: true });

  return (
    <>
      <form onSubmit={handleSubmit(submitHandler)}>
        <VStack gap={8}>
          <FormHeader 
            title={t("skills.customDescriptions.form.editTitle")}
            discardButtonLabel={t("common.form.cancel")}
            saveButtonLabel={t("common.form.save")}
            onBack={onCancelClick}
            isLoading={isPending}
            isDirty={isDirty}
            canSubmit={isCustomMarketSkillFormValid(watch())}
          />
          <EditSkillCustomDescriptionsForm
            {...form}
            isExistingSkill
            onChangeSimilarSkills={onChangeSimilarSkills}
          />
        </VStack>
      </form>
      <UnsavedChangesModal when={isDirty && !isSubmitted && !isSubmitting} />
    </>
  );
};

export const EditSkillCustomDescriptions = ({ skillName, onCancelClick }: Props) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetCustomMarketSkillByName(skillName);
  const { showLoading } = useProgressiveLoading({ isLoading });

  if (showLoading) {
    <VStack gap={8}>
      <FormHeader 
        title={t("skills.customDescriptions.form.editTitle")}
        discardButtonLabel={t("common.form.cancel")}
        saveButtonLabel={t("common.form.save")}
        onBack={onCancelClick}
        isLoading
      />
    </VStack>;
  }

  if (!data) {
    return null;
  }

  return (
    <Form skillMetadata={data.metadata} onCancelClick={onCancelClick} />
  );
};
