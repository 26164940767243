import { HStack } from "@/components/stack";
import { PremiumBadge } from "@/features/billing/components/PremiumBadge";
import { type Feature } from "@/lib/features/types/feature";
import { CheckmarkIcon } from "@hopper-ui/icons";
import classNames from "classnames";
import { type ReactNode } from "react";
import styles from "./MenuItemWrapper.module.css";

interface Props {
  children: ReactNode;
  className?: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  id?: string;
  variant?: "default" | "danger";
  blockedFeature?: Feature;
}

export const MenuItemWrapper = ({ children, className, isDisabled, isSelected, id, variant = "default", blockedFeature }: Props) => (
  <div 
    id={id}
    className={classNames(styles.menuItemWrapper, {
      [styles.danger]: variant === "danger",
      [styles.disabled]: isDisabled,
      [styles.selected]: isSelected
    }, className)}
  >
    <HStack align="center" justify="between">
      {isSelected && <CheckmarkIcon />}
      <>{children}</>
      {isDisabled && blockedFeature && (<PremiumBadge feature={blockedFeature} />)}
    </HStack>
  </div>
);
