import { Text } from "@/components";
import { type AtsOpenJobDto } from "@/features/ats/types/common";
import { TextLink } from "@workleap/orbiter-ui";

type Props = Pick<AtsOpenJobDto, "mergePostingLink" | "mergeName">;

export const AtsOpenPositionConditionalLink = ({ mergePostingLink, mergeName }: Props) => {
  if (!mergePostingLink) {
    return <Text>{mergeName}</Text>;
  }

  return (
    <TextLink href={mergePostingLink} variant="accent" underline="none" target="_blank">
      {mergeName}
    </TextLink>
  );
};