import { Text } from "@/components";

interface Props {
  data: {
    name: string;
    slug: string;
    squareImage: string;
  };
}

export const AtsIntegrationListItem = ({ data }: Props) => (
  <div className="flex items-center gap-3">
    <img src={data.squareImage} width={24} height={24} alt="square" />
    <Text className="font-690">{data.name}</Text>
  </div>
);