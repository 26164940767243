import { type GetAtsSettingsQueryDto } from "@/features/ats/types/common";
import { type UseQueryOptions, keepPreviousData, useQuery } from "@tanstack/react-query";
import { atsQueryKeys } from "@/features/ats/types/atsQueryKeys";
import api from "@/lib/http/api";

const getAtsSettings = async () => {
  const { data } = await api.get<GetAtsSettingsQueryDto>("/ats/settings");

  return data;
};

export const useGetAtsSettings = (options?: Omit<UseQueryOptions<GetAtsSettingsQueryDto>, "queryKey">) => useQuery<GetAtsSettingsQueryDto>({
  queryKey: atsQueryKeys.settings(),
  queryFn: getAtsSettings,
  ...options,
  placeholderData: keepPreviousData
});