import { type CollaboratorsDto } from "@/features/role/types";
import { type EmployeeRow } from "@/features/reports/types/search";

export const addCollaborator = (activeCollaborators: CollaboratorsDto, collaborator: EmployeeRow) => [ ...activeCollaborators.map(({ id }) => id), collaborator.id ];

export const removeCollaborator = (activeCollaborators: CollaboratorsDto, collaboratorId: string) => [ ...activeCollaborators.map(({ id }) => id).filter(id => id !== collaboratorId)];

export const filterCollaboratorSearchResults = (idsToExclude: string[], collaboratorsData?: EmployeeRow[]) => {
  if (!collaboratorsData) {
    return [];
  }

  return collaboratorsData.filter(({ id }) => !idsToExclude.includes(id));
};