
import { type GetUsersQueryDto, type GetUsersQueryParams, type UserDto, type UserStatus } from "@/features/users/types/common";
import { type UseQueryOptions, keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { directoryQueryKeys } from "@/features/directory/types/directoryQueryKeys";
import { employeesQueryKeys } from "@/features/employees/types/employeesQueryKeys";
import { getUsers } from "@/features/users/api/getUsers";
import { postDeleteUser } from "@/features/users/api/postDeleteUser";
import { roleQueryKeys } from "@/features/roles/types/roleQueryKeys";
import { toast } from "react-toastify";
import { useCommand } from "@/hooks";
import { useTranslation } from "@/hooks/useTranslation";
import { userQueryKeys } from "@/features/users/types/userQueryKeys";

export const useGetUsers = (
  config: GetUsersQueryParams,
  options?: UseQueryOptions<GetUsersQueryDto, unknown, GetUsersQueryDto, ReturnType<typeof userQueryKeys["list"]>>
) => (
  useQuery({
    queryKey: userQueryKeys.list(config), 
    queryFn: getUsers,
    placeholderData: keepPreviousData,
    ...options
  })
);

export const useInvalidateUsers = () => {
  const queryClient = useQueryClient();

  return {
    invalidate: () => queryClient.invalidateQueries({ queryKey: userQueryKeys.all })
  };
};

interface useOptimisticMutationProps<T> {
  operation: (variables: T) => Promise<void>;
  optimisticMutation: (variables: T, previousUsers: UserDto[]) => UserDto[];
  onSuccess: () => void;
  refreshOnSettled?: boolean;
}

const useUsersOptimisticMutation = <T>({ operation, optimisticMutation, onSuccess, refreshOnSettled }:useOptimisticMutationProps<T>) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: operation,
    // When mutate is called:
    onMutate: async variables => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: userQueryKeys.lists() });

      // Snapshot the previous value      
      const queriesData = queryClient.getQueriesData<GetUsersQueryDto>({ queryKey: userQueryKeys.lists(), type: "active" });

      if (queriesData.length === 1) {
        const [key, previousUsers] = queriesData[0];

        if (previousUsers) {
          queryClient.setQueryData<GetUsersQueryDto>(key, ({
            ...previousUsers,
            items: optimisticMutation(variables, previousUsers.items)
          }));
        }

        return { key, previousUsers };
      }

      return undefined;
    },
    onSuccess: onSuccess,
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (error, variables, context) => {     
      if (context) {
        queryClient.setQueryData(context.key, context.previousUsers);
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      if (refreshOnSettled) {
        queryClient.invalidateQueries({ queryKey: userQueryKeys.lists() });
      }
    }
  });

  return mutation;
};

interface useStatusChangeMutationProps {
  operation: (variables: string[]) => Promise<void>;
  successMessage: string;
  newStatus: UserStatus;
}

export const useStatusChangeMutation = ({ operation, successMessage, newStatus }:useStatusChangeMutationProps) => {
  const { mutateAsync } = useUsersOptimisticMutation<string[]>({
    operation: operation,
    optimisticMutation: (variables, list) => list.map(x => {
      if (variables.find(y => x.employeeId === y)) {
        return {
          ...x,
          status: newStatus
        };
      }

      return x;
    }),
    onSuccess: () => toast.success(successMessage)
  });

  return { mutateAsync };
};

export const useDeleteUser = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const deleteUserCommand = useCommand(postDeleteUser);

  const deleteUserMutation = useMutation({
    mutationFn: async(employeeId: string) => {
      await deleteUserCommand.send(employeeId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: directoryQueryKeys.all });
      queryClient.invalidateQueries({ queryKey: roleQueryKeys.all });
      queryClient.invalidateQueries({ queryKey: employeesQueryKeys.all });
      queryClient.invalidateQueries({ queryKey: userQueryKeys.all });
      toast.success(t("common.messages.changesSaved"));
    }
  });

  return {
    deleteUser: (employeeId: string) => deleteUserMutation.mutate(employeeId)
  };
};