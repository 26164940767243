import { type AriaOverlayProps } from "@react-aria/overlays";
import { type AriaDialogProps } from "@react-types/dialog";
import { CrossButton, Div, Flex, Grid, Text, useSlots } from "@workleap/orbiter-ui";
import { type PropsWithChildren, type ReactNode } from "react";
import { usePreventScroll } from "react-aria";

interface Props extends AriaOverlayProps, AriaDialogProps, PropsWithChildren {}

export type FlyoutContentChildren = (data: {
  header?: ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
  modals?: ReactNode;
}) => ReactNode;

export const FlyoutContent = ({ children, ...props }: Props) => {
  const { header, content, footer, modals } = useSlots(children, {
    _: {
      defaultWrapper: Text
    },
    header: {},
    content: {},
    footer: {},
    modals: {}
  });

  usePreventScroll();
  const { onClose } = props;

  return (
    <>
      <Grid gap="inline-lg" templateRows="auto 1fr auto" height="100%">
        <Flex
          width="100%"
          justifyContent="space-between"
          paddingTop="24px"
          paddingX="32px"
        >
          {header}
          <CrossButton
            size="sm"
            aria-label="Close"
            onClick={e => { 
              e.stopPropagation(); 
              onClose?.(); 
            }}
          />
        </Flex>
        <Div overflowY="auto" paddingX="32px">
          {content}
        </Div>
        <Div paddingBottom="24px" paddingX="32px">
          {footer}
        </Div>
      </Grid>
      {modals}
    </>
  );
};
