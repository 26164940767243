import { AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { Button } from "@/components";
import { atsQueryKeys } from "@/features/ats/types/atsQueryKeys";
import { useGetAtsSettings } from "@/features/ats/hooks/useGetAtsSettings";
import { useQueryClient } from "@tanstack/react-query";
import { useStartAtsSyncMutation } from "@/features/ats/hooks/useStartAtsSyncMutation";
import { useTrackAtsAction } from "@/features/ats/hooks/useTrackAtsAction";
import { useTranslation } from "@/hooks/useTranslation";

const POLLING_INTERVAL_MS = 5000;

export const AtsStartSyncButton = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data } = useGetAtsSettings({
    refetchInterval: query => {
      if (query.state.data?.isScheduledOrRunning) {
        return POLLING_INTERVAL_MS;
      }

      if (query.state.data?.syncStatus === "Success") {
        queryClient.invalidateQueries({ queryKey: atsQueryKeys.jobs() });
      }
      
      return false;
    }
  });

  const { mutate: startSync, isPending } = useStartAtsSyncMutation();
  const { trackAtsAction } = useTrackAtsAction();

  const onStartAtsSyncClick = () => {
    trackAtsAction(AnalyticValues.actionStartSync);
    startSync();
  };
  
  return (
    <Button
      size="small"
      variant="primary"
      onClick={onStartAtsSyncClick}
      loading={isPending}
      isDisabled={data?.isScheduledOrRunning}
    >
      {t("ats.sync.button")}
    </Button>
  );
};