import { Card, Heading, type SelectItem, TextField, VStack, WorkingOnMagicLoading } from "@/components";
import { type CustomSkillFormState } from "@/features/skills/types/customSkills";
import { SelectSkillType } from "./SelectSkillType";
import { type SkillCategory } from "@/features/skills/types/common";
import { type UseFormReturn } from "react-hook-form";
import { type ValidationErrorResult } from "@/models/error";
import { hasDescriptions, makeDefaultDescriptions } from "@/features/skills/utils/customSkills";
import { useOverlayTriggerState } from "react-stately";
import { useTranslation } from "@/hooks/useTranslation";

interface Props extends Omit<UseFormReturn<CustomSkillFormState>, "handleSubmit"> {
  errors?: ValidationErrorResult;
}

export const AddCustomSkillForm = ({ watch, setValue, errors }: Props) => {
  const { t } = useTranslation();

  const workingOnMagicOverlayState = useOverlayTriggerState({});
  
  const onSkillTypeSelectionChange = (selected?: SelectItem<SkillCategory>) => {
    const shouldGenerateDescriptions = watch("name") && selected?.id && !hasDescriptions(watch("ratingsDescriptions"));
    if (shouldGenerateDescriptions) {
      setValue("ratingsDescriptions", makeDefaultDescriptions(), { shouldDirty: true });
    }

    if (selected?.id) {
      setValue("category", selected?.id, { shouldDirty: true });
    }
  };
  
  return (
    <VStack gap={6}>
      <Card>
        <VStack gap={6}>
          <VStack gap={1.5}>
            <Heading size="xl">{t("skills.customSkills.form.detailsSection")}</Heading>
          </VStack>
          <TextField
            label={t("skills.customSkills.form.skillName")}
            description={t("skills.customSkills.form.skillNamePlaceholder")}
            errorMessage={errors?.field("Name")}
            value={watch("name")}
            onChange={value => setValue("name", value, { shouldDirty: true })}
          />
          <SelectSkillType
            value={watch("category")}
            selectItem={onSkillTypeSelectionChange}
            label={t("skills.customSkills.form.skillCategory")}
            description={t("common.labelDesc.select")}
            errorMessage={errors?.field("Category")}
          />
        </VStack>
      </Card>
      <Card className="!bg-neutral-surface-weak">
        <Heading size="xl" className="text-neutral-text-weak">{t("skills.customSkills.form.descriptions")}</Heading>
      </Card>
      <Card className="!bg-neutral-surface-weak">
        <Heading size="xl" className="text-neutral-text-weak">{t("similarSkills.manage.title")}</Heading>
      </Card>
      <WorkingOnMagicLoading overlayState={workingOnMagicOverlayState} />
    </VStack>
  );
};
