import { type AriaMenuProps } from "@react-types/menu";
import { MenuSection } from "./components/MenuSection";
import { useMenu } from "react-aria";
import { useRef } from "react";
import { useTreeState } from "react-stately";
import classNames from "classnames";
import styles from "./Menu.module.css";

export interface MenuProps<T extends object> extends AriaMenuProps<T> {
  menuClassName?: string;
}

export const Menu = <T extends object>(props: MenuProps<T>) => {
  const state = useTreeState(props);
  const { menuClassName } = props;

  const ref = useRef(null);
  const { menuProps } = useMenu(props, state, ref);

  return (
    <ul
      ref={ref}
      className={classNames(styles.menu, menuClassName)}
      {...menuProps}
    >
      {[...state.collection].map(item => (
        <MenuSection
          key={item.key}
          section={item}
          state={state}
        />
      ))}
    </ul>
  );
};