import { AngleRightIcon } from "@hopper-ui/icons";
import { HStack } from "@/components";
import { Link } from "react-router-dom";
import { fromUnion } from "@/utils/types";
import classNames from "classnames";

interface BreadcrumbsLinkItem {
  text: string;
  to: string;
}

interface BreadcrumbsButtonItem {
  text: string;
  isActive?: boolean;
  onClick: () => void;
}

export type BreadcrumbsItem = BreadcrumbsLinkItem | BreadcrumbsButtonItem;

interface Props {
  items: BreadcrumbsItem[];
}

const { isOfTypeOne: isLinkItem } = fromUnion<BreadcrumbsLinkItem, BreadcrumbsButtonItem>();

export const Breadcrumbs = ({ items }: Props) => {
  const divider = <AngleRightIcon size="sm" className="text-neutral-text-weak" />;
  const linkClasses = "text-overline uppercase mt-0.5";
  
  return (
    <HStack 
      align="center" 
      divider={divider}
    >
      {items.map((item, index) => {
        const isLastItem = index === items.length - 1;

        if (isLinkItem(item, "to")) {
          return (
            <Link key={item.to}
              to={item.to}
              className={classNames({
                "hover:underline text-neutral-text-weak": !isLastItem,
                "text-neutral-text cursor-default": isLastItem
              }, linkClasses)}
            >
              {item.text}
            </Link>
          );
        }

        return (
          <button
            type="button"
            key={item.text}
            className={classNames(
              { 
                "hover:underline text-neutral-text-weak": !isLastItem,
                "text-neutral-text cursor-default": isLastItem 
              }, linkClasses)}
            onClick={item.onClick}
          >
            {item.text}
          </button>
        );
      })}
    </HStack>
  );
};