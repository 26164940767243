import { type AddDepartmentCommandDto } from "@/features/departments/types/common";
import { DepartmentForm } from "./DepartmentForm";
import { ModalDialog } from "@/components";
import { OverlayContainer } from "react-aria";
import { type OverlayTriggerState } from "react-stately";
import { useAddDepartmentMutation } from "@/features/departments/api/useAddDepartmentMutation";
import { useForm } from "react-hook-form";
import { useTranslation } from "@/hooks/useTranslation";

export interface Props {
  overlayState: OverlayTriggerState;
}

export const AddDepartmentModal = ({ overlayState }: Props) => {
  const { t } = useTranslation();

  const form = useForm<AddDepartmentCommandDto>({ defaultValues: { name: "" } });

  const onSuccess = () => {
    form.reset();
  };

  const mutation = useAddDepartmentMutation(onSuccess);

  if (!overlayState.isOpen) {
    return null;
  }

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("departments.add.title")}
        isOpen
        onClose={overlayState.close}
        isDismissable
      >
        <DepartmentForm 
          {...form}
          {...mutation}
          close={overlayState.close}
          submitButtonText={t("common.form.add")}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};