import { Cell, Column, Row, TableBody, TableHeader } from "react-stately";
import { DEFAULT_LIST_PAGE_SIZE } from "@/lib/utils/constants";
import { Link } from "react-router-dom";
import { SkillList } from "@/features/skills/components/skill-list/SkillList";
import { Table, Text, useGetCurrentPageParam, usePaginationCountSummary, useTableSorting } from "@/components";
import { type TrainingDto } from "@/features/training/types/common";
import { TrainingsListMenu } from "./TrainingsListMenu";
import { nameOf } from "@/utils/types";
import { useGetTrainings } from "@/features/training/api/useGetTrainings";
import { useMemo } from "react";
import { useTranslation } from "@/hooks/useTranslation";

export const TrainingsList = () => {
  const page = useGetCurrentPageParam();
  const { t } = useTranslation();
  
  const { tableSortProps, sortingFields: sortBy } = useTableSorting<TrainingDto>({
    initialSortDescriptor: {
      columns: ["title", "link"],
      direction: "Ascending"
    }
  });

  const { data, isLoading } = useGetTrainings({ page, pageSize: DEFAULT_LIST_PAGE_SIZE, sortBy });

  const { trainings, total } = useMemo(() => ({
    trainings: data?.items ?? [],
    total: data?.total ?? 0
  }), [data]);

  const summaryLine = usePaginationCountSummary({
    currentPage: page,
    pageSize: DEFAULT_LIST_PAGE_SIZE,
    totalItems: total,
    listItemName: t("trainings.table.paginationItems")
  });

  return (
    <Table
      isLoading={isLoading}
      aria-label={t("trainings.table.ariaLabel")}
      emptyListProps={{
        title: t("trainings.table.empty.title"),
        description: t("trainings.table.empty.description"),
        showImage: true
      }}
      pagination={{
        totalItems: total,
        currentPage: page,
        pageSize: DEFAULT_LIST_PAGE_SIZE
      }}
      summary={summaryLine}
      {...tableSortProps}
    >
      <TableHeader>
        <Column
          key={nameOf<TrainingDto>("title")}
          allowsSorting
        >
          {t("trainings.table.heading.title")}
        </Column>
        <Column key={nameOf<TrainingDto>("link")} allowsSorting>
          {t("trainings.table.heading.link")}
        </Column>
        <Column key={nameOf<TrainingDto>("skills")}>
          {t("trainings.table.heading.skills")}
        </Column>
        <Column>
          {t("trainings.table.heading.actions")}
        </Column>
      </TableHeader>
      <TableBody>
        {trainings.map(training => {
          const { id, title, link, skills } = training;

          return (
            <Row key={id}>
              <Cell>
                {title}
              </Cell>
              <Cell>
                {link && (
                  <Link to={link} target="_blank">
                    <Text className="underline" color="primary-text">{link}</Text>
                  </Link>
                )}
              </Cell>
              <Cell>
                <SkillList skills={[...skills]} max={9} />
              </Cell>
              <Cell>
                <TrainingsListMenu {...training} />
              </Cell>
            </Row>
          );
        })}
      </TableBody>
    </Table>
  );
};