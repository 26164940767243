import { type EmployeeEmploymentFormState } from "@/features/employees/types/common";
import { type SearchRoleThenJobResponseItem } from "@/features/search/hooks/useSearchRoleThenJob";

export const mapEmploymentToSelectRoleOption = (
  employment?: EmployeeEmploymentFormState) : SearchRoleThenJobResponseItem | undefined => employment?.roleName
  ? {
    position: -1,
    name: employment.roleName,
    marketRoleId: employment.marketRoleId,
    roleId: employment.roleId,
    isInternal:  !!employment.roleId
  }
  : undefined;