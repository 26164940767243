import { Button, ButtonGroup, HStack, ModalDialog, Text, VStack } from "@/components";
import { OverlayContainer } from "react-aria";
import { type OverlayTriggerState } from "react-stately";
import { useTranslation } from "@/hooks/useTranslation";


export interface Props {
  overlayState: OverlayTriggerState;
  onClickReplace: () => void;
  onClickDontReplace: () => void;
  onClickCancel: () => void;
  confirmationMessage: string;
}

export const FormReplaceDataConfirmationModal = ({
  overlayState,
  onClickReplace: onReplace,
  onClickDontReplace: onDontReplace,
  onClickCancel: onCancel,
  confirmationMessage
}: Props) => {
  const { t } = useTranslation();

  if (!overlayState.isOpen) {
    return null;
  }

  const onClickConfirm = () => {
    onReplace();
    overlayState.close();
  };

  const onClickDontReplace = () => {
    onDontReplace();
    overlayState.close();
  };

  const onClickCancel = () => {
    onCancel();
    overlayState.close();
  };

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("common.form.replaceModal.title")}
        isOpen
        onClose={overlayState.close}
        isDismissable
      >
        <VStack className="w-[600px]" gap={8}>
          <Text className="mt-8" color="neutral-text-weak">{confirmationMessage}</Text>
          <HStack justify="between">
            <Button
              onPress={onClickCancel}
              variant="secondary"
            >
              {t("common.form.cancel")}
            </Button>
            <ButtonGroup gap={4}>
              <Button
                onPress={onClickDontReplace}
                variant="secondary"
              >
                {t("common.form.replaceModal.dontReplaceButtonLabel")}
              </Button>
              <Button
                onPress={onClickConfirm}
                variant="danger"
              >
                {t("common.form.replaceModal.replaceButtonLabel")}
              </Button>
            </ButtonGroup>
          </HStack>
        </VStack>
      </ModalDialog>
    </OverlayContainer>
  );
};