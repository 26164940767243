import { ConditionalLink, HStack, Text } from "@/components";
import { useDirectoryRoutes } from "@/features/directory/hooks/useDirectoryRoutes";
import { avatarSize } from "@/features/users/types/common";
import { Div } from "@workleap/orbiter-ui";
import classNames from "classnames";
import { isEmpty, times } from "lodash";
import { type ComponentProps, type ReactNode } from "react";
import Skeleton from "react-loading-skeleton";
import { UserAvatar } from "./UserAvatar";

interface Props {
  userAvatars: Omit<ComponentProps<typeof UserAvatar>, "size">[];
  count?: number;
  size?: "xs" | "sm" | "md";
  emptyState?: ReactNode;
  hideNumberOfUserAvatarsNotShown?: boolean;
  shiftLeft?: boolean;
}

export const UserAvatarList = ({ userAvatars, size = "md", count = 4, emptyState = null, hideNumberOfUserAvatarsNotShown, shiftLeft }: Props) => {
  const userAvatarsToShow = userAvatars.slice(0, count);
  const numberOfUserAvatarsNotShown = userAvatars.length - count;
  const hasUserAvatars = !isEmpty(userAvatars);
  const { person } = useDirectoryRoutes();
  const classes = classNames({
    "transition-all hover:z-20": userAvatarsToShow.length > 1,
    "-mr-3": !shiftLeft,
    "-ml-3": shiftLeft
  });

  if (!hasUserAvatars) {
    return <>{emptyState}</>;
  }

  return (
    <Wrapper>
      <HStack gap="space-inline-sm" align="center">
        {userAvatarsToShow.map((userAvatar, index) => {
          const lastUserAvatar = index === userAvatarsToShow.length - 1 && !shiftLeft;

          return (
            <ConditionalLink 
              key={userAvatar.avatarUrl || userAvatar.id || `${userAvatar.firstName}-${userAvatar.lastName}`}
              className={classNames({
                [classes]: !lastUserAvatar || numberOfUserAvatarsNotShown > 0
              })}
              condition={!!userAvatar.id}
              to={person(userAvatar.id)}
            >
              <UserAvatar
                {...userAvatar}
                size={size}
                className="border rounded-full border-neutral-icon-strong"
                showTooltip
              />
            </ConditionalLink>
          );
        })}
        {numberOfUserAvatarsNotShown > 0 && !hideNumberOfUserAvatarsNotShown && (
          <Div 
            width={`${avatarSize[size]}px`}
            height={`${avatarSize[size]}px`}
            borderRadius="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="neutral-strong"
            color="neutral-strong"
          >
            <Text size="sm" weight={590} color="inherit">
              {`+${numberOfUserAvatarsNotShown}`}
            </Text>
          </Div>
        )}
      </HStack>
    </Wrapper>
  );
};

type UserAvatarListLoadingProps = Pick<Props, "count" | "size">;

export const UserAvatarListLoading = ({ size = "md", count = 4 }: UserAvatarListLoadingProps) => (
  <Wrapper>
    {times(count, index => (
      <Skeleton 
        className="-mr-3"
        height={avatarSize[size]}
        width={avatarSize[size]}
        key={index}
        circle
      />
    ))}
  </Wrapper>
);

interface WrapperProps {
  children: ReactNode;
}

const Wrapper = ({ children }: WrapperProps) => <div className="flex flex-wrap items-center gap-1">{children}</div>;