import { type ChangeEvent, useRef, useState } from "react";
import { DefaultMaxFileSize, formatSize, getFileExtension } from "@/utils/files";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { useTranslation } from "./useTranslation";

interface UseFileInputOptions { 
  onFileChanged?: (file: File) => void;
  maxFileSize?: number;
}

export const useFileInput = (options: UseFileInputOptions = {}) => {
  const { onFileChanged, maxFileSize = DefaultMaxFileSize } = options;
  const { t } = useTranslation();
  const input = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  
  const open = () => input.current?.click();

  const handleFileChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (isEmpty(files)) { return; }
    const [_file] = files!;
    
    if (!getFileExtension(_file.name)) {
      toast.error(t("common.fileInput.noFileExtension"));
    } else if (_file.size > maxFileSize) {
      toast.error(t("common.fileInput.tooLarge", { size: formatSize(maxFileSize) }));
    } else {
      setFile(_file);
      onFileChanged?.(_file);
    }
  };
  
  const remove = () => {
    if (input.current) {
      input.current.value = "";
    }

    setFile(undefined);
  };

  return { 
    input,
    file, 
    setFile,
    open,
    handleFileChanged,
    remove
  };
};