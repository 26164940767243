import { type SkillDto } from "@/features/skills/types/Skill";
import { isEmpty } from "lodash";

interface ShouldShowAllProps {
  skills: Omit<SkillDto, "score">[];
  max?: number;
}

export const shouldShowAll = ({ skills, max }: ShouldShowAllProps) => {
  if (!max) {
    return true;
  }

  return max
    ? skills.length <= max
    : true;
};

interface GetSkillsToShowProps {
  skills: Omit<SkillDto, "score">[];
  max?: number;
}

export const getSkillsToShow = ({ skills, max }: GetSkillsToShowProps) => {
  if (!max || shouldShowAll({ skills, max })) {
    return skills;
  }

  return skills.slice(0, max);
};

export const sortedRatings: string[] = ["Expert", "Advanced", "Intermediate", "Beginner"];

export const orderSkillsByRating = (skills: Omit<SkillDto, "score">[]) => {
  if (isEmpty(skills)) {
    return skills;
  }

  return skills.sort((a, b) => sortedRatings.indexOf(a.rating) - sortedRatings.indexOf(b.rating));
};
