import { Dropdown, Menu, MenuItemWrapper, Text, TextField } from "@/components";
import { allUserRoles, type UserRoleType } from "@/features/users/types/userRoles";
import { useTranslation } from "@/hooks/useTranslation";
import { InfoIcon } from "@hopper-ui/icons";
import { Flex } from "@workleap/orbiter-ui";
import { AnimatePresence, motion } from "framer-motion";
import { useRef, type Key } from "react";
import { Item, Section, useMenuTriggerState } from "react-stately";

interface UserRoleDropdownProps {
  role: UserRoleType;
  setRole: (role: UserRoleType) => void;
  className?: string;
  label?: string;
  description?: string;
  errorMessage?: string;
  isDisabled?: boolean;
  excludeOptions?: UserRoleType[];
}

export const UserRoleDropdown = ({
  role,
  className,
  label,
  description,
  errorMessage,
  isDisabled,
  excludeOptions = [],
  setRole
}: UserRoleDropdownProps) => {
  const state = useMenuTriggerState({});
  const ref = useRef(null);
  const { t } = useTranslation();

  const onAction = (key: Key) => {
    setRole(key as UserRoleType);
    state.close();
  };

  const onClickTrigger = () => {
    if (!isDisabled) {
      state.open();
    }
  };

  const getRoleAccessDescription = () => {
    switch (role) {
      case "Collaborator":
        return t("userRoles.collaborator.description");
      case "Executive":
        return t("userRoles.executive.description");
      default:
        return "";
    }
  };

  const roleAccessDescription = getRoleAccessDescription();

  return (
    <div className={className}>
      <div ref={ref} onClick={onClickTrigger}>
        <TextField
          label={label}
          description={description}
          isDisabled={isDisabled}
          isReadOnly
          value={allUserRoles.find(x => x.value === role)?.text}
          errorMessage={errorMessage}
        />
        {roleAccessDescription && (
          <Flex className="mt-2" direction="row" alignItems="center">
            <InfoIcon className="text-neutral-text-weak" />
            <Text size="xs" color="neutral-text-weak">{roleAccessDescription}</Text>
          </Flex>
        )}
      </div>
      <AnimatePresence>
        {state.isOpen && (
          <Dropdown state={state} triggerRef={ref} placement="bottom right">
            <motion.div
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
            >
              <Menu
                autoFocus={state.focusStrategy || true}
                onAction={onAction}
              >
                <Section>
                  {allUserRoles.filter(({ value }) => !excludeOptions.includes(value)).map(r => (
                    <Item key={r.value} textValue={r.text} >
                      <MenuItemWrapper>
                        {r.text}
                      </MenuItemWrapper>
                    </Item>
                  ))}
                </Section>
              </Menu>
            </motion.div>
          </Dropdown>
        )}
      </AnimatePresence>
    </div>
  );
};

