import { AddSkillCustomDescriptionsForm } from "./AddSkillCustomDescriptionsForm";
import { type CustomMarketSkillFormState } from "@/features/skills/types/customSkills";
import { EditSkillCustomDescriptionsForm } from "./EditSkillCustomDescriptionsForm";
import { FormHeader, UnsavedChangesModal, VStack } from "@/components";
import { Routes } from "@/types/routes";
import { type SimilarSkillDto } from "@/features/skills/types/common";
import { hasDescriptions, isCustomMarketSkillFormValid, toMetadataCustomDescriptions } from "@/features/skills/utils/customSkills";
import { useAddCustomMarketSkillMutation } from "@/features/skills/hooks/useAddCustomMarketSkill";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  onCancelClick: () => void;
}

export const AddSkillCustomDescriptions = ({ onCancelClick }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const form = useForm<CustomMarketSkillFormState>({
    defaultValues: { marketId: "", ratingsDescriptions: {}, similarSkills: [] }
  });
  const { watch, handleSubmit, setValue, formState: { isDirty, isSubmitted } } = form;

  const { mutate, isPending } = useAddCustomMarketSkillMutation(() => navigate(Routes.SkillsCustomDescriptions));
  
  const submitHandler = (formData: CustomMarketSkillFormState) => {
    mutate({
      ...formData,
      ratingsDescriptions: toMetadataCustomDescriptions(formData.ratingsDescriptions)
    });
  };

  const onChangeSimilarSkills = (newSkills: SimilarSkillDto[]) =>
    setValue("similarSkills", newSkills, { shouldDirty: true });

  const isDescriptionsGenerated = hasDescriptions(watch("ratingsDescriptions"));
  
  return (
    <>
      <form onSubmit={handleSubmit(submitHandler)}>
        <VStack gap={8}>
          <FormHeader 
            title={t("skills.customDescriptions.form.addTitle")}
            discardButtonLabel={t("common.form.cancel")}
            saveButtonLabel={t("common.form.save")}
            onBack={onCancelClick}
            isLoading={isPending}
            isDirty={isDirty}
            canSubmit={isCustomMarketSkillFormValid(watch())}
          />
          {isDescriptionsGenerated
            ? <EditSkillCustomDescriptionsForm {...form} onChangeSimilarSkills={onChangeSimilarSkills} />
            : <AddSkillCustomDescriptionsForm {...form} />
          }
        </VStack>
      </form>
      <UnsavedChangesModal when={isDirty && !isSubmitted && !isPending} />
    </>
  );
};
