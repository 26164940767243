import { HStack, Text, TooltipWrapper } from "@/components";
import { QuestionIcon } from "@hopper-ui/icons";
import classNames from "classnames";
import { type ReactNode } from "react";
import { type LabelAriaProps } from "react-aria";

interface Props extends LabelAriaProps {
  children: ReactNode;
  isRequired?: boolean;
  helpText?: string;
  className?: string;
}

/**
 * @deprecated Use [FormField] with orbiter components and react-hook-form.
 */
export const FormLabel = ({ children, isRequired, helpText, className, ...rest }: Props) => (
  <label {...rest} className={classNames(className, "text-heading-xs text-neutral-text-weak block")}>
    <HStack align="center">
      <HStack gap={0}>
        <Text size="xs">{children}</Text>
        {isRequired && <Text size="xs" as="span" color="danger-icon-hover" className="ml-0.5">*</Text>}
      </HStack>
      {helpText && (
        <TooltipWrapper tooltip={helpText} anchorSelector="form-help-icon">
          <QuestionIcon className="text-neutral-text-weak" />
        </TooltipWrapper>
      )}
    </HStack>
  </label>
);
