import { Button, Text, VStack } from "@/components";
import { downloadFile } from "@/utils/files";
import { getCsvTemplate } from "@/features/users/api/getCsvTemplate";
import { useTranslation } from "@/hooks/useTranslation";

export const BulkImportStep1 = () => {
  const { t } = useTranslation();

  const onClickDownload = async () => {
    const response = await getCsvTemplate();
    downloadFile(response.data, "Workleap-Skills-import-users.csv");
  };

  return (
    <VStack>
      <Text size="md">{t("users.bulkImport.step1Title")}</Text>
      <VStack gap={4}>
        <Text>{`${t("users.bulkImport.step1DescriptionLine1")}. ${t("users.bulkImport.step1DescriptionLine2")}`}</Text>
        <Button variant="secondary" onClick={onClickDownload}>{t("users.bulkImport.step1DownloadButton")}</Button>
      </VStack>
    </VStack>
  );
};