import { type Gender } from "@/features/employees/types/common";
import { Select, type SelectInputProps, type SelectItem } from "@/components";
import { useTranslation } from "@/hooks/useTranslation";

interface Props extends Omit<SelectInputProps<Gender>, "items" | "value"> {
  value?: Gender;
}

export const SelectGender = ({ value, ...otherProps }: Props) => {
  const { t } = useTranslation();
  const selectItems: SelectItem<Gender>[] = [
    { id: "FEMALE", text: t("common.labels.gender.female") },
    { id: "MALE", text: t("common.labels.gender.male") },
    { id: "NON-BINARY", text: t("common.labels.gender.nonBinary") },
    { id: "OTHER", text: t("common.labels.gender.other") },
    { id: "PREFER_NOT_TO_DISCLOSE", text: t("common.labels.preferNotToDisclose") }
  ];

  return (
    <Select
      value={value
        ? selectItems.find(({ id }) => id === value)
        : undefined}
      items={selectItems}
      {...otherProps}
    />
  );
};