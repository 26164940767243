import { type RoleWatcher } from "@/features/roles/types/common";
import { roleQueryKeys } from "@/features/roles/types/roleQueryKeys";
import api from "@/lib/http/api";
import { type QueryFunctionContext, type UseQueryOptions, useSuspenseQuery } from "@tanstack/react-query";

const getRoleWatchers = async({ queryKey: [, { roleId }] }: QueryFunctionContext<ReturnType<typeof roleQueryKeys["watchers"]>>) => {
  const { data } = await api.get<RoleWatcher[]>(`/roles/${roleId}/watchers`);

  return data;
};


export const useGetRoleWatchers = (
  roleId: string,
  options?: UseQueryOptions<RoleWatcher[], unknown, RoleWatcher[], ReturnType<typeof roleQueryKeys.watchers>>) =>
  useSuspenseQuery({
    queryKey: roleQueryKeys.watchers(roleId),
    queryFn: getRoleWatchers,
    ...options
  });