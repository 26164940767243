import { type AccordionItemProps } from "./AccordionItem";
import { VStack } from "@/components";
import React, { type ReactElement } from "react";

interface Props {
  selectedItems: string[];
  onChange: (selectedItems: string[]) => void;
  children: ReactElement<AccordionItemProps> | ReactElement<AccordionItemProps>[];
  allowMultipleExpanded?: boolean;
  className?: string;
}

export const Accordion = ({
  children,
  onChange,
  selectedItems,
  allowMultipleExpanded,
  className
}: Props) => {
  const childrenList = Array.isArray(children)
    ? children
    : [children];
  const onToggleCollapsed = (itemId: string) => {
    if (allowMultipleExpanded) {
      onChange(getNewSelection(selectedItems, itemId));
    } else {
      const newItems = getNewSelection(selectedItems.filter(id => id === itemId), itemId);
      onChange(newItems);
    }
  };

  const getNewSelection = (currentItems: string[], newItem: string) =>
    currentItems.includes(newItem) ?
      [...currentItems].filter(item => item !== newItem)
      : [...currentItems, newItem];

  return (
    <VStack gap={4} className={className}>
      {React.Children.map(childrenList, item => React.cloneElement(item, {
        key: item.key || item.props.itemId,
        isCollapsed: !selectedItems.includes(item.props.itemId),
        onToggleCollapsed
      }))}
    </VStack>
  );
};