import { Button, HStack, PageHeader, VStack } from "@/components";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Routes } from "@/types";
import { TrainingsList } from "@/features/training";
import { useTranslation } from "@/hooks/useTranslation";

export const TrainingPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("pages.settingsTraining")}</title>
      </Helmet>
      <VStack gap={4}>
        <HStack align="end" justify="between">
          <PageHeader title={t("settings.training.heading.title")} subtitle={t("settings.training.heading.subtitle")} />
          <Button variant="primary" size="small">
            <Link to={Routes.AddTraining}>
              {t("settings.training.heading.addTraining")}
            </Link>
          </Button>
        </HStack>
        <TrainingsList />
      </VStack>
    </>
  );
};