import { HStack } from "@/components";
import { type DOMAttributes, type FocusableElement } from "@react-types/shared";
import classNames from "classnames";
import { type FC, type ReactNode } from "react";
import { ReactComponent as ErrorIcon } from "./assets/errorIcon.svg";
import styles from "./form-field-wrapper.module.css";

interface Props {
  children: ReactNode;
  errorMessage?: string | ReactNode;
  errorMessageProps?: DOMAttributes<FocusableElement>;
  errorStyle?: string;
  className?: string;
}

/**
 * @deprecated Use [FormField] with orbiter components and react-hook-form.
 */
export const FormFieldWrapper: FC<Props> = ({ children, errorMessage, errorMessageProps, errorStyle, className }) => (
  <div className={classNames(className, styles.container, { [styles.error]: errorMessage })}>
    {children}
    {(errorMessage && errorStyle !== "minimal") && (
      <HStack gap="space-inline-xs" className={styles.errorMessage} {...errorMessageProps}>
        <ErrorIcon />
        <div>{errorMessage}</div>
      </HStack>
    )}
  </div>
);

