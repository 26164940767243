import { type Dispatch, type SetStateAction, useState } from "react";
import { type UseQueryOptions, type UseQueryResult, keepPreviousData, useQuery } from "@tanstack/react-query";
import { useDebounce } from "use-hooks";

export interface TypeAheadQueryProps<TEntity> {
  extraQueryKeys?: string[];
  extraQueryOptions?: Omit<UseQueryOptions<Promise<TEntity[]>, unknown, TEntity[], string[]>, "queryKey">;
  fetchItemsQuery: (query: string) => Promise<TEntity[]>;
}

type Result<TEntity> = {
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
}
& UseQueryResult<TEntity[], unknown>;

export const useTypeAheadQuery = <TEntity>({
  extraQueryKeys = [],
  extraQueryOptions = {},
  fetchItemsQuery
}: TypeAheadQueryProps<TEntity>): Result<TEntity> => {
  const [query, setQuery] = useState<string>("");
  const debouncedQuery = useDebounce<string>(query.trimEnd(), 300);

  const queryResult = useQuery({
    queryKey: [...extraQueryKeys, debouncedQuery],
    queryFn: () => fetchItemsQuery(debouncedQuery),
    enabled: !!debouncedQuery,
    placeholderData: keepPreviousData,
    ...extraQueryOptions
  });
  
  return {
    setQuery,
    query,
    ...queryResult
  };
};
