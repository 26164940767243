import { Card, Heading, TextField, VStack, WorkingOnMagicLoading, type SelectItem } from "@/components";
import { useGenerateRatingsDescriptions } from "@/features/skills/hooks/useGenerateRatingsDescriptions";
import { type SimilarSkillDto, type SkillCategory } from "@/features/skills/types/common";
import { type CustomMarketSkillFormState, type CustomSkillFormState } from "@/features/skills/types/customSkills";
import { useTranslation } from "@/hooks/useTranslation";
import { type ValidationErrorResult } from "@/models/error";
import { nameOf } from "@/utils/types";
import { useState } from "react";
import { type UseFormReturn } from "react-hook-form";
import { useOverlayTriggerState } from "react-stately";
import { v4 as uuid } from "uuid";
import { ManageSimilarSkills } from "./ManageSimilarSkills";
import { SelectSkillType } from "./SelectSkillType";
import { SkillRatingsDescriptionsEditor } from "./SkillRatingsDescriptionsEditor";

interface Props extends Omit<UseFormReturn<CustomSkillFormState>, "handleSubmit"> {
  errors?: ValidationErrorResult;
  onChangeSimilarSkills: (newSkills: SimilarSkillDto[]) => void;
}

export const EditCustomSkillForm = ({ watch, setValue, errors, onChangeSimilarSkills }: Props) => {
  const { t } = useTranslation();
  const workingOnMagicOverlayState = useOverlayTriggerState({});
  const [descriptionsEditorKey, setDescriptionsEditorKey] = useState(uuid());
 
  const { generateDescription } = useGenerateRatingsDescriptions({
    workingOnMagicOverlayState,
    onDescriptionsGenerated: descriptions => {
      setValue("ratingsDescriptions", descriptions, { shouldDirty: true });
      setDescriptionsEditorKey(uuid());
    }
  });

  const onDescriptionsGenerate = () => generateDescription({ skillName: watch("name") });
  const onDescriptionsChange = (descriptions: CustomMarketSkillFormState["ratingsDescriptions"]) =>
    setValue("ratingsDescriptions", descriptions, { shouldDirty: true });

  const onSkillTypeSelectionChange = (selected?: SelectItem<SkillCategory>) => {
    if (selected?.id) {
      setValue("category", selected?.id, { shouldDirty: true });
    }
  };

  const onSelectSimilarSkill = (item: SimilarSkillDto) => {
    setValue("similarSkills", [...watch("similarSkills"), item], { shouldDirty: true });
  };

  return (
    <>
      <VStack gap={6}>
        <Card>
          <VStack gap={6}>
            <VStack gap={1.5}>
              <Heading size="xl">{t("skills.customSkills.form.detailsSection")}</Heading>
            </VStack>
            <TextField
              name={nameOf<CustomSkillFormState>("name")}
              label={t("skills.customSkills.form.skillName")}
              description={t("skills.customSkills.form.skillNamePlaceholder")}
              errorMessage={errors?.field("Name")}
              value={watch("name")}
              onChange={value => setValue("name", value, { shouldDirty: true })}
            />
            <SelectSkillType
              value={watch("category")}
              selectItem={onSkillTypeSelectionChange}
              label={t("skills.customSkills.form.skillCategory")}
              description={t("common.labelDesc.select")}
              errorMessage={errors?.field("Category")}
            />
          </VStack>
        </Card>
        <SkillRatingsDescriptionsEditor
          key={descriptionsEditorKey}
          descriptions={watch("ratingsDescriptions")}
          onDescriptionsChange={onDescriptionsChange}
          onDescriptionsRegenerate={onDescriptionsGenerate}
        />
        <Card>
          <ManageSimilarSkills
            onSelectSimilarSkill={onSelectSimilarSkill}
            onChangeSimilarSkills={onChangeSimilarSkills}
            currentSkillName={watch("name")}
            similarSkills={watch("similarSkills")}
          />
        </Card>
      </VStack>
      <WorkingOnMagicLoading overlayState={workingOnMagicOverlayState} />
    </>
  );
};

