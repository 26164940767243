import { DefaultMenuItem, DeleteConfirmationModal, DropdownMenuButton, TooltipWrapper } from "@/components";
import { type Key, useState } from "react";
import { Section, useOverlayTriggerState } from "react-stately";
import { type UserDto } from "@/features/users/types/common";
import { isEmpty } from "lodash";
import { postBulkActivate } from "@/features/users/api/postBulkActivate";
import { postBulkDeactivate } from "@/features/users/api/postBulkDeactivate";
import { postBulkDelete } from "@/features/users/api/postBulkDelete";
import { postBulkSendInvitation } from "@/features/users/api/postBulkSendInvitation";
import { toast } from "react-toastify";
import { useCommand } from "@/hooks";
import { useInvalidateUsers, useStatusChangeMutation } from "@/features/users/hooks/useUsers";
import { useTranslation } from "@/hooks/useTranslation";
import { useUserActions } from "@/features/users/hooks/useUserActions";

interface BulkActionsDropdownMenuProps {
  selectedUsers: UserDto[];
}

enum ActionKeys {
  ACTIVATE_USER = "activateUser",
  DEACTIVATE_USER = "deactivateUser",
  SEND_INVITE = "sendInvite",
  RESEND_INVITE = "resendInvite",
  DELETE = "delete"
}

export const BulkActionsDropdownMenu = ({ selectedUsers }: BulkActionsDropdownMenuProps) => {
  const { t } = useTranslation();
  const activateUserCommand = useCommand(postBulkActivate);
  const deactivateUserCommand = useCommand(postBulkDeactivate);
  const deleteUserCommand = useCommand(postBulkDelete);
  const sendInviteCommand = useCommand(postBulkSendInvitation);
  const resendInviteCommand = useCommand(postBulkSendInvitation);
  const [loading, setLoading] = useState(false);
  const userDeletionOverlayTriggerState = useOverlayTriggerState({});
  const { isDeactivable, isActivable, isInvitable, isReinvitable, isDeletable } = useUserActions();

  const { invalidate } = useInvalidateUsers();

  const { mutateAsync: sendInviteAndMutate } = useStatusChangeMutation({
    operation: sendInviteCommand.send,
    successMessage: t("users.actions.inviteSuccessMessage"),
    newStatus: "Invited"
  });
  const { mutateAsync: deactivateAndMutate } = useStatusChangeMutation({
    operation: deactivateUserCommand.send,
    successMessage: t("users.actions.deactivateUserSuccessMessage"),
    newStatus: "Deactivated"
  });

  const usersAllowedDeactivate = selectedUsers.filter(isDeactivable);
  const usersAllowedActivate = selectedUsers.filter(isActivable);
  const usersAllowedInvite = selectedUsers.filter(isInvitable);
  const usersAllowedReinvite = selectedUsers.filter(isReinvitable);
  const deletableUsers = selectedUsers.filter(isDeletable);

  const handleSendInvite = async () => {
    await sendInviteAndMutate(usersAllowedInvite.map(x => x.employeeId));
  };

  const handleResendInvite = async () => {
    await resendInviteCommand.send(usersAllowedReinvite.map(x => x.employeeId));
    toast.success(t("users.actions.inviteSuccessMessage"));
  };

  const handleActivateUser = async () => {
    await activateUserCommand.send(usersAllowedActivate.map(x => x.employeeId));
    invalidate();
    toast.success(t("users.actions.activateUserSuccessMessage"));
  };

  const handleDeactivateUser = async () => {
    await deactivateAndMutate(usersAllowedDeactivate.map(x => x.employeeId));
  };

  const handleDelete = async () => {
    await deleteUserCommand.send(deletableUsers.map(x => x.employeeId));
    invalidate();
    toast.success(t("users.bulkDelete.successMessage", { count: deletableUsers.length }));
  };

  const actionCommands = {
    [ActionKeys.ACTIVATE_USER]: handleActivateUser,
    [ActionKeys.DEACTIVATE_USER]: handleDeactivateUser,
    [ActionKeys.SEND_INVITE]: handleSendInvite,
    [ActionKeys.RESEND_INVITE]: handleResendInvite,
    [ActionKeys.DELETE]: userDeletionOverlayTriggerState.open
  };

  const onAction = async (key: Key) => {
    const command = actionCommands[key as ActionKeys];

    if (!command) {
      return;
    }
    setLoading(true);
    try {
      await command();
    } finally {
      setLoading(false);
    }
  };

  const items = [];

  if (usersAllowedInvite.length > 0) {
    items.push(DefaultMenuItem(ActionKeys.SEND_INVITE, t("users.bulkActions.sendInvite", { count: usersAllowedInvite.length })));
  }

  if (usersAllowedReinvite.length > 0) {
    items.push(DefaultMenuItem(ActionKeys.RESEND_INVITE, t("users.bulkActions.resendInvite", { count: usersAllowedReinvite.length })));
  }

  if (usersAllowedActivate.length > 0) {
    items.push(DefaultMenuItem(ActionKeys.ACTIVATE_USER, t("users.bulkActions.activateUser", { count: usersAllowedActivate.length })));
  }

  if (usersAllowedDeactivate.length > 0) {
    items.push(DefaultMenuItem(ActionKeys.DEACTIVATE_USER, t("users.bulkActions.deactivateUser", { count: usersAllowedDeactivate.length })));
  }

  if (deletableUsers.length > 0) {
    items.push(DefaultMenuItem(ActionKeys.DELETE, t("users.bulkActions.delete", { count: deletableUsers.length }), { variant: "danger" }));
  }

  const tooltip = isEmpty(items)
    ? t("users.bulkActions.disabled.message")
    : "";

  return (
    <>
      <TooltipWrapper anchorSelector="bulk-actions-dropdown" tooltip={tooltip}>
        <DropdownMenuButton isDisabled={isEmpty(items)} onAction={onAction} loading={loading} buttonText={t("users.heading.actions")} menuClassName="w-[260px]">
          <Section>
            {items}
          </Section>
        </DropdownMenuButton>
      </TooltipWrapper>
      <DeleteConfirmationModal
        title={t("users.bulkDelete.title", { count: deletableUsers.length })}
        confirmationMessage={t("users.bulkDelete.confirmationMessage", { count: deletableUsers.length })}
        onDelete={handleDelete}
        overlayState={userDeletionOverlayTriggerState}
        name=""
      />
    </>
  );
};
