import { DefaultLoader, type FlyoutContentChildren } from "@/components";
import { useSkillFlyoutState } from "@/features/skill/hooks/useSkillFlyoutState";
import { type SkillFlyoutRating } from "@/features/skill/types/skillTypes";
import { useMarketContentTranslation } from "@/hooks/useTranslation";
import { removeTextBetweenParenthesis } from "@/lib/utils";
import { Heading } from "@workleap/orbiter-ui";
import { Suspense, type ReactNode } from "react";
import { type OverlayTriggerState } from "react-stately";
import { SkillFlyoutViewModeActions } from "./SkillFlyoutViewModeActions";
import { SkillFlyoutViewModeContent } from "./SkillFlyoutViewModeContent";

export interface UseSkillFlyoutViewModeProps {
  skillName: string;
  overlayState: OverlayTriggerState;
  hideDefaultFlyoutActions?: boolean;
  extraActions?: ReactNode;
  showRecommendedMentors?: boolean;
  children: FlyoutContentChildren;
}

export const SkillFlyoutViewMode = (props: UseSkillFlyoutViewModeProps) => {
  const { skillName, overlayState, showRecommendedMentors, children } = props;
  const { changeRating, skillRating } = useSkillFlyoutState(overlayState);
  const { translateMarketContent } = useMarketContentTranslation();

  const onChangeRating = (rating: SkillFlyoutRating) => {
    changeRating(rating);
  };

  return (
    <>
      {children({
        header: (
          <Heading size="xl">
            {removeTextBetweenParenthesis(translateMarketContent(skillName))}
          </Heading>
        ),
        content: (
          <Suspense fallback={<DefaultLoader />}>
            <SkillFlyoutViewModeContent
              skillName={skillName}
              activeRating={skillRating}
              onChangeRating={onChangeRating}
              showRecommendedMentors={showRecommendedMentors}
            />
          </Suspense>
        ),
        footer: (
          <SkillFlyoutViewModeActions
            activeRating={skillRating}
            {...props}
          />
        )
      })}
    </>
  );
};