import { AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { useImportAtsJobOpeningFormMutation } from "@/features/ats/hooks/useImportAtsJobOpeningFormMutation";
import { useTrackAtsPositionAction } from "@/features/ats/hooks/useTrackAtsPositionAction";
import { type AtsOpenJobDto } from "@/features/ats/types/common";
import { JobOpeningFormFlyout } from "@/features/jobs/components/job-opening-form/JobOpeningFormFlyout";
import { type JobOpeningFormInput } from "@/features/jobs/types/jobOpeningFormTypes";
import { useTranslation } from "@/hooks/useTranslation";
import { AnimatePresence } from "framer-motion";
import { FormProvider } from "react-hook-form";
import { type OverlayTriggerState } from "react-stately";

interface Props {
  job: AtsOpenJobDto;
  overlayState: OverlayTriggerState;
}

export const AtsImportJobOpeningFlyout = (props: Props) => {
  const { overlayState } = props;

  return (
    <AnimatePresence>
      {overlayState.isOpen && (
        <AtsImportJobOpeningFlyoutOpen {...props} />
      )}
    </AnimatePresence>
  );
};

const AtsImportJobOpeningFlyoutOpen = ({ job, overlayState }: Props) => {
  const { t } = useTranslation();
  const { trackAtsPositionAction } = useTrackAtsPositionAction();
  
  const { mutation: { mutate, isPending }, form } = useImportAtsJobOpeningFormMutation({
    job,
    onSuccess: () => {
      trackAtsPositionAction(AnalyticValues.actionSave);

      overlayState.close();
    }
  });

  const onSubmit = async ({ openDate, role, hiringManager, postingLink }: JobOpeningFormInput) => {
    mutate({
      mergeId: job.mergeJobId,
      openDate,
      roleId: role!.id,
      hiringManagerId: hiringManager?.id,
      postingLink
    });
  };
  
  return (
    <FormProvider {...form}>
      <JobOpeningFormFlyout 
        title={t("jobs.new.form.heading")}
        submitLabel={t("ats.openPositions.actions.import")}
        isSaving={isPending}
        mode="import"
        isStatusDisabled
        onSubmit={onSubmit}
        onClose={() => overlayState.close()}
      />
    </FormProvider>
  );
};