import { type Locale, format } from "date-fns";
import { useCurrentUser } from "@/lib/auth/AuthProvider";
import localeEn from "date-fns/locale/en-US";
import localeFr from "date-fns/locale/fr";

interface Options {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: number;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
}

export const useFormatDate = () => {
  const { language } = useCurrentUser();

  const formatDate = (date: Date, dateFormat: string, options?: Options) => {
    if (language === "fr") {
      return format(date, dateFormat, { ...options, locale: localeFr });
    }
  
    return format(date, dateFormat, { ...options, locale: localeEn });
  };

  return formatDate;
};