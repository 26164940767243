import { useConnectAtsStore } from "./useConnectAtsStore";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { useSaveAtsAccountTokenMutation } from "./useSaveAtsAccountTokenMutation";
import { useState } from "react";

export const useConnectAts = () => {
  const [linkToken, setLinkToken] = useState("");
  const [selectedIntegration, setSelectedIntegration] = useState("");
  const { mutate: saveAtsAccountToken } = useSaveAtsAccountTokenMutation();
  const { setShowBanner } = useConnectAtsStore();
  
  const onSuccess = (publicToken: string) => {
    saveAtsAccountToken({ 
      integration: selectedIntegration, 
      publicToken 
    });

    setShowBanner(true);
  };

  const { open } = useMergeLink({
    linkToken: linkToken,
    onSuccess
  });

  const openMergeLink = async (integration: string, token: string) => {
    setSelectedIntegration(integration);
    setLinkToken(token);

    open();
  };

  return {
    openMergeLink
  };
};