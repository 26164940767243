import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles/hopper.css";
import "./styles/main.css";
import styles from "./styles/toast.module.css";

import { ToastContainer } from "react-toastify";
import { AppProvider } from "./providers/app";

const App = () => (
  <>
    <AppProvider />
    <ToastContainer className={styles.toast} />
  </>
);

export default App;
