import { EditIcon, LockIcon } from "@hopper-ui/icons";
import { HStack, Text, TooltipWrapper } from "@/components";
import { type RoleStatus } from "@/features/roles/types/common";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  status: RoleStatus;
  showTooltip?: boolean;
}

export const RoleStatusChip = ({ status, showTooltip }: Props) => {
  const { t } = useTranslation();

  if (status === "Published") { return null; }

  const { label, tooltip, icon } = status === "Draft"
    ? {
      label: t("roles.status.draft"),
      tooltip: t("roles.status.draftTooltip"),
      icon: <EditIcon size="sm" />
    }
    : {
      label: t("roles.status.archived"),
      tooltip: t("roles.status.archivedTooltip"),
      icon: <LockIcon size="sm" />
    };

  return (
    <TooltipWrapper tooltip={showTooltip ? tooltip : ""} anchorSelector="form-help-icon">
      <HStack gap={1} align="center" className="text-neutral-text">
        {icon}
        <Text size="sm">{label}</Text>
      </HStack>
    </TooltipWrapper>
  );
};