import { type UpdateAtsImportSkillsCommand } from "@/features/ats/types/common";
import { atsQueryKeys } from "@/features/ats/types/atsQueryKeys";
import { toast } from "react-toastify";
import { translate } from "@/lib/i18n";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "@/lib/http/api";

export const useUpdateAtsImportSkillsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: UpdateAtsImportSkillsCommand) => {
      await api.post("/ats/settings/import-skills", payload);
    },
    onSuccess: async (_, { isImportSkillsEnabled }) => {
      await queryClient.invalidateQueries({ queryKey: atsQueryKeys.settings() });

      toast.success(isImportSkillsEnabled 
        ? translate("ats.sync.importSkills.enabled")
        : translate("ats.sync.importSkills.disabled"));
    }
  });
};