import { ConditionalLink, Text } from "@/components";
import { SkillPill, SkillPillSkeleton } from "@/features/skills/components/skill-pill/SkillPill";
import { type SkillDto, type UncategorizedSkillDto } from "@/features/skills/types/Skill";
import { useTranslation } from "@/hooks/useTranslation";
import classNames from "classnames";
import { times } from "lodash";
import { useMemo, type ComponentProps } from "react";
import { type To } from "react-router-dom";
import { getSkillsToShow, orderSkillsByRating } from "./skillList.utils";

type PillPropsOverride = Omit<ComponentProps<typeof SkillPill>, "skill">;

interface Props {
  skills?: Omit<SkillDto, "score">[];
  uncategorizedSkills?: UncategorizedSkillDto[];
  max?: number;
  toLink?: To;
  skillPillPropsOverride?: PillPropsOverride;
}

export const SkillList = ({ skills = [], uncategorizedSkills = [], max, toLink, skillPillPropsOverride }: Props) => {
  const skillsToShow = useMemo(() => getSkillsToShow({
    skills: orderSkillsByRating(skills),
    max
  }), [skills, max]);

  if (!skillsToShow) {
    return null;
  }
  
  return (
    <div className="flex flex-wrap items-center gap-2">
      <ListSkillPills
        skills={skillsToShow}
        uncategorizedSkills={uncategorizedSkills}
        skillPillPropsOverride={skillPillPropsOverride}
      />
      <ShowCountOfNotShownSkills
        displaySkillCount={skillsToShow.length}
        totalSkillCount={skills.length}
        toLink={toLink}
      />
    </div>
  );
};

interface ListSkillPillsProps {
  skills: Omit<SkillDto, "score">[];
  skillPillPropsOverride?: PillPropsOverride;
  uncategorizedSkills: UncategorizedSkillDto[];
}

const ListSkillPills = ({ skills, skillPillPropsOverride, uncategorizedSkills }: ListSkillPillsProps) => (
  <>
    {skills.map(skill => (
      <SkillPill {...skillPillPropsOverride} key={skill.name} skill={skill} />
    ))}
    {/* TODO: Review if we still need to send the rating */}
    {uncategorizedSkills.map(skill => (
      <SkillPill key={skill.name} hideRating skill={{ ...skill, rating: "Beginner" }} />
    ))}
  </>
);

interface ShowCountOfNotShownSkillsProps {
  displaySkillCount: number;
  totalSkillCount: number;
  toLink?: To;
}

const ShowCountOfNotShownSkills = ({ displaySkillCount, totalSkillCount, toLink }: ShowCountOfNotShownSkillsProps) => {
  const { t } = useTranslation();
  
  const countOfSkillsNotShown = totalSkillCount - displaySkillCount;

  if (countOfSkillsNotShown <= 0) {
    return null;
  }

  const className = classNames(
    "flex items-center",
    "border border-decorative-option5-surface-weakest",
    "py-space-inset-sm px-3 rounded-shape-rounded-md text-decorative-option1-text bg-decorative-option5-surface-weakest", 
    {
      "cursor-pointer": !!toLink
    });

  return (
    <ConditionalLink condition={!!toLink} to={toLink ?? ""} className={className}>
      <Text className="text-sm" color="inherit">
        {`+${countOfSkillsNotShown} ` + t("skills.more")}
      </Text>
    </ConditionalLink>
  );
};

interface SkillListSkeletonProps {
  count?: number;
}

export const SkillListSkeleton = ({ count = 3 }: SkillListSkeletonProps) => (
  <div className="flex flex-wrap items-center gap-2">
    {times(count, index => <SkillPillSkeleton key={index} />)}
  </div>
);
