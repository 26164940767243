import { DefaultLayout } from "@/components";
import { IntercomProvider } from "@/features/analytics/components/IntercomProvider";
import { MixpanelProvider } from "@/features/analytics/components/MixpanelProvider";
import { WorkleapAiWidgetsProvider } from "@/features/workleap-ai/WorkleapAiWidgetsProvider";
import { ZendeskProvider } from "@/features/analytics/components/ZendeskProvider";
import { SetupRouteGuard } from "@/features/setup/routes/SetupRouteGuard";
import { Authorize } from "@/lib/auth";
import { CrowdinInContextTool } from "@/lib/i18n/CrowdinInContextTool";
import { MarketContentTranslationsProvider } from "@/lib/i18n/MarketContentTranslationsProvider";
import { Redirect } from "@/lib/router/Redirect";
import { lazyImport } from "@/utils";
import { Navigate, Outlet } from "react-router-dom";

const { SetupTenantRoutes } = lazyImport(() => import("@/pages/setup-tenant/SetupTenantRoutes"), "SetupTenantRoutes");
const { SetupUserRoutes } = lazyImport(() => import("@/pages/setup-user/SetupUserRoutes"), "SetupUserRoutes");
const { GrowPlanStepRoutes } = lazyImport(() => import("@/pages/grow-plan/GrowPlanRoutes"), "GrowPlanStepRoutes");
const { ErrorRoutes } = lazyImport(() => import("@/pages/error/ErrorRoutes"), "ErrorRoutes");
const { ActivationRoutes } = lazyImport(() => import("@/pages/activation/ActivationRoutes"), "ActivationRoutes");

// Routes with default layout
const { HomeRoutes } = lazyImport(() => import("@/pages/home/HomeRoutes"), "HomeRoutes");
const { GrowRoutes } = lazyImport(() => import("@/pages/grow/GrowRoutes"), "GrowRoutes");
const { MyRoleRoutes } = lazyImport(() => import("@/pages/myrole/MyRoleRoutes"), "MyRoleRoutes");
const { ProfileRoutes } = lazyImport(() => import("@/pages/profile"), "ProfileRoutes");
const { RolesRoutes } = lazyImport(() => import("@/pages/roles/RolesRoutes"), "RolesRoutes");
const { ReportsRoutes } = lazyImport(() => import("@/pages/reports/ReportsRoutes"), "ReportsRoutes");
const { SettingsRoutes } = lazyImport(() => import("@/pages/settings/SettingsRoutes"), "SettingsRoutes");
const { SeekRoutes } = lazyImport(() => import("@/pages/seek/SeekRoutes"), "SeekRoutes");
const { GrowPlanRoutes } = lazyImport(() => import("@/pages/grow-plan/GrowPlanRoutes"), "GrowPlanRoutes");

const App = () => (
  <Authorize>
    <MixpanelProvider>
      <IntercomProvider>
        <WorkleapAiWidgetsProvider>
          <ZendeskProvider>
            <MarketContentTranslationsProvider>
              <CrowdinInContextTool />
              <Outlet />
            </MarketContentTranslationsProvider>
          </ZendeskProvider>
        </WorkleapAiWidgetsProvider>
      </IntercomProvider>
    </MixpanelProvider>
  </Authorize>
);

export const protectedRoutes = [
  {
    path: "/",
    element: <App />,
    children: [
      { path: "setup-tenant/*", element: <SetupTenantRoutes /> },
      { path: "setup-user/*", element: <SetupUserRoutes /> },
      { path: "error/*", element: <ErrorRoutes /> },
      { path: "activation/*", element: <ActivationRoutes /> },
      { 
        path: "/", 
        element: <SetupRouteGuard />, 
        children: [
          { path: "grow/:id/steps/*", element: <GrowPlanStepRoutes /> },
          {
            path: "/",
            element: <DefaultLayout />,
            children: [
              { path: "", element: <HomeRoutes /> },
              { path: "grow", element: <GrowRoutes /> },
              { path: "grow/*", element: <GrowPlanRoutes /> },
              { path: "myrole/*", element: <MyRoleRoutes /> },
              { path: "account/profile/*", element: <ProfileRoutes /> },
              { path: "roles/*", element: <RolesRoutes /> },
              { path: "reports/*", element: <ReportsRoutes /> },
              { path: "settings/*", element: <SettingsRoutes /> },
              { path: "seek/*", element: <SeekRoutes /> }
            ]
          }
        ] 
      },
      { path: "*", element: <Navigate to="." /> }
    ]
  },
  { path: "/login", element: <Redirect to="/login" /> },
  { path: "/logout", element: <Redirect to="/logout" /> }
];
