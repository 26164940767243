import { atsQueryKeys } from "@/features/ats/types/atsQueryKeys";
import { directoryQueryKeys } from "@/features/directory/types/directoryQueryKeys";
import { getFullName } from "@/features/employees/utils";
import { type UpdateJobOpening, type UpdateJobOpeningCommand } from "@/features/jobs/types/common";
import { JobOpeningFormSchema, type JobOpeningFormInput } from "@/features/jobs/types/jobOpeningFormTypes";
import { jobsQueryKeys } from "@/features/jobs/types/jobsQueryKeys";
import { roleQueryKeys } from "@/features/roles/types/roleQueryKeys";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import api from "@/lib/http/api";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

interface UseUpdateJobOpeningOptions {
  jobOpening: UpdateJobOpening;
  onSuccess: () => void;
}

export const useUpdateJobOpeningFormMutation = ({ jobOpening, onSuccess }: UseUpdateJobOpeningOptions) => {
  const queryClient = useQueryClient();

  const { openDate, roleId, roleName, hiringManagerId, hiringManagerFirstName, hiringManagerLastName, postingLink, status } = jobOpening;

  const form = useForm<JobOpeningFormInput>({
    defaultValues: {
      openDate,
      role: {
        id: roleId,
        name: roleName
      },
      hiringManager: hiringManagerId ? {
        id: hiringManagerId,
        name: getFullName(hiringManagerFirstName, hiringManagerLastName)
      } : null,
      postingLink,
      status
    },
    resolver: valibotResolver(JobOpeningFormSchema)
  });

  const mutation = useMutationWithValidationResult<UpdateJobOpeningCommand>({
    mutationFn: (data: UpdateJobOpeningCommand) => api.post("/jobs/updateJobOpening", data),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: directoryQueryKeys.all }),
        queryClient.invalidateQueries({ queryKey: jobsQueryKeys.all }),
        queryClient.invalidateQueries({ queryKey: roleQueryKeys.all }),
        queryClient.invalidateQueries({ queryKey: atsQueryKeys.all })
      ]);

      onSuccess();
    }
  });

  return { form, mutation };
};