import { useGetSkillsQuery } from "@/features/setup/hooks/useGetSkillsSearchResults";
import { SearchSkillResultItem } from "@/features/skills/components/SearchSkillResultItem";
import { type UncategorizedSkillDto } from "@/features/skills/types/Skill";
import { useTranslation } from "@/hooks/useTranslation";
import { SearchIcon } from "@hopper-ui/icons";
import { Autocomplete, Item } from "@workleap/orbiter-ui";
import { useState, type ComponentProps } from "react";

interface Props extends Omit<ComponentProps<typeof Autocomplete>, "children"> {
  jobTitle?: string;
  onSkillSelect: (skill?: UncategorizedSkillDto) => void;
  disabledItems?: string[];
}

export const SearchSkills = ({ jobTitle, onSkillSelect, disabledItems, minCharacters = 2, ...props }: Props) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [value, setValue] = useState<string | null>(null);

  const { data, isFetching } = useGetSkillsQuery(query, jobTitle,
    { enabled: query.length >= minCharacters }
  );

  // TODO: Clicking on a disabled item closes the modal right now
  // Should be fixed in: https://github.com/gsoft-inc/wl-orbiter/issues/93
  // JIRA: https://gsoftdev.atlassian.net/browse/PRT-3643
  const uncategorizedSkills = data?.skills.map(x => ({ ...x, score: 0 })).filter(x => !disabledItems?.includes(x.name));

  return (
    <Autocomplete
      {...props}
      value={value}
      loading={isFetching}
      fluid
      icon={<SearchIcon />}
      onSearch={(_, searchTerm) => setQuery(searchTerm)}
      placeholder={t("setup.skills.addSkill")}
      aria-label={t("setup.skills.addSkill")}
      onSelectionChange={(_, selection) => {
        if (selection) {
          onSkillSelect(uncategorizedSkills?.find(x => x.name === selection.key));
        }
    
        // TODO: This is a hack to permit the Autocomplete to select multiple items
        // JIRA: https://gsoftdev.atlassian.net/browse/PRT-3642
        setValue(null);
      }}
    >
      {uncategorizedSkills?.map(({ name }) => (
        <Item key={name}>
          <SearchSkillResultItem skillName={name} />
        </Item>
      ))}
    </Autocomplete>
  );
};
