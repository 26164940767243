import { type Model } from "@/features/types/clientTypes";
import { useTranslation } from "@/hooks/useTranslation";
import { useCurrentUser } from "@/lib/auth/AuthProvider";
import { type Feature } from "@/lib/features/types/feature";

type PermissionLevel = Model<"Users.Queries.GetCurrentUserQueryDto.UserDto">["permissionLevel"];

export const useFeatures = () => {
  const { t } = useTranslation();
  const { features, permissionLevel } = useCurrentUser();

  const hasFeature = (feature: Feature) => features.includes(feature);

  const getPlanNameByFeature = (feature: Feature) => {
    if (hasFeature("Features.TeamTierPricing")) {
      switch (feature) {
        case "Features.ManagerAccessManagement":
        case "Features.SkillGapReport":
        case "Features.SkillsVariationReport": 
        case "Features.DepartmentManagement":
          return t("freemium.essential");
        case "Features.AdvancedFilters":
        case "Features.HRISSyncCollaborators":
          return t("freemium.pro");
        default:
          return t("freemium.team");
      }
    }
    
    switch (feature) {
      case "Features.AdvancedFilters":
      case "Features.HRISSyncCollaborators":
        return t("freemium.pro");
      default:
        return t("freemium.essential");
    }
  };

  const getBlockedFeature = (feature: Feature) => {
    if (hasFeature(feature)) {
      return undefined;
    }

    return feature;
  };

  /*
  * This function checks if a feature is available or if the user has a permission level that allows them to access it.
  * By default, the function will check if the user has a permission level of "super-admin" or "executive".
  * You can pass an array of permission levels to check if the user has any of them.
  **/
  const isFeatureAvailableOrHasPermissionLevel = (feature: Feature, permissionLevels: PermissionLevel[] = ["super-admin", "executive"]) =>
    (permissionLevel && permissionLevels.includes(permissionLevel)) || hasFeature(feature);

  return { hasFeature, getPlanNameByFeature, getBlockedFeature, isFeatureAvailableOrHasPermissionLevel };
};