import { AddCustomSkillForm } from "./AddCustomSkillForm";
import { type CustomSkillFormState } from "@/features/skills/types/customSkills";
import { EditCustomSkillForm } from "./EditCustomSkillForm";
import { FormHeader, UnsavedChangesModal, VStack } from "@/components";
import { Routes } from "@/types/routes";
import { type SimilarSkillDto } from "@/features/skills/types/common";
import { isCustomSkillFormValid, toMetadataCustomDescriptions } from "@/features/skills/utils/customSkills";
import { isEmpty } from "lodash";
import { useAddCustomSkillMutation } from "@/features/skills/hooks/useAddCustomSkillMutation";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@/hooks/useTranslation";

export const AddCustomSkill = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const form = useForm<CustomSkillFormState>({
    defaultValues: { name: "", ratingsDescriptions: {}, similarSkills: [] }
  });
  const { watch, handleSubmit, setValue, formState: { isDirty, isSubmitted } } = form;
  
  const onCancelClick = () => navigate(Routes.CustomSkills);

  const { mutate, isPending, validationErrors } = useAddCustomSkillMutation(() => 
    navigate(Routes.CustomSkills));
  
  const submitHandler = (formData: CustomSkillFormState) => {
    mutate({
      ...formData,
      ratingsDescriptions: toMetadataCustomDescriptions(formData.ratingsDescriptions)
    });
  };

  const onChangeSimilarSkills = (newSkills: SimilarSkillDto[]) =>
    setValue("similarSkills", newSkills, { shouldDirty: true });

  const isDescriptionsGenerated = Object.entries(watch("ratingsDescriptions")).some(([_, value]) => !isEmpty(value));
  
  return (
    <>
      <form onSubmit={handleSubmit(submitHandler)}>
        <VStack gap={8}>
          <FormHeader 
            title={t("skills.customSkills.form.addTitle")}
            discardButtonLabel={t("common.form.cancel")}
            saveButtonLabel={t("common.form.save")}
            onBack={onCancelClick}
            isLoading={isPending}
            isDirty={isDirty}
            canSubmit={isCustomSkillFormValid(watch())}
          />
          {isDescriptionsGenerated
            ? <EditCustomSkillForm {...form} errors={validationErrors} onChangeSimilarSkills={onChangeSimilarSkills} />
            : <AddCustomSkillForm {...form} errors={validationErrors} />}
        </VStack>
      </form>
      <UnsavedChangesModal when={isDirty && !isSubmitted && !isPending} />
    </>
  );
};

