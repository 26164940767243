import { Card, ConfirmationModal } from "@/components";
import { useDeleteSkillMutation } from "@/features/skill/hooks/useDeleteSkillMutation";
import { type SkillReferencesDto } from "@/features/skill/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { WarningIcon } from "@hopper-ui/icons";
import { Checkbox, Div, Flex, Text } from "@workleap/orbiter-ui";
import { isEmpty } from "lodash";
import { useState } from "react";
import { Trans } from "react-i18next";
import { type OverlayTriggerState } from "react-stately";

interface Props {
  skillName: string;
  overlayState: OverlayTriggerState;
  onDeleteSuccess?: () => void;
  skillReferencesData: SkillReferencesDto;
}

export const SkillDeletionWithRiskModal = ({
  overlayState,
  skillName,
  skillReferencesData,
  onDeleteSuccess
}: Props) => {
  const { t } = useTranslation();

  const { mutate: deleteSkill, isPending } = useDeleteSkillMutation(() => {
    overlayState.close();
    onDeleteSuccess?.();
  });
  const [isDeletionRiskAccepted, setIsDeletionRiskAccepted] = useState(false);
  const onRiskAcceptanceChange = () => setIsDeletionRiskAccepted(!isDeletionRiskAccepted);
  const {
    employees,
    growPlans,
    roles,
    trainings
  } = skillReferencesData;

  const impactedEmployees = employees;
  const impactedTrainings = trainings.filter(({ isActive }) => isActive);
  const impactedActivePlans = growPlans.filter(({ isActive }) => isActive);
  const impactedActiveRoles = roles.filter(({ isActive }) => isActive);

  const onClickConfirm = () => deleteSkill(skillName);

  return (
    <ConfirmationModal
      title={t("skills.delete.title")}
      onClickConfirm={onClickConfirm}
      overlayState={overlayState}
      cancelButtonContent={t("common.form.cancel")}
      confirmButtonContent={t("skills.actions.delete")}
      confirmButtonProps={{ isDisabled: !isDeletionRiskAccepted, loading: isPending }}
      cancelButtonProps={{ isDisabled: isPending }}
      className="!w-[480px]"
      dontCloseOnConfirm
    >
      <Flex direction="column" gap="stack-md">
        <Text className="text-center">
          <Trans i18nKey="skills.delete.withRisk.summary" components={{ br: <br /> }} />
        </Text>
        <Card className="bg-warning-surface border-warning-text-weak">
          <Flex direction="column" gap="stack-md">
            <Flex direction="row" gap="inline-md">
              <Div>
                <WarningIcon className="text-warning-text-weak" size="lg" />
              </Div>
              <Flex direction="column">
                <Text className="text-warning-text-strong" size="md" fontWeight={690}>{t("roles.delete.roleWillBeDissociated")}</Text>
                <Div className="text-warning-text-strong">
                  <ul className="px-4 list-disc">
                    {!isEmpty(impactedEmployees) && (
                      <li>
                        {t("skills.delete.warning.impact.employees", {
                          count: impactedEmployees.length })}
                      </li>
                    )}
                    {!isEmpty(impactedActiveRoles) && (
                      <li>
                        {t("skills.delete.warning.impact.roles", {
                          count: impactedActiveRoles.length })}
                      </li>
                    )}
                    {!isEmpty(impactedActivePlans) && (
                      <li>
                        {t("skills.delete.warning.impact.growthPlans", {
                          count: impactedActivePlans.length })}
                      </li>
                    )}
                    {!isEmpty(impactedTrainings) && (
                      <li>
                        {t("skills.delete.warning.impact.trainings", {
                          count: impactedTrainings.length })}
                      </li>
                    )}
                    <li>
                      {t("skills.delete.warning.impact.skillVariationsReport")}
                    </li>
                  </ul>
                </Div>
              </Flex>
            </Flex>
            <Flex alignItems="center" justifyContent="center">
              <Checkbox className="text-warning-text-strong" checked={isDeletionRiskAccepted} onChange={onRiskAcceptanceChange}>
                {t("skills.delete.warning.impact.confirmation")}
              </Checkbox>
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </ConfirmationModal>
  );
};