import { HStack, VStack } from "@/components";
import { type EmployeeEmploymentFormState } from "@/features/employees/types/common";
import { RoleLink } from "@/features/roles/components/RoleLink";
import { RoleStatusChip } from "@/features/roles/components/RoleStatusChip";
import { ExternalChip } from "@/features/search/components/search-role-then-job/components/ExternalChip";
import { useMarketContentTranslation, useTranslation } from "@/hooks/useTranslation";
import { Div, Flex, Text } from "@workleap/orbiter-ui";
import { differenceInMonths, format } from "date-fns";
import { useMemo } from "react";
import { CurrentRoleChip } from "./CurrentRoleChip";

interface Props extends EmployeeEmploymentFormState{
  isCurrentRole?: boolean;
}

export const WorkHistoryEmployment = ({ roleName, effectiveDate, roleId, roleStatus, endDate, isCurrentRole, isInternal }: Props) => {
  const { t } = useTranslation();
  const { translateMarketContent } = useMarketContentTranslation();
  const end = endDate
    ? ` ${format(endDate, "MMM yyyy")}`
    : ` ${t("employee.employment.present")}`;

  const difference = useMemo(() => {
    if (!effectiveDate || !endDate) {
      return "";
    }

    const totalMonths = differenceInMonths(endDate, effectiveDate);

    if (totalMonths < 1) {
      return "";
    }

    const shownMonths = totalMonths % 12;
    const shownYears = (totalMonths - shownMonths) / 12;

    if (shownYears > 0) {
      if (shownMonths > 0) {
        return ` (${t("employee.employment.years", { count: shownYears })} ${t("employee.employment.months", { count: shownMonths })})`;
      } else {
        return ` (${t("employee.employment.years", { count: shownYears })})`;
      }
    }

    return ` (${t("employee.employment.months", { count: shownMonths })})`;
  }, [effectiveDate, endDate]);

  const isArchived = roleStatus === "Archived";

  const roleDate = useMemo(() => {
    if (!effectiveDate) {
      return null;
    }

    if (isCurrentRole) {
      return `${t("employee.employment.since")} ${format(effectiveDate, "MMM yyyy")} ${difference}`;
    }
    
    return `${format(effectiveDate, "MMM yyyy")} — ${end}${difference}`;
  }, [effectiveDate, isCurrentRole]);

  return (
    <Div color={isCurrentRole ? "neutral" : "neutral-weak"}>
      <VStack gap={1}>             
        <HStack gap={2} align="center">
          <Flex alignItems="center" gap="inline-md">
            <Text size="md" fontWeight={505} color="inherit">
              {roleId && roleStatus !== "Archived" ? (
                <RoleLink 
                  roleId={roleId}
                  roleStatus={roleStatus}
                  className="hover:underline"
                >
                  {translateMarketContent(roleName ?? "")}
                </RoleLink>
              ) : translateMarketContent(roleName ?? "")}
            </Text>
            {isCurrentRole && (
              <CurrentRoleChip />
            )}
            {!isInternal && (
              <ExternalChip />
            )}
          </Flex>
          {isArchived && <RoleStatusChip status={roleStatus} />}
        </HStack>
        {roleDate && (
          <Text size="sm" color="inherit">
            {roleDate}
          </Text>
        )}
      </VStack>
    </Div>
  );
};