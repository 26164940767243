import { AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { HStack, Switch, TooltipWrapper } from "@/components";
import { InfoIcon } from "@hopper-ui/icons";
import { PremiumBadge } from "@/features/billing/components/PremiumBadge";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import { useGetHrisSettingsQuery } from "@/features/hris/hooks/useGetHrisSettings";
import { useTrackHrisAction } from "@/features/hris/hooks/useTrackHrisAction";
import { useTranslation } from "@/hooks/useTranslation";
import { useUpdateAutoAssignManagersAsCollaboratorsMutation } from "@/features/hris/hooks/useUpdateAutoAssignManagersAsCollaboratorsMutation";

export const HrisAutoAssignManagersAsCollaboratorsToggle = () => {
  const { t } = useTranslation();
  const { data: settingsQueryData } = useGetHrisSettingsQuery();
  const { mutate: updateActivation, isPending } = useUpdateAutoAssignManagersAsCollaboratorsMutation();
  const { hasFeature } = useFeatures();
  const isDisabled = settingsQueryData?.isScheduledOrRunning || isPending || !hasFeature("Features.HRISSyncCollaborators");
  const { trackHrisAction } = useTrackHrisAction();

  const handleChange = (isEnabled: boolean) => {
    updateActivation({ isEnabled });
  };
  
  const onHrisCollaboratorSyncChange = (isSelected: boolean) => {
    trackHrisAction(AnalyticValues.actionAutoSync, isSelected.toString());
    handleChange(isSelected);
  };
  
  return (
    <HStack align="center">
      <Switch
        isSelected={settingsQueryData?.isAutoAssignManagersAsCollaboratorsEnabled} 
        isDisabled={isDisabled}
        onChange={onHrisCollaboratorSyncChange}
      >
        {t("hrisSettings.sync.collaborators.toggle.label")}
      </Switch>
      <TooltipWrapper tooltip={t("hrisSettings.sync.collaborators.toggle.tooltip")} anchorSelector="form-help-icon">
        <InfoIcon className="text-neutral-text-weak" />
      </TooltipWrapper>
      {!hasFeature("Features.HRISSyncCollaborators") && <PremiumBadge feature="Features.HRISSyncCollaborators" />}
    </HStack>
  );
};