import { TrainingForm } from "@/features/training";
import { useTranslation } from "@/hooks/useTranslation";
import { Helmet } from "react-helmet-async";

export const AddTrainingPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("pages.addTraining")}</title>
      </Helmet>
      <TrainingForm defaultValues={{ title: "", skills: [], uncategorizedSkills: [] }} />
    </>
  );
};
