import { createCalendar } from "@internationalized/date";
import { type DateValue } from "@react-types/datepicker";
import { useRef } from "react";
import { type AriaDatePickerProps, useDateField, useDateSegment, useLocale } from "react-aria";
import { type DateFieldState, type DateSegment, useDateFieldState } from "react-stately";

import classNames from "classnames";
import styles from "./DateField.module.css";
import { type DatePickerSize } from "./types";

interface SegmentProps {
  segment: DateSegment;
  state: DateFieldState;
}

const getMonthOrDayLabel = (segment: DateSegment) : string => {
  const { value, text } = segment;

  if (!value) {
    return text;
  }

  const prefix = value < 10 && text.length === 1
    ? "0"
    : "";

  return `${prefix}${text}`;
};

const DateSegmentView = ({ segment, state }: SegmentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  const { maxValue, isEditable, isPlaceholder, text, type } = segment;

  const segmentValue = type === "day" || type === "month"
    ? getMonthOrDayLabel(segment)
    : text;

  return (
    <div
      {...segmentProps}
      ref={ref}
      style={{
        ...segmentProps.style,
        minWidth: maxValue
          ? `${String(maxValue).length}ch`
          : ""
      }}
      className={classNames(styles.segmentContainer, {
        [styles.segmentContainerDisabled]: !isEditable || state.isDisabled,
        [styles.segmentPlaceholder]: isPlaceholder
      })}
    >
      {segmentValue}
    </div>
  );
};

interface Props extends AriaDatePickerProps<DateValue> {
  size: DatePickerSize;
}

export const DateField = (props: Props) => {
  const { locale } = useLocale();
  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar
  });

  const ref = useRef<HTMLDivElement>(null);
  const { fieldProps } = useDateField(props, state, ref);

  return (
    <div {...fieldProps} ref={ref} className={classNames(styles.container, { [styles.small]: props.size === "sm" })}>
      {state.segments.map((segment, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <DateSegmentView key={i} segment={segment} state={state} />
      ))}
    </div>
  );
};
