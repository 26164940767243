import { Tenants } from "@/features/tenants/routes/Tenants";
import { Authorize } from "@/lib/auth";
import { Redirect } from "@/lib/router/Redirect";
import { ProfileRoutes } from "@/pages/profile";
import { SettingsRoutes } from "@/pages/settings/SettingsRoutes";
import { Navigate, Outlet } from "react-router-dom";

const HostApp = () => (
  <Authorize>
    <Outlet />
  </Authorize>
);

export const hostRoutes = [
  {
    path: "/",
    element: <HostApp />,
    children: [
      { path: "tenants", element: <Tenants /> },
      { path: "account/profile/*", element: <ProfileRoutes /> },
      { path: "settings/*", element: <SettingsRoutes /> },
      { path: "*", element: <Navigate to="." /> }
    ]
  },
  { path: "/login", element: <Redirect to="/login" /> },
  { path: "/logout", element: <Redirect to="/logout" /> }
];