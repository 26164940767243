import { type DatePickerProps } from "./types";

import { Button } from "@/components";

import { DatePicker } from "./DatePicker";
import { useState } from "react";
import { useTranslation } from "@/hooks/useTranslation";

interface ClearableDatePickerProps extends DatePickerProps {
  onClear: () => void;
}

export const ClearableDatePicker = (props: ClearableDatePickerProps) => {
  const { t } = useTranslation();

  const [datePickerKey, setDatePickerKey] = useState(0);

  const { onClear, value } = props;

  const clear = () => {
    setDatePickerKey(datePickerKey + 1);
    onClear();
  };
  
  return (
    <DatePicker {...props} key={datePickerKey}>
      {value && (
        <Button variant="danger"
          size="small"
          className="self-center"
          onClick={clear}
        >
          {t("common.datePicker.clear")}
        </Button>
      )}
    </DatePicker>
  );
};
