
import { type operations } from "@/features/types/client";
import { type Model } from "@/features/types/clientTypes";
import { translate } from "@/lib/i18n";

export type GetEmployeeByIdQueryEmployeeDto = Model<"Employees.Queries.GetEmployeeByIdQuery.EmployeeDto">;
export type GetEditEmployeeModelDto = Model<"Employees.Queries.GetEditEmployeeModelByIdQuery.EditEmployeeModelDto">;
export type EmployeeResumeDto = Model<"Employees.Models.EmployeeResumeDto">;
export type AddEmployeeResult = Model<"Employees.Commands.AddEmployeeCommand.AddEmployeeResult">;
type Employment = Model<"Employees.Models.EmploymentDto">;

export type Employee = {
  currentEmployment?: Employment;
  employments?: Employment[];
} & GetEmployeeByIdQueryEmployeeDto;

type OmitUndefined<T> = T extends undefined ? never : T;

export type Gender = OmitUndefined<Model<"Employees.Commands.AddEmployeeCommand.HttpRequestBody">["gender"]>;
export type Ethnicity = OmitUndefined<Model<"Employees.Commands.AddEmployeeCommand.HttpRequestBody">["ethnicity"]>;

export const GenderLabels = new Map<Gender, string>([
  ["FEMALE", translate("common.labels.gender.female")],
  ["MALE", translate("common.labels.gender.male")],
  ["NON-BINARY", translate("common.labels.gender.nonBinary")],
  ["OTHER", translate("common.labels.gender.other")],
  ["PREFER_NOT_TO_DISCLOSE", translate("common.labels.preferNotToDisclose")]
]);

export const EthnicityLabels = new Map<Ethnicity, string>([
  ["AMERICAN_INDIAN_OR_ALASKA_NATIVE", translate("common.labels.ethnicity.americanIndianOrAlaskaNative")],
  ["ASIAN_OR_INDIAN_SUBCONTINENT", translate("common.labels.ethnicity.asianOrIndianSubcontinent")],
  ["BLACK_OR_AFRICAN_AMERICAN", translate("common.labels.ethnicity.blackOrAfricanAmerican")],
  ["HISPANIC_OR_LATINO", translate("common.labels.ethnicity.hispanicOrLatino")],
  ["NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER", translate("common.labels.ethnicity.nativeHawaiianOrOtherPacificIslander")],
  ["WHITE", translate("common.labels.ethnicity.white")],
  ["TWO_OR_MORE_RACES", translate("common.labels.ethnicity.twoOrMoreRaces")],
  ["PREFER_NOT_TO_DISCLOSE", translate("common.labels.preferNotToDisclose")]
]);

export type EmployeeTenantRole = Model<"Roles.Models.TenantRole">;
export interface EmployeeEmployment extends Omit<Model<"Employees.Models.EmploymentDto">, "roleName"> {
  roleName?: string;
}

export interface EmployeeEmploymentFormState extends Omit<EmployeeEmployment, "effectiveDate"> {
  isNew?: boolean;
  effectiveDate?: Date;
}

export type EmployeeEditModel = Omit<Model<"Employees.Commands.UpdateEmployeeCommand.HttpRequestBody">, "employments"> & {
  employments: EmployeeEmploymentFormState[];
  reportsToId?: string;
};

export type EmployeesQueryParameters = NonNullable<operations["Employees_GetEmployees"]["parameters"]>["query"];

export type GetTopMatchingRolesQueryDto = Model<"Employees.Queries.GetTopMatchingRolesQueryDto">;
export type EmployeeRoleInterestsDto = Model<"Employees.Queries.EmployeeRoleInterestsDto">;
export type MatchingRole = Model<"Employees.Models.MatchingRoleDto">;

export type AddOrUpdateEmployeeSkillCommand = Model<"Employees.Commands.AddOrUpdateEmployeeSkillCommand">;

export type GetEmployeeRoleSkillDiffQueryDto = Model<"Employees.Queries.GetEmployeeRoleSkillDiffQueryDto">;

export type SkillChangeAuditLogDto = Model<"Employees.Queries.GetEmployeeByIdQuery.SkillChangeAuditLogDto">;

export type AskForEmployeeSkillsReviewCommand = Model<"Employees.Commands.AskForEmployeeSkillsReviewCommand">;

export type GetRoleRecommendationsDto = Model<"Employees.Queries.GetRoleRecommendationsQueryDto">;
export type RoleRecommendationDto = Model<"Employees.Queries.GetRoleRecommendationsQueryDto.RoleRecommendationDto">;
export type EditMemberSettingSection = Model<"Members.Queries.EditMemberSettingSection">;
