import { useConfiguration } from "@/lib/core/ConfigurationProvider";
import { useCurrentUser } from "@/lib/auth/AuthProvider";
import React from "react";

const STRIPE_PRICING_TABLE = "stripe-pricing-table";
const PRICING_TABLE_ID = "pricing-table-id";
const PUBLISHABLE_KEY = "publishable-key";
const CLIENT_REFERENCE_ID = "client-reference-id";

export const PricingTable = () => {
  const { stripePublishableKey, stripePricingTableId } = useConfiguration();
  const currentUser = useCurrentUser();
  
  return React.createElement(STRIPE_PRICING_TABLE, {
    [PRICING_TABLE_ID]: stripePricingTableId,
    [PUBLISHABLE_KEY]: stripePublishableKey,
    [CLIENT_REFERENCE_ID]: `${currentUser.tenant.id}_${currentUser.id}`
  });
};