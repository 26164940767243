import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface ConnectAtsStore {
  showBanner: boolean;
  setShowBanner: (showBanner: boolean) => void;
}

export const useConnectAtsStore = create<ConnectAtsStore>()(
  persist(
    set => ({
      showBanner: false,
      setShowBanner: (showBanner: boolean) => set({ showBanner })
    }),
    {
      name: "connect-ats-store",
      storage: createJSONStorage(() => sessionStorage)
    }
  ));