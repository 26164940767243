import { EmptyState } from "@/components/search-empty-state/SearchEmptyState";
import { type FetchStatus } from "@tanstack/react-query";
import { FieldFormHeader, type FieldFormHeaderProps, FormFieldWrapper } from "@/components";
import { type Key, type ReactElement, type RefObject, useLayoutEffect, useRef, useState } from "react";
import { SelectSearchField } from "./SelectSearchField";
import { useTranslation } from "@/hooks/useTranslation";
import Downshift from "downshift";
import classNames from "classnames";

interface Props<TItem> extends FieldFormHeaderProps {
  description?: string;
  errorMessage?: string;
  resultsTitle?: string;
  results?: TItem[];
  setQuery: (query: string) => void;
  fetchStatus: FetchStatus;
  initialValue?: string;
  itemDisplay: (item: TItem) => ReactElement;
  keySelector: (item: TItem) => Key | undefined;
  itemToText: (item: TItem) => string;
  emptyState?: ReactElement;
  onSelect: (selectedValue: TItem) => void;
  onClear?: () => void;
  disabledItems?: string[];
  clearOnSelect?: boolean;
  defaultSelectedItem?: TItem;
  className?: string;
  inputRef?: RefObject<HTMLInputElement> | null;
  maxDropDownHeight?: number;
  onFocus?: () => void;
  onBlur?: () => void;
}

const OPTION_CLASSES = "flex items-center list-none px-4 py-1.5 font-410 text-sm cursor-pointer";

export const SelectWithSearch = <T, TItem = T>({
  label,
  errorMessage,
  setQuery,
  fetchStatus,
  results,
  keySelector,
  itemDisplay,
  description,
  emptyState,
  initialValue,
  disabledItems,
  onSelect,
  clearOnSelect,
  isDisabled,
  itemToText,
  onClear,
  defaultSelectedItem,
  className,
  inputRef,
  onFocus,
  onBlur,
  maxDropDownHeight = 350,
  ...headerProps
}: Props<TItem>) => {
  const { t } = useTranslation();
  const defaultRef = useRef<HTMLInputElement>(null);
  const ref = inputRef || defaultRef;
  const [dropdownWidth, setWidth] = useState(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (ref.current?.offsetWidth) {
        setWidth(ref.current?.offsetWidth);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const hasResults = !!results && results.length > 0;
  const showEmptyState = !hasResults && fetchStatus !== "fetching" && !!ref?.current?.value.length;
  const canShow = hasResults || showEmptyState;

  const defaultSelected: TItem | null = defaultSelectedItem === undefined
    ? null
    : defaultSelectedItem;

  return (
    <FormFieldWrapper errorMessage={errorMessage}>
      <Downshift
        initialInputValue={initialValue === undefined
          ? ""
          : initialValue}
        itemToString={item => item
          ? itemToText(item)
          : ""}
        onChange={(_, state) => {
          if (clearOnSelect) {
            state.clearSelection();
          }
        }}
        onSelect={selectedItem => selectedItem && onSelect(selectedItem)}
        initialSelectedItem={defaultSelected}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
          selectedItem,
          clearSelection,
          inputValue
        }) => (
          <div
            className={classNames(className, {
              "h-[64px]": label,
              "h-[34px]": !label
            })}
            onFocus={onFocus}
            onBlur={onBlur}
          >
            <FieldFormHeader
              {...headerProps}
              isDisabled={isDisabled}
              label={label}
              labelProps={getLabelProps()}
            />
            <SelectSearchField
              setQuery={setQuery}
              getInputProps={getInputProps}
              fetchStatus={fetchStatus}
              ref={ref}
              description={description || t("common.phrases.search")}
              isDisabled={isDisabled}
              isOpen={isOpen && canShow}
              onClear={() => {
                onClear?.();
                clearSelection();
              }}
              inputValue={inputValue}
              hasError={!!errorMessage}
            />
            <div
              {...getMenuProps()}
              className={classNames("absolute z-[100] overflow-x-hidden bg-neutral-surface border-neutral-border shadow-elevation-raised rounded-b-shape-rounded-md", {
                "border-b border-l border-r": isOpen && canShow
              })}
              style={{
                width: `${dropdownWidth}px`
              }}
            >
              {isOpen && canShow && hasResults && (
                <div
                  className="z-[100]"
                  style={{ maxHeight: `${maxDropDownHeight}px` }}
                >
                  {results?.map((item, index) => {
                    const isItemDisabled = disabledItems?.includes(keySelector(item) as string);

                    return (
                      <div
                        {...getItemProps({
                          key: keySelector(item),
                          index,
                          item,
                          disabled: isItemDisabled
                        })}
                        className={classNames(OPTION_CLASSES, {
                          "text-neutral-text bg-primary-surface-weak": highlightedIndex === index || selectedItem === item,
                          "text-neutral-text-disabled !cursor-not-allowed": isItemDisabled
                        })}
                      >
                        {itemDisplay(item)}
                      </div>
                    );
                  }
                  )}
                </div>
              )}
              <EmptyState
                isOpen={isOpen}
                showEmptyState={showEmptyState}
                emptyState={emptyState}
              />
            </div>
          </div>
        )}
      </Downshift>
    </FormFieldWrapper>
  );
};