import { RouterTextLink } from "@/components/router/RouterTextLink";
import { type AtsOpenJobDto } from "@/features/ats/types/common";
import { useDirectoryRoutes } from "@/features/directory/hooks/useDirectoryRoutes";
import { useTranslation } from "@/hooks/useTranslation";
import { Text } from "@workleap/orbiter-ui";

type Props = Pick<AtsOpenJobDto, "isSynced" | "roleId" | "roleName"> ;

export const AtsOpenPositionLinkStatus = ({ isSynced, roleId, roleName }: Props) => {
  const { t } = useTranslation();
  const { role } = useDirectoryRoutes();

  if (isSynced && roleId) {
    return (
      <RouterTextLink to={role(roleId)} variant="accent" underline="none" target="_blank">
        {roleName}
      </RouterTextLink>
    );
  }

  return (
    <Text size="sm" color="neutral-disabled">{t("ats.openPositions.notLinked")}</Text>
  );
};