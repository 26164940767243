import { useEffect, useRef, useState } from "react";
import { type ProgressiveLoadingProps } from "./types";

export const useProgressiveLoading = ({
  isLoading,
  delay = 300,
  minDisplayTime = 1_000,
  showAfterInitialLoading
}: ProgressiveLoadingProps) => {
  const displayStartTime = useRef<number>(0);
  const prevIsLoading = useRef<boolean>(false);
  const showLoadingTimeoutId = useRef<number | undefined>();
  const isInitialLoading = useRef<boolean>(true);

  const [showLoadingValue, setShowLoadingValue] = useState(false);

  const displayLoading = () => {
    if (prevIsLoading.current) {
      // delay has elapsed and we're still loading
      displayStartTime.current = Date.now();
      setShowLoadingValue(true);
    }
  };

  const hideLoading = () => {
    displayStartTime.current = 0;
    setShowLoadingValue(false);
  };

  useEffect(() => {
    const prevValue = prevIsLoading.current;
    prevIsLoading.current = isLoading;

    if (!prevValue && isLoading) {
      clearTimeout(showLoadingTimeoutId.current);
      showLoadingTimeoutId.current = window.setTimeout(displayLoading, delay);

      return;
    }

    if (!isLoading && displayStartTime.current > 0) {
      // loading has completed (transitioning from true to false)
      isInitialLoading.current = false;
      const elapsedTime = Date.now() - displayStartTime.current;
      if (elapsedTime >= minDisplayTime) {
        hideLoading();
      } else {
        // loading has already been displayed, but minDisplayTime has not elapsed yet
        setTimeout(hideLoading, Math.max(delay, minDisplayTime - elapsedTime));
      }
    }
  }, [isLoading]);

  const showLoading = showLoadingValue && (showAfterInitialLoading || isInitialLoading.current);
  
  return { showLoading };
};
