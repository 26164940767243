import { type Gap } from "@/components";
import classNames from "classnames";
import { type ReactNode } from "react";

type AsGridTags = "div" | "ul";

interface Props {
  children: ReactNode;
  cols?: number | "auto";
  rows?: number | "auto";
  as?: AsGridTags;
  gap?: Gap;
  className?: string;
}

/**
 * @deprecated Use Grid from @workleap/orbiter-ui instead
 */
export const Grid = ({ children, className, cols, rows, as = "div", gap = 4 }: Props) => {
  const GridElem = `${as}` as AsGridTags;

  return (
    <GridElem
      className={classNames("grid relative", `gap-${gap}`, className)}
      style={{
        gridTemplateColumns: cols === "auto"
          ? "auto"
          : `repeat(${cols}, 1fr)`,
        gridTemplateRows: rows === "auto"
          ? "auto"
          : `repeat(${rows}, 1fr)`
      }}
    >
      {children}
    </GridElem>
  );
};