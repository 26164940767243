
import { DeleteTenantForm } from "@/features/tenants/components/DeleteTenantForm";
import { Helmet } from "react-helmet-async";
import { SettingsForm } from "@/features/tenants/components/SettingsForm";
import { VStack } from "@/components";
import { useTranslation } from "@/hooks/useTranslation";

export const OrganizationDetailsPage = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <Helmet>
        <title>{t("pages.settingsOrganizationDetails")}</title>
      </Helmet>
      <VStack gap={6}>
        <SettingsForm />
        <DeleteTenantForm />
      </VStack>
    </>
  );
};
