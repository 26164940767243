import { DemoWrapper } from "@/features/demo";
import { useGetResourcePermissions } from "@/hooks/useGetResourcePermissions";
import { type PropsWithChildren } from "react";
import { useAuth } from "./AuthProvider";
import { hasPermission } from "./hasPermission";
import { type AppPermission, type ResourceType } from "./types";

export interface AuthorizeResourceProps {
  resourceType: ResourceType;
  permission: AppPermission;
  resourceId: string;
}

type Props = AuthorizeResourceProps & Required<PropsWithChildren>;

/**
 * Use for Resource-based permissions (role, employee, progression plan, etc.).
 * Controls access to specific resources.
 */

export const AuthorizeResource = ({ children, permission, resourceId, resourceType }: Props) => {
  const { currentUser } = useAuth();
  const {
    isLoading,
    data
  } = useGetResourcePermissions(resourceType, resourceId, [permission]);

  if (isLoading || (!isLoading && !data)) {
    return null;
  }

  const isAuthorized = hasPermission(permission, data);

  if (!isAuthorized) {
    if (currentUser?.isDemo) {
      return <DemoWrapper>{children}</DemoWrapper>;
    }

    return null;
  }

  return <>{children}</>;
};