import { AuthorizeResource } from "@/lib/auth/AuthorizeResource";
import { Button, Card, CardHeader, HStack, Text, VStack } from "@/components";
import { type EmployeeFormResume, type FormResume, type NewResumeUpload } from "./types";
import { type MouseEvent, useEffect } from "react";
import { NoteIcon } from "@hopper-ui/icons";
import { downloadFile, formatSize } from "@/utils/files";
import { fromUnion } from "@/utils/types";
import { useFileInput } from "@/hooks/useFileInput";
import { useGetEmployeeResumeContent } from "@/features/employees/hooks/useGetEmployeeResumeContent";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  employeeId: string;
  resume?: EmployeeFormResume;
  onFileChanged: (file: File) => void;
  onResumeRemoved: () => void;
}

const { isOfTypeTwo: isNewResumeUpload } = fromUnion<FormResume, NewResumeUpload>();

export const EmployeeResume = (props: Props) => {
  const {
    resume,
    employeeId,
    onFileChanged,
    onResumeRemoved
  } = props;
  const { t } = useTranslation();
  const { input, handleFileChanged, open, remove } = useFileInput({ onFileChanged });

  const onRemoveClicked = () => {
    remove();
    onResumeRemoved();
  };

  const isResumeDownloadable = resume && !isNewResumeUpload(resume, "uploadedFile");
  const { data, isFetching: isDownloading, refetch, isSuccess } = useGetEmployeeResumeContent(employeeId, {
    enabled: false
  });

  useEffect(() => {
    if (isSuccess) {
      downloadFile(data, resume!.name);
    }
  }, [isSuccess]);

  const onClickDownload = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!isResumeDownloadable) { return; }
    refetch();
  };

  return (
    <AuthorizeResource permission="Permissions.People.ManageResume" resourceId={employeeId} resourceType="employee">
      <Card>
        <VStack gap={6}>
          <CardHeader title={t("employee.edit.resume.title")} />

          <HStack justify="between" align="center">
            {resume && (
              <button
                type="button"
                className="block"
                onClick={onClickDownload}
              >
                <HStack gap={2} align="center">
                  <NoteIcon size="lg" />
                  <VStack gap={0} align="start">
                    <Text weight={590}>{resume.name}</Text>
                    <Text>{formatSize(resume.size)}</Text>
                  </VStack>
                </HStack>
              </button>
            )}
            <input
              aria-label="avatar"
              ref={input}
              type="file"
              id="file"
              className="hidden"
              onChange={handleFileChanged}
              accept="application/pdf, text/plain, application/msword"
            />
            <Button variant="secondary" onClick={open} isDisabled={isDownloading}>
              {t("employee.edit.resume.uploadNew")}
            </Button>
            {resume && (
              <Button variant="danger" onClick={onRemoveClicked} isDisabled={isDownloading}>
                {t("common.form.remove")}
              </Button>
            )}
          </HStack>
        </VStack>
      </Card>
    </AuthorizeResource>
  );
};