import { ViewContentWrapper } from "@/components";
import { Navigate, Route, Routes } from "react-router-dom";
import { ProfilePage } from "./ProfilePage";

export const ProfileRoutes = () => (
  <Routes>
    <Route element={<ViewContentWrapper />}>
      <Route path="" element={<ProfilePage />} />
      <Route path="*" element={<Navigate to="." />} />
    </Route>
  </Routes>
);
