import { type Model } from "@/features/types/clientTypes";
import { type ReactElement, createContext, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import api from "@/lib/http/api";

export type ConfigurationDto = Model<"Configuration.GetConfigurationQuery.ConfigurationDto">;

export const Configuration = createContext<ConfigurationDto | null>(null);

interface ConfigurationProviderProps {
  children?: ReactElement;
}

export const ConfigurationProvider = ({ children }: ConfigurationProviderProps) => {
  const { isLoading, data } = useQuery<ConfigurationDto | undefined>({
    queryKey: ["configuration"],
    queryFn: async () => {
      // bypass AuthMiddleware
      const res = await api.get("/configuration", {
        validateStatus: status => (status >= 200 && status < 300) || status === 401
      });

      return res.data;
    }
  });

  if (isLoading || !data) {
    return null; // TODO show progress indicator
  }

  return (
    <Configuration.Provider value={data}>{children}</Configuration.Provider>
  );
};

export const useConfiguration = () => {
  const config = useContext(Configuration);

  if (!config) {
    throw new Error("useConfiguration must be used within ConfigurationProvider");
  }

  return config;
};