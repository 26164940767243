import { type GetCustomMarketSkillDto } from "@/features/skills/types/common";
import { skillsQueryKeys } from "@/features/skills/types/skillsQueryKeys";
import api from "@/lib/http/api";
import { useSuspenseQuery, type QueryFunctionContext, type UseQueryOptions } from "@tanstack/react-query";

const getCustomMarketSkillByName = async({ queryKey: [, { skillName }] }: QueryFunctionContext<ReturnType<typeof skillsQueryKeys.getCustomMarketSkillByName>>) => {
  const { data } = await api.get<GetCustomMarketSkillDto>(`/skills/custom-market-skill?skillName=${encodeURIComponent(skillName)}`);

  return data;
};


export const useGetCustomMarketSkillByName = (
  skillName: string,
  options?: UseQueryOptions<GetCustomMarketSkillDto, unknown, GetCustomMarketSkillDto, ReturnType<typeof skillsQueryKeys.getCustomMarketSkillByName>>) =>
  useSuspenseQuery({
    queryKey: skillsQueryKeys.getCustomMarketSkillByName(skillName), 
    queryFn: getCustomMarketSkillByName, 
    ...options
  }); 