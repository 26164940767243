import { type GetSubscriptionQueryDto } from "@/features/billing/types/common";
import { type UseQueryOptions, keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";
import { billingQueryKeys } from "./billingQueryKeys";
import { currentUserQueryKey } from "@/lib/auth/AuthProvider";
import { toast } from "react-toastify";
import { translate } from "@/lib/i18n";
import { useEffect } from "react";
import api from "@/lib/http/api";

const getSubscription = async () => {
  const { data } = await api.get("/billing/subscription");

  return data;
};

const POLLING_INTERVAL_MS = 2000;

export const useGetSubscription = (
  enablePolling: boolean,
  options?: UseQueryOptions<GetSubscriptionQueryDto, unknown, GetSubscriptionQueryDto, ReturnType<typeof billingQueryKeys["single"]>>
) => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: billingQueryKeys.single(), 
    queryFn: getSubscription,
    placeholderData: keepPreviousData,
    refetchInterval: result => {
      if (enablePolling && !result?.state?.data?.subscription?.isActive) {
        return POLLING_INTERVAL_MS;
      }
      
      return false;
    },
    ...options
  });

  const { data, isSuccess } = query;

  useEffect(() => {
    if (isSuccess) {
      if (enablePolling && data?.subscription?.isActive) {
        queryClient.invalidateQueries({ queryKey: [currentUserQueryKey] });

        toast.success(translate("billing.subscription.successMessage"));
      }
    }
  }, [isSuccess]);

  return query;
};