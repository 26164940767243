import { AngleDownIcon } from "@hopper-ui/icons";
import { AnimatePresence, motion } from "framer-motion";
import { Dropdown, Menu, MenuItemWrapper, TextField, VStack } from "@/components";
import { FilterItemPill } from "./FilterItemPill";
import { Item, Section, useMenuTriggerState } from "react-stately";
import { type Key, useRef } from "react";
import { type ListFilterItem } from "@/features/filter/types/common";
import classNames from "classnames";
import styles from "./FilterSelectors.module.css";

interface Props {
  selectedItems?: ListFilterItem[];
  items: ListFilterItem[];
  selectItem: (item: ListFilterItem) => void;
  removeItem: (item: ListFilterItem) => void;
  className?: string;
  label?: string;
  description?: string;
  errorMessage?: string;
  isDisabled?: boolean;
}

export const FilterMultipleSelect = ({ selectedItems, items, selectItem, removeItem, className, label, description, errorMessage, isDisabled }: Props) => {
  const state = useMenuTriggerState({});
  const ref = useRef(null);

  const onAction = (key: Key) => {
    if (selectedItems?.find(x => x.id === key)) {
      return;
    }

    const value = items.find(x => x.id === key);

    if (value) {
      selectItem(value);
    }

    state.close();
  };

  const onClickTrigger = () => {
    if (!isDisabled) {
      state.open();
    }
  };

  const onRemoveItem = (key: string) => {
    const value = items.find(x => x.name === key);

    if (value) {
      removeItem(value);
    }
  };

  return (
    <div className={className}>
      <VStack gap={2}>
        <div ref={ref} onClick={onClickTrigger} className="relative w-full">
          <TextField
            className="w-full"
            label={label}
            description={description}
            isDisabled={isDisabled}
            isReadOnly
            errorMessage={errorMessage}
          />
          <AngleDownIcon className={classNames("absolute bottom-[6px] right-6", state.isOpen && "rotate-180")} />
        </div>
        <div className={styles.itemsListContainer}>
          {selectedItems?.map(item => (
            <FilterItemPill
              key={item.id}
              label={item.name}
              onRemove={onRemoveItem}
            />
          ))}
        </div>
      </VStack>
      <AnimatePresence>
        {state.isOpen && (
          <Dropdown variant="wide" state={state} triggerRef={ref} placement="bottom left">
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              exit={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
            >
              <Menu
                aria-label={label}
                autoFocus={state.focusStrategy || true}
                onAction={onAction}
              >
                <Section>
                  {items.map(item => (
                    <Item key={item.id} textValue={item.name}>
                      <MenuItemWrapper isDisabled={!!selectedItems?.find(x => x.id === item.id)}>
                        {item.name}
                      </MenuItemWrapper>
                    </Item>
                  ))}
                </Section>
              </Menu>
            </motion.div>
          </Dropdown>
        )}
      </AnimatePresence>
    </div >
  );
};

