import { type GetCustomSkillSettingsDto } from "@/features/skills/types/common";
import { type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { skillsQueryKeys } from "@/features/skills/types/skillsQueryKeys";
import api from "@/lib/http/api";

type Options = UseQueryOptions<Promise<GetCustomSkillSettingsDto>, unknown, GetCustomSkillSettingsDto, ReturnType<typeof skillsQueryKeys.customSkillSettings>>;

export const getCustomSkillSettings = async () => {
  const { data } = await api.get<GetCustomSkillSettingsDto>("/skills/custom-skills/settings");

  return data;
};

export const useGetCustomSkillSettings = (
  options?: Options
) => useQuery({
  queryKey: skillsQueryKeys.customSkillSettings(), 
  queryFn: getCustomSkillSettings, 
  ...options
});