import { type ReactNode } from "react";
import { Tooltip } from "react-tooltip";

interface Props {
  anchorSelector: string;
  children: ReactNode;
  tooltip: string;
}

export const TooltipWrapper = ({ anchorSelector, children, tooltip }: Props) => (
  <>
    <span className={anchorSelector} data-tooltip-content={tooltip}>
      {children}
    </span>
    <Tooltip 
      noArrow
      anchorSelect={`.${anchorSelector}`}
      className="whitespace-normal !text-xs !rounded-shape-rounded-md !bg-[--hop-neutral-surface-selected] !text-neutral-text-strong max-w-[250px] z-50 !font-410 !shadow-elevation-raised !px-space-inset-md !py-2"
    />
  </>
);