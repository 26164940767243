import { type SubmitData, isNewResumeUpload } from "@/features/employees/components/employee-form/types";
import { employeesQueryKeys } from "@/features/employees/types/employeesQueryKeys";
import { isEmpty, omit } from "lodash";
import { toast } from "react-toastify";
import { translate } from "@/lib/i18n";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import { useQueryClient } from "@tanstack/react-query";
import api from "@/lib/http/api";

const updateAvatar = async (employeeId: string, data: SubmitData) => {
  const { avatarFile, oldAvatarUrl } = data;

  if (avatarFile) {
    const avatarData = new FormData();
    avatarData.append("file", avatarFile);
    await api.post(`employees/${employeeId}/avatar`, avatarData);
  } else if (oldAvatarUrl) {
    await api.post(`employees/${employeeId}/remove-avatar`);
  }
};

const updateResume = async (employeeId: string, data: SubmitData) => {
  const shouldRemoveExistingResume = isEmpty(data.resume) && data.oldResume !== undefined;

  if (data.formData.resume && isNewResumeUpload(data.formData.resume, "uploadedFile")) {
    const formData = new FormData();
    formData.append("file", data.formData.resume.uploadedFile);
    await api.post(`employees/${employeeId}/resume`, formData);
  } else if (shouldRemoveExistingResume) {
    await api.delete(`employees/${employeeId}/resume`);
  }
};

const saveUser = async (employeeId: string, data: SubmitData, hasResumeInUserProfileFeature: boolean) => {
  const { formData } = data;
  await api.post(`employees/${employeeId}/edit`, omit(formData, "resume"));
  await updateAvatar(employeeId, data);

  if (hasResumeInUserProfileFeature) {
    await updateResume(employeeId, data);
  }
};

export const useEditEmployeeMutation = (id: string, onSuccess?: (data: SubmitData) => void) => {
  const queryClient = useQueryClient();
  const { hasFeature } = useFeatures();

  return useMutationWithValidationResult({
    mutationFn: async (data: SubmitData) => {
      await saveUser(id, data, hasFeature("Features.ResumeInUserProfile"));

      return data;
    },
    onSuccess: async data => {
      await queryClient.invalidateQueries({ queryKey: employeesQueryKeys.all });
      toast.success(translate("common.messages.changesSaved"));
      onSuccess?.(data);
    }
  });
};
