import { type SkillRating } from "@/features/skills/types/Skill";
import { type GenerateRatingsDescriptionsDto } from "@/features/skills/types/SkillRatings";
import { type RatingsDescription } from "@/features/skills/types/customSkills";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import api from "@/lib/http/api";
import { type OverlayTriggerState } from "react-stately";

interface Props {
  workingOnMagicOverlayState?: OverlayTriggerState;
  onDescriptionsGenerated: (descriptions: Partial<RatingsDescription>) => void;
}

interface RequestPayload {
  skillName: string;
  rating?: SkillRating;
}

export const useGenerateRatingsDescriptions = ({ onDescriptionsGenerated, workingOnMagicOverlayState }: Props) => {
  const { mutate: generateDescription } = useMutationWithValidationResult({
    onMutate: () => {
      workingOnMagicOverlayState?.open();
    },
    mutationFn: async ({ skillName, rating }: RequestPayload) => {
      workingOnMagicOverlayState?.open();

      const { data } = await api.post<GenerateRatingsDescriptionsDto>("/skills/generate-ratings-descriptions", { skillName });

      return { response: data, rating };
    },
    onSuccess: ({ response, rating }) => {
      const { descriptions } = response;

      onDescriptionsGenerated(rating ? { [rating]: descriptions[rating] } : descriptions);
    },
    onSettled: () => {
      workingOnMagicOverlayState?.close();
    }
  });

  return { generateDescription };
};