import { AddEmploymentModal } from "./AddEmploymentModal";
import { Button } from "@/components";
import { type EmployeeEditModel, type EmployeeEmploymentFormState } from "@/features/employees/types/common";
import { PlusIcon } from "@hopper-ui/icons";
import { type UseFormReturn } from "react-hook-form";
import { type ValidationErrorResult } from "@/models/error";
import { orderBy } from "lodash";
import { useOverlayTriggerState } from "react-stately";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  commandErrors?: ValidationErrorResult;
  form: UseFormReturn<EmployeeEditModel>;
}

export const AddEmploymentButton = ({ commandErrors, form: { setValue, watch } }: Props) => {
  const overlayState = useOverlayTriggerState({});
  const { t } = useTranslation();

  const onAddEmployment = (employment: EmployeeEmploymentFormState) => {
    const newEmployments = orderBy([employment, ...watch("employments")], ["endDate", "effectiveDate"], ["desc", "desc"]);
    setValue("employments", newEmployments, { shouldDirty: true });
  };

  return (
    <>
      <Button 
        size="small"
        onClick={overlayState.open} 
        variant="secondary" 
        fluid 
        icon={<PlusIcon size="sm" />}
      >
        {t("employee.edit.addPosition")}
      </Button>
      <AddEmploymentModal
        // ensure form is reset on modal close
        key={`${overlayState.isOpen}`}
        overlayState={overlayState}
        commandErrors={commandErrors}
        onAddEmployment={onAddEmployment}
      />
    </>
  );
};
