import { AtsLock } from "@/features/ats/components/AtsLock";
import { type Feature } from "@/lib/features/types/feature";
import { HrisLock } from "@/features/hris/components/lock/HrisLock";
import { PremiumBadge } from "@/features/billing/components/PremiumBadge";

interface Props {
  blockedFeature?: Feature;
  isHrisConnected?: boolean;
  isAtsSynced?: boolean;
}

export const DefaultDisabledExplainer = ({ blockedFeature, isHrisConnected, isAtsSynced }: Props) => {
  if (blockedFeature) {
    return <PremiumBadge feature={blockedFeature} />;
  }

  if (isHrisConnected) {
    return <HrisLock />;
  }

  if (isAtsSynced) {
    return <AtsLock />;
  }

  return null;
};