import { type ChangeAudit } from "@/features/app/types/common";
import { getFullName } from "@/features/employees/utils";
import { useTranslation } from "@/hooks/useTranslation";
import { Text } from "@workleap/orbiter-ui";
import { format } from "date-fns";
import { type ComponentProps } from "react";

interface Props extends ComponentProps<typeof Text>{
  isGeneratedByAI: boolean;
  changeAudit?: ChangeAudit;
}

export const ChangeAuditContent = ({ isGeneratedByAI, changeAudit, ...textProps }: Props) => {
  const { t } = useTranslation();

  const date = changeAudit?.lastModifiedOn;
  const user = changeAudit?.lastModifiedBy;
  const shortMonthDayYearFormat = t("common.formats.dates.shortMonthDayYear");

  const getText = () => {
    const name = getFullName(user?.firstName, user?.lastName); 

    if (!date || !user) {
      return t("changeAudit.aiGenerated");
    }

    if (isGeneratedByAI) {
      return t("changeAudit.aiGeneratedWithDate", { date: format(date, shortMonthDayYearFormat) });
    }

    return t("changeAudit.userGeneratedWithDate", { name, date: format(date, shortMonthDayYearFormat) });
  };

  return <Text {...textProps} size="xs" color="neutral-weak">{getText()}</Text>;
};