import { type GetCustomSkillDto } from "@/features/skills/types/common";
import { type QueryFunctionContext, type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { skillsQueryKeys } from "@/features/skills/types/skillsQueryKeys";
import api from "@/lib/http/api";

const getCustomSkillById = async({ queryKey: [, { id }] }: QueryFunctionContext<ReturnType<typeof skillsQueryKeys.getCustomSkillById>>) => {
  const { data } = await api.get<GetCustomSkillDto>(`/skills/custom-skills/${id}`);

  return data;
};


export const useGetCustomSkillById = (
  id: string,
  options?: UseQueryOptions<GetCustomSkillDto, unknown, GetCustomSkillDto, ReturnType<typeof skillsQueryKeys.getCustomSkillById>>) =>
  useQuery({
    queryKey: skillsQueryKeys.getCustomSkillById(id), 
    queryFn: getCustomSkillById, 
    ...options
  }); 