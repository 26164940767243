
import { ConditionalLink, Text } from "@/components";
import { useDirectoryRoutes } from "@/features/directory/hooks/useDirectoryRoutes";
import { useMarketContentTranslation } from "@/hooks/useTranslation";
import classNames from "classnames";

interface Props {
  roleId?: string;
  roleName?: string;
}

export const RoleCell = ({ roleId, roleName }: Props) => {
  const { role } = useDirectoryRoutes();
  const { translateMarketContent } = useMarketContentTranslation();

  return (
    <ConditionalLink 
      condition={!!roleId}
      to={role(roleId)}
      className={classNames({
        "hover:underline": !!roleId
      })}
    >
      <Text size="sm">{translateMarketContent(roleName ?? "")}</Text>
    </ConditionalLink>
  );
};