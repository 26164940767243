import { AngleDownIcon, AngleUpIcon } from "@hopper-ui/icons";
import classNames from "classnames";
import { type ReactNode } from "react";
import styles from "./AccordionSummary.module.css";

export interface AccordionSummaryProps {
  children: ReactNode;
  iconPosition?: "left" | "right";
  expandIcon?: ReactNode;
  collapseIcon?: ReactNode;
  isCollapsed?: boolean;
  className?: string;
  onClick?: () => void;
}

export const AccordionSummary = ({
  children,
  collapseIcon = <AngleUpIcon size="sm" />,
  expandIcon = <AngleDownIcon size="sm" />,
  isCollapsed,
  iconPosition = "right",
  className,
  onClick
}: AccordionSummaryProps) => {
  const icon = isCollapsed
    ? expandIcon
    : collapseIcon;
  const iconContent = (
    <div className={styles.accordionSummaryIcon}>
      {icon}
    </div>
  );

  return (
    <button
      aria-disabled="false"
      className={classNames(styles.accordionSummary, className, {
        [styles.accordionSummaryExpanded]: !isCollapsed
      })}
      onClick={onClick}
    >
      {iconPosition === "left" && iconContent}
      <div className={styles.accordionSummaryContent}>
        {children}
      </div>
      {iconPosition === "right" && iconContent}
    </button>
  );
};
