import classNames from "classnames";
import styles from "./InfiniteProgress.module.css";

interface Props {
  className?: string;
}

export const InfiniteProgress = ({ className }: Props) => (
  <div className={classNames("overflow-hidden h-2.5 w-[220px] bg-gray-200 rounded-full", className)}>
    <div className={classNames("bg-primary-surface-strong w-full h-full rounded-full", styles.progressBarValue)}></div>
  </div>
);