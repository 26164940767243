export const redirectToLogin = (redirectUrl?: string) => {
  const baseUrl = "/login";

  // Bypass Next.js router, `/login` and `/logout` are not handled by the frontend.
  window.location.href = redirectUrl
    ? `${baseUrl}?redirectUrl=${redirectUrl}`
    : baseUrl;
};

export const redirectToHome = (params?: string) => {
  if (params) {
    window.location.href = `/?${params}`;

    return;
  }

  window.location.href = "/";
};