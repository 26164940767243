import { Redirect } from "@/lib/router/Redirect";
import { lazyImport } from "@/utils";
import { Navigate } from "react-router-dom";

const { ActivationRoutes } = lazyImport(() => import("@/pages/activation/ActivationRoutes"), "ActivationRoutes");
const { ErrorRoutes } = lazyImport(() => import("@/pages/error/ErrorRoutes"), "ErrorRoutes");

export const publicRoutes = [
  { path: "/", element: <Navigate to="/login" /> },
  { path: "/login", element: <Redirect to="/login" /> },
  { path: "/signup", element: <Redirect to="/register" /> },
  { path: "/activation", element: <ActivationRoutes /> },
  { path: "/error", element: <ErrorRoutes /> },
  // TODO: address concern that a protected url cannot be shared (no route, no challenge)
  { path: "*", element: <Navigate to={`/login?returnUrl=${encodeURIComponent(window.location.href)}`} /> }
];