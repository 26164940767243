import { Text, VStack } from "@/components";
import classNames from "classnames";
import { type ReactNode } from "react";
import { ReactComponent as EmptyImage } from "./assets/empty-state.svg";
import styles from "./EmptyList.module.css";

export interface EmptyListProps {
  title?: string;
  description?: ReactNode;
  callToActionContent?: ReactNode;
  className?: string;
  size?: "large" | "small";
  showImage?: boolean;
  variant?: "primary" | "secondary";
  image?: ReactNode;
}

export const EmptyList = ({
  title,
  description,
  callToActionContent,
  className,
  showImage,
  image,
  size = "large",
  variant = "primary"
} : EmptyListProps) => {
  const imageComponent = () => {
    if (!showImage) {
      return null;
    }

    return image || <EmptyImage className={classNames(styles.illustration)} />;
  };

  return (
    <div className={classNames(
      "flex items-center justify-center text-center rounded-xl", 
      className, 
      {
        "py-16": size === "large",
        "py-4": size === "small",
        "rounded-xl border-neutral-border-weak border-2 border-dashed": variant === "secondary"
      })}
    >
      <VStack gap={2} align="center">
        <VStack align="center" gap={6}>
          {imageComponent()}
          <VStack gap={1} align="center">
            {title && (
              <Text 
                size={size === "large"
                  ? "lg"
                  : "md"}
              >
                {title}
              </Text>
            )}
            {description && (
              <Text 
                size={size === "large"
                  ? "sm"
                  : "xs"} 
                color="neutral-text-weak"
                className="whitespace-pre-line"
              >
                {description}
              </Text>
            )}
          </VStack>
          {callToActionContent}
        </VStack>
      </VStack>
    </div>
  );
};