import { initialFilterKeys, type InitialFilters, type ListFilterItem } from "@/features/filter/types/common";
import { type operations } from "@/features/types/client";
import { type Model } from "@/features/types/clientTypes";

export type GetUsersQueryParams = NonNullable<operations["Users_GetUsers"]["parameters"]>["query"];
export type UserDto = Model<"Users.Queries.GetUsersQueryDto.UserDto">;
export type GetUsersQueryDto = Model<"Users.Queries.GetUsersQueryDto">;
export type UserAvatarDto = Model<"Users.Models.UserAvatarDto">;
export type UserStatus = Model<"Users.Models.UserStatus">;

export const userFilterKeysConst = [
  "userRoles", 
  "statuses"
] as const;

export const userFilterKeys = [
  ...initialFilterKeys,
  ...userFilterKeysConst
];

export type UserFilterQueryParamKeys = typeof userFilterKeysConst[number];

export type CreateUserRequestPayload = Model<"Users.Commands.CreateNewUserCommand.HttpRequestBody">;
export type CreateUserResult = NonNullable<Model<"Users.Commands.CreateNewUserResult">["data"]>;
export type InviteUserCurrentRole = CreateUserRequestPayload["currentRole"];

export interface NewUserFormData {
  firstName: string;
  lastName: string;
  email: string;
  currentRole?: InviteUserCurrentRole;
}

export type UserFilters = {
  [key in UserFilterQueryParamKeys]: ListFilterItem[];
} & InitialFilters;

export enum UserDropdownActionKeys {
  EDIT_USER = "editUser",
  ACTIVATE_USER = "activateUser",
  DEACTIVATED_USER = "deactivatedUser",
  SEND_INVITE = "sendInvite",
  DELETE = "delete"
}

export const avatarSize = {
  "xs": 16,
  "sm": 24,
  "md": 32,
  "lg": 48,
  "xl": 64
};