import classNames from "classnames";
import { Stack, type StackProps } from "./Stack";

interface Props extends Omit<StackProps, "flow"> {
  justify?: "start" | "end" | "center" | "between" | "around" | "evenly";
  align?: "start" | "end" | "center" | "baseline" | "stretch";
}

/**
 * @deprecated Use Grid or Flex from @workleap/orbiter-ui instead
 */
export const VStack = ({ as = "div", gap = 2, children, className, divider, justify, align }: Props) => {
  const classes = classNames(className,
    !!justify && `justify-${justify}`,
    !!align && `items-${align}`
  );

  return (
    <Stack
      as={as}
      flow="col"
      className={classes}
      gap={gap}
      divider={divider}
    >
      {children}
    </Stack>
  );
};
