import { ActionButtonDropdown, type ActionButtonItem, DeleteConfirmationModal } from "@/components";
import { type ListCustomSkillsDto } from "@/features/skills/types/common";
import { Routes } from "@/types";
import { generatePath, useNavigate } from "react-router-dom";
import { useAuth } from "@/lib/auth/AuthProvider";
import { useDeleteCustomSkill } from "@/features/skills/hooks/useDeleteCustomSkill";
import { useOverlayTriggerState } from "react-stately";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  skill: ListCustomSkillsDto["items"][number];
}

type ActionItemType = "edit" | "delete";

export const CustomSkillListActions = ({ skill: { name, id, isDeletable } }: Props) => {
  const { t } = useTranslation();
  const { currentUserHasPermission } = useAuth();
  const navigate = useNavigate();
  const deleteMetadataModalState = useOverlayTriggerState({});
  const { mutate: onDeleteItem } = useDeleteCustomSkill(id);

  const actionItems: ActionButtonItem<ActionItemType>[] = [];
  const onEditClick = () => navigate(generatePath(Routes.EditCustomSkill, { id }));

  if (currentUserHasPermission("Permissions.Skills.UpdateSkill")) {
    actionItems.push({
      key: "edit",
      text: t("common.actions.edit"),
      onClick: onEditClick
    });
  }

  if (currentUserHasPermission("Permissions.Skills.DeleteSkill") && isDeletable) {
    actionItems.push({
      key: "delete",  
      text: t("common.actions.delete"),
      onClick: deleteMetadataModalState.open,
      isDestructive: true
    });
  }

  return (
    <>
      <ActionButtonDropdown actions={actionItems} />
      <DeleteConfirmationModal
        title={t("skills.customSkills.deleteModal.title")}
        confirmationMessage={t("skills.customSkills.deleteModal.confirmationMessage", { skillName: name })}
        onDelete={onDeleteItem}
        overlayState={deleteMetadataModalState}
      />
    </>
  );
};
