import { AddTrainingPage } from "./add/AddTrainingPage";
import { EditTrainingPage } from "./add/EditTrainingPage";
import { Navigate, Route, Routes } from "react-router-dom";
import { PermissionGuardRoute } from "@/pages/components/PermissionGuardRoute";
import { TrainingPage } from "./TrainingPage";
import { ViewContentWrapper } from "@/components";

export const TrainingRoutes = () => (
  <Routes>
    <Route element={<ViewContentWrapper />}>
      <Route path="" element={<TrainingPage />} />
    </Route>
    <Route 
      path="add"
      element={(
        <PermissionGuardRoute permission="Permissions.Trainings.Create">
          <AddTrainingPage />
        </PermissionGuardRoute>
      )}
    />
    <Route 
      path="edit/:id"
      element={(
        <PermissionGuardRoute permission="Permissions.Trainings.Edit">
          <EditTrainingPage />
        </PermissionGuardRoute>
      )}
    />
    <Route path="*" element={<Navigate to="." />} />
  </Routes>
);
