import { Grid } from "@workleap/orbiter-ui";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DraggableSkillDragLayer } from "./DraggableSkillDragLayer";
import { SkillRatingLevelBuckets } from "./SkillRatingLevelBuckets";
import { SkillRatingUncategorizedSkills } from "./SkillRatingUncategorizedSkills";

interface Props {
  jobTitle?: string;
}

export const SkillRatingContent = ({ jobTitle }: Props) => (
  <DndProvider backend={HTML5Backend}>
    <DraggableSkillDragLayer />
    <Grid gap="inline-md" templateRows={["auto 1fr"]} height="100%" overflow="hidden">
      <SkillRatingUncategorizedSkills jobTitle={jobTitle} />
      <SkillRatingLevelBuckets />
    </Grid>
  </DndProvider>
);