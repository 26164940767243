import { type RichContent } from "@/features/roles/types/common";
import { Extension } from "@tiptap/core";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import Document from "@tiptap/extension-document";
import HardBreak from "@tiptap/extension-hard-break";
import History from "@tiptap/extension-history";
import Italic from "@tiptap/extension-italic";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import Underline from "@tiptap/extension-underline";
import { type Extensions, useEditor as useTipTapEditor } from "@tiptap/react";
import classNames from "classnames";
import { type DependencyList } from "react";
import styles from "./Editor.module.css";

const DisableEnter = Extension.create({
  addKeyboardShortcuts: () => ({
    Enter: () => true,
    "Shift-Enter": () => true
  })
});

export interface UseEditorProps {
  editable: boolean;
  className?: string;
  onChange?: (value: RichContent) => void;
  content?: RichContent;
  extensions?: Extensions;
  placeholder?: string;
  isDisabled?: boolean;
  disableEnter?: boolean;
  lineClamp?: 1 | 2 | 3 | 4 | 5;
}

export const DEFAULT_EXTENSIONS = [Document, Paragraph, Text, Bold, History, HardBreak, Italic, Underline, BulletList, OrderedList, ListItem];

export const useEditor = ({ editable, onChange, content, className, placeholder, isDisabled, disableEnter, lineClamp, extensions = [] }: UseEditorProps, deps?: DependencyList) => useTipTapEditor({
  editable: editable,
  editorProps: {
    attributes: {
      class: classNames(className, styles.editor, { [styles.disabled]: isDisabled, [styles[`clamp-${lineClamp}`]]: lineClamp, [styles.clamp]: lineClamp })
    }
  },
  extensions: [
    ...DEFAULT_EXTENSIONS,
    ...(disableEnter
      ? [DisableEnter]
      : []),
    ...extensions,
    Placeholder.configure({
      emptyEditorClass: styles.placeholder,
      placeholder
    })
  ],
  content,
  onUpdate: ({ editor: innerEditor }) => {  
    onChange?.(innerEditor.getJSON());
  }
}, deps);