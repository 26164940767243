import { ClockIcon, ProgressIcon, RefreshIcon, RemoveIcon, WarningIcon } from "@hopper-ui/icons";
import { IconText } from "@/components";
import { format } from "date-fns";
import { useGetAtsSettings } from "@/features/ats/hooks/useGetAtsSettings";
import { useTranslation } from "@/hooks/useTranslation";

export const AtsSyncStatus = () => {
  const { t } = useTranslation();
  const { data } = useGetAtsSettings();
  const timestamp = format(data?.syncStatusLastModified ?? new Date(), "h:mm aa");

  const renderSyncStatus = () => {
    switch (data?.syncStatus) {
      case "Scheduled": return <IconText icon={<ClockIcon size="lg" />} color="tertiary-text">{t("ats.sync.status.scheduled")}</IconText>;
      case "Running": return <IconText icon={<RefreshIcon size="lg" />} color="tertiary-text">{t("ats.sync.status.running")}</IconText>;
      case "Success": return <IconText icon={<ProgressIcon size="lg" />} color="success-icon-weak">{t("ats.sync.status.success", { timestamp })}</IconText>;
      case "Error": return <IconText icon={<RemoveIcon size="lg" />} color="danger-icon">{t("ats.sync.status.error", { timestamp })}</IconText>;
      case "AuthError": return <IconText icon={<WarningIcon size="lg" />} color="danger-icon">{t("ats.sync.status.authError")}</IconText>;
      case "NeverRan": 
      default: return;
    }
  };

  return <>{renderSyncStatus()}</>;
};