import { ReactComponent as LogoEN } from "@/lib/assets/logo.svg";
import { ReactComponent as LogoFR } from "@/lib/assets/logo-fr.svg";
import { useTranslation } from "@/hooks/useTranslation";
import classNames from "classnames";

interface Props {
  className?: string;
}

export const AppLogo = ({ className }: Props) => {
  const { i18n } = useTranslation();

  const classes = classNames(className);

  if (i18n.language === "fr") {
    return <LogoFR className={classes} />;
  }

  return <LogoEN className={classes} />;
};