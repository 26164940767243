import { useEffect } from "react";
import { type OverlayTriggerState } from "react-stately";
import { useSkillFlyoutState } from "./useSkillFlyoutState";

export const useInitialSkillFlyoutState = (overlayState: OverlayTriggerState, name?: string) => {
  const { skillName, skillRating, openFlyout, skillFlyoutMode, openAddFlyout } = useSkillFlyoutState(overlayState);

  useEffect(() => {
    if (name && skillName === name && !!skillRating) {
      openFlyout(name, skillRating, skillFlyoutMode === "edit");
    } else if (skillFlyoutMode === "add") {
      openAddFlyout();
    }
  }, []);
};