import { EmployeeEdit } from "@/features/employees/components/employee-edit/EmployeeEdit";
import { Helmet } from "react-helmet-async";
import { useEmployeeParams } from "@/pages/people/employee/hooks/useEmployeeParams";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@/hooks/useTranslation";

export const EditUserPage = () => {
  const employeeId = useEmployeeParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onBack = () => navigate("/settings/users");

  return (
    <>
      <Helmet>
        <title>{t("pages.settingsEditUser")}</title>
      </Helmet>
      <EmployeeEdit title={t("users.edit.title")} employeeId={employeeId} onBack={onBack} showPermissions />
    </>
  );
};
