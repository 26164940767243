import { apiPost } from "@/lib/http/api";
import { atsQueryKeys } from "@/features/ats/types/atsQueryKeys";
import { toast } from "react-toastify";
import { translate } from "@/lib/i18n";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUnlinkAtsAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await apiPost("/ats/unlink-account");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: atsQueryKeys.settings() });
      toast.success(translate("ats.disconnect.successMessage"));
    }
  });
};