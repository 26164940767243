import { useGetCurrentPageParam } from "@/components";
import { useAnalytics } from "@/features/analytics/hooks/useAnalytics";
import { AnalyticEvents } from "@/features/analytics/types/analyticEvents";
import { EthnicityLabels, GenderLabels } from "@/features/employees/types/common";
import { type DateFilterQueryParamKeys, type InitialFilterQueryParamKeys, type InitialFilters, type ListFilterItem, dateFilterKeys, filtersByIds, listFilterKeys } from "@/features/filter/types/common";
import { useGetSeniorityLevels } from "@/features/roles/hooks/useGetSeniorityLevels";
import { getDepartmentNamesByIds, getEmployeesNamesByIds, getRolesNamesByIds } from "@/features/search/api";
import { type SearchResponse } from "@/features/search/types/common";
import { type Model } from "@/features/types/clientTypes";
import { getSegmentNamesByIds, getTeamNamesByIds } from "@/features/workleap/api/search";
import { useCurrentUser } from "@/lib/auth/AuthProvider";
import { isISODateFormat } from "@/utils/dates";
import { type AxiosResponse } from "axios";
import { parseJSON } from "date-fns";
import { isEmpty, isNaN } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { paramsToArray, setNamesForKnownIds, setNamesFromIds } from "./..";

type SkillCategory = Model<"Skills.SkillCategory">;

const split = (filter: string) => filter.split(",");

const splitCategory = (filter: string) => filter.split(",").map(x => x as SkillCategory);

const splitSeniority = (filter: string) => filter?.split(",").map(x => Number(x));

const getSplit = (key: string, filter: string | null) => {
  if (!filter) {
    return undefined;
  }

  if (key === "seniorityLevels") {
    return splitSeniority(filter);
  }

  if (key === "skillsCategories") {
    return splitCategory(filter);
  }

  return split(filter);
};

const parseDate = (value: string | null) => value
  ? parseJSON(value)
  : undefined;

const isDateFilter = (searchParams: URLSearchParams, key: string) => {
  if (dateFilterKeys.includes(key as DateFilterQueryParamKeys)) {
    return true;
  }

  const value = searchParams.get(key);

  return value && isISODateFormat(value);
};

const buildFromSearchParams = <T>(searchParams: URLSearchParams, filterKeys: string[], allListFilterKeys: string[]) =>
  filterKeys
    .reduce((o, key) => ({ 
      ...o,
      [key]: allListFilterKeys.includes(key)
        ? paramsToArray(searchParams.get(key))
        : isDateFilter(searchParams, key) ?
          parseDate(searchParams.get(key))
          : searchParams.get(key)
    }), {}) as T;

interface useFiltersOptions<TInitialFilters> {
  additionalListFilterKeys?: (keyof TInitialFilters)[];
  idToNameResolvers?: Partial<Record<keyof TInitialFilters, (_ids: string[]) => Promise<AxiosResponse<SearchResponse>>>>;
}

export const useFilters = <T extends InitialFilters, S extends InitialFilterQueryParamKeys>(filterKeys: string[], source: string, options?: useFiltersOptions<T>) => {
  const { trackEvent } = useAnalytics();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data } = useGetSeniorityLevels();
  const { tenant: { isWorkleapTenant } } = useCurrentUser();

  const allListFilterKeys = (options
    ? [...listFilterKeys, ...(options.additionalListFilterKeys ?? [])]
    : [...listFilterKeys]) as S[];

  const allListAndDateKeys = [...allListFilterKeys, ...dateFilterKeys];

  const initialValues: T = useMemo(() =>
    buildFromSearchParams(searchParams, filterKeys, allListFilterKeys)
  , [searchParams]);

  const [filters, setFiltersState] = useState(initialValues);

  const setFilters = useCallback((newValue: Partial<T>) => {
    setFiltersState(currentFilters => ({ ...currentFilters, ...newValue }));
  }, []);

  const resetFilters = useCallback(() => {
    const {
      employeeIds,
      managerIds,
      roleIds,
      seniorityLevels,
      departmentIds,
      genders,
      ethnicities,
      segmentIds,
      teamIds
    } = initialValues;

    setNamesFromIds(getEmployeesNamesByIds, employeeIds);
    setNamesFromIds(getEmployeesNamesByIds, managerIds);
    setNamesFromIds(getRolesNamesByIds, roleIds);
    setNamesFromIds(getDepartmentNamesByIds, departmentIds);
    
    if (isWorkleapTenant) {
      setNamesFromIds(getTeamNamesByIds, teamIds);
      setNamesFromIds(getSegmentNamesByIds, segmentIds);
    }

    setNamesForKnownIds(genders, GenderLabels);
    setNamesForKnownIds(ethnicities, EthnicityLabels);

    if (options?.idToNameResolvers) {
      Object.entries(options?.idToNameResolvers).forEach(([key, func]) => {
        const values = initialValues[key as keyof T] as ListFilterItem[];
        if (!isEmpty(values)) {
          setNamesFromIds(func, values);
        }
      });
    }
    
    seniorityLevels.forEach(item => item.name = data?.find(x => x.level === Number(item.id))?.name ?? item.name);

    setFilters(initialValues);
  }, [initialValues]);

  useEffect(() => {
    resetFilters();
  }, [initialValues, data]);

  const queryFilters = useMemo(() => filterKeys
    .reduce((o, key) => ({ 
      ...o,
      [key]: allListFilterKeys.includes(key as S)
        ? getSplit(key, searchParams.get(key))
        : isDateFilter(searchParams, key) ?
          parseDate(searchParams.get(key))
          : searchParams.get(key)
    }), {}), [searchParams]);
  

  const page = useGetCurrentPageParam();
  const totalAppliedFilters = useMemo(() => {
    const allFilters: boolean[] = filterKeys.map((key: string) => !!searchParams.get(key));
  
    return allFilters.filter(filter => !!filter).length;
  }, [searchParams]);

  const onClearFilters = () => {
    filterKeys.forEach(key => searchParams.delete(key));
    searchParams.set("page", "1");

    setSearchParams(searchParams, { replace: true });
  };

  const setValuesInSearchParams = useCallback((values: Record<string, string>) => {
    Object.entries(values).forEach(([key, value]) => {
      searchParams.set(key, value);
    });

    setSearchParams(searchParams, { replace: true });
  }, [searchParams, setSearchParams]);

  const onApplyFilters = () => {
    filterKeys.filter(key => !allListAndDateKeys.includes(key as S)).forEach(x => {
      const key = x as S;
      
      if (filters[key] instanceof Date) {
        searchParams.set(key, (filters[key] as Date).toISOString());
        
        return;
      }

      if ((typeof filters[key] === "number" && !isNaN(filters[key])) || filters[key]) {
        searchParams.set(key, String(filters[key]));

        return;
      }

      searchParams.delete(key);
    });

    allListFilterKeys.map(key => ({ key, value: filters[key] as ListFilterItem[] }))
      .filter(({ key, value }) => (value?.length) || searchParams.get(key))
      .forEach(({ key, value }) => {
        if (!value?.length) {
          searchParams.delete(key);

          return;
        }

        if (filtersByIds.includes(key) || typeof options?.idToNameResolvers?.[key] == "function") {
          searchParams.set(key, value.map(({ id }) => id).join(","));

          return;
        }

        searchParams.set(key, value.map(({ name }) => name).join(","));
      });

    dateFilterKeys.forEach(key => {
      if (!filters[key]) {
        searchParams.delete(key);

        return;
      }

      searchParams.set(key, filters[key].toISOString());
    });

    searchParams.set("page", "1");

    setSearchParams(searchParams, { replace: true });

    trackEvent(AnalyticEvents.filterApplied, {
      "Source": source
    });
  };

  return {
    onClearFilters,
    onApplyFilters,
    filters,
    setFilters,
    resetFilters,
    queryFilters,
    searchParams,
    setSearchParams,
    setValuesInSearchParams,
    totalAppliedFilters,
    page
  };
};