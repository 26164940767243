import { HStack, Text, TypeAhead } from "@/components";
import { type SearchRoleThenJobResponseItem, useSearchRoleThenJob } from "@/features/search/hooks/useSearchRoleThenJob";
import { useMarketContentTranslation } from "@/hooks/useTranslation";
import { type Key, type ReactElement } from "react";
import { ExternalChip } from "./components/ExternalChip";

interface Props {
  label?: string;
  description?: string;
  onRoleSelected: (role?: SearchRoleThenJobResponseItem) => void;
  initialValue?: SearchRoleThenJobResponseItem;
  errorMessage?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  disabledExplainer?: ReactElement;
  hideExternalChip?: boolean;
  size?: "sm" | "lg";
  hideIcon?: boolean;
}

export const SearchRoleThenJob = ({
  label,
  description,
  onRoleSelected,
  initialValue,
  errorMessage,
  isRequired,
  isDisabled,
  disabledExplainer,
  hideExternalChip,
  size,
  hideIcon
}: Props) => {
  const { translateMarketContent } = useMarketContentTranslation();
  const { data: results, ...otherProps } = useSearchRoleThenJob();
  
  const onItemSelected = (key?: Key) => {
    onRoleSelected(results?.find(x => x.position === key));
  };

  return (
    <TypeAhead
      {...otherProps}
      size={size}
      hideIcon={hideIcon}
      label={label}
      description={description}
      results={results}
      onItemSelected={onItemSelected}
      initialValue={initialValue}
      errorMessage={errorMessage}
      keySelector={item => item?.position}
      itemDisplay={item => (
        <div key={item.position}>
          <HStack gap={2} align="center">
            <Text>{translateMarketContent(item.name)}</Text>
            {!item.isInternal && !hideExternalChip && <ExternalChip />}
          </HStack>
        </div>
      )}
      itemToText={item => translateMarketContent(item.name)}
      isRequired={isRequired}
      isDisabled={isDisabled}
      disabledExplainer={disabledExplainer}
    />
  );
};