export const DefaultMaxFileSize = 10 * 1024 * 1024;

export const getFileExtension = (name: string) => name.split(".").pop()?.toLocaleLowerCase();

// Source : https://gist.github.com/zentala/1e6f72438796d74531803cc3833c039c
export const formatSize = (bytes: number) => {
  if (bytes === 0) { return "0 Bytes"; }
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${Math.ceil(bytes / k ** i)} ${sizes[i]}`;
};

export const downloadFile = (fileContent: Blob, fileName: string) => {
  const url = URL.createObjectURL(fileContent);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a); // we need to append the element to the DOM -> otherwise it will not work in Firefox
  a.click();
  a.remove(); //afterwards we remove the element again
};