import { AuthorizeResource } from "@/lib/auth/AuthorizeResource";
import { Button } from "@/components";
import { useProgressionPlanContext } from "@/features/progression-plan/hooks/useProgressionPlanContext";
import { useRemoveProgressionPlanMentorMutation } from "@/features/progression-plan/hooks/useRemoveProgressonPlanMentorMutation";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  id: string;
}

export const RemoveSkillMentorButton = ({ id }: Props) => {
  const { planId } = useProgressionPlanContext();
  const { t } = useTranslation(); 
  const { mutate, isPending } = useRemoveProgressionPlanMentorMutation(planId);

  const onRemoveMentorClick = async (mentorId: string) => mutate(mentorId);

  return (
    <AuthorizeResource
      permission="Permissions.ProgressionPlans.Edit"
      resourceId={planId}
      resourceType="progression-plan"
    >
      <Button
        size="small"
        variant="danger"
        onClick={() => onRemoveMentorClick(id)}
        loading={isPending}
      >
        {t("common.form.remove")}
      </Button>
    </AuthorizeResource>
  );
};
