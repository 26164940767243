import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface ConnectHrisStore {
  showBanner: boolean;
  setShowBanner: (showBanner: boolean) => void;
}

export const useConnectHrisStore = create<ConnectHrisStore>()(
  persist(
    set => ({
      showBanner: false,
      setShowBanner: (showBanner: boolean) => set({ showBanner })
    }),
    {
      name: "connect-hris-store",
      storage: createJSONStorage(() => sessionStorage)
    }
  ));