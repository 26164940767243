import { DefaultLoader } from "@/components";
import { EditSkillCustomDescriptions } from "@/features/skills/components/EditSkillCustomDescriptions";
import { useSkillNameParams } from "@/hooks/routing/skillMetadataParams";
import { useTranslation } from "@/hooks/useTranslation";
import { Routes } from "@/types/routes";
import { Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

export const EditSkillCustomDescriptionsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onCancelClick = () => navigate(Routes.SkillsCustomDescriptions);
  const skillName = useSkillNameParams();

  return (
    <>
      <Helmet>
        <title>{t("pages.settingsEditCustomDescription")}</title>
      </Helmet>
      <Suspense fallback={<DefaultLoader />}>
        <EditSkillCustomDescriptions skillName={skillName} onCancelClick={onCancelClick} />
      </Suspense>
    </>
  );
};
