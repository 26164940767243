import { Button, type FlyoutContentChildren } from "@/components";
import { useGetSkillFlyoutSuspenseQuery } from "@/features/skill/hooks/useGetSkillFlyout";
import { useSkillFlyoutState } from "@/features/skill/hooks/useSkillFlyoutState";
import { getSkillsRatingsWithErrors } from "@/features/skill/utils/skills.utils";
import { useUpdateCustomMarketSkillMutation } from "@/features/skills/hooks/useUpdateCustomMarketSkill";
import { editMarketSkillSchema, type EditMarketSkillFormState } from "@/features/skills/types/common";
import { toMetadataCustomDescriptions } from "@/features/skills/utils/customSkills";
import { useMarketContentTranslation, useTranslation } from "@/hooks/useTranslation";
import { useAuth } from "@/lib/auth/AuthProvider";
import { removeTextBetweenParenthesis } from "@/lib/utils";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { Flex, Grid, Heading, minmax } from "@workleap/orbiter-ui";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type OverlayTriggerState } from "react-stately";
import { DeleteSkillConfirmationModal } from "./DeleteSkillConfirmationModal";
import { SkillFlyoutFormConfirmationModal } from "./SkillFlyoutFormConfirmationModal";
import { SkillFlyoutFormDescriptions } from "./SkillFlyoutFormDescriptions";
import { SkillFlyoutFormSimilarSkills } from "./SkillFlyoutFormSimilarSkills";

interface Props {
  skillName: string;
  overlayState: OverlayTriggerState;
  children: FlyoutContentChildren;
}

export const SkillFlyoutEditModeMarket = (props: Props) => {
  const { t } = useTranslation();
  const { skillName, overlayState, children } = props;
  const { data: { customMarketSkillId, ratingsDescriptions, similarSkills } } = useGetSkillFlyoutSuspenseQuery(skillName);
  const { currentUserHasPermission } = useAuth();
  const {
    closeFlyout,
    setIsDirty,
    forceCloseFlyout,
    confirmationOverlayState,
    deleteOverlayState
  } = useSkillFlyoutState(overlayState);
  const { translateMarketContent } = useMarketContentTranslation();

  const { handleSubmit, formState: { errors, isDirty, isSubmitting }, watch, setValue } = useForm<EditMarketSkillFormState>({
    defaultValues: { ratingsDescriptions, similarSkills },
    resolver: valibotResolver(editMarketSkillSchema)
  });

  const { mutate, isPending } = useUpdateCustomMarketSkillMutation(customMarketSkillId!, forceCloseFlyout);

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty]);

  const onSubmitForm = (formData: EditMarketSkillFormState) => {
    mutate({ ...formData, ratingsDescriptions: toMetadataCustomDescriptions(formData.ratingsDescriptions) });
  };

  const ratingsWithErrors = useMemo(() => getSkillsRatingsWithErrors(watch("ratingsDescriptions")), [isSubmitting]);

  return (
    <>
      {children({
        header: <Heading size="xl">{removeTextBetweenParenthesis(translateMarketContent(skillName))}</Heading>,
        content: (
          <Flex gap="32px" direction="column">
            <SkillFlyoutFormDescriptions
              ratingsWithErrors={ratingsWithErrors}
              skillName={skillName}
              ratingsDescription={watch("ratingsDescriptions")}
              onChangeRatingDescription={ratingsDescription => setValue("ratingsDescriptions", ratingsDescription, { shouldDirty: true })}
              errorMessage={errors?.ratingsDescriptions?.message}
              isMarketSkill
            />

            <SkillFlyoutFormSimilarSkills
              skillName={skillName}
              similarSkills={watch("similarSkills")}
              onSelectSimilarSkill={item => setValue("similarSkills", [...watch("similarSkills"), item], { shouldDirty: true })}
              onRemoveSimilarSkill={item => setValue("similarSkills", watch("similarSkills").filter(x => x.name !== item.name), { shouldDirty: true })}
            />
          </Flex>
        ),
        footer: (
          <Flex alignItems="center" justifyContent="space-between">
            {currentUserHasPermission("Permissions.Skills.DeleteSkill") && (
              <Button className="min-w-[140px]" variant="danger" onClick={deleteOverlayState.open}>
                {t("common.actions.delete")}
              </Button>
            )}
            <Grid gap="inline-md" templateColumns={[minmax("140px", "min-content"), "auto"]}>
              <Button variant="secondary" onClick={closeFlyout} fluid>{t("common.form.cancel")}</Button>
              <Button type="submit"
                onClick={handleSubmit(onSubmitForm)}
                fluid
                isDisabled={!isDirty}
                loading={isPending}
              >
                {t("common.form.save")}
              </Button>
            </Grid>
          </Flex>
        ),
        modals: (
          <>
            <DeleteSkillConfirmationModal
              skillName={skillName}
              overlayState={deleteOverlayState}
              onDeleteSuccess={forceCloseFlyout}
            />
            <SkillFlyoutFormConfirmationModal overlayState={confirmationOverlayState} onClickConfirm={forceCloseFlyout} />
          </>
        )
      })}
    </>
  );
};