import { useEditor } from "@/components";
import { type SkillRating } from "@/features/skill/types/skillTypes";
import { getDefaultSkillRatingDescriptions } from "@/features/skills/types/SkillLevels";
import { type RichContent } from "@/features/types/common";
import { EditorContent } from "@tiptap/react";

interface SkillRatingDescriptionProps {
  description: RichContent;
  rating: SkillRating;
}

export const SkillRatingDescription = ({ description, rating }: SkillRatingDescriptionProps) => {
  const editor = useEditor({
    content: description,
    editable: false
  }, [description]);

  const defaultTranslations = getDefaultSkillRatingDescriptions();

  return description
    ? <EditorContent editor={editor} />
    : <>{defaultTranslations[rating]}</>;
};