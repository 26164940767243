import { FieldFormHeader, FormFieldWrapper } from "@/components";

import { DatePickerInput } from "./DatePickerInput";
import { type DatePickerProps } from "./types";

type Props = DatePickerProps;

export const DatePicker = (props: Props) => {
  const { errorMessage, errorStyle, label, ...headerProps } = props;

  return (
    <FormFieldWrapper errorMessage={errorMessage} errorStyle={errorStyle}>
      <FieldFormHeader {...headerProps} label={label} />
      <DatePickerInput {...props} />
    </FormFieldWrapper>
  );
};
