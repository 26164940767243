import { DraggableType } from "@/features/skill-rating/types/common";
import { type SkillRatingForm } from "@/features/skill-rating/types/skillRatingForm";
import { useGetSkillFlyoutQuery } from "@/features/skill/hooks/useGetSkillFlyout";
import { type SkillDto, type SkillRating } from "@/features/skills/types/Skill";
import { useMarketContentTranslation, useTranslation } from "@/hooks/useTranslation";
import { removeTextBetweenParenthesis } from "@/lib/utils";
import { GripIcon } from "@hopper-ui/icons";
import { Item, Menu, MenuTrigger, Tag, Text } from "@workleap/orbiter-ui";
import classNames from "classnames";
import { type SyntheticEvent, useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useFormContext } from "react-hook-form";

enum Actions {
  REMOVE = "remove"
}

interface Props extends Pick<SkillDto, "displayName" | "name" | "category" | "isConfirmed"> {
  isUncategorized?: boolean;
  rating?: SkillRating;
}

export const DraggableSkill = (skill: Props) => {
  const { t } = useTranslation();
  const { displayName, name, isUncategorized = false, isConfirmed = true } = skill;
  const { translateMarketContent } = useMarketContentTranslation();
  const skillName = removeTextBetweenParenthesis(displayName ?? translateMarketContent(name));
  const [hovered, setHovered] = useState(false);
  const { getValues, setValue } = useFormContext<SkillRatingForm>();

  // We want to cache the description before the drag to optimize user experience and diminish loading time
  useGetSkillFlyoutQuery(name, {
    enabled: hovered
  });

  const [_, dragRef, dragPreview] = useDrag(
    () => ({
      type: DraggableType.SKILL,
      item: skill
    }), []);
  
  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const handleClickRemoveSkill = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    
    const newSkills = getValues("skills").filter(x => x.name !== name);
    const newUncategorizedSkills = getValues("uncategorizedSkills").filter(x => x.name !== name);

    setValue("skills", newSkills, { shouldDirty: true });
    setValue("uncategorizedSkills", newUncategorizedSkills, { shouldDirty: true });
  };

  const handleMouseOver = () => {
    setHovered(true);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (__: SyntheticEvent, newOpen: boolean) => {
    if (!newOpen) {
      setIsOpen(newOpen);
    }
  };

  const handleRightClick = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const handleSelectionChange = (e: SyntheticEvent, keys: string[]) => {
    if (keys[0] === Actions.REMOVE) {
      handleClickRemoveSkill(e);
    }
  };
  return (
    <MenuTrigger open={isOpen} onOpenChange={handleOpenChange}>
      <Tag
        onContextMenu={handleRightClick}
        ref={dragRef}
        paddingY="6px"
        paddingX="10px"
        borderRadius="rounded-md"
        backgroundColor={isUncategorized || !isConfirmed ? "neutral" : "decorative-option5-weakest"}
        cursor="grab"
        onMouseOver={handleMouseOver}
        className={classNames("border-2", {
          "border-decorative-option5-surface-weakest": !isUncategorized,
          "border-information-border": isUncategorized,
          "border-information-border border-dashed": !isConfirmed
        })}
        onRemove={handleClickRemoveSkill}
      >
        <GripIcon size="sm" color="information-weak" />
        <Text size="sm" color="information" whiteSpace="nowrap" paddingX="inset-xs">
          {skillName}
        </Text>
      </Tag>
      <Menu onSelectionChange={handleSelectionChange}>
        <Item key={Actions.REMOVE}>{t("common.form.remove")}</Item>
      </Menu>
    </MenuTrigger>
  );
};