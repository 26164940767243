import { ViewContentWrapper } from "@/components";
import { useCurrentUser } from "@/lib/auth/AuthProvider";
import { PermissionGuardRoute } from "@/pages/components/PermissionGuardRoute";
import { Navigate, Route, Routes } from "react-router-dom";
import { SettingsPage } from "./SettingsPage";
import { AtsPage } from "./ats/AtsPage";
import { BillingPage } from "./billing/BillingPage";
import { OrganizationDetailsPage } from "./company/OrganizationDetailsPage";
import { DepartmentsPage } from "./departments/DepartmentsPage";
import { SettingsGeneralPage } from "./general/SettingsGeneralPage";
import { HrisSettingsPage } from "./hris/HrisSettingsPage";
import { PermissionsSettingsPage } from "./permissions/PermissionsSettingsPage";
import { RolesManagementPage } from "./roles/RolesManagementPage";
import { SettingsSkillsRoutes } from "./skills/SettingsSkillsRoutes";
import { TrainingRoutes } from "./training/TrainingRoutes";
import { UsersRoutes } from "./users/UsersRoutes";

export const SettingsRoutes = () => {
  const { tenant: { isWorkleapTenant } } = useCurrentUser();

  if (!isWorkleapTenant) {
    return (
      <Routes>
        <Route element={<ViewContentWrapper />}>
          <Route path="" element={<SettingsPage />} />
          <Route path="company"
            element={(
              <PermissionGuardRoute permission="Permissions.Settings.Read">
                <OrganizationDetailsPage />
              </PermissionGuardRoute>
            )} 
          />
          <Route path="departments"
            element={(
              <PermissionGuardRoute permission="Permissions.Departments.Read">
                <DepartmentsPage />
              </PermissionGuardRoute>
            )}
          />
          <Route path="billing"
            element={(
              <PermissionGuardRoute permission="Permissions.Billing.Read">
                <BillingPage />
              </PermissionGuardRoute>
            )}
          />
          <Route path="hris"
            element={(
              <PermissionGuardRoute permission="Permissions.Settings.Hris.Write">
                <HrisSettingsPage />
              </PermissionGuardRoute>
            )}
          />
          <Route path="permissions"
            element={(
              <PermissionGuardRoute permission="Permissions.Settings.Write">
                <PermissionsSettingsPage />
              </PermissionGuardRoute>
            )}
          />
          <Route path="skills/*"
            element={(
              <PermissionGuardRoute permission="Permissions.Skills.UpdateSkill">
                <SettingsSkillsRoutes />
              </PermissionGuardRoute>
            )}
          />
          <Route path="roles"
            element={(
              <PermissionGuardRoute permission="Permissions.RoleManagement.Read">
                <RolesManagementPage />
              </PermissionGuardRoute>
            )}
          />
          <Route path="ats"
            element={(
              <PermissionGuardRoute permission="Permissions.Settings.Ats.Write">
                <AtsPage />
              </PermissionGuardRoute>
            )}
          />
        </Route>
        <Route path="users/*"
          element={(
            <PermissionGuardRoute permission="Permissions.UserManagement.Read">
              <UsersRoutes />
            </PermissionGuardRoute>
          )}
        />
        <Route path="training/*"
          element={(
            <PermissionGuardRoute permission="Permissions.Trainings.Read">
              <TrainingRoutes />
            </PermissionGuardRoute>
          )}
        />
        <Route path="*" element={<Navigate to="." />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route element={<ViewContentWrapper />}>
        <Route path="" element={<SettingsPage />} />
        <Route path="roles"
          element={(
            <PermissionGuardRoute permission="Permissions.RoleManagement.Read">
              <RolesManagementPage />
            </PermissionGuardRoute>
          )}
        />
        <Route path="ats"
          element={(
            <PermissionGuardRoute permission="Permissions.Settings.Ats.Write">
              <AtsPage />
            </PermissionGuardRoute>
          )}
        />
      </Route>
      <Route path="general"
        element={(
          <PermissionGuardRoute permission="Permissions.Settings.Write">
            <SettingsGeneralPage />
          </PermissionGuardRoute>
        )}
      />
      <Route path="training/*"
        element={(
          <PermissionGuardRoute permission="Permissions.Trainings.Read">
            <TrainingRoutes />
          </PermissionGuardRoute>
        )}
      />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};