import { type AriaButtonProps } from "@react-types/button";
import { type ReactElement, type ReactNode, type RefObject, useRef } from "react";
import { useButton } from "@react-aria/button";
import classNames from "classnames";
import styles from "./IconButton.module.css";

export type IconButtonVariant = "primary" | "secondary" | "ghost";

interface Props extends AriaButtonProps<"button"> {
  variant?: IconButtonVariant;
  disabled?: boolean;
  fluid?: boolean;
  loading?: boolean;
  autoFocus?: boolean;
  type?: "button" | "submit" | "reset";
  icon: ReactElement;
  dataTest?: string;
  className?: string;
  forwardedRef?: RefObject<HTMLButtonElement> | null;
  badge?: ReactNode;
  size?: "small" | "medium";
}

export const IconButton = ({
  variant = "primary",
  size = "medium",
  icon,
  disabled,
  loading,
  autoFocus,
  type = "button",
  dataTest,
  className,
  forwardedRef,
  badge,
  ...props
}: Omit<Props, "children">) => {
  const ref = useRef(null);
  const buttonRef = forwardedRef ?? ref;
  const { buttonProps } = useButton(props, ref);
  const isSmall = size === "small";

  const buttonClasses = classNames(className, styles["icon-button"], styles[`icon-button--${variant}`], {
    [styles["icon-button--small"]]: isSmall,
    [styles["icon-button--loading"]]: loading,
    [styles["iconButtonWithBadge"]]: !!badge
  });

  // Set the icon size based on the button size
  const badgeContent = badge
    ? (
      <div className={classNames(styles.iconButtonBadge, {
        [styles["iconButtonBadgeDisabled"]]: disabled
      })}
      >
        {badge}
      </div>
    )
    : null;

  return (
    <button
      {...buttonProps}
      disabled={disabled || loading}
      className={buttonClasses}
      autoFocus={autoFocus}
      ref={buttonRef}
      type={type}
      data-test={dataTest}
    >
      {
        !loading 
          ? (
            <>
              {icon}
              {badgeContent}
            </>
          ) 
          : null
      }
    </button>
  );
};
