import { type SaveAtsAccountTokenCommand } from "@/features/ats/types/common";
import { atsQueryKeys } from "@/features/ats/types/atsQueryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "@/lib/http/api";

export const useSaveAtsAccountTokenMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: SaveAtsAccountTokenCommand) => {
      await api.post("/ats/account-token", payload);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: atsQueryKeys.settings() });
    }
  });
};