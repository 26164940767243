import { type AriaDialogProps, useDialog } from "react-aria";
import { type ReactNode, useRef } from "react";

interface Props extends AriaDialogProps {
  children: ReactNode;
  className?: string;
}

export const DropdownDialog = ({ children, ...props }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { dialogProps } = useDialog(props, ref);

  return (
    <div {...dialogProps} ref={ref}>
      {children}
    </div>
  );
};
