import { HStack, Text, VStack } from "@/components";
import { UserAvatar } from "@/features/users/components/UserAvatar";
import { useTranslation } from "@/hooks/useTranslation";
import { useCollaborateContext } from "./Collaborate";
import { Collaborator } from "./Collaborator";
import { type CollaborateVariant } from "./types";

interface Props {
  variant: CollaborateVariant;
}

export const CollaborateListing = ({ variant }: Props) => {
  const { t } = useTranslation();
  const { activeCollaborators } = useCollaborateContext();

  if (!activeCollaborators) {
    return null;
  }

  const adminTitle = variant === "collaborate" ? t("collaborate.list.adminTitle") : t("mentor.list.adminTitle");

  return (
    <VStack gap={2}>
      <HStack align="center" justify="between" gap={4}>
        <HStack align="center" justify="start" gap={4}>
          <UserAvatar />
          <VStack gap={0}>
            <Text>{adminTitle}</Text>
            <Text size="xs" color="neutral-text-weak">{t("collaborate.list.adminTitleAccess")}</Text>
          </VStack>
        </HStack>
      </HStack>
      {activeCollaborators.map(collaborator => (
        <Collaborator key={collaborator.id} collaborator={collaborator} />
      ))}
    </VStack>
  );
};
