import classNames from "classnames";
import { type ComponentType, type ReactNode, useMemo } from "react";

const sizes = ["3xl", "2xl", "xl", "lg", "md", "sm", "xs"] as const;

interface Props {
  children: ReactNode;
  size: typeof sizes[number];
  className?: string;
}

/**
 * @deprecated Use Heading from @workleap/orbiter-ui instead
 */
export const Heading = ({ children, size, className }: Props) => {
  const number = sizes.indexOf(size) + 1;

  const HeadingTag = useMemo(() => {
    let type = "div";

    // HTML only has h1 to h6
    if (number <= 6) {
      type = `h${number}`;
    }

    return type as ComponentType | keyof JSX.IntrinsicElements;
  }, [number]);

  return (
    <HeadingTag className={classNames(className, `text-heading-${size}`)}>
      {children}
    </HeadingTag>
  );
};