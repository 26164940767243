import { type ReactNode } from "react";
import classNames from "classnames";

interface Props {
  children: ReactNode;
  className?: string;
}

export const CardAction = ({ children, className }: Props) => (
  <div className={classNames("absolute top-8 right-8", className)}>
    {children}
  </div>
);
