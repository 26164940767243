import { Text, VStack } from "@/components";
import { useTranslation } from "@/hooks/useTranslation";


export const BulkImportStep2 = () => {
  const { t } = useTranslation();

  return (
    <VStack>
      <Text size="md">{t("users.bulkImport.step2Title")}</Text>
      <Text>{t("users.bulkImport.step2Description")}</Text>
    </VStack>
  );
};