import { type SortDirection as ApiSortDirection } from "@/features/types/common";
import {
  type CompositeFieldSortDescriptor,
  type InitialSortDescriptor,
  type TableSortingDescriptor,
  type TableSortingProps
} from "./types";
import { type SortDescriptor, type SortDirection as TableSortDirection } from "@react-types/shared";
import { useCallback, useMemo, useState } from "react";

interface Props<T> {
  initialSortDescriptor?: InitialSortDescriptor<T>;
  onSortChange?: (fields: TableSortingDescriptor<T>[]) => void;
}

type SortingFieldMap<T> = Record<keyof T, ApiSortDirection>;

export interface UseTableSortingResult<T> {
  sortingFields: SortingFieldMap<T>;
  tableSortProps: TableSortingProps;
}

const toTableSortDirection = (apiSortDirection: ApiSortDirection) : TableSortDirection =>
  apiSortDirection === "Ascending"
    ? "ascending"
    : "descending";

const toApiSortDirection = (tableSortDirection: TableSortDirection) : ApiSortDirection =>
  tableSortDirection === "ascending"
    ? "Ascending"
    : "Descending";

const isCompositeDescriptor = <T>(descriptor: InitialSortDescriptor<T>):
  descriptor is CompositeFieldSortDescriptor<T> => !!(descriptor as CompositeFieldSortDescriptor<T>).columns;

const getInitialDescriptorValue = <T>(initialValue?: InitialSortDescriptor<T>) => {
  if (!initialValue) {
    return undefined;
  }

  if (isCompositeDescriptor(initialValue)) {
    const csvColumn = initialValue.columns.join(",");

    return { column: csvColumn, direction: toTableSortDirection(initialValue.direction) };
  }

  return { ...initialValue, direction: toTableSortDirection(initialValue.direction) } as SortDescriptor;
};

const getSortingFields = <T>(descriptor: SortDescriptor) => {
  const sortKeys = descriptor.column?.toString().split(",") ?? [];
  const sortDirection = toApiSortDirection(descriptor.direction ?? "ascending");

  return sortKeys.map(key => ({ column: key as keyof T, direction: sortDirection }));
};

export const useTableSorting = <T>({
  initialSortDescriptor,
  onSortChange: onChange
}: Props<T>) : UseTableSortingResult<T> => {
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor | undefined>(
    getInitialDescriptorValue(initialSortDescriptor)
  );

  const onSortChange = useCallback((descriptor: SortDescriptor) => {
    setSortDescriptor(descriptor);
    onChange?.(getSortingFields(descriptor));
  }, [sortDescriptor]);

  const sortingFields = useMemo(() => {
    if (!sortDescriptor) {
      return {} as SortingFieldMap<T>;
    }

    const fields = getSortingFields(sortDescriptor);

    return fields.reduce((acc: SortingFieldMap<T>, value) => {
      const col = value.column as keyof T;
      acc[col] = value.direction;

      return acc;
    }, {} as SortingFieldMap<T>);
  }, [sortDescriptor]);

  const tableSortProps = useMemo(() => ({
    onSortChange,
    sortDescriptor
  }), [sortDescriptor, onSortChange]);

  return { tableSortProps, sortingFields };
};
