import { roleQueryKeys } from "@/features/roles/types/roleQueryKeys";
import { searchRoles } from "@/features/search/api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

export interface SearchRolesFilters {
  enforceCollaboratorAccess?: boolean;
}

interface UseSearchRoleQueryOptions {
  enabled: boolean;
}

export const useSearchRolesQuery = (query: string, filters: SearchRolesFilters, options?: UseSearchRoleQueryOptions) => {
  return useQuery({
    queryKey: roleQueryKeys.searchRoles(query, filters), 
    queryFn: async () => {
      const { data } = await searchRoles({ search: query, ...filters });

      return data.results;
    },
    placeholderData: keepPreviousData,
    ...options
  });
};