import { type HRISIntegration, SetupQueryKeys } from "@/features/setup/types";
import { type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { getHRISIntegrations } from "@/features/setup/api/getHRISIntegrations";

export const useGetHRISIntegrations = (
  options?: UseQueryOptions<Promise<HRISIntegration[]>, unknown, HRISIntegration[], SetupQueryKeys[]>
) => (
  useQuery({
    queryKey: [SetupQueryKeys.HRIS_INTEGRATIONS], 
    queryFn: getHRISIntegrations, 
    ...options
  })
);
