import { AddDepartmentModal } from "@/features/departments/components/AddDepartmentModal";
import { Button, HStack, Heading, Text, VStack, useGetCurrentPageParam } from "@/components";
import { DepartmentList } from "@/features/departments/components/DepartmentList";
import { Helmet } from "react-helmet-async";
import { HrisLock } from "@/features/hris/components/lock/HrisLock";
import { useGetHrisSettingsQuery } from "@/features/hris/hooks/useGetHrisSettings";
import { useOverlayTriggerState } from "react-stately";
import { useTranslation } from "@/hooks/useTranslation";

export const DepartmentsPage = () => {
  const { t } = useTranslation();

  const page = useGetCurrentPageParam();

  const { data: hrisSettings } = useGetHrisSettingsQuery();
  const isHrisConnected = hrisSettings?.isConnected;
    
  const addDepartmentModalState = useOverlayTriggerState({});
  
  return (
    <>
      <Helmet>
        <title>{t("pages.settingsDepartment")}</title>
      </Helmet>
      <VStack gap={3}>
        <VStack gap={6}>
          <HStack align="center" justify="between">
            <VStack gap={1}>
              <Heading size="xl">
                {t("settings.departments.title")}
              </Heading>
              <Text>{t("settings.departments.subtitle")}</Text>
            </VStack>
            {
              isHrisConnected
                ? <HrisLock />
                : <Button onClick={addDepartmentModalState.open}>{t("common.form.add")}</Button>
            }
          </HStack>
          <DepartmentList page={page} />
        </VStack>
      </VStack>
      <AddDepartmentModal overlayState={addDepartmentModalState} />
    </>
  );
};