import { Checkbox } from "@/components";
import { type GridNode } from "@react-types/grid";
import { type TableState } from "react-stately";
import { useRef } from "react";
import { useTableColumnHeader, useTableSelectAllCheckbox } from "react-aria";

interface Props {
  column: GridNode<unknown>;
  state: TableState<unknown>;
}

export const TableSelectAllCell = ({ column, state }: Props) => {
  const ref = useRef(null);
  const isSingleSelectionMode = state.selectionManager.selectionMode === "single";
  const { columnHeaderProps } = useTableColumnHeader(
    { node: column },
    state,
    ref
  );
  const { checkboxProps } = useTableSelectAllCheckbox(state);

  return (
    <th
      {...columnHeaderProps}
      ref={ref}
      className="w-5 p-4 pl-5"
    >
      {!isSingleSelectionMode && <Checkbox {...checkboxProps} />}
    </th>
  );
};