import { type SkillsSearchDto } from "@/features/skills/types/common";
import api from "@/lib/http/api";

export const getSkillsSearch = async (search: string, jobTitle?: string) => {
  const { data } = await api.get<SkillsSearchDto>("/skills/search", {
    params: {
      jobTitle,
      search
    }
  });

  return data;
};
