import { Filter, FilterMultipleSelect, type FilterProps } from "@/features/filter";
import { type ListFilterItem } from "@/features/filter/types/common";
import { type UserFilterQueryParamKeys, type UserFilters } from "@/features/users/types/common";
import { allStatuses } from "@/features/users/types/useStatuses";
import { allUserRoles } from "@/features/users/types/userRoles";
import { useTranslation } from "@/hooks/useTranslation";

interface Props extends FilterProps {
  filters: UserFilters;
}

export const UsersFilter = (props: Props) => {
  const { t } = useTranslation();
  const { filters, setFilters } = props;

  const onSelectItem = (value: ListFilterItem, field: UserFilterQueryParamKeys) => {
    setFilters({
      [field]: [...(filters[field] ?? []), value]
    });
  };

  const onRemoveItem = (value: ListFilterItem, field: UserFilterQueryParamKeys) => {
    const newFilter = filters[field].filter(x => x.id.toLowerCase() !== value.id.toLowerCase());

    setFilters({
      [field]: newFilter
    });
  };

  return (
    <Filter {...props}>
      <FilterMultipleSelect 
        selectedItems={filters.userRoles} 
        label={t("users.filter.roles")}
        description={t("common.phrases.select")}
        items={allUserRoles.map(role => ({ id: role.value, name: role.text }))}
        selectItem={value => onSelectItem(value, "userRoles")}
        removeItem={value => onRemoveItem(value, "userRoles")}
      />
      <FilterMultipleSelect 
        selectedItems={filters.statuses} 
        label={t("users.filter.status")}
        description={t("common.phrases.select")}
        items={allStatuses}
        selectItem={value => onSelectItem(value, "statuses")}
        removeItem={value => onRemoveItem(value, "statuses")}
      />
    </Filter>
  );
};