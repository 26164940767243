import { Flyout } from "@/components";
import { useSkillFlyoutState } from "@/features/skill/hooks/useSkillFlyoutState";
import { AnimatePresence } from "framer-motion";
import { Suspense, type ReactNode } from "react";
import { type OverlayTriggerState } from "react-stately";
import { SkillFlyoutSections } from "./SkillFlyoutSections";

interface Props {
  overlayState: OverlayTriggerState;
  hideDefaultFlyoutActions?: boolean;
  extraActions?: ReactNode;
  showRecommendedMentors?: boolean;
}

export const SkillFlyout = (props: Props) => {
  const { overlayState } = props;

  return (
    <AnimatePresence>
      {overlayState.isOpen && (
        <SkillFlyoutOpen {...props} />
      )}
    </AnimatePresence>
  );
};

const SkillFlyoutOpen = (props: Props) => {
  const { overlayState } = props;
  const { skillName } = useSkillFlyoutState(overlayState);

  if (!skillName) {
    return null;
  }

  return (
    <Suspense>
      <SkillFlyoutOpenWithName {...props} skillName={skillName} />
    </Suspense>
  );
};

interface SkillFlyoutOpenWithNameProps extends Props {
  skillName: string;
}

const SkillFlyoutOpenWithName = (props: SkillFlyoutOpenWithNameProps) => {
  const { overlayState } = props;
  const { closeFlyout } = useSkillFlyoutState(overlayState);

  return (
    <Flyout
      onClose={closeFlyout}
      isOpen
      isDismissable
    >
      <SkillFlyoutSections {...props} />
    </Flyout>
  );
};