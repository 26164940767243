import { Button, HStack, Heading, Table, Text, VStack, usePaginationCountSummary, useTableSorting } from "@/components";
import { Cell, Column, Row, TableBody, TableHeader } from "react-stately";
import { DEFAULT_LIST_PAGE_SIZE } from "@/lib/utils/constants";
import { type InitialFilterQueryParamKeys } from "@/features/filter/types/common";
import { Link } from "react-router-dom";
import { RoleCell } from "@/features/roles/components/RoleCell";
import { RoleFilter } from "@/features/roles/components/RoleFilter";
import { type RoleFilterQueryParamKeys, type RoleFilters, type RoleManagementDto, roleFilterKeys } from "@/features/roles/types/common";
import { RoleManagementBulkDropdown } from "./RoleManagementBulkDropdown";
import { RoleManagementDropdown } from "./RoleManagementDropdown";
import { type Selection } from "@react-types/shared";
import { isEmpty } from "lodash";
import { nameOf } from "@/utils/types";
import { useDirectoryRoutes } from "@/features/directory/hooks/useDirectoryRoutes";
import { useFilters } from "@/features/filter";
import { useGetRolesManagement } from "@/features/roles/hooks/useGetRolesManagement";
import { useMemo, useState } from "react";
import { useTranslation } from "@/hooks/useTranslation";
import { getEmployeesNamesByIds } from "@/features/search/api";

export const RoleManagementList = () => {
  const { t } = useTranslation();

  const { tableSortProps, sortingFields: sortBy } = useTableSorting<RoleManagementDto>({
    initialSortDescriptor: {
      columns: ["name"],
      direction: "Ascending"
    }
  });

  const { addRole } = useDirectoryRoutes();
  const { page, queryFilters, ...otherProps } = useFilters<RoleFilters, RoleFilterQueryParamKeys & InitialFilterQueryParamKeys>(roleFilterKeys, "roles", {
    additionalListFilterKeys: ["statuses", "collaboratorIds"],
    idToNameResolvers: {
      collaboratorIds: getEmployeesNamesByIds
    }
  });

  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set());
  const { data, isLoading } = useGetRolesManagement({ page, pageSize: DEFAULT_LIST_PAGE_SIZE, ...queryFilters, sortBy });
  const { roles, total } = useMemo(() => ({
    roles: data?.items ?? [],
    total: data?.total ?? 0
  }), [data]);

  const selectedRoles = useMemo(() => {
    if (selectedKeys === "all") {
      return roles; 
    }

    return roles.filter(({ id }) => selectedKeys.has(id));
  }, [selectedKeys, roles]);


  const summaryLine = usePaginationCountSummary({
    currentPage: page,
    pageSize: DEFAULT_LIST_PAGE_SIZE,
    totalItems: total,
    listItemName: t("roles.table.paginationItems")
  });

  return (
    <VStack gap={6}>
      <HStack align="center" justify="between">
        <VStack gap={1}>
          <Heading size="xl">
            {t("settings.roles.title")}
          </Heading>
          <Text>{t("settings.roles.description")}</Text>
        </VStack>
        {isEmpty(selectedRoles)
          ? (
            <HStack>
              <RoleFilter {...otherProps} />
              <Link to={addRole()}>
                <Button size="small">{t("common.form.add")}</Button>
              </Link>
            </HStack>
          )
          : (
            <HStack>
              <Button size="small" variant="secondary" onClick={() => setSelectedKeys(new Set())}>{t("common.form.cancel")}</Button>
              <RoleManagementBulkDropdown selectedRoles={selectedRoles} />
            </HStack>
          )}
      </HStack>
      <Table
        isLoading={isLoading}
        aria-label={t("roles.table.title")}
        emptyListProps={{
          title: t("roles.table.empty.title"),
          description: t("roles.table.empty.description"),
          showImage: true
        }}
        selectedKeys={selectedKeys}
        onSelectionChange={setSelectedKeys}
        selectionMode="multiple"
        pagination={{
          totalItems: total,
          currentPage: page,
          pageSize: DEFAULT_LIST_PAGE_SIZE
        }}
        summary={summaryLine}
        {...tableSortProps}
      >
        <TableHeader>
          <Column key={nameOf<RoleManagementDto>("name")} allowsSorting>
            {t("roles.table.roleName")}
          </Column>
          <Column key={nameOf<RoleManagementDto>("employeesCount")} allowsSorting>
            {t("roles.table.peopleInRole")}
          </Column>
          <Column key={nameOf<RoleManagementDto>("status")} allowsSorting>
            {t("roles.table.status")}
          </Column>
          <Column>{t("roles.table.actions")}</Column>
        </TableHeader>
        <TableBody>
          {roles.map(role => {
            const { id, name, employeesCount, status } = role;

            return (
              <Row key={id}>
                <Cell>
                  <RoleCell roleId={id} roleName={name} />
                </Cell>
                <Cell>
                  <Text size="xs">
                    {employeesCount}
                  </Text>
                </Cell>
                <Cell>
                  <Text size="xs">
                    {status === "Draft" && t("roles.status.draft")}
                    {status === "Published" && t("roles.status.published")}
                    {status === "Archived" && t("roles.status.archived")}
                  </Text>
                </Cell>
                <Cell>
                  <RoleManagementDropdown role={role} />
                </Cell>
              </Row>
            );
          })}
        </TableBody>
      </Table>
    </VStack>
  );
};