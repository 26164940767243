import { SelectWithSearch } from "@/components";
import { useGetSkillSearchResults } from "@/features/setup/hooks/useGetSkillsSearchResults";
import { type SimilarSkillDto } from "@/features/skills/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { keepPreviousData } from "@tanstack/react-query";

interface SearchSimilarSkillProps {
  currentSkillName: string;
  selectedSkills: SimilarSkillDto[];
  onSelect: (item: SimilarSkillDto) => void;
  isDisabled?: boolean;
}

export const SearchSimilarSkill = ({ selectedSkills, onSelect, currentSkillName, isDisabled }: SearchSimilarSkillProps) => {
  const { data, setQuery, fetchStatus } = useGetSkillSearchResults(
    "",
    { placeholderData: keepPreviousData }
  );
  const { t } = useTranslation();
  const disabledItems = [...selectedSkills.map(({ name }) => name), currentSkillName];

  return (
    <SelectWithSearch
      setQuery={setQuery}
      results={data?.skills || []}
      fetchStatus={fetchStatus}
      description={t("similarSkills.search.description")}
      clearOnSelect
      itemToText={item => item.name}
      keySelector={item => item.name}
      disabledItems={disabledItems}
      onSelect={onSelect}
      isDisabled={isDisabled}
      itemDisplay={({ name }) => (
        <>
          {disabledItems.includes(name)
            ? t("setup.skills.added", { name })
            : name}
        </>
      )}
    />
  );
};