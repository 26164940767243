import { Button, HStack, Text, VStack } from "@/components";
import { useDemo } from "@/features/demo/hooks/useDemo";
import { useTranslation } from "@/hooks/useTranslation";
import { CrossButton } from "@workleap/orbiter-ui";

export const DemoCard = () => {
  const { t } = useTranslation();

  const {
    isDemo,
    showCard,
    launchDemo,
    closeDemoCard,
    exitDemo
  } = useDemo();
  
  if (!showCard) {
    return null;
  }
  
  return (
    <div className="p-space-inset-md text-neutral-text bg-neutral-surface-weakest-selected rounded-shape-rounded-md">
      <VStack gap={4}>
        <VStack gap={1.5}>
          <HStack gap={0} justify="between">
            <Text color="inherit" weight={690} className="whitespace-pre-line">
              {!isDemo ? t("demo.card.title") : t("demo.card.titleBrowsing")}
            </Text>
            {!isDemo && (
              <CrossButton 
                size="xs"
                aria-label="close-demo"
                onClick={closeDemoCard}
                className="text-neutral-icon"
              />
            )}
          </HStack>
          <Text size="xs" color="inherit">
            {!isDemo ? t("demo.card.browse") : t("demo.card.browsing")}
          </Text>
        </VStack>
        {!isDemo
          ? (
            <Button size="small" fluid onClick={launchDemo}>
              {t("demo.card.launch")}
            </Button>
          )
          : (
            <Button size="small" variant="danger" fluid onClick={exitDemo}>
              {t("demo.card.exit")}
            </Button>
          )}
      </VStack>
    </div>
  );
};