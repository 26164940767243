import { Button, HStack, Heading, Text, VStack, useGetCurrentPageParam } from "@/components";
import { GenerateSkillsDescriptionAutomaticallyBanner } from "@/features/skills/components/GenerateSkillsDescriptionAutomaticallyBanner";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Routes } from "@/types/routes";
import { SkillCustomDescriptionsList } from "@/features/skills/components/SkillCustomDescriptionsList";
import { useTranslation } from "@/hooks/useTranslation";

export const ListSkillsCustomDescriptionsPage = () => {
  const { t } = useTranslation();
  const page = useGetCurrentPageParam();

  return (
    <>
      <Helmet>
        <title>{t("pages.settingsCustomDescription")}</title>
      </Helmet>
      <VStack gap={6}>
        <HStack align="center" justify="between">
          <VStack gap={1}>
            <Heading size="xl">
              {t("skills.customDescriptions.table.title")}
            </Heading>
            <Text>{t("skills.customDescriptions.table.subtitle")}</Text>
          </VStack>
          <Link to={Routes.AddSkillCustomDescriptions}>
            <Button>{t("common.form.createNew")}</Button>
          </Link>
        </HStack>
        <GenerateSkillsDescriptionAutomaticallyBanner />
        <SkillCustomDescriptionsList page={page} />
      </VStack>
    </>
  );
};