import { translate } from "@/lib/i18n";
import { date, literal, minLength, nullable, object, type Output, string, toTrimmed, union, url } from "valibot";
import { type JobStatus } from "./common";

export const JobOpeningFormSchema = object({
  openDate: date(),
  role: object({
    id: string(),
    name: string()
  }, translate("jobs.form.role.required")),
  hiringManager: nullable(object({
    id: string(),
    name: string()
  })),
  postingLink: string([
    toTrimmed(), 
    minLength(1, translate("jobs.form.postingLink.required")),
    url(translate("jobs.form.postingLink.invalid"))
  ]),
  status: union([literal<JobStatus>("Open"), literal<JobStatus>("Draft"), literal<JobStatus>("Closed"), literal<JobStatus>("Archived")])
});

export type JobOpeningFormOutput = Output<typeof JobOpeningFormSchema>;
/**
 * It should be easier to have different Input and Output schemas for the same form when this PR will be merged
 * https://github.com/fabian-hiller/valibot/pull/502
 */
export type JobOpeningFormInput = Omit<JobOpeningFormOutput, "role"> & {
  role: JobOpeningFormOutput["role"] | null;
};