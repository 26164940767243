import { InfoIcon } from "@hopper-ui/icons";
import { ErrorMessage, Field, Flex, HelpMessage, Label, Text, type FieldProps } from "@workleap/orbiter-ui";
import { useMemo, type ReactElement } from "react";
import { useController, type Control, type ControllerFieldState, type ControllerRenderProps, type FieldPath, type FieldValues, type UseFormStateReturn } from "react-hook-form";

enum FieldValidationState {
  Valid = "valid",
  Invalid = "invalid"
}

interface ControllerRenderWithIdProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> extends ControllerRenderProps<TFieldValues, TName> {
  id: TName;
}

interface FormFieldProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> extends Omit<FieldProps, "children" | "validationState"> {
  /**
   * The control object provided by useForm from React Hook Form, which enables form inference.
   */
  control: Control<TFieldValues>;
  /**
   * The name (key) of the field, which should be one of the fields declared in useForm<FormType>.
   */
  name: TName;
  label?: string;
  helpMessage?: string;
  /**
   * An optional React element to be displayed at the top right of the field such as DefaultDisableExplainer.
   */
  topRightLabel?: ReactElement;
  /**
   * A render function that receives the field, fieldState, and formState from useController, and returns a React element.
   * 
   * This function has been extracted from the Controller component of React Hook Form.
   * 
   * @see https://react-hook-form.com/docs/usecontroller/controller
   */
  render: ({ field, fieldState, formState }: {
    field: ControllerRenderWithIdProps<TFieldValues, TName>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<TFieldValues>;
  }) => React.ReactElement;
}

/**
 * FormField combines various form-related components from Orbiter.
 * 
 * It also manages the field's state using useController from React Hook Form.
 */
export const FormField = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(props: FormFieldProps<TFieldValues, TName>) => {
  const { label, name, control, helpMessage, topRightLabel, render, ...rest } = props;

  const { field, fieldState, formState } = useController({ name, control });
  const { error } = fieldState;

  const validationState = useMemo(() => error ? FieldValidationState.Invalid : undefined, [error]);
  const fieldWithId = useMemo(() => ({ ...field, id: name }), [field, name]);

  return (
    <Field {...rest} validationState={validationState}>
      {label && (
        <Flex justifyContent="space-between">
          <Label htmlFor={name}>{label}</Label>
          {topRightLabel}
        </Flex>
      )}
      {render({ field: fieldWithId, fieldState, formState })}
      {helpMessage && (
        <HelpMessage>
          <Flex gap="inline-xs">
            <InfoIcon className="my-0.5" />
            <Text size="sm" color="neutral-text-weak">
              {helpMessage}
            </Text>
          </Flex>
        </HelpMessage>
      )}
      {error && <ErrorMessage>{error?.message}</ErrorMessage>}
    </Field>
  );
};