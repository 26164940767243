import { type ReactElement } from "react";
import { useTranslation } from "@/hooks/useTranslation";

interface EmptyStateProps {
  isOpen: boolean;
  showEmptyState: boolean;
  emptyState?: ReactElement;
}

export const EmptyState = ({ isOpen, showEmptyState, emptyState }: EmptyStateProps) => {
  if (isOpen && showEmptyState) {
    if (emptyState) {
      return emptyState;
    }

    return <DefaultEmptyState />;
  }

  return null;
};

const DefaultEmptyState = () => {
  const { t } = useTranslation();
  
  return (
    <div className="px-4 py-2">{t("common.empty.title")}</div>
  );
};