import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface DemoStore {
  showCard: boolean;
  setShowCard: (showCard: boolean) => void;
}

export const useDemoStore = create<DemoStore>()(
  persist(
    set => ({
      showCard: true,
      setShowCard: (showCard: boolean) => set({ showCard })
    }),
    {
      name: "demo-store",
      storage: createJSONStorage(() => localStorage)
    }
  )
);