
import { initialFilterKeys, type InitialFilters, type ListFilterItem } from "@/features/filter/types/common";
import { type JobStatus } from "@/features/jobs/types/common";
import { type SkillRatingForm } from "@/features/skill-rating/types/skillRatingForm";
import { type Model } from "@/features/types/clientTypes";

export type GetRolesManagementQueryDto = Model<"Roles.Queries.GetRolesManagementQueryDto">;
export type GetRolesManagementQuery = Model<"Roles.Queries.GetRolesManagementQuery">;
export type RoleManagementDto = Model<"Roles.Queries.GetRolesManagementQueryDto.RoleManagementDto">;

export type RolesRoleTenantRole = Model<"Roles.Models.TenantRole">;
export type RoleModel = Model<"Roles.Queries.GetRoleByIdDto">;
export type RoleJobOpeningDto = Model<"Roles.Queries.RoleJobOpeningDto">;
export type HiringManagerDto = RoleJobOpeningDto["hiringManager"];
export type RoleUpdateCollaboratorsModel = Model<"Roles.Commands.UpdateRoleCollaboratorsCommand.HttpRequestBody">;

export type RolePreviousAndNextRoles = Model<"Roles.Queries.GetRolePreviousAndNextRolesByIdDto">;
export type NextRoleDto = Model<"Roles.Models.NextRoleDto">;

export interface RoleEditModel extends Omit<Model<"Roles.Commands.AddRoleCommand.HttpRequestBody">, "description" | "nextRoles" | "skills">, SkillRatingForm {
  /**
   * The field `description` is of type { type: string, text: string, content: ContentNode[] }
   * which references itself, unfortunately react-hook-form does not currently support circular
   * reference types. See https://github.com/react-hook-form/react-hook-form/issues/9884 for more details
   */ 
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  description: any;
  nextRoles: NextRoleDto[];
}

export type RoleStatus = RoleModel["status"];

export type GetNextRolesByMarketIdDto = Model<"Roles.Queries.GetMarketRoleByIdDto">;
export type SearchNextMarketRoles = Model<"Roles.Queries.SearchNextRolesByMarketIdDto">;
export type SearchNextMarketRolesItem = Model<"Roles.Models.NextRoleDto"> & {
  position: number;
  selected?: boolean;
};

export const roleFilterKeys = [
  "skillGapFrom", 
  "skillGapTo",
  "jobStatus",
  "statuses",
  "collaboratorIds",
  ...initialFilterKeys
];

const roleFilterKeysConst = [
  "skillGapFrom", 
  "skillGapTo",
  "jobStatus",
  "statuses",
  "collaboratorIds"
] as const;


export type RoleFilterQueryParamKeys = typeof roleFilterKeysConst[number];

export type RoleFilters = {
  skillGapFrom: number;
  skillGapTo: number;
  jobStatus: JobStatus;
  statuses: ListFilterItem<RoleStatus>[];
  collaboratorIds: ListFilterItem[];
} & InitialFilters;

export type RichContent = Model<"Skills.RichContent">;
export type RoleWatcher = Model<"Roles.Models.RoleWatcherDto">;
export type GetCollaboratorRolesQueryDto = Model<"Roles.Queries.GetCollaboratorRolesQueryDto">;
export type CollaboratorRoleDto = Model<"Roles.Queries.GetCollaboratorRolesQueryDto.CollaboratorRoleDto">;
export type GetRoleTopMatchingPeopleQueryDto = Model<"Roles.Queries.GetRoleTopMatchingPeopleQueryDto">;
export type GetRoleRecommendedMatchesQueryDto = Model<"Roles.Queries.GetRoleRecommendedMatchesQueryDto">;
export type RoleUsersInterestsDto = Model<"Roles.Queries.RoleUsersInterestsDto">;

export type RoleDto = Model<"Roles.Models.RoleDto">;
export type RoleMatchingPerson = Model<"Roles.Models.RoleMatchingPersonDto">;
export type RoleSentiment = Model<"Shared.Sentiment">;
export type SeniorityLevel = Model<"Roles.Queries.SeniorityLevelDto">;

export type RoleReferencesDto = Model<"Roles.Queries.GetRoleReferencesDataQueryDto">;

export type BulkPublishRolesCommand = Model<"Roles.Commands.BulkPublishRolesCommand.HttpRequestBody">;
export type BulkArchiveRolesCommand = Model<"Roles.Commands.BulkArchiveRolesCommand.HttpRequestBody">;
export type BulkDeleteRolesCommand = Model<"Roles.Commands.BulkDeleteRolesCommand.HttpRequestBody">;

export type CurrentRoleDto = Model<"Employees.Models.CurrentRoleDto">;
export type PastRoleDto = Model<"Employees.Models.PastRoleDto">;