import { SkillRatingDescription } from "@/features/skill/components/SkillRatingDescription";
import { useGetSkillFlyoutQuery } from "@/features/skill/hooks/useGetSkillFlyout";
import { type SkillRating } from "@/features/skills/types/Skill";
import { UserAvatar } from "@/features/users/components/UserAvatar";
import { UserAvatarList, UserAvatarListLoading } from "@/features/users/components/UserAvatarList";
import { useTranslation } from "@/hooks/useTranslation";
import { Flex, Grid, Text } from "@workleap/orbiter-ui";
import { isEmpty } from "lodash";
import { type ComponentProps } from "react";
import { useDragLayer } from "react-dnd";
import { type DraggableSkill } from "./DraggableSkill";

type DraggedSkill = Omit<ComponentProps<typeof DraggableSkill>, "onRemove"> & {
  isUncategorized: boolean;
};

interface Props {
  rating: SkillRating;
}

export const SkillRatingLevelBucketContent = ({ rating }: Props) => {
  const { t } = useTranslation();

  const { item, isDragging } = useDragLayer(
    monitor => ({
      item: monitor.getItem<DraggedSkill>(),
      isDragging: monitor.isDragging()
    }));

  const { data: draggedSkill, isLoading } = useGetSkillFlyoutQuery(item?.name || "", {
    enabled: isDragging && !!item?.name
  });

  const getDraggedContent = () => {
    if (isLoading) {
      return (
        <UserAvatarListLoading />
      );
    }

    if (!draggedSkill) {
      return null;
    }

    const { possessedBy, ratingsDescriptions } = draggedSkill;

    return (
      <>
        {!isEmpty(possessedBy[rating]) ? 
          <UserAvatarList count={6} userAvatars={possessedBy[rating].map(x => x.avatar)} /> : 
          (
            <Flex gap="inline-sm" alignItems="center">
              <UserAvatar />
              <Text size="sm">{t("skillRating.bucket.noEmployees")}</Text>
            </Flex>
          )}
        <Text 
          color="neutral"
          size="xs"
          display={{
            base: "none",
            md: "block"
          }}
        >
          <SkillRatingDescription 
            description={ratingsDescriptions[rating]}
            rating={rating}
          />
        </Text>
      </>
    ); 
  };

  return (
    <Grid gap="inline-md">
      {getDraggedContent()}
    </Grid> 
  );
};