import classNames from "classnames";
import { type PropsWithChildren } from "react";
import styles from "./TableFooter.module.css";

interface TableFooterProps extends PropsWithChildren {
  className?: string;
}

export const TableFooter = ({ children, className }: TableFooterProps) => (
  <div className={classNames(styles.tableFooter, className)}>
    {children}
  </div>
);

