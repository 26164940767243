import { type SkillReferencesDto } from "@/features/skill/types/common";
import { skillQueryKeys } from "@/features/skill/types/skillQueryKeys";
import api from "@/lib/http/api";
import { useQuery, type QueryFunctionContext, type UseQueryOptions } from "@tanstack/react-query";

const getSkillReferences = async({ queryKey: [, { skillName }] }: QueryFunctionContext<ReturnType<typeof skillQueryKeys["skillReferences"]>>) => {
  const { data } = await api.post<SkillReferencesDto>("/skills/references", { skillName });

  return data;
};


export const useGetSkillReferences = (
  roleId: string,
  options?: Omit<UseQueryOptions<SkillReferencesDto, unknown, SkillReferencesDto, ReturnType<typeof skillQueryKeys.skillReferences>>, "queryKey">) =>
  useQuery({
    queryKey: skillQueryKeys.skillReferences(roleId), 
    queryFn: getSkillReferences, 
    ...options
  });