import { ReactComponent as AlertIcon } from "./assets/alert.svg";
import { IconButton, TooltipWrapper } from "@/components";
import { Routes } from "@/types";
import { useGetHrisSettingsQuery } from "@/features/hris/hooks/useGetHrisSettings";
import { useTranslation } from "@/hooks/useTranslation";
import { Link } from "react-router-dom";

export const HrisAuthErrorButton = () => {
  const { t } = useTranslation();
  const { data: hrisSettings } = useGetHrisSettingsQuery();

  if (!hrisSettings) {
    return null;
  }

  const { isConnected, syncStatus } = hrisSettings;

  if (!isConnected || syncStatus !== "AuthError") {
    return null;
  }

  return (
    <TooltipWrapper tooltip={t("hrisSettings.authError.button.tooltip")} anchorSelector="hris-auth-tooltip">
      <Link to={`${Routes.Settings}/hris`}>
        <IconButton
          icon={<AlertIcon />}
          variant="ghost"
        />
      </Link>
    </TooltipWrapper>
  );
};