import { AddSkillCustomDescriptionsPage } from "./add/AddSkillCustomDescriptionsPage";
import { EditSkillCustomDescriptionsPage } from "./edit/EditSkillCustomDescriptionsPage";
import { ListSkillsCustomDescriptionsPage } from "./ListSkillsCustomDescriptionsPage";
import { Navigate, Route, Routes } from "react-router-dom";
import { PermissionGuardRoute } from "@/pages/components/PermissionGuardRoute";

export const SkillCustomDescriptionsRoutes = () => (
  <Routes>
    <Route path="" element={<ListSkillsCustomDescriptionsPage />} />
    <Route path="add"
      element={(
        <PermissionGuardRoute permission="Permissions.Skills.CreateSkill">
          <AddSkillCustomDescriptionsPage />
        </PermissionGuardRoute>
      )}
    />
    <Route path="edit/:skillName"
      element={(
        <PermissionGuardRoute permission="Permissions.Skills.UpdateSkill">
          <EditSkillCustomDescriptionsPage />
        </PermissionGuardRoute>
      )}
    />
    <Route path="*" element={<Navigate to="." />} />
  </Routes>
);
