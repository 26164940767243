import { SelectWithSearch, useTypeAheadQuery } from "@/components";
import { searchRoles } from "@/features/search/api";
import { useSearchRolesQuery } from "@/features/search/hooks/useSearchRolesQuery";
import { type AutocompleteSelection, type SearchResultItem } from "@/features/search/types/common";
import { useMarketContentTranslation, useTranslation } from "@/hooks/useTranslation";
import { SearchIcon } from "@hopper-ui/icons";
import { Autocomplete, Item, type AutocompleteProps } from "@workleap/orbiter-ui";
import { forwardRef, useCallback, useState, type SyntheticEvent } from "react";

interface DeprecatedProps {
  label?: string;
  description?: string;
  initialValue?: string;
  errorMessage?: string;
  onSelect: (role: SearchResultItem) => void;
  isRequired?: boolean;
  enforceCollaboratorAccess?: boolean;
  isDisabled?: boolean;
  onClear?: () => void;
}

/**
 * @deprecated Use SearchInternalRoles instead
 */
export const DeprecatedSearchRoles = ({ label, description, initialValue, errorMessage, onSelect, isRequired, isDisabled, enforceCollaboratorAccess, onClear }: DeprecatedProps) => {
  const { setQuery, fetchStatus, data } = useTypeAheadQuery({
    fetchItemsQuery: async search => (await searchRoles({ search, enforceCollaboratorAccess })).data.results
  });
  const { translateMarketContent } = useMarketContentTranslation();
  
  return (
    <SelectWithSearch
      label={label}
      isRequired={isRequired}
      description={description}
      setQuery={setQuery}
      results={data}
      fetchStatus={fetchStatus}
      onSelect={onSelect}
      onClear={onClear}
      initialValue={initialValue}
      errorMessage={errorMessage}
      keySelector={({ id }) => id}
      itemDisplay={({ id, name }) => (
        <div key={id}>
          {translateMarketContent(name)}
        </div>
      )}
      itemToText={({ name }) => translateMarketContent(name)}
      isDisabled={isDisabled}
    />
  );
};

interface Props extends Omit<AutocompleteProps, "value" | "onSelectionChange" | "children" | "ref"> {
  value: SearchResultItem | null;
  enforceCollaboratorAccess?: boolean;
  onSelectionChange: (role: SearchResultItem | null) => void;
}

/**
 * Autocomplete for internal roles only (roles with a roleId)
 */
export const SearchInternalRoles = forwardRef<HTMLInputElement, Props>(({ value, enforceCollaboratorAccess, onSelectionChange, minCharacters = 3, ...rest }: Props, ref) => {
  const { t } = useTranslation();
  const { translateMarketContent } = useMarketContentTranslation();
  const [query, setQuery] = useState("");

  const { data: roles, isFetching } = useSearchRolesQuery(query, 
    { enforceCollaboratorAccess },
    { enabled: query.length >= minCharacters }
  );

  const handleSelectionChange = useCallback((_: SyntheticEvent, selection: AutocompleteSelection) => {
    let selectedRole = roles?.find(x => x.id === selection?.key) || null;

    if (selectedRole) {
      selectedRole = { ...selectedRole, name: translateMarketContent(selectedRole.name) };
    }
    
    onSelectionChange(selectedRole);
  }, [roles, onSelectionChange]);
  
  return (
    <Autocomplete
      {...rest}
      ref={ref}
      aria-label={t("search.roles")}
      value={value?.name || null} 
      onSearch={(_, searchTerm) => setQuery(searchTerm)}
      onSelectionChange={handleSelectionChange}
      zIndex={Number.MAX_SAFE_INTEGER}
      loading={isFetching}
      minCharacters={minCharacters}
      icon={<SearchIcon />}
    >
      {roles?.map(({ id, name }) => (
        <Item key={id}>
          {translateMarketContent(name)}
        </Item>
      ))}
    </Autocomplete>
  );
});