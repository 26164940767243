import { type AddDepartmentCommandDto } from "@/features/departments/types/common";
import { departmentQueryKeys } from "./departmentQueryKeys";
import { toast } from "react-toastify";
import { translate } from "@/lib/i18n";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import { useQueryClient } from "@tanstack/react-query";
import api from "@/lib/http/api";

type OnSuccess = () => void;

export const useAddDepartmentMutation = (onSuccess?: OnSuccess) => {
  const queryClient = useQueryClient();

  return useMutationWithValidationResult<AddDepartmentCommandDto>({
    mutationFn: async ({ name } : AddDepartmentCommandDto) => {
      await api.post("/departments", { name });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: departmentQueryKeys.lists() });

      toast.success(translate("departments.add.successMessage"));

      onSuccess?.();
    }
  });
};