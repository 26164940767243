import { AddCustomSkillPage } from "./add/AddCustomSkillPage";
import { EditCustomSkillPage } from "./edit/EditCustomSkillPage";
import { ListCustomSkillsPage } from "./ListCustomSkillsPage";
import { Navigate, Route, Routes } from "react-router-dom";
import { PermissionGuardRoute } from "@/pages/components/PermissionGuardRoute";

export const CustomSkillsRoutes = () => (
  <Routes>
    <Route path="" element={<ListCustomSkillsPage />} />
    <Route path="add"
      element={(
        <PermissionGuardRoute permission="Permissions.Skills.CreateSkill">
          <AddCustomSkillPage />
        </PermissionGuardRoute>
      )}
    />
    <Route path="edit/:id"
      element={(
        <PermissionGuardRoute permission="Permissions.Skills.UpdateSkill">
          <EditCustomSkillPage />
        </PermissionGuardRoute>
      )}
    />
    <Route path="*" element={<Navigate to="." />} />
  </Routes>
);
