import { Dropdown, type FieldFormHeaderProps, Menu, MenuItemWrapper, TextField } from "@/components";
import { useTranslation } from "@/hooks/useTranslation";
import { AngleDownIcon } from "@hopper-ui/icons";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { type Key, useRef } from "react";
import { Item, Section, useMenuTriggerState } from "react-stately";

export interface SelectItem<T = string> {
  id: T;
  text: string;
}

export interface SelectInputProps<T = string> extends FieldFormHeaderProps {
  value?: SelectItem<T>;
  items: SelectItem<T>[];
  selectItem?: (item?: SelectItem<T>) => void;
  label?: string;
  description?: string;
  errorMessage?: string;
  className?: string;
  textFieldClassName?: string;
  variant?: "small" | "medium";
  id?: string;
}

/**
 * @deprecated Use Select from @workleap/orbiter-ui instead
 */
export function Select<T>({ value, items, selectItem, label, description, errorMessage, isDisabled, className, textFieldClassName, variant = "small", ...rest }: SelectInputProps<T>) {
  const state = useMenuTriggerState({});
  const ref = useRef(null);
  const { t } = useTranslation();

  const { isOpen, close, focusStrategy, toggle } = state;

  const onAction = (key: Key) => {
    const level = items.find(x => x.id === key);
    selectItem?.(level);
    close();
  };

  const onClickTrigger = () => {
    if (isDisabled) {
      return;
    }

    toggle();
  };

  const chevronClasses = classNames({ "rotate-180": isOpen, "text-neutral-text-weak": isDisabled });

  return (
    <>
      <div ref={ref} onClick={onClickTrigger} className={classNames(className)}>
        <TextField
          {...rest}
          variant={variant}
          isDisabled={isDisabled}
          className={classNames(textFieldClassName, {
            "cursor-pointer": !isDisabled
          })}
          label={label}
          description={description ?? t("common.phrases.select")}
          isReadOnly
          value={value?.text || ""}
          errorMessage={errorMessage}
          iconRight={<AngleDownIcon size="sm" className={chevronClasses} />}
        />
      </div>
      <AnimatePresence>
        {isOpen && (
          <Dropdown 
            variant="auto"
            state={state}
            triggerRef={ref}
            placement="bottom left"
          >
            <motion.div
              className="overflow-y-auto max-h-80"
              initial={{ opacity: 0, height: 0 }}
              exit={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
            >
              <Menu
                aria-label={label}
                autoFocus={focusStrategy || true}
                onAction={onAction}
              >
                <Section>
                  {items.map(i => (
                    <Item key={i.id as string} textValue={i.text}>
                      <MenuItemWrapper>
                        {i.text}
                      </MenuItemWrapper>
                    </Item>
                  ))}
                </Section>
              </Menu>
            </motion.div>
          </Dropdown>
        )}
      </AnimatePresence>
    </>
  );
}

