import { debounce } from "lodash";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { useWindowSize } from "usehooks-ts";
import styles from "./Tabs.module.css";

interface Props {
  activeTab: string | null;
  // TODO: Define this type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refs: Record<string, any>;
}

export const TabUnderline = ({ refs, activeTab }: Props) => {
  const { width: windowWidth } = useWindowSize();

  const [{ x, width }, setAttributes] = useState({
    x: 0,
    width: 0
  });

  const updateAttributes = useCallback(() => {
    if (!activeTab) {
      return;
    }

    if (refs && refs[activeTab]) {
      setAttributes({
        x: refs[activeTab].current?.offsetLeft,
        width: refs[activeTab].current?.getBoundingClientRect().width
      });
    }
  }, [activeTab, refs]);

  useEffect(() => {
    updateAttributes();
  }, [activeTab, refs, updateAttributes]);

  useEffect(() => {
    const recalculateAttrs = debounce(() => {
      updateAttributes();
    }, 500);

    window.addEventListener("resize", recalculateAttrs);

    return () => {
      window.removeEventListener("resize", recalculateAttrs);
    };
  }, [windowWidth]);

  return (
    <motion.div
      className={styles.underline}
      animate={{
        x,
        width
      }}
    />
  );
};
