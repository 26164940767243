import { SelectWithSearch } from "@/components";
import { useIndustriesSearch } from "@/hooks";
import { useMarketContentTranslation, useTranslation } from "@/hooks/useTranslation";

interface Props {
  value?: string;
  errorMessage?: string;
  onChange: (value: string) => void;
}

export const SelectIndustryTenant = ({ onChange, value, errorMessage }: Props) => {
  const { t } = useTranslation();
  const {
    data: industriesQueryData,
    setQuery: setIndustriesQuery,
    fetchStatus: industriesFetchStatus
  } = useIndustriesSearch();
  const { translateMarketContent } = useMarketContentTranslation(); 
  
  return (
    <SelectWithSearch
      setQuery={setIndustriesQuery}
      label={t("setup.form.companyIndustryLabel")}
      description={t("common.phrases.select")}
      results={industriesQueryData}
      fetchStatus={industriesFetchStatus}
      keySelector={item => item}
      itemToText={item => item}
      itemDisplay={item => (
        <div key={item}>
          {translateMarketContent(item)}
        </div>
      )}
      onSelect={onChange}
      onClear={() => onChange("")}
      initialValue={value || ""}
      errorMessage={errorMessage}
      isRequired
    />
  );
};