import { FormLabel, VStack } from "@/components";
import { type PropsWithChildren, createContext } from "react";
import { type RadioGroupProps, type RadioGroupState, useRadioGroupState } from "react-stately";
import { useRadioGroup } from "react-aria";

export const RadioContext = createContext<RadioGroupState | null>(null);

export const RadioGroup = (props: PropsWithChildren<RadioGroupProps>) => {
  const { children, label, description, errorMessage, validationState } = props;
  const state = useRadioGroupState(props);
  const { radioGroupProps, labelProps, descriptionProps, errorMessageProps } =
    useRadioGroup(props, state);

  return (
    <div {...radioGroupProps}>
      <VStack gap={2.5}>
        <FormLabel {...labelProps}>{label}</FormLabel>
        <RadioContext.Provider value={state}>
          <VStack gap={2.5}>
            {children}
          </VStack>
        </RadioContext.Provider>
      </VStack>
      {description && 
        (
          <div {...descriptionProps} className="text-sm">{description}</div>
        )}
      {errorMessage && validationState === "invalid" &&
        (
          <div {...errorMessageProps} className="text-sm text-danger-icon-hover">
            <>{errorMessage}</>
          </div>
        )}
    </div>
  );
};
