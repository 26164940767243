import { useGetMemberActivationStatusSuspenseQuery } from "@/features/activation/hooks/useGetMemberActivationStatusSuspenseQuery";
import { SUCCESS_ACTIVATION_STATUSES } from "@/features/activation/types/common";
import { Routes } from "@/types";
import { Suspense, type PropsWithChildren } from "react";

export const WorkleapActivationGuard = (props: PropsWithChildren) => {
  return (
    <Suspense fallback={null}>
      <WorkleapActivationGuardChild {...props} />
    </Suspense>
  );
};

const WorkleapActivationGuardChild = ({ children }: PropsWithChildren) => {
  const { data: { activationStatus } } = useGetMemberActivationStatusSuspenseQuery();

  const shouldSkipActivation = SUCCESS_ACTIVATION_STATUSES.includes(activationStatus) || window.location.pathname === Routes.Activation;

  if (!shouldSkipActivation) {
    window.location.href = Routes.Activation;
    
    return null;
  }

  return children;
};