import { DismissIcon, ProgressIcon, RemoveIcon } from "@hopper-ui/icons";
import { HStack, Text, VStack } from "@/components";
import classNames from "classnames";

interface Props {
  variant?: "success" | "error";
  title: string;
  description?: string;
  onClose: () => void;
}

export const Banner = ({ variant = "success", title, description, onClose }: Props) => {
  const bannerClasses = classNames(
    "p-6 rounded-xl border w-full bg-neutral-surface",
    {
      "text-success-icon-weak border-success-icon-weak": variant === "success",
      "text-danger-icon border-critial-medium": variant === "error"
    });

  const icon = variant === "success"
    ? <ProgressIcon />
    : <RemoveIcon />;
  
  return (
    <VStack gap={1} className={bannerClasses}>
      <div className="flex items-center justify-between">
        <HStack gap={3} align="center">
          {icon}
          <Text color="inherit">{title}</Text>
        </HStack>
        <button onClick={onClose}>
          <DismissIcon size="lg" />
        </button>
      </div>
      {description && <Text className="ml-[30px]" color="inherit" size="xs">{description}</Text>}
    </VStack>
  );
};