import { apiPost } from "@/lib/http/api";
import { hrisQueryKeys } from "@/features/hris/types/hrisQueryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUnlinkAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await apiPost("/hris/unlink-account");
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: hrisQueryKeys.settings() });
    }
  });
};