import { AtsIntegrationListItem } from "./AtsIntegrationListItem";
import { Button, HStack, SelectableCard, SelectableCardGroup, VStack } from "@/components";
import { useCreateAtsLinkTokenMutation } from "@/features/ats/hooks/useCreateAtsLinkTokenMutation";
import { useGetAtsIntegrations } from "@/features/ats/hooks/useGetAtsIntegrations";
import { useState } from "react";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  onClose: () => void;
  onConfirm: (token: string, integration: string) => void;
}

export const AtsIntegrationList = ({ onClose, onConfirm }: Props) => {
  const { t } = useTranslation();

  const { data } = useGetAtsIntegrations();
  const { mutate, isPending } = useCreateAtsLinkTokenMutation(({ linkToken }) => onConfirm(selectedIntegration, linkToken));
  
  const [selectedIntegration, setSelectedIntegration] = useState("");

  const createLinkToken = async () => {
    mutate({ integration: selectedIntegration });
  };

  return (
    <VStack gap={6}>
      <SelectableCardGroup
        label={t("ats.integrationList.label")}
        value={selectedIntegration}
        onChange={setSelectedIntegration}
      >
        <div className="grid grid-cols-3 gap-2 h-[320px] relative overflow-y-scroll">
          {data?.integrations?.map(x => (
            <SelectableCard key={x.slug} value={x.slug} title={x.name}>
              <AtsIntegrationListItem data={x} />
            </SelectableCard>
          ))}
        </div>
      </SelectableCardGroup>
      <HStack align="center" justify="end">
        <Button variant="secondary" onClick={onClose}>
          {t("common.form.cancel")}
        </Button>
        <Button
          variant="primary"
          onClick={createLinkToken}
          loading={isPending}
          isDisabled={!selectedIntegration}
        >
          {t("common.form.confirm")}
        </Button>
      </HStack>
    </VStack>
  );
};
