import { AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { Button } from "@/components";
import { NewTabIcon } from "@hopper-ui/icons";
import { useGetHrisSettingsQuery } from "@/features/hris/hooks/useGetHrisSettings";
import { useTrackHrisAction } from "@/features/hris/hooks/useTrackHrisAction";
import { useTranslation } from "@/hooks/useTranslation";
import { useUnlinkAccountMutation } from "@/features/hris/hooks/useUnlickAccountMutation";

export const DisconnectHrisButton = () => {
  const { t } = useTranslation();
  const { data: settingsQueryData } = useGetHrisSettingsQuery();
  const { mutate: unlinkAccount, isPending: isMutating } = useUnlinkAccountMutation();
  const { trackHrisAction } = useTrackHrisAction();

  const onDisconnectHrisClick = () => {
    trackHrisAction(AnalyticValues.actionDisconnect);
    unlinkAccount();
  };
  
  return (
    <Button
      size="small"
      icon={<NewTabIcon />}
      variant="secondary"
      onClick={onDisconnectHrisClick}
      loading={isMutating}
      isDisabled={settingsQueryData?.isScheduledOrRunning}
    >
      {t("hrisSettings.disconnect.button")}
    </Button>
  );
};