import { AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { Button } from "@/components";
import { ConnectHrisModal } from "@/features/hris/components/modal/ConnectHrisModal";
import { NewTabIcon } from "@hopper-ui/icons";
import { useOverlayTriggerState } from "react-stately";
import { useTrackHrisAction } from "@/features/hris/hooks/useTrackHrisAction";
import { useTranslation } from "@/hooks/useTranslation";

export const ReauthHrisButton = () => {
  const { t } = useTranslation();
  const hrisConnectModalState = useOverlayTriggerState({});
  const { trackHrisAction } = useTrackHrisAction();

  const onReconnectHrisClick = () => {
    trackHrisAction(AnalyticValues.actionReconnect);
    hrisConnectModalState.open();
  };

  return (
    <>      
      <Button
        icon={<NewTabIcon />}
        variant="primary"
        onClick={onReconnectHrisClick}
      >
        {t("hrisSettings.reauth.button")}
      </Button>
      <ConnectHrisModal overlayState={hrisConnectModalState} />
    </>
  );
};