import { type AddOrUpdateEmployeeSkillCommand } from "@/features/employees/types/common";
import { directoryQueryKeys } from "@/features/directory/types/directoryQueryKeys";
import { employeesQueryKeys } from "@/features/employees/types/employeesQueryKeys";
import { skillQueryKeys } from "@/features/skill/types/skillQueryKeys";
import { toast } from "react-toastify";
import { translate } from "@/lib/i18n";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "@/lib/http/api";

export const useAddOrUpdateEmployeeSkills = (employeeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: AddOrUpdateEmployeeSkillCommand) => await api.post(`/employees/${employeeId}/add-or-update-skill`, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: employeesQueryKeys.all });
      queryClient.invalidateQueries({ queryKey: directoryQueryKeys.all });
      queryClient.invalidateQueries({ queryKey: skillQueryKeys.all });

      toast.success(translate("common.messages.changesSaved"));
    }
  });
};