import { ConditionalLink } from "@/components";
import { type PropsWithChildren } from "react";
import { type RoleStatus } from "@/features/roles/types/common";
import { useDirectoryRoutes } from "@/features/directory/hooks/useDirectoryRoutes";

interface Props extends Required<PropsWithChildren> {
  roleId?: string;
  roleStatus?: RoleStatus;
  className?: string;
}

export const RoleLink = ({ roleId, children, roleStatus, className }: Props) => {
  const { role } = useDirectoryRoutes();

  return (
    <ConditionalLink
      to={role(roleId)} 
      condition={!!roleId && roleStatus !== "Archived"}
      className={className}
    >
      {children}
    </ConditionalLink>
  );
};
