import { type RichContent } from "@/features/types/common";

export const isRichContentEmpty = (content?: RichContent): boolean => {
  if (!content || !content.type) {
    return true;
  }

  if (content.text) {
    return !content.text?.trim();
  }

  return content.content?.every(_content => isRichContentEmpty(_content)) ?? true;
};

export const sameRichContent = (a?: RichContent, b?: RichContent): boolean => {
  if (!a || !b) {
    return false;
  }

  if (a.type !== b.type) {
    return false;
  }

  if (a.text && b.text) {
    return a.text === b.text;
  }

  if (a.content && b.content) {
    return a.content.length === b.content.length && a.content.every((content, index) => sameRichContent(content, b.content?.[index]));
  }

  return false;
};