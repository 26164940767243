import { AppLogo } from "@/features/app";
import { Children, type ReactElement, type ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const WizardLayout = ({ children }: Props) => {
  const childrens = Children.toArray(children);
  const leftContent = childrens.find(child => (child as ReactElement).type === WizardLayout.LeftContent);
  const rightContent = childrens.find(child => (child as ReactElement).type === WizardLayout.RightContent);

  if (!leftContent || !rightContent) {
    throw new Error("WizardLayout must have a WizardLayout.LeftContent and a WizardLayout.RightContent");
  }

  if (childrens.filter(c => leftContent !== c && rightContent !== c).length > 0) {
    throw new Error("WizardLayout can only have a WizardLayout.LeftContent and a WizardLayout.RightContent");
  }

  return (
    <div className="flex flex-wrap h-screen bg-primary-surface-strong">
      {leftContent}
      {rightContent}
    </div>
  );
};

const LeftContent = ({ children }: Props) => (
  <div className="w-[50%] bg-primary-surface-strong relative h-screen">
    <AppLogo className="absolute text-samoyed left-6 top-6" />
    <div className="flex items-center justify-center h-screen">
      {children}
    </div>
  </div>
);


const RightContent = ({ children }: Props) => (
  <div className="w-[50%] bg-neutral-surface h-screen">
    <div className="grid container min-h-[100vh] grid-rows-[1fr_auto] align-middle items-center w-full">
      {children}
    </div>
  </div>
);


WizardLayout.LeftContent = LeftContent;
WizardLayout.RightContent = RightContent;

export { WizardLayout };