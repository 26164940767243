import { type SearchRolesFilters } from "@/features/search/hooks/useSearchRolesQuery";
import { type GetRolesManagementQuery, type RoleSentiment } from "./common";

export const roleQueryKeys = {
  all: [{ scope: "roles" }] as const,
  lists: () => [...roleQueryKeys.all, { entity: "list" }] as const,
  single: (roleId: string) => [...roleQueryKeys.all, { entity: "single", roleId }] as const,
  nextRoles: (roleId: string) => [...roleQueryKeys.all, { entity: "nextRoles", roleId }] as const,
  flyout: (roleId: string) => [...roleQueryKeys.all, { entity: "flyout", roleId }] as const,
  nextMarketRoles: (marketId: string) => [...roleQueryKeys.all, { entity: "nextMarketRoles", marketId }] as const,
  singleByMarketId: (marketId: string) => [...roleQueryKeys.all, { entity: "single", marketId }] as const,
  watchers: (roleId: string) => [...roleQueryKeys.all, { entity: "watchers", roleId }] as const,
  collaboratorRoles: () => [...roleQueryKeys.all, { entity: "collaboratorRoles" }] as const,
  topMatchingPeople: (roleId: string) => [...roleQueryKeys.all, { entity: "topMatchingPeople", roleId }] as const,
  roleRecommendedMatches: (roleId: string) => [...roleQueryKeys.all, { entity: "roleRecommendedMatches", roleId }] as const,
  roleInterests: (roleId: string, sentimentFilter?: RoleSentiment) => [...roleQueryKeys.all, { entity: "roleInterests", roleId, sentimentFilter }] as const,
  rolesManagement: (params: GetRolesManagementQuery) => [...roleQueryKeys.lists(), { entity: "rolesManagement", ...params }] as const,
  roleReferences: (roleId: string) => [...roleQueryKeys.all, { entity: "roleReferences", roleId }] as const,
  searchRoles: (query: string, filters: SearchRolesFilters = {}) => [{ ...roleQueryKeys.all, entity: "searchRoles", query, filters }] as const,
  searchAllRoles: (query: string) => [{ ...roleQueryKeys.all, entity: "searchAllRoles", query }] as const
};