import { DismissIcon } from "@hopper-ui/icons";
import { IconButton, Text } from "@/components";
import styles from "./FilterItemPill.module.css";

interface Props {
  label: string;
  onRemove: (key: string) => void;
}

export const FilterItemPill = ({ label, onRemove }: Props) => (
  <div className={styles.pillContainer}>
    <Text size="xs">
      {label}
    </Text>
    <IconButton
      variant="ghost"
      icon={<DismissIcon size="sm" />}
      size="small"
      onPress={() => onRemove(label)}
      className={styles.pillRemoveBtn}
    />
  </div>
);