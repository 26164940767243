import { type AriaPopoverProps, DismissButton, Overlay, usePopover } from "@react-aria/overlays";
import classNames from "classnames";
import { type ReactNode, useRef } from "react";
import type { OverlayTriggerState } from "react-stately";

import { ThemeProvider } from "@workleap/orbiter-ui";
import styles from "./Dropdown.module.css";

interface PopoverProps extends Omit<AriaPopoverProps, "popoverRef"> {
  children: ReactNode;
  state: OverlayTriggerState;
  variant?: "narrow" | "wide" | "auto";
  className?: string;
}

export const Dropdown = (props: PopoverProps) => {
  const ref = useRef(null);
  const { state, children, variant = "narrow", className } = props;

  const { popoverProps } = usePopover(
    {
      ...props,
      popoverRef: ref
    },
    state
  );

  const classes = classNames(styles.container, styles[`container--${variant}`], className);

  return (
    <Overlay>
      <ThemeProvider colorScheme="light">
        <div
          {...popoverProps}
          ref={ref}
          className={classes}
        >
          <DismissButton onDismiss={state.close} />
          {children}
          <DismissButton onDismiss={state.close} />
        </div>
      </ThemeProvider>
    </Overlay>
  );
};