import { AnalyticEvents, AnalyticProperties } from "@/features/analytics/types/analyticEvents";
import { type Dict } from "mixpanel-browser";
import { type Feature } from "@/lib/features/types/feature";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import { useLocation } from "react-router-dom";
import { useMixpanel } from "@/features/analytics/hooks/useMixpanel";
import { useRef } from "react";

const TRACK_PREMIUM_ACTION_DEBOUNCE = 1000;

export const useTrackPremiumLimitationAction = () => {  
  const { trackEvent } = useMixpanel();
  const { pathname } = useLocation(); 
  const { getPlanNameByFeature } = useFeatures();
  const timeoutId = useRef<number | undefined>();

  const trackPremiumLimitationAction = (action: string, feature: Feature) => {
    clearTimeout(timeoutId.current);

    timeoutId.current = window.setTimeout(() => {
      const properties: Dict = {};
      properties[AnalyticProperties.action] = action;
      properties[AnalyticProperties.source] = pathname;
      properties[AnalyticProperties.module] = getPlanNameByFeature(feature);
      trackEvent(AnalyticEvents.premiumLimitationAction, properties);
    }, TRACK_PREMIUM_ACTION_DEBOUNCE);
  };
  
  return {
    trackPremiumLimitationAction
  };
};