import { Card, ConfirmationModal } from "@/components";
import { useDeleteRoleMutation } from "@/features/roles/hooks/useDeleteRoleMutation";
import { type RoleReferencesDto } from "@/features/roles/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { WarningIcon } from "@hopper-ui/icons";
import { Checkbox, Div, Flex, Text } from "@workleap/orbiter-ui";
import { isEmpty } from "lodash";
import { useState } from "react";
import { Trans } from "react-i18next";
import { type OverlayTriggerState } from "react-stately";

interface Props {
  roleId: string;
  overlayState: OverlayTriggerState;
  roleReferencesData: RoleReferencesDto;
}

export const RoleDeletionWithRiskModal = ({ overlayState, roleId, roleReferencesData }: Props) => {
  const { t } = useTranslation();

  const { mutate: deleteRole } = useDeleteRoleMutation(roleId);
  const [isDeletionRiskAccepted, setIsDeletionRiskAccepted] = useState(false);
  const onRiskAcceptanceChange = () => setIsDeletionRiskAccepted(!isDeletionRiskAccepted);
  const {
    careerProgression,
    growPlans,
    openings,
    workHistory
  } = roleReferencesData;

  const activeCareerProgressions = careerProgression.filter(({ isActive }) => isActive);
  const activeGrowPlans = growPlans.filter(({ isActive }) => isActive);
  const activeJobOpenings = openings.filter(({ isActive }) => isActive);
  const workHistories = workHistory.filter(({ isActive }) => isActive);

  return (
    <ConfirmationModal
      title={t("roles.delete.title")}
      onClickConfirm={deleteRole}
      overlayState={overlayState}
      cancelButtonContent={t("common.form.cancel")}
      confirmButtonContent={t("roles.delete.confirm")}
      confirmButtonProps={{ isDisabled: !isDeletionRiskAccepted }}
      className="!w-[480px]"
    >
      <Flex direction="column" gap="stack-md">
        <Text className="text-center">{t("roles.delete.withRisk.summary")}</Text>
        <Card className="bg-warning-surface border-warning-text-weak">
          <Flex direction="column" gap="stack-md">
            <Flex direction="row" gap="inline-md">
              <Div>
                <WarningIcon className="text-warning-text-weak" size="lg" />
              </Div>
              <Flex direction="column">
                <Text className="text-warning-text-strong" size="md" fontWeight={690}>{t("roles.delete.roleWillBeDissociated")}</Text>
                <Div className="text-warning-text-strong">
                  <ul className="px-4 list-disc">
                    {!isEmpty(activeCareerProgressions) && (
                      <li>
                        {t("roles.delete.warning.impact.careerPath", {
                          count: activeCareerProgressions.length })}
                      </li>
                    )}
                    {!isEmpty(activeGrowPlans) && (
                      <li>
                        {t("roles.delete.warning.impact.growPlans", {
                          count: activeGrowPlans.length })}
                      </li>
                    )}
                    {!isEmpty(activeJobOpenings) && (
                      <li>
                        {t("roles.delete.warning.impact.openings", {
                          count: activeJobOpenings.length })}
                      </li>
                    )}
                    {!isEmpty(workHistories) && (
                      <li>
                        <Trans
                          // when using the <Trans /> component with a pluralized key, the count needs to be passed
                          // as prop instead of through the values prop `values={{ count: myCount }}`
                          // otherwise the i18next-parser plugin used to extract translations
                          // will not recognize the key as pluralized
                          count={workHistories.length}
                          components={{ br: <br /> }}
                          i18nKey="roles.delete.warning.impact.workHistory"
                        />
                      </li>
                    )}
                  </ul>
                </Div>
              </Flex>
            </Flex>
            <Div display="flex" alignItems="center" justifyContent="center">
              <Checkbox className="text-warning-text-strong" checked={isDeletionRiskAccepted} onChange={onRiskAcceptanceChange}>
                {t("roles.delete.warning.impact.confirmation")}
              </Checkbox>
            </Div>
          </Flex>
        </Card>
      </Flex>
    </ConfirmationModal>
  );
};