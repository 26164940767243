import { HStack, Text } from "@/components";
import classNames from "classnames";
import { useContext, useRef } from "react";
import { type AriaRadioProps, VisuallyHidden, useFocusRing, useRadio } from "react-aria";
import { type RadioGroupState } from "react-stately";
import { RadioContext } from "./RadioGroup";

export const Radio = (props: AriaRadioProps) => {
  const { children } = props;
  const state = useContext(RadioContext) as unknown as RadioGroupState;
  const ref = useRef(null);
  const { inputProps, isSelected } = useRadio(props, state, ref);
  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <label className="block cursor-pointer">
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <HStack gap={2} align="center">
        <div className={classNames("w-4 h-4 rounded-full border",
          {
            "border-neutral-border bg-neutral-surface hover:border-neutral-border-hover hover:bg-neutral-surface-hover": !isSelected,
            "border-4 border-neutral-border-press bg-neutral-surface": isSelected,
            "outline outline-primary-border-focus outline-offset-2": isFocusVisible
          })}
        />
        <Text size="sm">
          {children}
        </Text>
      </HStack>
    </label>
  );
};