import { ActionButtonDropdown, type ActionButtonItem } from "@/components";
import { useDirectoryRoutes } from "@/features/directory/hooks/useDirectoryRoutes";
import { useDuplicateRoleMutation } from "@/features/role/hooks/useDuplicateRoleMutation";
import { usePublishRoleMutation } from "@/features/role/hooks/usePublishRoleMutation";
import { DeleteRoleConfirmationModal } from "@/features/roles/components/DeleteRoleConfirmationModal";
import { useArchiveRoleMutation } from "@/features/roles/hooks/useArchiveRoleMutation";
import { useRoleActions } from "@/features/roles/hooks/useRoleActions";
import { type RoleManagementDto } from "@/features/roles/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { useNavigate } from "react-router-dom";
import { useOverlayTriggerState } from "react-stately";

interface Props {
  role: RoleManagementDto;
}

type Action = "viewDetails" | "edit" | "publish" | "delete" | "archive" | "duplicate";

export const RoleManagementDropdown = ({ role }: Props) => {
  const { id, status } = role;
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { canDeleteRole } = useRoleActions();
  const { mutate: archiveRole } = useArchiveRoleMutation(id);
  const { mutate: publishRole } = usePublishRoleMutation(id);
  
  const { mutate: duplicateRole } = useDuplicateRoleMutation();
  const deleteRoleModalState = useOverlayTriggerState({});
  const { editRole } = useDirectoryRoutes();

  const actions: ActionButtonItem<Action>[] = [];

  if (status !== "Archived") {
    actions.push({
      key: "edit",
      text: t("roles.actions.edit"),
      onClick: () => navigate(editRole(id))
    });
  }

  if (status !== "Published") {
    actions.push({
      key: "publish",
      text: t("roles.actions.publish"),
      onClick: () => publishRole()
    });
  }

  if (status === "Published") {
    actions.push({
      key: "archive",
      text: t("roles.actions.archive"),
      onClick: () => archiveRole()
    });
  }

  actions.push({
    key: "duplicate",
    text: t("roles.actions.duplicate"),
    onClick: () => duplicateRole(id)
  });

  if (canDeleteRole(role)) {
    actions.push({
      key: "delete",
      text: t("roles.actions.delete"),
      isDestructive: true,
      onClick: deleteRoleModalState.open
    });
  }

  return (
    <>
      <ActionButtonDropdown
        actions={actions}
        iconButtonClassName="!h-[34px] !w-[34px]"
      />
      <DeleteRoleConfirmationModal
        overlayState={deleteRoleModalState}
        role={role}
      />
    </>
  );
};