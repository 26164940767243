import { Banner, Card, CardHeader, HStack, IconText, Text, VStack } from "@/components";
import { DisconnectHrisButton } from "@/features/hris/components/button/DisconnectHrisButton";
import { StartHrisSyncButton } from "@/features/hris/components/button/StartHrisSyncButton";
import { HrisActivateToggle } from "@/features/hris/components/toggle/HrisActivateToggle";
import { HrisAutoAssignManagersAsCollaboratorsToggle } from "@/features/hris/components/toggle/HrisAutoAssignManagersAsCollaboratorsToggle";
import { useConnectHrisStore } from "@/features/hris/hooks/useConnectHrisStore";
import { useGetHrisSettingsQuery } from "@/features/hris/hooks/useGetHrisSettings";
import { useTranslation } from "@/hooks/useTranslation";
import { RemoveIcon, SuccessIcon } from "@hopper-ui/icons";
import { useEffect } from "react";
import { ConnectedHrisIntegration } from "./ConnectedHrisIntegration";
import { HrisSyncStatus } from "./HrisSyncStatus";

export const HrisConnectedSettings = () => {
  const { t } = useTranslation();
  const { showBanner, setShowBanner } = useConnectHrisStore();
  const { data } = useGetHrisSettingsQuery();

  useEffect(() => {
    if (showBanner && !data?.isScheduledOrRunning) {
      setShowBanner(false);
    }
  }, [data?.isScheduledOrRunning]);

  return (
    <VStack gap={4}>
      {showBanner && (
        <Banner 
          title={t("hrisSettings.connect.success.title")}
          description={t("hrisSettings.connect.success.description")}
          onClose={() => setShowBanner(false)}
        />
      )}
      <Card>
        <VStack gap={4}>
          <ConnectedHrisIntegration />
          <DisconnectHrisButton />
        </VStack>
      </Card>
      <Card>
        <VStack gap={4}>
          <CardHeader title={t("hrisSettings.activate.title")} />
          <Text>{t("hrisSettings.activate.subtitle")}</Text>
          <ul>
            <li>              
              <IconText icon={<SuccessIcon className="text-status-positive-icon" />}>
                {t("hrisSettings.activate.list.item1")}
              </IconText>
            </li>
            <li>
              <IconText icon={<SuccessIcon className="text-status-positive-icon" />}>
                {t("hrisSettings.activate.list.item2")}
              </IconText>
            </li>
            <li>   
              <IconText icon={<SuccessIcon className="text-status-positive-icon" />}>
                {t("hrisSettings.activate.list.item3")}
              </IconText>
            </li>
            <li>             
              <IconText icon={<RemoveIcon className="text-danger-icon-press" />}>
                {t("hrisSettings.activate.list.item4")}
              </IconText>
            </li>
            <li>
              <IconText icon={<RemoveIcon className="text-danger-icon-press" />}>
                {t("hrisSettings.activate.list.item5")}
              </IconText>
            </li>
          </ul>
          <HStack align="center" gap={6}>
            <StartHrisSyncButton />
            <HrisSyncStatus />
          </HStack>
          <HrisAutoAssignManagersAsCollaboratorsToggle />          
          <HrisActivateToggle />
        </VStack>
      </Card>
    </VStack>
  );
};