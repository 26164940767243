import { Cell, Column, Row, TableBody, TableHeader } from "react-stately";
import { DEFAULT_LIST_PAGE_SIZE } from "@/lib/utils/constants";
import { EmployeeCell } from "@/features/employees/components/EmployeeCell";
import { type GetUsersQueryParams, type UserDto } from "@/features/users/types/common";
import { RoleCell } from "@/features/roles/components/RoleCell";
import { type Selection } from "@react-types/shared";
import { Table, Text, usePaginationCountSummary, useTableSorting } from "@/components";
import { UserListActionsDropdownMenu } from "./UserListActionsDropdownMenu";
import { nameOf } from "@/utils/types";
import { useCurrentUser } from "@/lib/auth/AuthProvider";
import { useEffect, useMemo, useState } from "react";
import { useGetUsers } from "@/features/users/hooks/useUsers";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  onEdit: (employeeId: string) => void;
  setUsersChecked: (value: Array<UserDto>) => void;
  page: number;
  filters: Omit<GetUsersQueryParams, "pageSize" | "page">;
}

export const UserList = ({ onEdit, setUsersChecked, page, filters }: Props) => {
  const currentUser = useCurrentUser();
  const { t } = useTranslation();
  const [selectedKeys, setSelectedKeys] = useState<Selection>();

  const { tableSortProps, sortingFields: sortBy } = useTableSorting<UserDto>({
    initialSortDescriptor: {
      columns: ["firstName", "lastName"],
      direction: "Ascending"
    }
  });

  const { data, isLoading } = useGetUsers({ page, pageSize: DEFAULT_LIST_PAGE_SIZE, ...filters, sortBy });
  const { users, total } = useMemo(() => ({
    users: data?.items ?? [],
    total: data?.total ?? 0
  }), [data]);

  const deactivatedEmployeeIds = useMemo(() => users.filter(({ status }) => status === "Deactivated")
    .map(({ employeeId }) => employeeId),
  [users]);

  const summaryLine = usePaginationCountSummary({
    currentPage: page,
    pageSize: DEFAULT_LIST_PAGE_SIZE,
    totalItems: total,
    listItemName: t("settings.users.paginationItems")
  });

  const onSelectionChange = (keys: Selection) => {
    setSelectedKeys(keys);
  };

  useEffect(() => {
    if (!selectedKeys) {
      return;
    }
    
    if (selectedKeys !== "all") {
      setUsersChecked(users.filter(user => selectedKeys.has(user.employeeId)));
    } else {
      setUsersChecked(users.filter(({ employeeId }) => employeeId !== currentUser.employeeId));
    }
  }, [selectedKeys, users, currentUser]);

  return (
    <Table
      isLoading={isLoading}
      aria-label={t("users.heading.title")}
      emptyListProps={{
        title: t("users.table.empty.title"),
        description: t("users.table.empty.description"),
        showImage: true
      }}
      selectedKeys={selectedKeys}
      onSelectionChange={onSelectionChange}
      selectionMode="multiple"
      disabledKeys={[currentUser.employeeId, ...deactivatedEmployeeIds]}
      pagination={{
        totalItems: total,
        currentPage: page,
        pageSize: DEFAULT_LIST_PAGE_SIZE
      }}
      summary={summaryLine}
      {...tableSortProps}
    >
      <TableHeader>
        <Column
          key={`${nameOf<UserDto>("firstName")},${nameOf<UserDto>("lastName")}`}
          allowsSorting
        >
          {t("users.table.employee")}
        </Column>
        <Column key={nameOf<UserDto>("roleName")} allowsSorting>{t("users.table.role")}</Column>
        <Column key={nameOf<UserDto>("status")} allowsSorting>
          {t("users.table.status")}
        </Column>
        <Column>{t("users.table.actions")}</Column>
      </TableHeader>
      <TableBody>
        {users.map(user => {
          const { employeeId, status } = user;

          return (
            <Row key={employeeId}>
              <Cell>
                <EmployeeCell {...{ ...user, id: employeeId }} />
              </Cell>
              <Cell>
                <RoleCell {...user} />
              </Cell>
              <Cell>
                <Text size="xs">
                  {status === "Created" && t("users.status.created")}
                  {status === "Invited" && t("users.status.invited")}
                  {status === "Active" && t("users.status.active")}
                  {status === "Deactivated" && t("users.status.deactivated")}
                </Text>
              </Cell>
              <Cell>
                <UserListActionsDropdownMenu user={user} onEdit={onEdit} />
              </Cell>
            </Row>
          );
        })}
      </TableBody>
    </Table>
  );
};
