import { AnalyticEvents, AnalyticProperties } from "@/features/analytics/types/analyticEvents";
import { type NavigationType, useLocation, useNavigationType } from "react-router-dom";
import { type ReactElement, createContext, useContext, useEffect } from "react";
import { isDev, isProd } from "@/utils/environment";
import { useConfiguration } from "@/lib/core/ConfigurationProvider";
import { useCurrentUser } from "@/lib/auth/AuthProvider";
import mixpanel, { type Config, type Dict, type OverridedMixpanel } from "mixpanel-browser";

const MixpanelContext = createContext<OverridedMixpanel>(mixpanel);

const defaultOptions: Partial<Config> = {
  ignore_dnt: !isProd,
  debug: isDev,
  api_host: `${window.location.origin}/api/mixpanel`
};

interface Props {
  children: ReactElement;
  options?: Partial<Config>;
}

export const MixpanelProvider = ({ children, options }: Props) => {
  const { mixpanelProjectToken } = useConfiguration();
  const { id, email, firstName, lastName, fullName, roleName, permissionLevel, isCollaborator, tenant } = useCurrentUser();
  const location = useLocation();
  const navigationType: NavigationType = useNavigationType();

  const onLoaded = () => {
    mixpanel.identify(id);

    mixpanel.people.set({
      $email: email,
      $first_name: firstName,
      $last_name: lastName,
      $name: fullName
    });
      
    mixpanel.register({
      "Role Name": roleName,
      "Permission Level": permissionLevel,
      "Is Collaborator": isCollaborator ?? false,        
      "Tenant Id": tenant.id,
      "Company Name": tenant.name,
      "Industry": tenant.industry,
      "Subscription": tenant.hasSubscription 
        ? "paid" 
        : "freemium"
    });
  };

  const config = Object.assign({
    loaded: onLoaded
  }, defaultOptions, options);

  useEffect(() => {
    mixpanelProjectToken && mixpanel.init(mixpanelProjectToken, config);
  }, []);

  useEffect(() => {
    const pushOrPop = navigationType === "POP" || navigationType === "PUSH";
    
    if (mixpanelProjectToken && pushOrPop) {
      const properties: Dict = {};
      properties[AnalyticProperties.route] = `${location.pathname}${location.search}`;
      mixpanel.track(AnalyticEvents.pageViewed, properties);
    }
  }, [location.pathname, location.search, navigationType]);

  return (
    <MixpanelContext.Provider value={mixpanel}>
      {children}
    </MixpanelContext.Provider>
  );
};

export const useMixpanelContext = () => useContext(MixpanelContext);