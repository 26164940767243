import { useMarketContentTranslation, useTranslation } from "@/hooks/useTranslation";

interface Props {
  skillName: string;
  disabled?: boolean;
}

export const SearchSkillResultItem = ({ skillName, disabled }: Props) => {
  const { t } = useTranslation();
  const { translateMarketContent } = useMarketContentTranslation();

  return (
    <>
      {disabled
        ? t("setup.skills.added", { name: translateMarketContent(skillName) })
        : translateMarketContent(skillName)}
    </>
  );
};