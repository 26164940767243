import { Routes } from "@/types";
import { generatePath } from "react-router-dom";

export const useDirectoryRoutes = () => {
  // Seek People
  const { SeekPeopleTab, SeekPerson, SeekPeopleAdd, SeekPeopleEdit, SeekPeopleEditSkills, SeekPeopleEditSkillsWithEmployeeId } = Routes;

  // Seek Roles
  const { SeekRolesTab, SeekRole, SeekRoleEdit, SeekRoleAddFromMarketId } = Routes;

  return {
    people: SeekPeopleTab,
    person: (employeeId?: string) => generatePath(SeekPerson, { employeeId: employeeId ?? null }),
    addPerson: SeekPeopleAdd,
    editPerson: (employeeId: string) => generatePath(SeekPeopleEdit, { employeeId }),
    editPersonSkills: generatePath(SeekPeopleEditSkills),
    editPersonSkillsWithEmployeeId: (employeeId: string) => generatePath(SeekPeopleEditSkillsWithEmployeeId, { employeeId }),

    roles: SeekRolesTab,
    role: (roleId?: string) => generatePath(SeekRole, { roleId: roleId ?? null }),
    addRole: (marketId?: string) => generatePath(SeekRoleAddFromMarketId, { marketId: marketId ?? null }),
    editRole: (roleId: string) => generatePath(SeekRoleEdit, { roleId })
  };
};