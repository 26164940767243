import { DefaultMenuItem, DropdownMenuButton } from "@/components";
import { type Key } from "react";
import { Section } from "react-stately";
import { getIsCrowdinToolActive } from "@/lib/i18n/tools/CrowdinInContextTool.tools";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import { useTranslation } from "@/hooks/useTranslation";

enum Actions {
  ENGLISH = "english",
  FRENCH = "french",
  IN_CONTEXT = "in-context"
}

export const LanguageToggle = () => {
  const { i18n } = useTranslation();
  const { hasFeature } = useFeatures();

  if (!hasFeature("Features.CrowdinInContextTool") || !getIsCrowdinToolActive()) {
    return null;
  }

  const getLanguage = (language: string) => {
    switch (language) {
      case "en": return "English";
      case "fr": return "French";
      case "bal": return "In Context";
      default: return "English";
    }
  };

  const actions = [
    DefaultMenuItem(Actions.ENGLISH, getLanguage("en")),
    DefaultMenuItem(Actions.FRENCH, getLanguage("fr")),
    DefaultMenuItem(Actions.IN_CONTEXT, getLanguage("bal"))
  ];

  const onAction = async (key: Key) => {
    switch (key) {
      case Actions.ENGLISH: i18n.changeLanguage("en"); break;
      case Actions.FRENCH: i18n.changeLanguage("fr"); break;
      case Actions.IN_CONTEXT: i18n.changeLanguage("bal"); break;
    }
  };

  return (
    <DropdownMenuButton
      onAction={onAction}
      buttonText={getLanguage(i18n.language)}
    >
      <Section>
        {actions}
      </Section>
    </DropdownMenuButton>
  );
};