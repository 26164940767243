import { HStack, Text } from "@/components";
import { type ReactElement } from "react";
import { type TextProps } from "@/components/text/Text";

interface Props extends TextProps {
  icon: ReactElement;
}

export const IconText = ({ icon, children, ...props }: Props) => (
  <Text {...props}>
    <HStack align="center" gap={1.5}>
      {icon}
      <>{children}</>
    </HStack>
  </Text>
);