import { type CalendarDate } from "@internationalized/date";
import { type CalendarState } from "react-stately";
import { type ChangeEvent } from "react";
import { useDateFormatter } from "react-aria";

import styles from "./Calendar.module.css";

interface YearListItem {
  value: CalendarDate;
  formatted: string;
}

interface Props {
  state: CalendarState;
}

const YEAR_LOOK_BACK = 30;

export const YearDropdown = ({ state }: Props) => {
  const { timeZone, focusedDate, setFocusedDate } = state;
  const years: YearListItem[] = [];
  const formatter = useDateFormatter({
    year: "numeric",
    timeZone
  });

  // Format ${YEAR_LOOK_BACK} years on each side of the current year according
  // to the current locale and calendar system.
  for (let i = -1 * YEAR_LOOK_BACK; i <= YEAR_LOOK_BACK; i++) {
    const date = focusedDate.add({ years: i });
    years.push({
      value: date,
      formatted: formatter.format(date.toDate(timeZone))
    });
  }

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const index = Number(e.target.value);
    const date = years[index].value;
    setFocusedDate(date);
  };

  return (
    <select
      aria-label="Year"
      onChange={onChange}
      value={YEAR_LOOK_BACK}
      className={styles.calendarNavDropdown}
    >
      {years.map((year, i) => (
        <option key={year.formatted} value={i}>
          {year.formatted}
        </option>
      ))}
    </select>
  );
};