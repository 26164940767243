import { type SkillMentors } from "@/features/skill/types/skillTypes";
import { type SkillRating } from "@/features/skills/types/Skill";
import { type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { skillQueryKeys } from "@/features/skill/types/skillQueryKeys";
import api from "@/lib/http/api";

export const getSkillMentors = async (skillName: string, targetRating: SkillRating) => {
  const { data } = await api.get<SkillMentors>("/skills/mentor-recommendations", { params: { skillName, targetRating } });

  return data;
};

export const useGetSkillMentors = (skillName: string, targetRating: SkillRating, options?: UseQueryOptions<SkillMentors, unknown, SkillMentors, ReturnType<typeof skillQueryKeys.mentors>>) => 
  useQuery({
    queryKey: skillQueryKeys.mentors(skillName, targetRating), 
    queryFn: () => getSkillMentors(skillName, targetRating), 
    ...options
  });
 