import { usePremiumBadge } from "@/features/billing/hooks/usePremiumBadge";
import { type Feature } from "@/lib/features/types/feature";
import { LockIcon } from "@hopper-ui/icons";
import { IconButton, Tooltip, TooltipTrigger, type IconButtonProps } from "@workleap/orbiter-ui";


interface Props {
  feature?: Feature;
  size?: IconButtonProps["size"];
}

export const PremiumBadge = (props: Props) => {
  if (!props.feature) {
    return null;
  }

  return <InnerPremiumBadge {...props} />;
};

const InnerPremiumBadge = ({ feature, size = "xs" }: Props) => {
  const { message, cursor, onClick, onMouseOver } = usePremiumBadge(feature!);
  
  return (
    <TooltipTrigger>
      <IconButton 
        variant="upsell"
        aria-label={message}
        onClick={onClick}
        onMouseOver={onMouseOver}
        size={size}
        borderRadius="circle"
        cursor={cursor}
      >
        <LockIcon />
      </IconButton>
      <Tooltip>{message}</Tooltip>
    </TooltipTrigger>
  );
};