import { ConfirmationModal } from "@/components";
import { useTranslation } from "@/hooks/useTranslation";
import { type OverlayTriggerState } from "react-stately";

interface Props {
  overlayState: OverlayTriggerState;
  onClickConfirm: () => void;
}

export const SkillFlyoutFormConfirmationModal = ({ overlayState, onClickConfirm }: Props) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      overlayState={overlayState}
      title={t("common.unsavedChanges.title")}
      onClickCancel={overlayState.close}
      onClickConfirm={onClickConfirm}
      confirmButtonContent={t("common.unsavedChanges.leave")}
      cancelButtonContent={t("common.unsavedChanges.keepEditing")}
    >
      {t("common.unsavedChanges.description")}
    </ConfirmationModal>
  );
};