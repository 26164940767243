import { HStack, Text, useProgressiveLoading } from "@/components";
import { useGetAtsIntegrations } from "@/features/ats/hooks/useGetAtsIntegrations";
import { useGetAtsSettings } from "@/features/ats/hooks/useGetAtsSettings";
import { useTranslation } from "@/hooks/useTranslation";
import Skeleton from "react-loading-skeleton";

const IMAGE_SIZE = 28;

export const AtsConnectedIntegration = () => {
  const { t } = useTranslation();
  const { data, isLoading: isIntegrationsLoading } = useGetAtsIntegrations();
  const { data: settings, isLoading: isSettingsLoading } = useGetAtsSettings();
  const { showLoading } = useProgressiveLoading({
    isLoading: isIntegrationsLoading || isSettingsLoading
  });

  if (showLoading) {
    return (
      <HStack gap={2} align="center">
        <Skeleton height={IMAGE_SIZE} width={IMAGE_SIZE} circle />
        <Skeleton height="24" width="200" />
      </HStack>
    );
  }

  if (!data?.integrations || !settings) {
    return null;
  }

  const currentIntegration = data.integrations.find(i => i.slug === settings.integrationId);

  if (!currentIntegration) {
    return null;
  }

  const { name, squareImage } = currentIntegration;

  return (
    <HStack gap={2} align="center">
      <img src={squareImage} alt={name} width={IMAGE_SIZE} height={IMAGE_SIZE} />
      <Text>{t("ats.connectedTo", { integration: name })}</Text>
    </HStack>
  );
};