import { type SkillFlyoutMode, type SkillFlyoutRating } from "@/features/skill/types/skillTypes";
import { useAuth } from "@/lib/auth/AuthProvider";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useOverlayTriggerState, type OverlayTriggerState } from "react-stately";

const SKILL_NAME_PARAM = "skillName";
const SKILL_RATING_PARAM = "skillRating";
const SKILL_MODE_PARAM = "mode";

export const useSkillFlyoutState = (overlayState: OverlayTriggerState) => {
  const confirmationOverlayState = useOverlayTriggerState({});
  const deleteOverlayState = useOverlayTriggerState({});
  const [isDirty, setIsDirty] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentUserHasPermission } = useAuth();
  
  const skillName = searchParams.get(SKILL_NAME_PARAM);
  const skillRating = searchParams.get(SKILL_RATING_PARAM) as SkillFlyoutRating;
  const skillFlyoutMode = searchParams.get(SKILL_MODE_PARAM) as SkillFlyoutMode;

  const openFlyout = (name: string, rating: SkillFlyoutRating, edit?: boolean) => {
    searchParams.set(SKILL_NAME_PARAM, name);
    searchParams.set(SKILL_RATING_PARAM, rating);

    if (edit && currentUserHasPermission("Permissions.Skills.UpdateSkill")) {
      searchParams.set(SKILL_MODE_PARAM, "edit");
    } else {
      searchParams.delete(SKILL_MODE_PARAM);
    }

    setSearchParams(searchParams);
    overlayState.open();
  };

  const closeFlyout = () => {
    if (isDirty && !!skillFlyoutMode) {
      confirmationOverlayState.open();

      return;
    }

    searchParams.delete(SKILL_NAME_PARAM);
    searchParams.delete(SKILL_RATING_PARAM);
    searchParams.delete(SKILL_MODE_PARAM);
    setSearchParams(searchParams);
    overlayState.close();
  };

  const forceCloseFlyout = () => {
    setIsDirty(false);
    searchParams.delete(SKILL_NAME_PARAM);
    searchParams.delete(SKILL_RATING_PARAM);
    searchParams.delete(SKILL_MODE_PARAM);
    setSearchParams(searchParams);
    overlayState.close();
  };

  const changeRating = (rating: SkillFlyoutRating) => {
    searchParams.set(SKILL_RATING_PARAM, rating);
    setSearchParams(searchParams, { replace: true });
  };

  const openAddFlyout = () => {
    if (!currentUserHasPermission("Permissions.Skills.CreateSkill")) {
      return;
    }

    searchParams.set(SKILL_MODE_PARAM, "add");
    setSearchParams(searchParams);
    overlayState.open();
  };

  return {
    confirmationOverlayState,
    deleteOverlayState,
    openFlyout,
    openAddFlyout,
    closeFlyout,
    forceCloseFlyout,
    changeRating,
    setIsDirty,
    overlayState,
    skillName,
    skillRating,
    skillFlyoutMode
  };
};