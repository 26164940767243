// eslint-disable-next-line no-shadow
export enum Routes {
  "Home" = "/",

  "People" = "/people",
  "PeopleAdd" = "/people/add",
  "PeopleEdit" = "/people/edit/:employeeId?",
  "PeopleDetail" = "/people/:employeeId?",
  "PeopleEditSkills" = "/people/edit/skills",
  "PeopleEditSkillsWithEmployeeId" = "/people/edit/skills/:employeeId?",
  
  "Roles" = "/roles",
  "CreateRoleFromMarketId" = "/roles/add/:marketId?",
  "EditRolePosition" = "/roles/edit-position/:positionId",
  "CreateRolePosition" = "/roles/new-position",
  "EditRole" = "/roles/edit/:roleId",
  "ViewRoleDetails" = "/roles/:roleId?",

  "SkillsCustomDescriptions" = "/settings/skills/custom-descriptions",
  "AddSkillCustomDescriptions" = "/settings/skills/custom-descriptions/add",
  "EditSkillCustomDescriptions" = "/settings/skills/custom-descriptions/edit/:marketId",
  
  "CustomSkills" = "/settings/skills/custom-skills",
  "AddCustomSkill" = "/settings/skills/custom-skills/add",
  "EditCustomSkill" = "/settings/skills/custom-skills/edit/:id",

  "Reports" = "/reports",
  "ReportsSkillsInventory" = "/reports/skills-inventory",
  
  "Settings" = "/settings",
  "SettingsRoles" = "/settings/roles",
  "SettingsBilling" = "/settings/billing",
  "SettingsPermissions" = "/settings/permissions",
  "SettingsAts" = "/settings/ats",
  "SettingsHris" = "/settings/hris",
  "SettingsTraining" = "/settings/training",

  "AddTraining" = "/settings/training/add",
  "EditTraining" = "/settings/training/edit/:id",

  "Profile" = "/account/profile",
  
  "Setup" = "/setup",

  "Grow" = "/grow",
  "GrowView" = "/grow/view/:id",
  "GrowPlan" = "/grow/:id",
  "GrowPlanEdit" = "/grow/:id/edit",
  "GrowPlanStepType" = "/grow/:id/steps/type",
  "GrowPlanStepTimeline" = "/grow/:id/steps/timeline",
  "GrowPlanStepNextRole" = "/grow/:id/steps/next-role",
  "GrowPlanStepUpdateSkills" = "/grow/:id/steps/update-skills",
  "GrowPlanStepSkillGap" = "/grow/:id/steps/skill-gap",

  "Seek" = "/seek",
  
  "SeekPeopleTab" = "/seek?tab=People",
  "SeekRolesTab" = "/seek?tab=Roles",
  "SeekOpeningsTab" = "/seek?tab=Openings",
  
  "SeekPerson" = "/seek/people/:employeeId?",
  "SeekPeopleAdd" = "/seek/people/add",
  "SeekPeopleEdit" = "/seek/people/edit/:employeeId?",
  "SeekPeopleEditSkills" = "/seek/people/edit/skills",
  "SeekPeopleEditSkillsWithEmployeeId" = "/seek/people/edit/skills/:employeeId?",

  "SeekRole" = "/seek/roles/:roleId?",
  "SeekRoleAdd" = "/seek/roles/add",
  "SeekRoleEdit" = "/seek/roles/edit/:roleId",
  "SeekRoleAddFromMarketId" = "/seek/roles/add/:marketId?",

  "SeekSetup" = "/seek/setup",
  "SeekSetupUpload" = "/seek/setup/upload",
  "SeekSetupUploadStatus" = "/seek/setup/upload/status/:jobId",
  "SeekSetupUploadResumes" = "/seek/setup/upload/resumes",
  "SeekSetupUploadJobs" = "/seek/setup/upload/jobs",
  "SeekSetupSync" = "/seek/setup/sync",
  
  "SetupTenant" = "/setup-tenant",
  "SetupTenantSelectEmployee" = "/setup-tenant/steps/select-employee",
  "SetupTenantSelectNextRole" = "/setup-tenant/steps/select-next-role",
  "SetupTenantCustomizePlan" = "/setup-tenant/steps/customize-plan",

  "SetupUser" = "/setup-user",
  "SetupUserDetails" = "/setup-user/details",
  "SetupUserEmployeeSkills" = "/setup-user/employee-skills",
  "SetupUserRateSkills" = "/setup-user/rate-skills",
  "SetupUserComplete" = "/setup-user/complete",
  
  "Activation" = "/activation",

  "Error" = "/error"
}
