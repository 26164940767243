import { type GetDepartmentsDto, type GetDepartmentsQueryParams } from "@/features/departments/types/common";
import { type QueryFunctionContext, type UseQueryOptions, keepPreviousData, useQuery } from "@tanstack/react-query";
import { departmentQueryKeys } from "./departmentQueryKeys";
import api from "@/lib/http/api";

const getDepartments = async ({ queryKey: [, params] }: QueryFunctionContext<ReturnType<typeof departmentQueryKeys["list"]>>) => {
  const { data } = await api.get("/departments", {
    params
  });

  return data;
};

export const useGetDepartments = (
  config: GetDepartmentsQueryParams,
  options?: UseQueryOptions<GetDepartmentsDto, unknown, GetDepartmentsDto, ReturnType<typeof departmentQueryKeys["list"]>>
) => (
  useQuery({
    queryKey: departmentQueryKeys.list(config), 
    queryFn: getDepartments,
    placeholderData: keepPreviousData,
    ...options
  })
);