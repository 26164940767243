import { type CreateTrainingCommand } from "@/features/training/types/common";
import { Routes } from "@/types";
import { toast } from "react-toastify";
import { trainingQueryKeys } from "@/features/training/types/trainingQueryKeys";
import { translate } from "@/lib/i18n";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import api from "@/lib/http/api";

export const useCreateTrainingMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutationWithValidationResult({
    mutationFn: async (payload: CreateTrainingCommand) => {
      const response = await api.post<CreateTrainingCommand>("/trainings", payload);

      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: trainingQueryKeys.all });
      toast.success(translate("common.messages.changesSaved"));
      navigate(Routes.SettingsTraining);
    }
  });
};