import { type operations } from "@/features/types/client";


export type SearchRequest = NonNullable<Required<operations["Search_SearchDepartments"]["parameters"]>>["query"];
export type SearchRoleSkillsRequest = NonNullable<Required<operations["Search_SearchRolesSkills"]["parameters"]>>["query"];

export interface AutocompleteSelection {
  key: string;
  value: string;
}

export interface SearchResultItem<T = string> {
  id: T;
  name: string;
}

export interface SearchResponse<T = string> {
  results: SearchResultItem<T>[];
}

const makeSearchRequestKeys = ({ search, maxResults = 10 }: SearchRequest) =>
  [search, maxResults];


export const searchQueryKeys = {
  all: [{ scope: "search" }] as const,
  searchRoleSkills: [{ entity: "roleSkills" }] as const,
  searchAll: (params: SearchRequest) => [{
    ...searchQueryKeys.all[0],
    entity: "searchAll"
  }, ...makeSearchRequestKeys(params)] as const
};
