import { Text } from "@/components";
import { type TextColor } from "@/components/text/Text";
import classNames from "classnames";
import { type ComponentProps } from "react";

interface Props {
  number: number;
  size?: ComponentProps<typeof Text>["size"];
  className?: string;
  textColor?: TextColor;
}

export const Badge = ({ number, className, size = "sm", textColor = "primary-text-strong" }: Props) => {
  const classes = classNames(className, "flex items-center justify-center bg-danger-icon rounded-full",
    {
      "h-5 w-5": size === "xs",
      "h-6 w-6": size === "sm",
      "h-8 w-8": size === "md"
    });

  return (
    <div className={classes}>
      <Text size={size} color={textColor} className="leading-none">{number}</Text>
    </div>
  );
};