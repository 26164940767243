import * as React from "react";

const reactLazyRefreshKey = "retry-lazy-refreshed";

// named imports for React.lazy: https://github.com/facebook/react/issues/14603#issuecomment-726551598
export const lazyImport = <
  T extends React.ComponentType<unknown>,
  // eslint-disable-next-line no-use-before-define
  I extends { [K2 in K]: T },
  K extends keyof I
>(factory: () => Promise<I>, name: K): I => Object.create({
  [name]: React.lazy<T>(() => new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(reactLazyRefreshKey) || "false"
    );

    factory()
      .then(module => {
        window.sessionStorage.setItem(reactLazyRefreshKey, "false");
        resolve({ default: module[name] });
      })
      .catch(error => {
        // this tries to catch the error when the chunk fails to load
        // and then refreshes the page to try again (only once)
        // usually chunk load errors occur when the user has an old version of the app
        // and the chunk hash has changed since then (e.g. after a new deploy)
        console.error(error);
        
        if (!hasRefreshed && error.name === "ChunkLoadError") {
          // eslint-disable-next-line no-console
          console.log("Loading chunk failed, retrying...");

          window.sessionStorage.setItem(reactLazyRefreshKey, "true");
          
          return window.location.reload(); // refresh the page
        }

        reject(error);
      });
  }))
});


