import { Button, Card, Grid, HStack, Heading, Select, Switch, Text, TextField, TooltipWrapper, VStack, useProgressiveLoading } from "@/components";
import { useGetSettingsQuery } from "@/features/tenants/hooks/useGetSettingsQuery";
import { useUpdateSettingsMutation } from "@/features/tenants/hooks/useUpdateSettingsMutation";
import { type SettingsDto, type UpdateSettingsCommand } from "@/features/tenants/types/settings";
import { useLanguages } from "@/hooks/useLanguages";
import { useTranslation } from "@/hooks/useTranslation";
import { useAuth } from "@/lib/auth/AuthProvider";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import { InfoIcon } from "@hopper-ui/icons";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { SelectIndustryTenant } from "./SelectIndustryTenant";
import { TenantCardTitle } from "./TenantCardTitle";

interface FormProps {
  defaultValues: SettingsDto;
}

const Form = ({ defaultValues }: FormProps) => {
  const { hasFeature } = useFeatures();
  const { t } = useTranslation();
  const { languages, getLanguage } = useLanguages();
  const { handleSubmit, watch, setValue } = useForm<UpdateSettingsCommand>({
    defaultValues
  });
  const { mutate, validationErrors, isPending: isSaving } = useUpdateSettingsMutation();
  const { currentUserHasPermission } = useAuth();

  const submitHandler = async (settings: UpdateSettingsCommand) => {
    mutate(settings);
  };

  const hasAdvancedRecommendations = hasFeature("Features.SettingsAdvancedRecommendations");

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <VStack gap={8}>
        <HStack align="center" justify="between">
          <Heading size="xl">{t("accountSettings.heading.title")}</Heading>
          <Button type="submit" isDisabled={!currentUserHasPermission("Permissions.Settings.Write")} loading={isSaving}>
            {t("common.form.update")}
          </Button>
        </HStack>
        <Card>
          <TenantCardTitle title={t("accountSettings.form.generalTitle")} />
          <Grid cols={hasAdvancedRecommendations ? 2 : 1} gap={4}>
            <TextField
              label={t("accountSettings.form.companyNameLabel")}
              description={t("accountSettings.form.companyNameDesc")}
              isReadOnly={!currentUserHasPermission("Permissions.Settings.Write")}
              errorMessage={validationErrors?.field("TenantName")}
              value={watch("tenantName")}
              onChange={value => setValue("tenantName", value, { shouldDirty: true })}
            />
            {hasAdvancedRecommendations && (
              <SelectIndustryTenant 
                value={watch("industry")}
                onChange={industry => setValue("industry", industry)}
                errorMessage={validationErrors?.field("Industry")}
              />
            )}
          </Grid>
          {hasAdvancedRecommendations && (
            <Grid cols={2}>
              <TextField
                label={t("accountSettings.form.companyWebsite.label")}
                description={t("accountSettings.form.companyWebsite.description")}
                errorMessage={validationErrors?.field("CompanyWebsiteUrl")}
                value={watch("companyWebsiteUrl")}
                onChange={value => setValue("companyWebsiteUrl", value, { shouldDirty: true })}
              />
            </Grid>
          )}
        </Card>

        {hasAdvancedRecommendations && (
          <Card>
            <TenantCardTitle title={t("accountSettings.form.ai.title")} />
            <HStack align="center" justify="between">
              <HStack align="center" gap={4}>
                <Text>{t("accountSettings.form.ai.useAdvancedMatching")}</Text>
                <TooltipWrapper tooltip={t("accountSettings.form.ai.advancedMatchingTooltip")} anchorSelector="form-info-icon">
                  <InfoIcon className="text-neutral-text-weak" />
                </TooltipWrapper>
              </HStack>
              <Switch
                aria-label={t("skills.customDescriptions.generateSkillsBanner.ariaLabel")}
                isSelected={watch("useCompanyClustering")} 
                onChange={isSelected => setValue("useCompanyClustering", isSelected)}
              />
            </HStack>
          </Card>
        )}

        {hasFeature("Features.FrenchTranslation") && ( 
          <Card>
            <TenantCardTitle title={t("accountSettings.form.language.title")} />
            <Select
              label={t("accountSettings.form.language.application.label")}
              value={getLanguage(watch("language"))}
              items={languages}
              selectItem={item => setValue("language", item?.id)}
              errorMessage={validationErrors?.field("Language")}
            />
          </Card>
        )}
      </VStack>
    </form>
  );
};

export const SettingsForm = () => {
  const { hasFeature } = useFeatures();
  const { t } = useTranslation();
  const { data: settingsQueryData, isLoading } = useGetSettingsQuery();
  const { showLoading } = useProgressiveLoading({ isLoading });

  if (showLoading) {
    return (
      <VStack gap={8}>
        <HStack align="center" justify="between">
          <Heading size="xl">{t("accountSettings.heading.title")}</Heading>
          <Button type="submit" isDisabled>
            {t("common.form.update")}
          </Button>
        </HStack>
        <Card>
          <Skeleton height={26} width={80} className="mb-4" />
          <Skeleton height={20} width={120} className="mb-1" />
          <Skeleton height={50} />
        </Card>
        {hasFeature("Features.SettingsAdvancedRecommendations") && (
          <Card>
            <Skeleton height={26} width={80} className="mb-4" />
            <HStack justify="between">
              <Skeleton height={20} width={120} className="mb-1" />
              <Skeleton height={20} width={120} className="mb-1" />
            </HStack>
          </Card>
        )}
        {hasFeature("Features.FrenchTranslation") && (
          <Card>
            <Skeleton height={26} width={80} className="mb-4" />
            <Skeleton height={20} width={120} className="mb-1" />
            <Skeleton height={50} />
          </Card>
        )}
      </VStack>
    );
  }

  if (!settingsQueryData) { return null; }

  return (
    <Form defaultValues={settingsQueryData} />
  );
};
