import { AnalyticEvents, AnalyticProperties } from "@/features/analytics/types/analyticEvents";
import { type Dict } from "mixpanel-browser";
import { useMixpanel } from "@/features/analytics/hooks/useMixpanel";

export const useTrackAtsAction = () => {  
  const { trackEvent } = useMixpanel();

  const trackAtsAction = (action: string, value?: string) => {
    const properties: Dict = {};
    properties[AnalyticProperties.action] = action;
    properties[AnalyticProperties.value] = value;
    trackEvent(AnalyticEvents.atsAction, properties);
  };
  
  return {
    trackAtsAction
  };
};