import { ReactComponent as CaretDownIcon } from "@/components/table/assets/caretDown.svg";
import { ReactComponent as CaretUpSolidIcon } from "@/components/table/assets/caretUpSolid.svg";
import { type GridNode } from "@react-types/grid";
import { HStack } from "@/components";
import { type SortDescriptor } from "@react-types/shared";
import { type TableState } from "react-stately";
import { mergeProps, useFocusRing, useTableColumnHeader } from "react-aria";
import { useRef } from "react";
import classNames from "classnames";
import styles from "./TableColumnHeader.module.css";

interface TableColumnHeaderProps {
  column: GridNode<unknown>;
  state: TableState<unknown>;
  className?: string;
}

interface ColumSortingProps {
  column: GridNode<unknown>;
  sortDescriptor?: SortDescriptor;
}

const ColumSortingControls = ({ column, sortDescriptor }: ColumSortingProps) => {
  const isSorted = sortDescriptor?.column === column.key;
  const isAscending = isSorted && sortDescriptor?.direction === "ascending";

  if (!isSorted) {
    return <CaretDownIcon />;
  }

  if (isAscending) {
    return <CaretUpSolidIcon />;
  }

  return <CaretUpSolidIcon className="rotate-180" />;
};

export const TableColumnHeader = ({ column, state, ...rest }: TableColumnHeaderProps) => {
  const ref = useRef<HTMLTableCellElement>(null);
  const { columnHeaderProps } = useTableColumnHeader(
    { node: column },
    state,
    ref
  );
  const { focusProps } = useFocusRing();

  const styleProps: Record<string, string> = {};
  const { props: { width } } = column;
  if (width) {
    styleProps.width = width;
  }

  return (
    <th
      {...mergeProps(columnHeaderProps, focusProps, rest)}
      colSpan={column.colspan}
      ref={ref}
      className={classNames(styles.tableColHeader, {
        [styles.sortableTableColHeader]: column.props.allowsSorting
      })}
      style={{ ...styleProps }}
    >
      <HStack align="center" gap="space-inline-xs">
        <span>{column.rendered}</span>
        {column.props.allowsSorting &&
          (
            <ColumSortingControls column={column} sortDescriptor={state.sortDescriptor} />  
          )}
      </HStack>
    </th>
  );
};
