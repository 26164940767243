import { type ReactNode } from "react";
import classNames from "classnames";
import styles from "./PaginationLink.module.css";

interface Props {
  children: ReactNode;
  onClick: () => void;
  isActive?: boolean;
  id?: string;
}

export const PaginationLink = ({ children, onClick, isActive, id }: Props) => (
  <button
    id={id}
    onClick={onClick}
    className={classNames(styles.paginationLink, {
      [styles.paginationLinkActive]: isActive
    })}
  >
    {children}
  </button>
);