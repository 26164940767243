import { type QueryFunctionContext, type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { type SettingsDto } from "@/features/tenants/types/settings";
import { settingsQueryKeys } from "@/features/tenants/types/settingsQueryKeys";
import api from "@/lib/http/api";

const getSettings = async (_: QueryFunctionContext<ReturnType<typeof settingsQueryKeys["single"]>>) => {
  const { data } = await api.get<SettingsDto>("/settings");

  return data;
};

export const useGetSettingsQuery = (
  options?: Omit<UseQueryOptions<Promise<SettingsDto>, unknown, SettingsDto, ReturnType<typeof settingsQueryKeys["single"]>>, "queryKey">
) => (
  useQuery({
    queryKey: settingsQueryKeys.single(), 
    queryFn: getSettings, 
    ...options
  })
);