import { HStack, Heading, ModalDialog, SelectWithSearch, VStack } from "@/components";
import { getFullName } from "@/features/employees/utils";
import { HighlightedQuery } from "@/features/search/components/HighlightedQuery";
import { UserAvatar } from "@/features/users/components/UserAvatar";
import { useSearchEmployees } from "@/hooks/useSearchEmployees";
import { useTranslation } from "@/hooks/useTranslation";
import { useCurrentUser } from "@/lib/auth/AuthProvider";
import { Div, Grid } from "@workleap/orbiter-ui";
import { OverlayContainer } from "react-aria";
import { type OverlayTriggerState } from "react-stately";
import { useCollaborateContext } from "./Collaborate";
import { filterCollaboratorSearchResults } from "./collaborate.utils";
import { CollaborateListing } from "./CollaborateListing";
import { type CollaborateVariant } from "./types";

export interface Props {
  overlayState: OverlayTriggerState;
  variant: CollaborateVariant;
  allowCurrentUserAsCollaborator?: boolean;
}

export const CollaborateModal = ({ overlayState, variant, allowCurrentUserAsCollaborator }: Props) => {
  const { t } = useTranslation();
  const { data: searchEmployeesQueryData, setQuery, query, fetchStatus } = useSearchEmployees();
  const { onAddCollaboratorClick, activeCollaborators, activeCollaboratorIds } = useCollaborateContext();
  const { employeeId: currentUserId } = useCurrentUser();
  
  const filteredUserIds = allowCurrentUserAsCollaborator
    ? activeCollaboratorIds
    : [...activeCollaboratorIds, currentUserId];

  const results = filterCollaboratorSearchResults(filteredUserIds, searchEmployeesQueryData);

  if (!overlayState.isOpen) {
    return null;
  }

  const texts = variant === "collaborate"
    ? {
      title: t("collaborate.modal.title"),
      selectDescription: t("collaborate.add"),
      listTitle: t("collaborate.modal.listTitle")
    }
    : {
      title: t("mentor.modal.title"),
      selectDescription: t("mentor.add"),
      listTitle: t("mentor.modal.listTitle")
    };

  const { title, selectDescription, listTitle } = texts;

  return (
    <OverlayContainer>
      <ModalDialog
        title={title}
        isOpen
        onClose={overlayState.close}
        isDismissable
      >
        <VStack gap={6} className="w-[400px]">
          <SelectWithSearch
            onSelect={value => onAddCollaboratorClick(activeCollaborators, value)}
            description={selectDescription}
            results={results}
            setQuery={setQuery}
            fetchStatus={fetchStatus}
            keySelector={item => item.id}
            itemDisplay={item => (
              <HStack key={item.id} align="center" gap={3} className="py-1">
                <UserAvatar firstName={item.firstName} lastName={item.lastName} />
                <HighlightedQuery searchQuery={query} text={getFullName(item.firstName, item.lastName)} />
              </HStack>
            )}
            itemToText={({ firstName, lastName }) => getFullName(firstName, lastName)}
            emptyState={<NoResults />}
            clearOnSelect
          />
          <Grid gap="inline-sm">
            <Heading size="xl" className="text-lg">{listTitle}</Heading>
            <Div maxHeight="210px" overflowY="auto">
              <CollaborateListing variant={variant} />
            </Div>
          </Grid>
        </VStack>
      </ModalDialog>
    </OverlayContainer>
  );
};

const NoResults = () => {
  const { t } = useTranslation();

  return (
    <div className="px-4 py-2">{t("collaborate.empty.title")}</div>
  );
}; 