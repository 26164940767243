import { Div, Loader } from "@workleap/orbiter-ui";
import { useProgressiveLoading } from "./useProgressiveLoading";

export const DefaultLoader = () => {
  const { showLoading } = useProgressiveLoading({
    isLoading: true
  });

  if (showLoading) {
    return (
      <Div width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
        <Loader aria-label="Loading" />
      </Div>
    );
  }

  return null;
};