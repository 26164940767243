import { type GridNode } from "@react-types/grid";
import { type TableState } from "react-stately";
import { mergeProps, useFocusRing, useTableCell } from "react-aria";
import { useRef } from "react";

import styles from "./TableCell.module.css";

interface TableCellProps {
  cell: GridNode<unknown>;
  state: TableState<unknown>;
  className?: string;
  extraCellProps?: JSX.IntrinsicElements["td"];
}

export const TableCell = ({ cell, state, extraCellProps, ...rest }: TableCellProps) => {
  const ref = useRef<HTMLTableCellElement>(null);
  const { gridCellProps } = useTableCell({ node: cell }, state, ref);
  const { focusProps } = useFocusRing();

  return (
    <td
      {...mergeProps(gridCellProps, focusProps, rest)}
      ref={ref}
      className={styles.tableCell}
      {...extraCellProps}
    >
      {cell.rendered}
    </td>
  );
};
