import { TextField, type SelectItem } from "@/components";
import { SelectSkillType } from "@/features/skills/components/SelectSkillType";
import { type SkillCategory } from "@/features/skills/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { Flex, Heading } from "@workleap/orbiter-ui";

interface Props {
  errorMessage?: string;
  skillName: string;
  category: SkillCategory;
  onChangeName: (value: string) => void;
  onChangeCategory: (selected?: SelectItem<SkillCategory>) => void;
}

export const SkillFlyoutFormDetails = ({ skillName, errorMessage, category, onChangeName, onChangeCategory }: Props) => {
  const { t } = useTranslation();
  
  return (
    <Flex gap="12px" direction="column">
      <Heading size="md">{t("skills.form.section.details")}</Heading>
      <Flex gap="8px">
        <TextField
          label={t("skills.form.name.label")}
          description={t("skills.form.name.placeholder")}
          errorMessage={errorMessage}
          value={skillName}
          onChange={onChangeName}
        />
        <div className="w-[180px]">
          <SelectSkillType
            value={category}
            selectItem={onChangeCategory}
            label={t("skills.customSkills.form.skillCategory")}
            description={t("common.labelDesc.select")}
          />
        </div>
      </Flex>
    </Flex>
  );
};