import { useGetRoutes } from "@/routes";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

export const Router = () => {
  const routes = useGetRoutes();
  const router = createBrowserRouter(routes);
  
  return (
    <RouterProvider router={router} />
  );
};