import {
  Card,
  CardHeader,
  ClearableDatePicker,
  DefaultDisabledExplainer,
  Grid,
  HStack,
  Text,
  VStack
} from "@/components";
import { useGetEmployeeIsSynced } from "@/features/employees/hooks/useGetEmployeeIsSynced";
import { type EmployeeEditModel, type GetEditEmployeeModelDto } from "@/features/employees/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { useAuth } from "@/lib/auth/AuthProvider";
import { type ValidationErrorResult } from "@/models/error";
import { EyeVisibleIcon } from "@hopper-ui/icons";
import { type UseFormReturn } from "react-hook-form";
import { SelectEthnicity } from "./profile-information/SelectEthnicity";
import { SelectGender } from "./profile-information/SelectGender";

interface Props extends UseFormReturn<EmployeeEditModel> {
  commandErrors?: ValidationErrorResult;
  employee?: GetEditEmployeeModelDto;
}

export const ReportingAttributes = ({
  commandErrors,
  watch,
  setValue,
  employee
}: Props) => {
  const { t } = useTranslation();
  const { isHrisConnectedAndEmployeeIsSynced } = useGetEmployeeIsSynced(employee?.isSynced);
  const { currentUserHasPermission } = useAuth();

  return (
    <Card>
      <VStack gap={2}>
        <HStack align="center" justify="between">
          <HStack align="center">
            <EyeVisibleIcon className="text-neutral-text-weak" />
            <Text color="neutral-text-weak">{t("employee.edit.reportingAttributes.managersAndAdmins")}</Text>
          </HStack>
          <DefaultDisabledExplainer isHrisConnected={isHrisConnectedAndEmployeeIsSynced} />
        </HStack>
        <VStack gap={3}>
          <CardHeader title={t("employee.edit.reportingAttributes.title")} />
          <Text>{t("employee.edit.reportingAttributes.description")}</Text>
        </VStack>
        <Grid cols={2} rows="auto">
          <SelectGender
            value={watch("gender")}
            selectItem={gender => setValue("gender", gender?.id, { shouldDirty: true })}
            label={t("common.labels.gender.title")}
            description={t("common.labels.gender.title")}
            errorMessage={commandErrors?.field("Body.Gender")}
            isDisabled={isHrisConnectedAndEmployeeIsSynced}
          />
          <SelectEthnicity
            value={watch("ethnicity")}
            selectItem={ethnicity => setValue("ethnicity", ethnicity?.id, { shouldDirty: true })}
            description={t("common.labels.ethnicity.title")}
            errorMessage={commandErrors?.field("Body.Ethnicity")}
            label={t("common.labels.ethnicity.title")}
            isDisabled={isHrisConnectedAndEmployeeIsSynced}
          />
        </Grid>
        <Grid cols={2} rows="auto">
          <ClearableDatePicker
            value={watch("dateOfBirth")}
            label={t("common.labels.dateOfBirth")}
            errorMessage={commandErrors?.field("Body.DateOfBirth")}
            isDisabled={isHrisConnectedAndEmployeeIsSynced || !currentUserHasPermission("Permissions.People.EditAdvancedFields")}
            onChange={dateOfBirth => setValue("dateOfBirth", dateOfBirth, { shouldDirty: true })}
            onClear={() => setValue("dateOfBirth", undefined, { shouldDirty: true })}
            size="sm"
          />
          <ClearableDatePicker
            value={watch("startDate")}
            label={t("common.labels.hireDate")}
            errorMessage={commandErrors?.field("Body.StartDate")}
            isDisabled={isHrisConnectedAndEmployeeIsSynced || !currentUserHasPermission("Permissions.People.EditAdvancedFields")}
            onChange={startDate => setValue("startDate", startDate, { shouldDirty: true })}
            onClear={() => setValue("startDate", undefined, { shouldDirty: true })}
            size="sm"
          />
        </Grid>
      </VStack>
    </Card>
  );
};