import { Button } from "@/components";
import { useSkillFlyoutState } from "@/features/skill/hooks/useSkillFlyoutState";
import { useTranslation } from "@/hooks/useTranslation";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import { type OverlayTriggerState } from "react-stately";

interface Props {
  skillName: string;
  overlayState: OverlayTriggerState;
}

export const SkillFlyoutUpdateDescriptionsButton = ({ skillName, overlayState }: Props) => {
  const { t } = useTranslation();
  const { hasFeature, getBlockedFeature } = useFeatures();
  const { openFlyout } = useSkillFlyoutState(overlayState);

  const onClickEdit = () => {
    openFlyout(skillName, "Beginner", true);
  };

  return (
    <Button
      fluid
      variant="secondary"
      onClick={onClickEdit}
      isDisabled={!hasFeature("Features.CustomizeSkills")}
      blockedFeature={getBlockedFeature("Features.CustomizeSkills")}
    >
      {t("common.actions.edit")}
    </Button>
  );
};