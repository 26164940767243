import { type DuplicateRoleResult } from "@/features/role/types";
import { roleQueryKeys } from "@/features/roles/types/roleQueryKeys";
import { toast } from "react-toastify";
import { translate } from "@/lib/i18n";
import { useDirectoryRoutes } from "@/features/directory/hooks/useDirectoryRoutes";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import api from "@/lib/http/api";

export const useDuplicateRoleMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { editRole } = useDirectoryRoutes();

  return useMutationWithValidationResult({
    mutationFn: async (roleId: string) => {
      const response = await api.post<DuplicateRoleResult>(`/roles/${roleId}/duplicate`);

      return response.data.roleId;
    },
    onSuccess: async roleId => {
      await queryClient.invalidateQueries({ queryKey: roleQueryKeys.all });
      toast.success(translate("common.messages.changesSaved"));
      navigate(editRole(roleId));
    }
  });
};