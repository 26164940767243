
import { type Model } from "@/features/types/clientTypes";
import { translate } from "@/lib/i18n";

export type UserRoleType = Model<"Users.UserRoleType">;

interface UserRole {
  value: UserRoleType;
  text: string;
}

export const allUserRoles: UserRole[] = [{
  value: "Admin",
  text: translate("users.roles.admin")
}, {
  value: "User",
  text: translate("users.roles.user")
},
{
  value: "Collaborator",
  text: translate("users.roles.collaborator")
},
{
  value: "Executive",
  text: translate("users.roles.executive")
}];