import { Button, Card, ContentEditor, HStack, Heading, Text, VStack } from "@/components";
import { type CustomMarketSkillFormState } from "@/features/skills/types/customSkills";
import { getSkillLevelsMetadata } from "@/features/skills/types/SkillLevels";
import { type SkillRatingNames } from "@/features/skills/types/SkillRatings";
import { type RichContent } from "@/features/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { RefreshIcon } from "@hopper-ui/icons";

interface Props {
  descriptions: CustomMarketSkillFormState["ratingsDescriptions"];
  onDescriptionsChange: (descriptions: CustomMarketSkillFormState["ratingsDescriptions"]) => void;
  onDescriptionsRegenerate: () => void;
}

export const SkillRatingsDescriptionsEditor = ({ descriptions, onDescriptionsChange, onDescriptionsRegenerate }: Props) => {
  const { t } = useTranslation();
  const skillLevels = getSkillLevelsMetadata();

  return (
    <Card>
      <VStack gap={6}>
        <HStack justify="between">
          <VStack gap={1.5}>
            <Heading size="xl">{t("skills.customDescriptions.form.descriptions")}</Heading>
            <Text>{t("skills.customDescriptions.form.descriptionsSubtitle")}</Text>
          </VStack>
          <Button icon={<RefreshIcon />} variant="secondary" onClick={onDescriptionsRegenerate}>{t("skills.customDescriptions.form.generateNewDescriptions")}</Button>
        </HStack>
        <VStack gap={8}>
          {Object.entries(descriptions).map(([ratingName, description]) => (
            <ContentEditor
              key={ratingName}
              hasToolbar
              editorConfig={{
                editable: true,
                content: description as RichContent,
                onChange: content => onDescriptionsChange({ ...descriptions, [ratingName]: content })
              }}
              label={skillLevels[ratingName as SkillRatingNames].name}
            />
          ))}
        </VStack>
      </VStack>
    </Card>
  );
};
