import { type DateValue, getLocalTimeZone, parseDate } from "@internationalized/date";
import { format } from "date-fns";
import { type ReactElement, type ReactNode } from "react";
import { type AriaDatePickerProps } from "react-aria";

type OmittedFields = "minValue" | "maxValue" | "value" | "onChange";

export type DatePickerSize = "sm" | "md";

export interface DatePickerProps extends Omit<AriaDatePickerProps<DateValue>, OmittedFields> {
  label?: string | ReactNode;
  errorMessage?: string;
  errorStyle?: string;

  minValue?: Date;
  maxValue?: Date;
  value?: Date;

  children?: ReactNode;
  className?: string;
  size?: DatePickerSize;

  isRequired?: boolean;

  onChange: (selectedDate: Date) => void;
  disabledExplainer?: ReactElement;
}

const ARIA_ISO_DATE_FORMAT = "yyyy-MM-dd";

export const toAriaDatePickerProps = (baseProps: DatePickerProps) => ({
  ...baseProps,
  minValue: baseProps.minValue
    ? parseDate(format(baseProps.minValue, ARIA_ISO_DATE_FORMAT))
    : undefined,
  maxValue: baseProps.maxValue
    ? parseDate(format(baseProps.maxValue, ARIA_ISO_DATE_FORMAT))
    : undefined,
  value: baseProps.value
    ? parseDate(format(baseProps.value, ARIA_ISO_DATE_FORMAT))
    : undefined,
  onChange: (value?: DateValue | null) => {
    if (!value) {
      return;
    }

    baseProps.onChange(value.toDate(getLocalTimeZone()));
  }
});

