import { type CreateCustomerPortalSessionResult } from "@/features/billing/types/common";
import { useMutation } from "@tanstack/react-query";
import api from "@/lib/http/api";

export const useCreateCustomerPortalSessionMutation = () => useMutation<CreateCustomerPortalSessionResult, unknown, void, unknown>({
  mutationFn: async() => {
    const { data } = await api.post("/stripe/create-customer-portal-session");

    return data;
  },
  onSuccess: ({ sessionUrl }) => {
    window.location.href = sessionUrl;
  }
});