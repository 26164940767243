import { type GetTrainingsQueryDto, type GetTrainingsQueryParams } from "@/features/training/types/common";
import { type QueryFunctionContext, type UseQueryOptions, keepPreviousData, useQuery } from "@tanstack/react-query";
import { trainingQueryKeys } from "@/features/training/types/trainingQueryKeys";
import api from "@/lib/http/api";

const getTrainings = async ({ queryKey: [, params] }: QueryFunctionContext<ReturnType<typeof trainingQueryKeys["list"]>>) => {
  const { data } = await api.get("/trainings", {
    params
  });

  return data;
};

export const useGetTrainings = (
  config: GetTrainingsQueryParams,
  options?: UseQueryOptions<GetTrainingsQueryDto, unknown, GetTrainingsQueryDto, ReturnType<typeof trainingQueryKeys["list"]>>
) => (
  useQuery({
    queryKey: trainingQueryKeys.list(config), 
    queryFn: getTrainings,
    placeholderData: keepPreviousData,
    ...options
  })
);
