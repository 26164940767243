import { skillsQueryKeys } from "@/features/skills/types/skillsQueryKeys";
import { toast } from "react-toastify";
import { translate } from "@/lib/i18n";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import { useQueryClient } from "@tanstack/react-query";
import api from "@/lib/http/api";

export const useDeleteCustomMarketSkillMutation = (id: string) => {
  const queryClient = useQueryClient();

  return useMutationWithValidationResult<void>({
    mutationFn: async () => {
      await api.delete(`/skills/custom-market-skills/${id}`);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: skillsQueryKeys.all });

      toast.success(translate("skills.customDescriptions.deleteSuccessMessage"));
    }
  });
};