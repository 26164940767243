import { ReactComponent as OneStarLg } from "@/features/skills/assets/1-star-lg.svg";
import { ReactComponent as OneStar } from "@/features/skills/assets/1-star.svg";
import { ReactComponent as TwoStarLg } from "@/features/skills/assets/2-star-lg.svg";
import { ReactComponent as TwoStar } from "@/features/skills/assets/2-star.svg";
import { ReactComponent as ThreeStarLg } from "@/features/skills/assets/3-star-lg.svg";
import { ReactComponent as ThreeStar } from "@/features/skills/assets/3-star.svg";
import { ReactComponent as FourStarLg } from "@/features/skills/assets/4-star-lg.svg";
import { ReactComponent as FourStar } from "@/features/skills/assets/4-star.svg";
import { type Languages } from "@/hooks/useLanguages";
import { translate } from "@/lib/i18n";
import { type FunctionComponent, type SVGProps } from "react";
import { type SkillRatingNames } from "./SkillRatings";

interface Skill {
  name: string;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  IconLg: FunctionComponent<SVGProps<SVGSVGElement>>;
  rating: SkillRatingNames;
  key: SkillRatingNames;
}

export const getSkillLevelsMetadata = () : Record<SkillRatingNames, Skill> => ({
  "Beginner": {
    name: translate("setup.skills.levelBeginner"),
    Icon: OneStar,
    IconLg: OneStarLg,
    rating: "Beginner",
    key: "Beginner"
  },
  "Intermediate": {
    name: translate("setup.skills.levelIntermediate"),
    Icon: TwoStar,
    IconLg: TwoStarLg,
    rating: "Intermediate",
    key: "Intermediate"
  },
  "Advanced": {
    name: translate("setup.skills.levelAdvanced"),
    Icon: ThreeStar,
    IconLg: ThreeStarLg,
    rating: "Advanced",
    key: "Advanced"
  },
  "Expert": {
    name: translate("setup.skills.levelExpert"),
    Icon: FourStar,
    IconLg: FourStarLg,
    rating: "Expert",
    key: "Expert"
  }
});

export const getDefaultSkillRatingDescriptions = (language?: Languages) => ({
  Beginner: translate("skills.levels.beginner.description", { lng: language }),
  Intermediate: translate("skills.levels.intermediate.description", { lng: language }),
  Advanced: translate("skills.levels.advanced.description", { lng: language }),
  Expert: translate("skills.levels.expert.description", { lng: language })
} as const);