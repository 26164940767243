import { type GridNode } from "@react-types/grid";
import { type KeyboardEvent, type ReactNode, useRef } from "react";
import { type AriaTableProps, useTable } from "react-aria";
import {
  type TableStateProps,
  useTableState
} from "react-stately";
import { TableCell } from "./TableCell";
import { TableColumnHeader } from "./TableColumnHeader";
import { TableHeaderRow, TableRow, TableRowGroup } from "./TableRow";

import { EmptyList, type EmptyListProps, Pagination, usePagination } from "@/components";
import { useTranslation } from "@/hooks/useTranslation";
import classNames from "classnames";
import { isEmpty, times } from "lodash";
import Skeleton from "react-loading-skeleton";
import styles from "./Table.module.css";
import { TableCheckboxCell } from "./TableCheckboxCell";
import { TableFooter } from "./TableFooter";
import { TableSelectAllCell } from "./TableSelectAllCell";

interface TablePaginationProp {
  totalItems: number;
  pageSize?: number; 
  currentPage: number;
  onChangePage?: (pageNumber: number) => void;
}

interface TableProps extends TableStateProps<object>, AriaTableProps {
  emptyListProps?: EmptyListProps;
  pagination?: TablePaginationProp;
  summary?: ReactNode;
  isLoading?: boolean;
  numSkeletonRows?: number;
  getExtraCellProps?: <T = unknown>(cell: GridNode<T>) => JSX.IntrinsicElements["td"];
  className?: string;
  variant?: "default" | "border-less";
}

export const Table = (props: TableProps) => {
  const { t } = useTranslation();
  const { selectionMode, selectionBehavior, emptyListProps, getExtraCellProps } = props;
  const state = useTableState({
    ...props,
    showSelectionCheckboxes: selectionMode === "multiple" && selectionBehavior !== "replace"
  });
  const { summary, pagination, isLoading, numSkeletonRows = 10, className, variant } = props;
  
  const ref = useRef<HTMLTableElement>(null);
  const { collection } = state;
  const { gridProps } = useTable(props, state, ref);
  
  const firstHeaderRow = collection.headerRows[0];
  const firstHeaderRowChildNodes = [...collection.getChildren!(firstHeaderRow.key)];
  
  const tableRows = isLoading
    ? times(numSkeletonRows).map(i => ({
      ...firstHeaderRow,
      key: i,
      props: {},
      childNodes:
      firstHeaderRowChildNodes.map(column => ({ ...column, rendered: <Skeleton width="90%" height={24} /> }))
    }))
    : [...collection.getChildren!(collection.body.key)];

  const onKeyDownCapture = (event: KeyboardEvent<HTMLDivElement>) => {
    // When we capture a keydown event for the space key,
    // replace the key property with another value so that react-spectrum
    // does not trigger its special logic for the space key
    // (assuming we don't really that need, anyway).
    // The new value is a non-breakable space, which is similar to
    // the original space, just in case any other code relies on this event.
    if (event.nativeEvent.code === "Space") {
      event.key = "\u00a0";
    }
  };

  return (
    <div 
      className="relative overflow-x-auto"
      onKeyDownCapture={onKeyDownCapture}
    >
      <table {...gridProps}
        ref={ref}
        className={classNames(styles.table, {
          [styles.borderLess]: variant === "border-less"
        }, className)}
      >
        <TableRowGroup type="thead">
          {[...collection.headerRows].map(headerRow => (
            <TableHeaderRow key={headerRow.key} item={headerRow} state={state}>
              {[...headerRow.childNodes].map(column => (
                column.props.isSelectionCell ? 
                  <TableSelectAllCell key={column.key} column={column} state={state} />
                  : (
                    <TableColumnHeader key={column.key} column={column} state={state} />
                  )
              ))}
            </TableHeaderRow>
          ))}
        </TableRowGroup>
        <TableRowGroup type="tbody">
          {isEmpty(tableRows)
            ? (
              <TableRow key="empty" item={{ ...firstHeaderRowChildNodes[0], childNodes: [], props: {} }} state={state}>
                <td className="h-[500px] border-l border-r border-neutral-border-weak" colSpan={[...firstHeaderRow.childNodes].length}>
                  {emptyListProps
                    ? <EmptyList {...emptyListProps} />
                    : <EmptyList title={t("common.empty.title")} description={t("common.empty.description")} />}
                </td>
              </TableRow> 
            )
            : tableRows.map(row => (
              <TableRow key={row.key} item={row} state={state}>
                {[...row.childNodes].map(cell => (
                  cell.props.isSelectionCell
                    ? <TableCheckboxCell key={cell.key} cell={cell} state={state} />
                    : (
                      <TableCell
                        key={cell.key}
                        cell={cell}
                        state={state}
                        {...getExtraCellProps?.(cell)}
                      />
                    )
                ))}
              </TableRow>
            ))}
        </TableRowGroup>
      </table>
      <TableFooter className={classNames({ "!border-none !border-0": variant === "border-less" })}>
        {
          !isLoading && (
            <>
              {summary && (
                <div className={styles.tableSummary}>
                  {summary}
                </div>
              )}
              {pagination && (
                <div>
                  <TablePagination {...pagination} />
                </div>
              )}
            </>
          )
        }
      </TableFooter>
    </div>
  );
};

const TablePagination = (props: TablePaginationProp) => {
  const { pageSize, totalItems, currentPage, onChangePage } = props;

  const paginationProps = usePagination({
    pageSize: pageSize ?? 25,
    initialPage: currentPage,
    totalItems,
    onChangePage
  });

  return (
    <Pagination {...paginationProps} />
  );
};
