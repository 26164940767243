interface MenuItem {
  isDestructive?: boolean;
  isDisabled?: boolean;
}

export const getMenuItemProps = ({ isDestructive, isDisabled }: MenuItem) => ({
  paddingY: "10px",
  paddingX: "inset-md",
  color: isDestructive ? "danger-weak" : "inherit",
  disabled: isDisabled
});