import { Button, HStack, Heading, Text, VStack, useGetCurrentPageParam } from "@/components";
import { CustomSkillsList } from "@/features/skills/components/CustomSkillsList";
import { Helmet } from "react-helmet-async";
import { Routes } from "@/types/routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@/hooks/useTranslation";

export const ListCustomSkillsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const page = useGetCurrentPageParam();

  return (
    <>
      <Helmet>
        <title>{t("pages.settingsCustomSkills")}</title>
      </Helmet>
      <VStack gap={6}>
        <HStack align="center" justify="between">
          <VStack gap={1}>
            <Heading size="xl">
              {t("skills.customSkills.table.title")}
            </Heading>
            <Text>{t("skills.customSkills.table.subtitle")}</Text>
          </VStack>
          <Button onClick={() => navigate(Routes.AddCustomSkill)}>{t("common.form.createNew")}</Button>
        </HStack>
        <CustomSkillsList page={page} />
      </VStack>
    </>
  );
};