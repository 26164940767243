import { Button } from "@/components";
import { useAddOrUpdateEmployeeSkills } from "@/features/employees/hooks/useAddOrUpdateEmployeeSkills";
import { type GetEmployeeByIdQueryEmployeeDto } from "@/features/employees/types/common";
import { useGetSkillFlyoutSuspenseQuery } from "@/features/skill/hooks/useGetSkillFlyout";
import { useSkillTranslations } from "@/features/skill/hooks/useSkillTranslations";
import { type SkillFlyoutRating } from "@/features/skill/types/skillTypes";
import { useTranslation } from "@/hooks/useTranslation";
import { Authorize } from "@/lib/auth";
import { useCallback } from "react";

interface Props {
  skillName: string;
  employee: GetEmployeeByIdQueryEmployeeDto;
  activeRating: SkillFlyoutRating;
}

export const SkillFlyoutAddSkillButton = ({ employee, skillName, activeRating }: Props) => {
  const { data: { skillCategory, marketId } } = useGetSkillFlyoutSuspenseQuery(skillName);
  const { t } = useTranslation();
  const mutateEmployeeSkills = useAddOrUpdateEmployeeSkills(employee.id);
  const { getSkillRatingTranslation } = useSkillTranslations();

  const onClickAddSkill = useCallback(() => {
    mutateEmployeeSkills.mutate({
      skill: {
        name: skillName,
        category: skillCategory || "Unknown",
        rating: activeRating,
        marketId: marketId,
        source: { name: "user", id: employee.id }
      }
    });
  }, [skillCategory, skillName, activeRating, marketId]);

  const { skills } = employee;

  const employeeSkill = skills.find(({ name }) => name === skillName);
  const hasSkill = !!employeeSkill;

  const buttonText = hasSkill
    ? t("customSkills.actions.added", { rating: getSkillRatingTranslation(employeeSkill.rating) })
    : t("customSkills.actions.add", { rating: getSkillRatingTranslation(activeRating) });

  return (
    <Authorize permission="Permissions.UserProfile.Edit">
      <Button
        fluid
        isDisabled={hasSkill}
        onClick={onClickAddSkill}
        loading={mutateEmployeeSkills.isPending}
        dataTest="skill-flyout-add-skill"
      >
        {buttonText}
      </Button>
    </Authorize>
  );
};
