import { type GetEmployeeByIdQueryEmployeeDto } from "@/features/employees/types/common";
import { employeesQueryKeys } from "@/features/employees/types/employeesQueryKeys";
import api from "@/lib/http/api";
import { useQuery, useSuspenseQuery, type QueryFunctionContext, type UseQueryOptions } from "@tanstack/react-query";

const getEmployee = async ({ queryKey: [{ employeeId }] }: QueryFunctionContext<ReturnType<typeof employeesQueryKeys["single"]>>) => {
  const { data } = await api.get<GetEmployeeByIdQueryEmployeeDto>(`/employees/${employeeId}`);

  return data;
};

export const useGetEmployee = (
  employeeId: string,
  options?: Omit<UseQueryOptions<Promise<GetEmployeeByIdQueryEmployeeDto>, unknown, GetEmployeeByIdQueryEmployeeDto, ReturnType<typeof employeesQueryKeys["single"]>>, "queryKey">
) => (
  useQuery({
    queryKey: employeesQueryKeys.single(employeeId), 
    queryFn: getEmployee, 
    ...options
  })
);

export const useGetEmployeeQuery = (
  employeeId: string,
  options?: Omit<UseQueryOptions<Promise<GetEmployeeByIdQueryEmployeeDto>, unknown, GetEmployeeByIdQueryEmployeeDto, ReturnType<typeof employeesQueryKeys["single"]>>, "queryKey">
) => (
  useSuspenseQuery({
    queryKey: employeesQueryKeys.single(employeeId), 
    queryFn: getEmployee, 
    ...options
  })
);