import { type GeneralSettingsDto } from "@/features/tenants/types/settings";
import { type QueryFunctionContext, type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { settingsQueryKeys } from "@/features/tenants/types/settingsQueryKeys";
import api from "@/lib/http/api";

const getGeneralSetttings = async (_: QueryFunctionContext<ReturnType<typeof settingsQueryKeys["general"]>>) => {
  const { data } = await api.get<GeneralSettingsDto>("/settings/general");

  return data;
};

export const useGetGeneralSettings = (
  options?: UseQueryOptions<Promise<GeneralSettingsDto>, unknown, GeneralSettingsDto, ReturnType<typeof settingsQueryKeys["general"]>>
) => (
  useQuery({
    queryKey: settingsQueryKeys.general(), 
    queryFn: getGeneralSetttings, 
    ...options
  })
);