import classNames from "classnames";
import { isNil } from "lodash";
import { type ReactNode } from "react";

// TODO: TOKENS - Clean up unused and old colors later
export type TextColor = "primary-text"
| "neutral-text"
| "neutral-text-weak"
| "neutral-text-weakest"
| "tertiary-text"
| "success-icon-weak"
| "success-icon"
| "warning-icon-weak"
| "danger-icon"
| "danger-icon-hover"
| "primary-text-strong"
| "inherit"
| "status-neutral-text";

export interface TextProps {
  size?: "3xl" | "2xl" | "xl" | "lg" | "md" | "sm" | "xs" | "overline";
  color?: TextColor;
  children: ReactNode;
  as?: "span" | "p";
  className?: string;
  hoverable?: boolean;
  weight?: 400 | 410 | 505 | 580 | 590 | 680 | 690;
}

const DEFAULT_ELEMENT = "span";

export const Text = ({
  size = "sm",
  children,
  as: TextElem = DEFAULT_ELEMENT, 
  className, 
  color = "neutral-text",
  hoverable,
  weight
}: TextProps) => {
  const classnames = classNames(
    className,
    { 
      "hover:underline": hoverable,
      [`font-${weight}`]: !isNil(weight)
    },
    `text-${size} text-${color} block`);

  return (
    <TextElem className={classnames}>
      {children}
    </TextElem>
  );
};