import { Button, TooltipWrapper } from "@/components";
import { type MouseEventHandler } from "react";
import { useGetHrisSettingsQuery } from "@/features/hris/hooks/useGetHrisSettings";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const InviteUserButton = ({ onClick }: Props) => { 
  const { t } = useTranslation(); 
  const { data: settings } = useGetHrisSettingsQuery();

  const isDisabled = settings?.isConnected && settings?.isScheduledOrRunning;

  return (
    <TooltipWrapper 
      tooltip={isDisabled
        ? t("common.hris.running")
        : ""}
      anchorSelector="invite-user-button-tooltip"
    >
      <Button size="small" onClick={onClick} isDisabled={isDisabled}>{t("users.heading.invite")}</Button>
    </TooltipWrapper>
  );
};