import { DefaultDisabledExplainer, FieldFormHeader, FormFieldWrapper } from "@/components";
import { type InitialFilters } from "@/features/filter/types/common";
import { useAutoComplete, type AutoCompleteProps } from "@/hooks/useAutocomplete";
import { useTranslation } from "@/hooks/useTranslation";
import { type Feature } from "@/lib/features/types/feature";
import { SearchIcon } from "@hopper-ui/icons";
import { Autocomplete, Div, Grid, Item } from "@workleap/orbiter-ui";
import { useState, type ReactElement, type ReactNode, type SyntheticEvent } from "react";
import { FilterItemPill } from "./FilterItemPill";
import styles from "./FilterSelectors.module.css";

interface Props<T, TFilterValues = InitialFilters> {
  autoCompleteProps: Omit<AutoCompleteProps<T>, "onItemAdded">;
  label?: ReactNode;
  errorMessage?: string;
  field: keyof TFilterValues;
  blockedFeature?: Feature;
  itemDisplay?: (item: string) => ReactElement;
  onChange: (values: Partial<TFilterValues>) => void;
}

export function FilterSelector<T, TFilterValues = InitialFilters>({
  autoCompleteProps,
  label,
  errorMessage,
  field,
  blockedFeature,
  itemDisplay,
  onChange
}: Props<T, TFilterValues>) {
  const [value, setValue] = useState(null);
  const { t } = useTranslation();
  const { selectedItems, itemKeyGetter, itemNameGetter } = autoCompleteProps;
  const {
    filteredResults,
    setQuery,
    fetchStatus,
    onSelectionChange
  } = useAutoComplete({
    ...autoCompleteProps,
    onItemAdded: item => {
      onChange({
        [field]: [...selectedItems, item]
      } as Partial<TFilterValues>);
    }
  });

  const onRemoveItem = (itemName: string) => {
    const newItems = selectedItems.filter(item => itemName !== itemNameGetter(item));
    onChange({
      [field]: newItems
    } as Partial<TFilterValues>);
  };

  const handleSelectionChange = (_: SyntheticEvent, selection: { key: string }) => {
    onSelectionChange(selection.key);
    setValue(null);
  };
  
  return (
    <Grid gap="inline-sm">
      <Grid>
        <FormFieldWrapper errorMessage={errorMessage}>
          <FieldFormHeader 
            label={label}
            isDisabled={!!blockedFeature}
            disabledExplainer={<DefaultDisabledExplainer blockedFeature={blockedFeature} />}
          />
          <Autocomplete
            value={value}
            aria-label="searchEmployees"
            placeholder={t("common.filters.autocomplete.description")}
            onSearch={(_, searchTerm) => setQuery(searchTerm)}
            onSelectionChange={handleSelectionChange}
            zIndex={Number.MAX_SAFE_INTEGER}
            loading={fetchStatus === "fetching"}
            minCharacters={2}
            icon={<SearchIcon size="sm" />}
            disabled={!!blockedFeature}
            fluid
            size="sm"
          >
            {filteredResults?.map(item => (
              <Item key={item} value={item} cursor="pointer">
                <Div cursor="pointer">
                  {itemDisplay ? itemDisplay(item) : (<div key={item}>{item}</div>)}
                </Div>
              </Item>
            ))}
          </Autocomplete>
        </FormFieldWrapper>
      </Grid>
      {selectedItems && (
        <div className={styles.itemsListContainer}>
          {selectedItems.map(item => (
            <FilterItemPill
              key={itemKeyGetter(item)}
              label={itemNameGetter(item)}
              onRemove={onRemoveItem}
            />
          ))}
        </div>
      )}
    </Grid>
  );
}