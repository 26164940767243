import { FieldFormHeader, FormFieldWrapper } from "@/components";
import { EditorContent, type Editor as TipTapEditor } from "@tiptap/react";
import { Div } from "@workleap/orbiter-ui";
import { EditorToolbar } from "./EditorToolbar";
import styles from "./hooks/Editor.module.css";

interface ChildEditorProps {
  label?: string;
  errorMessage?: string;
  editor: TipTapEditor | null;
  isRequired?: boolean;
  className?: string;
  
}

export interface EditorProps extends ChildEditorProps {
  hasToolbar?: boolean;
}

const EditorWithToolbar = ({ errorMessage, editor, label, isRequired, className }: ChildEditorProps) => (
  <div className={className}>
    {editor?.options.editable && label && <FieldFormHeader isRequired={isRequired} label={label} />}
    <FormFieldWrapper errorMessage={errorMessage}>
      <Div border="neutral" borderRadius="rounded-md" className={styles.editorWrapper}>
        <EditorToolbar editor={editor} />
        <EditorContent editor={editor} />
      </Div>
    </FormFieldWrapper>
  </div>
);

const EditorWithoutToolbar = ({ errorMessage, editor, label, isRequired, className }: ChildEditorProps) => (
  <div className={className}>
    {editor?.options.editable && label && <FieldFormHeader isRequired={isRequired} label={label} />}
    <FormFieldWrapper errorMessage={errorMessage}>
      <EditorContent editor={editor} />
    </FormFieldWrapper>
  </div>
);

export const Editor = ({ hasToolbar, ...props }: EditorProps) => {
  if (hasToolbar) {
    return <EditorWithToolbar {...props} />;
  }
  
  return <EditorWithoutToolbar {...props} />;
};
