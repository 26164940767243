import { AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { AtsConnectModal } from "./AtsConnectModal";
import { Button } from "@/components";
import { NewTabIcon } from "@hopper-ui/icons";
import { useOverlayTriggerState } from "react-stately";
import { useTrackAtsAction } from "@/features/ats/hooks/useTrackAtsAction";
import { useTranslation } from "@/hooks/useTranslation";

export const AtsConnectButton = () => {
  const { t } = useTranslation();
  const overlayState = useOverlayTriggerState({});
  const { trackAtsAction } = useTrackAtsAction();
  
  const onConnectAtsClick = () => {
    trackAtsAction(AnalyticValues.actionConnect);
    overlayState.open();
  };

  return (
    <>
      <Button size="small" icon={<NewTabIcon size="sm" />} iconPosition="right" onClick={onConnectAtsClick}>
        {t("ats.connect.button")}
      </Button>
      <AtsConnectModal overlayState={overlayState} />
    </>
  );
};