import { SearchSimilarSkill } from "@/features/skills/components/SearchSimilarSkills";
import { type SimilarSkillDto } from "@/features/skills/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { Flex, Heading, Text } from "@workleap/orbiter-ui";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { SimilarSkill } from "./SimilarSkill";

interface Props {
  skillName: string;
  similarSkills: SimilarSkillDto[];
  onSelectSimilarSkill: (skill: SimilarSkillDto) => void;
  onRemoveSimilarSkill: (skill: SimilarSkillDto) => void;
}

export const SkillFlyoutFormSimilarSkills = ({ skillName, similarSkills, onSelectSimilarSkill, onRemoveSimilarSkill }: Props) => {
  const [disableSimilarSkillsSearch, setDisableSimilarSkillsSearch] = useState(isEmpty(skillName));
  const { t } = useTranslation();

  useEffect(() => {
    if (!isEmpty(skillName) && disableSimilarSkillsSearch) {
      setDisableSimilarSkillsSearch(false);
    }
  }, [disableSimilarSkillsSearch, skillName]);

  return (
    <Flex gap="inline-md" direction="column">
      <Flex gap="4px" direction="column">
        <Heading size="md">{t("skills.form.section.similarSkills")}</Heading>
        <Text size="sm" color="neutral-weak">{t("skills.form.section.similarSkillsDescription")}</Text>
      </Flex>

      <SearchSimilarSkill
        isDisabled={disableSimilarSkillsSearch}
        currentSkillName={skillName}
        selectedSkills={similarSkills}
        onSelect={onSelectSimilarSkill}
      />

      <Flex gap="8px" wrap="wrap">
        {similarSkills.map(skill => <SimilarSkill key={skill.name} skillName={skill.name} onRemoveSkill={() => onRemoveSimilarSkill(skill)} />)}
      </Flex>
    </Flex>
  );
};