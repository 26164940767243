import { OverlayContainer, type AriaOverlayProps } from "@react-aria/overlays";
import { type AriaDialogProps } from "@react-types/dialog";
import { ThemeProvider } from "@workleap/orbiter-ui";
import { motion } from "framer-motion";
import { useCallback, useRef, type PropsWithChildren } from "react";
import {
  FocusScope,
  useDialog,
  useModal,
  useOverlay,
  usePreventScroll
} from "react-aria";

const FLYOUT_WRAPPER_CLASS_NAMES =
  "fixed z-50 top-0 right-0 flex justify-end h-screen w-full max-w-[600px]";
const FLYOUT_CLASS_NAMES = "fixed z-50 inset-0 bg-flyout-overlay";
const FLYOUT_CONTENT_CLASS_NAMES =
  "bg-neutral-surface shadow-elevation-raised h-full relative";

interface Props extends AriaOverlayProps, AriaDialogProps, PropsWithChildren {}

export const Flyout = ({ children, ...props }: Props) => {
  const ref = useRef(null);

  // Hack that prevents the flyout from closing when clicking on an Orbiter modal or dropdown
  const shouldCloseOnInteractOutside = useCallback((element: Element) => {
    if (element.classList.contains("bg-flyout-overlay")) {
      return true;
    }

    return false;
  }, []);

  const { overlayProps, underlayProps } = useOverlay({ ...props, shouldCloseOnInteractOutside }, ref);

  usePreventScroll();
  const { modalProps } = useModal();
  const { dialogProps } = useDialog(props, ref);

  return (
    <OverlayContainer>
      <ThemeProvider colorScheme="light">
        <div className={FLYOUT_CLASS_NAMES}
          {...underlayProps}
          onClick={e => e.stopPropagation()}
        >
          <div className={FLYOUT_WRAPPER_CLASS_NAMES}>
            <FocusScope contain restoreFocus autoFocus>
              <motion.aside
                initial={{ x: "100%" }}
                animate={{ x: 0 }}
                exit={{ x: "100%" }}
                transition={{ duration: 0.2 }}
                className="w-full"
              >
                <div
                  {...overlayProps}
                  {...dialogProps}
                  {...modalProps}
                  ref={ref}
                  className={FLYOUT_CONTENT_CLASS_NAMES}
                >
                  {children}
                </div>
              </motion.aside>
            </FocusScope>
          </div>
        </div>
      </ThemeProvider>
    </OverlayContainer>
  );
};
