import { type CreateUserRequestPayload, type CreateUserResult } from "@/features/users/types/common";
import { userQueryKeys } from "@/features/users/types/userQueryKeys";
import { workleapQueryKeys } from "@/features/workleap/types/workleapQueryKeys";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import api from "@/lib/http/api";
import { useQueryClient } from "@tanstack/react-query";

export const useCreateUserMutation = (onSuccess?: (response: CreateUserResult) => void) => {
  const queryClient = useQueryClient();
  
  return useMutationWithValidationResult({
    mutationFn: async (payload: CreateUserRequestPayload) => {
      const response = await api.post<CreateUserResult>("/users", payload);

      return response.data;
    },
    onSuccess: async response => {
      await queryClient.invalidateQueries({ queryKey: userQueryKeys.all });
      await queryClient.invalidateQueries({ queryKey: workleapQueryKeys.all });

      onSuccess?.(response);
    }
  });
};