import { WorkleapActivationGuard } from "@/features/activation/components/WorkleapActivationGuard";
import AzureAppInsights from "@/lib/app-insights/AzureAppInsights";
import { AuthProvider } from "@/lib/auth";
import { ConfigurationProvider } from "@/lib/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Suspense } from "react";
import { OverlayProvider } from "react-aria";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { AppErrorBoundary } from "./AppErrorBoundary";
import { Router } from "./Router";

export const AppProvider = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false
      }
    }
  });

  return (
    <Suspense>
      <ErrorBoundary FallbackComponent={AppErrorBoundary}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ConfigurationProvider>
            <WorkleapActivationGuard>
              <AuthProvider>
                <AzureAppInsights>
                  <OverlayProvider>
                    <HelmetProvider>
                      <Router />
                    </HelmetProvider>
                  </OverlayProvider> 
                </AzureAppInsights>
              </AuthProvider>
            </WorkleapActivationGuard> 
          </ConfigurationProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </Suspense>
  );
};