import { Cell, Column, Row, TableBody, TableHeader } from "react-stately";
import { DEFAULT_LIST_PAGE_SIZE } from "@/lib/utils/constants";
import { type DeletableDepartmentDto, type DepartmentDto } from "@/features/departments/types/common";
import { DepartmentActionsDropdownMenu } from "./DepartmentActionsDropdownMenu";
import { Table, Text, usePaginationCountSummary, useTableSorting } from "@/components";
import { nameOf } from "@/utils/types";
import { useGetDepartments } from "@/features/departments/api/useGetDepartments";
import { useMemo } from "react";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  page: number;
}

export const DepartmentList = ({ page }: Props) => {
  const { t } = useTranslation();
  
  const { tableSortProps, sortingFields: sortBy } = useTableSorting<DeletableDepartmentDto>({
    initialSortDescriptor: {
      columns: ["name"],
      direction: "Ascending"
    }
  });

  const { data, isLoading } = useGetDepartments({ page, pageSize: DEFAULT_LIST_PAGE_SIZE, sortBy });

  const { departments, total } = useMemo(() => ({
    departments: data?.items ?? [],
    total: data?.total ?? 0
  }), [data]);

  const summaryLine = usePaginationCountSummary({
    currentPage: page,
    pageSize: DEFAULT_LIST_PAGE_SIZE,
    totalItems: total,
    listItemName: t("settings.departments.paginationItems")
  });

  return (
    <Table
      isLoading={isLoading}
      aria-label={t("settings.departments.title") ?? ""}
      emptyListProps={{
        title: t("departments.table.empty.title"),
        description: t("departments.table.empty.description"),
        showImage: true
      }}
      pagination={{
        totalItems: total,
        currentPage: page,
        pageSize: DEFAULT_LIST_PAGE_SIZE
      }}
      summary={summaryLine}
      {...tableSortProps}
    >
      <TableHeader>
        <Column
          key={nameOf<DepartmentDto>("name")}
          width="100%"
          allowsSorting
        >
          {t("departments.table.department")}
        </Column>
        <Column>
          {t("departments.table.actions")}
        </Column>
      </TableHeader>
      <TableBody>
        {departments.map(department => {
          const { id, name } = department;

          return (
            <Row key={id}>
              <Cell>
                <Text>{name}</Text>
              </Cell>
              <Cell>
                <DepartmentActionsDropdownMenu department={department} />
              </Cell>
            </Row>
          );
        })}
      </TableBody>
    </Table>
  );
};