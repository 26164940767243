import { AddCustomSkill } from "@/features/skills/components/AddCustomSkill";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "@/hooks/useTranslation";

export const AddCustomSkillPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("pages.settingsAddCustomSkill")}</title>
      </Helmet>
      <AddCustomSkill />
    </>
  );
};
