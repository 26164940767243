import { type PaginationListItem } from "./types";
import { range } from "lodash";

// inspired from material-ui pagination implementation
// https://github.com/mui/material-ui/blob/master/packages/mui-material/src/usePagination/usePagination.js
export const buildPaginationList = (totalPages: number, currentPage: number) : PaginationListItem[] => {
  const boundaryCount = 1;
  const siblingCount = 2;
  
  const startPages = range(1, Math.min(boundaryCount, totalPages) + 1);
  const endPages = range(Math.max(totalPages - boundaryCount + 1, boundaryCount + 1), totalPages + 1);

  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      currentPage - siblingCount,
      // Lower boundary when page is high
      totalPages - boundaryCount - siblingCount * 2 - 1
    ),
    // Greater than startPages
    boundaryCount + 2
  );

  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      currentPage + siblingCount,
      // Upper boundary when page is low
      boundaryCount + siblingCount * 2 + 2
    ),
    // Less than endPages
    endPages.length > 0
      ? endPages[0] - 2
      : totalPages - 1
  );

  const startEllipsis: PaginationListItem[] = siblingsStart > boundaryCount + 2
    ? ["start-ellipsis"]
    : boundaryCount + 1 < totalPages - boundaryCount
      ? [boundaryCount + 1]
      : [];
  
  const endEllipsis: PaginationListItem[] = siblingsEnd < totalPages - boundaryCount - 1
    ? ["end-ellipsis"]
    : totalPages - boundaryCount > boundaryCount
      ? [totalPages - boundaryCount]
      : [];

  const siblingPages: PaginationListItem[] = siblingsEnd >= siblingsStart ?
    range(siblingsStart, siblingsEnd + 1)
    : [];

  // Basic list of items to render
  // e.g. itemList = [1, 'ellipsis', 4, 5, 6, 'ellipsis', 10]
  return [
    ...startPages,

    // Start ellipsis
    ...startEllipsis,

    // Sibling pages
    ...siblingPages,

    // End ellipsis
    ...endEllipsis,

    ...endPages
  ];
};