import { useEffect } from "react";

export interface RedirectProps {
  to: string;
}

export const Redirect = ({ to }: RedirectProps) => {
  // TODO: /login is shown in the url bar for a sec there.. Can we avoid that?
  // TODO: leverage ENV vars to redirect to backend API on localhost
  useEffect(() => {
    if (window.location.href.startsWith("https://local-skills.workleap-dev.com:3000")) {
      window.location.href = "https://local-skills.workleap-dev.com:5001" + to;
    } else {
      window.location.href = to;
    }
  }, [to]);

  return null;
};