import { AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { Button } from "@/components";
import { useGetAtsSettings } from "@/features/ats/hooks/useGetAtsSettings";
import { useTrackAtsAction } from "@/features/ats/hooks/useTrackAtsAction";
import { useTranslation } from "@/hooks/useTranslation";
import { useUnlinkAtsAccountMutation } from "@/features/ats/hooks/useUnlinkAtsAccountMutation";

export const AtsDisconnectButton = () => {
  const { t } = useTranslation();
  const { data } = useGetAtsSettings();
  const { mutate, isPending } = useUnlinkAtsAccountMutation();
  const { trackAtsAction } = useTrackAtsAction();

  const onDisconnectAtsClick = () => {
    trackAtsAction(AnalyticValues.actionDisconnect);
    mutate();
  };
  
  return (
    <Button
      size="small"
      variant="secondary"
      onClick={onDisconnectAtsClick}
      loading={isPending}
      isDisabled={data?.isScheduledOrRunning}
    >
      {t("ats.disconnect.button")}
    </Button>
  );
};