import { type GetEditEmployeeModelDto } from "@/features/employees/types/common";
import { type QueryFunctionContext, type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { employeesQueryKeys } from "@/features/employees/types/employeesQueryKeys";
import api from "@/lib/http/api";

const getEditEmployeeModel = async ({ queryKey: [{ employeeId }] }: QueryFunctionContext<ReturnType<typeof employeesQueryKeys["editModel"]>>) => {
  const { data } = await api.get<GetEditEmployeeModelDto>(`/employees/${employeeId}/edit`);

  return data;
};

export const useGetEditEmployeeModel = (
  employeeId: string,
  options?: Omit<UseQueryOptions<Promise<GetEditEmployeeModelDto>, unknown, GetEditEmployeeModelDto, ReturnType<typeof employeesQueryKeys["editModel"]>>, "queryKey">
) => (
  useQuery({
    queryKey: employeesQueryKeys.editModel(employeeId), 
    queryFn: getEditEmployeeModel, 
    ...options
  })
);