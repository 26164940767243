import { type AriaCalendarGridProps, useCalendarGrid, useLocale } from "react-aria";
import { CalendarCell } from "./CalendarCell";
import { type CalendarState } from "react-stately";
import { getWeeksInMonth } from "@internationalized/date";

import { range } from "lodash";
import styles from "./CalendarGrid.module.css";

interface Props extends AriaCalendarGridProps {
  state: CalendarState;
}

export const CalendarGrid = ({ state, ...props }: Props) => {
  const { locale } = useLocale();
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  return (
    <table {...gridProps} cellPadding="0" className={styles.calendarGrid}>
      <thead {...headerProps} className={styles.calendarWeekDays}>
        <tr>
          {weekDays.map((day, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <th className={styles.calendarGridDay} key={`${day}-${index}`}>
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...range(weeksInMonth)].map(weekIndex => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) =>
                date
                  ? (
                    <CalendarCell key={date.toString()} state={state} date={date} />
                  )
                  : (
                    // eslint-disable-next-line react/no-array-index-key
                    <td key={i} />
                  )
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
