import { type ListFilterItem } from "@/features/filter/types/common";
import { translate } from "@/lib/i18n";

export const allStatuses: ListFilterItem[] = [{
  id: "created",
  name: translate("users.status.created")
}, {
  id: "invited",
  name: translate("users.status.invited")
}, {
  id: "active",
  name: translate("users.status.active")
}, {
  id: "deactivated",
  name: translate("users.status.deactivated")
}];