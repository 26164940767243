import { EditCustomSkill } from "@/features/skills/components/EditCustomSkill";
import { Helmet } from "react-helmet-async";
import { Routes } from "@/types/routes";
import { useIdRouteParam } from "@/hooks/routing/useIdRouteParam";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@/hooks/useTranslation";

export const EditCustomSkillPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onCancelClick = () => navigate(Routes.CustomSkills);
  const id = useIdRouteParam();

  return (
    <>
      <Helmet>
        <title>{t("pages.settingsEditCustomSkill")}</title>
      </Helmet>
      <EditCustomSkill id={id} onCancelClick={onCancelClick} />
    </>
  );
};
