import { type AriaCheckboxProps, useCheckbox } from "react-aria";
import { CheckmarkIcon } from "@hopper-ui/icons";
import { useRef } from "react";
import { useToggleState } from "react-stately";
import classNames from "classnames";
import styles from "./Checkbox.module.css";

type Props = AriaCheckboxProps & {
  size?: "large" | "small";
};

export const Checkbox = (props: Props) => {
  const { children } = props;
  const state = useToggleState(props);
  const ref = useRef(null);
  const { inputProps } = useCheckbox(props, state, ref);
  const { isSelected } = state;
  const { disabled, onClick } = inputProps;

  return (
    <>
      <label className={classNames(styles.checkbox, { [styles.selected]: isSelected, [styles.disabled]: disabled, [styles.large]: props.size === "large" })}>
        <input {...inputProps} ref={ref} />
        <span className={classNames(styles.checkmark, { [styles.large]: props.size === "large" })}
          onClick={onClick}
        >
          {isSelected && <CheckmarkIcon size="lg" />}
        </span>
        {children}
      </label>
    </>
  );
};