import { useGetHrisSettingsQuery } from "@/features/hris/hooks/useGetHrisSettings";
import { useAuth } from "@/lib/auth/AuthProvider";
import { type AppPermission } from "@/lib/auth/types";
import { type PropsWithChildren } from "react";
import { ProtectedRoute } from "./ProtectedRoute";

interface Props extends PropsWithChildren {
  permission: AppPermission;
  checkHrisStatus?: boolean;
  redirectPath?: string;
}

export const PermissionGuardRoute = ({
  permission,
  checkHrisStatus,
  ...rest
}: Props) => {
  const { data: hrisSettings } = useGetHrisSettingsQuery();
  const { currentUserHasPermission } = useAuth();

  const isAllowed = currentUserHasPermission(permission) && (!checkHrisStatus || !hrisSettings?.isConnected);

  return (
    <ProtectedRoute
      isAllowed={isAllowed}
      {...rest}
    />
  );
};