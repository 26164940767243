import { type FetchStatus } from "@tanstack/react-query";
import { type GetInputPropsOptions } from "downshift";
import { HStack } from "@/components/stack";
import { IconButton } from "@/components/icon-button";
import { LoadingAnimation } from "./LoadingAnimaton";
import { type Ref, forwardRef } from "react";
import { RemoveIcon, SearchIcon } from "@hopper-ui/icons";
import classNames from "classnames";

interface Props {
  setQuery: (query: string) => void;
  getInputProps: <K>(options?: K) => K & GetInputPropsOptions;
  fetchStatus: FetchStatus;
  description?: string;
  isDisabled?: boolean;
  onClear: () => void;
  inputValue: string | null;
  isOpen: boolean;
  hasError?: boolean;
}

export const SelectSearchField = forwardRef(({
  getInputProps,
  setQuery,
  fetchStatus,
  description,
  isDisabled,
  onClear,
  inputValue,
  isOpen,
  hasError
}: Props, ref: Ref<HTMLInputElement>) => (
  <div className="relative">
    <SearchIcon
      size="sm"
      className={classNames("text-neutral-text z-50 absolute top-1/2 -translate-y-1/2 transform left-3", {
        "text-neutral-text-weak": isDisabled
      })}
    />
    <input
      {...getInputProps({
        onChange: e => setQuery(e.target.value),
        placeholder: description,
        disabled: isDisabled
      })}
      className={classNames("input pl-9", {
        "bg-neutral-surface cursor-not-allowed text-neutral-text-weak border-neutral-surface": isDisabled,
        "border rounded-shape-rounded-md": !isOpen,
        "border-t border-l border-r rounded-t-shape-rounded-md": isOpen,
        "!border-danger-border-strong": hasError
      })}
      ref={ref}
    />
    <HStack className="absolute z-50 transform -translate-y-1/2 top-1/2 right-3" align="center" justify="center">
      <LoadingAnimation fetchStatus={fetchStatus} />
      {inputValue?.length && !isDisabled && onClear && (
        <IconButton icon={<RemoveIcon />} onPress={onClear} variant="ghost" />
      )}
    </HStack>
  </div>
));