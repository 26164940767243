import { type UpdateDepartmentCommandDto } from "@/features/departments/types/common";
import { departmentQueryKeys } from "./departmentQueryKeys";
import { toast } from "react-toastify";
import { translate } from "@/lib/i18n";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import { useQueryClient } from "@tanstack/react-query";
import api from "@/lib/http/api";

export const useUpdateDepartmentMutation = (departmentId: string) => {
  const queryClient = useQueryClient();

  return useMutationWithValidationResult<UpdateDepartmentCommandDto>({
    mutationFn: async ({ name } : UpdateDepartmentCommandDto) => {
      await api.post(`/departments/${departmentId}`, { name });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: departmentQueryKeys.lists() });

      toast.success(translate("departments.edit.successMessage"));
    }
  });
};