import { Card, FormReplaceDataConfirmationModal, Heading, SelectWithSearch, Text, VStack, WorkingOnMagicLoading } from "@/components";
import { useSearchSkills } from "@/features/search/hooks/useSearchSkills";
import { type SearchResultItem } from "@/features/search/types/common";
import { useGenerateRatingsDescriptions } from "@/features/skills/hooks/useGenerateRatingsDescriptions";
import { type SimilarSkillDto } from "@/features/skills/types/common";
import { type CustomMarketSkillFormState } from "@/features/skills/types/customSkills";
import { hasDescriptions } from "@/features/skills/utils/customSkills";
import { useTranslation } from "@/hooks/useTranslation";
import { type ValidationErrorResult } from "@/models/error";
import { Routes } from "@/types/routes";
import { useRef, useState } from "react";
import { type UseFormReturn } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { useOverlayTriggerState } from "react-stately";
import { v4 as uuid } from "uuid";
import { ManageSimilarSkills } from "./ManageSimilarSkills";
import { SkillRatingsDescriptionsEditor } from "./SkillRatingsDescriptionsEditor";

interface Props extends Omit<UseFormReturn<CustomMarketSkillFormState>, "handleSubmit"> {
  errors?: ValidationErrorResult;
  isExistingSkill?: boolean;
  onChangeSimilarSkills: (newSkills: SimilarSkillDto[]) => void;
}

export const EditSkillCustomDescriptionsForm = ({ watch, setValue, errors, isExistingSkill, getValues, onChangeSimilarSkills }: Props) => {
  const { t } = useTranslation();
  const workingOnMagicOverlayState = useOverlayTriggerState({});
  const confirmReplaceDataOverlayState = useOverlayTriggerState({});
  const [descriptionsEditorKey, setDescriptionsEditorKey] = useState(uuid());
  const prevSkillMarketId = useRef<string>();
  const navigate = useNavigate();
  
  const {
    data: skillData,
    fetchStatus: skillFetchStatus,
    setQuery: setSkillQuery
  } = useSearchSkills({ exclude: isExistingSkill
    ? "WithoutCustomization"
    : "WithCustomization" });

  const { generateDescription } = useGenerateRatingsDescriptions({
    workingOnMagicOverlayState,
    onDescriptionsGenerated: descriptions => {
      setValue("ratingsDescriptions", descriptions, { shouldDirty: true });
      setDescriptionsEditorKey(uuid());
    }
  });

  const onClickCancel = () => {
    confirmReplaceDataOverlayState.close();
    if (prevSkillMarketId.current) {
      setValue("marketId", prevSkillMarketId.current, { shouldDirty: true });
    }
  };

  const onClickReplace = () => {
    generateDescription({ skillName: watch("marketId") });
  };
  
  const onDescriptionsGenerate = () => generateDescription({ skillName: watch("marketId") });
  
  const onSkillSelectionChange = (selected: SearchResultItem) => {
    if (isExistingSkill) {
      navigate(generatePath(Routes.EditSkillCustomDescriptions, { marketId: encodeURIComponent(selected.name) }));

      return;
    }

    const areDescriptionsGenerated = hasDescriptions(watch("ratingsDescriptions"));
    prevSkillMarketId.current = getValues("marketId");

    setValue("marketId", selected.name, { shouldDirty: true });
    if (!areDescriptionsGenerated) { return; }

    confirmReplaceDataOverlayState.open();
  };

  const onDescriptionsChange = (descriptions: CustomMarketSkillFormState["ratingsDescriptions"]) =>
    setValue("ratingsDescriptions", descriptions, { shouldDirty: true });

  const onSelectSimilarSkill = (item: SimilarSkillDto) => {
    setValue("similarSkills", [...watch("similarSkills"), item], { shouldDirty: true });
  };

  return (
    <>
      <VStack gap={6}>
        <Card>
          <VStack gap={6}>
            <VStack gap={1.5}>
              <Heading size="xl">{t("skills.customDescriptions.form.skillName")}</Heading>
              <Text>{t("skills.customDescriptions.form.skillNameSubtitle")}</Text>
            </VStack>
            <SelectWithSearch
              key={watch("marketId")}
              description={t("skills.customDescriptions.form.skillNamePlaceholder")}
              setQuery={setSkillQuery}
              results={skillData?.results ?? []}
              fetchStatus={skillFetchStatus}
              onSelect={onSkillSelectionChange}
              keySelector={item => item.name}
              itemDisplay={item => (
                <div key={item.name}>
                  {item.name}
                </div>
              )}
              itemToText={item => item.name}
              errorMessage={errors?.field("Body.MarketId")}
              initialValue={watch("marketId")}
              isRequired
            />
          </VStack>
        </Card>
        <SkillRatingsDescriptionsEditor
          key={descriptionsEditorKey}
          descriptions={watch("ratingsDescriptions")}
          onDescriptionsChange={onDescriptionsChange}
          onDescriptionsRegenerate={onDescriptionsGenerate}
        />
        <Card>
          <ManageSimilarSkills
            onChangeSimilarSkills={onChangeSimilarSkills}
            onSelectSimilarSkill={onSelectSimilarSkill}
            currentSkillName={watch("marketId")}
            similarSkills={watch("similarSkills")}
          />
        </Card>
      </VStack>
      <WorkingOnMagicLoading overlayState={workingOnMagicOverlayState} />
      <FormReplaceDataConfirmationModal
        confirmationMessage={t("skills.customDescriptions.form.replaceDataConfirmationMessage")}
        overlayState={confirmReplaceDataOverlayState}
        onClickReplace={onClickReplace}
        onClickDontReplace={onClickCancel}
        onClickCancel={onClickCancel}
      />
      <WorkingOnMagicLoading overlayState={workingOnMagicOverlayState} />
    </>
  );
};
