import { Heading } from "@/components";
import { Authorize } from "@/lib/auth/Authorize";
import { type AppPermission } from "@/lib/auth/types";
import { type Feature } from "@/lib/features/types/feature";
import { FeatureGuardRoute } from "@/pages/components/FeatureGuardRoute";
import classNames from "classnames";
import { type ReactElement } from "react";
import { NavLink } from "react-router-dom";

interface Props {
  permission?: AppPermission;
  feature?: Feature;
  icon: ReactElement;
  to: string;
  children: string;
}

export const NavigationItem = ({ feature, permission, icon, children, to }: Props) => {
  const renderItem = () => (
    <NavLink
      className={({ isActive }) => classNames(
        "flex h-12 p-space-inset-sm gap-space-inline-md items-center transition-all rounded-shape-rounded-md",
        "hover:bg-neutral-surface-weakest-hover",
        { 
          "bg-neutral-surface-weakest-selected": isActive 
        })}
      to={to}
    >
      {icon}
      <Heading size="xs" className="font-580">
        {children}
      </Heading>
    </NavLink>
  );

  if (feature) {
    return (
      <FeatureGuardRoute feature={feature}>
        {renderItem()}
      </FeatureGuardRoute>
    );
  }

  if (permission) {
    return (
      <Authorize permission={permission}>
        {renderItem()}
      </Authorize>
    );
  }

  return renderItem();
};
