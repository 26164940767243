import { type CalendarState } from "react-stately";
import { type ChangeEvent } from "react";
import { useDateFormatter } from "react-aria";

import styles from "./Calendar.module.css";

interface Props {
  state: CalendarState;
}

const NUM_MONTHS = 12;

export const MonthDropdown = ({ state }: Props) => {
  const { focusedDate, timeZone, setFocusedDate } = state;
  const months = [];
  const formatter = useDateFormatter({
    month: "long",
    timeZone
  });

  for (let i = 1; i <= NUM_MONTHS; i++) {
    const date = focusedDate.set({ month: i });
    months.push(formatter.format(date.toDate(timeZone)));
  }

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    const date = focusedDate.set({ month: value });
    setFocusedDate(date);
  };

  return (
    <select
      aria-label="Month"
      onChange={onChange}
      value={focusedDate.month}
      className={styles.calendarNavDropdown}
    >
      {months.map((month, i) => (
        <option key={month} value={i + 1}>
          {month}
        </option>
      ))}
    </select>
  );
};
