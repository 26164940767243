import { Helmet } from "react-helmet-async";
import { UserProfileForm } from "@/features/profile/components/UserProfileForm";
import { useGetUserProfile } from "@/features/profile/api/useGetUserProfile";
import { useTranslation } from "@/hooks/useTranslation";

export const ProfilePage = () => {
  const { t } = useTranslation();
  const { data: userProfile } = useGetUserProfile();

  if (!userProfile) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{t("pages.profile")}</title>
      </Helmet>
      <UserProfileForm userProfile={userProfile} />
    </>
  );
};
