import { Button, ContentEditor, FormFieldWrapper, Text, WorkingOnMagicLoading } from "@/components";
import { sameRichContent } from "@/features/rich-content/rich-content.utils";
import { useSkillFlyoutFormDescriptionsNote } from "@/features/skill/hooks/useSkillFlyoutFormDescriptionsNote";
import { useSkillTranslations } from "@/features/skill/hooks/useSkillTranslations";
import { allSkillRatings } from "@/features/skill/types/skillTypes";
import { useGenerateRatingsDescriptions } from "@/features/skills/hooks/useGenerateRatingsDescriptions";
import { type SkillRating } from "@/features/skills/types/Skill";
import { type RatingsDescription } from "@/features/skills/types/common";
import { type RichContent } from "@/features/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { SparklesIcon, StartOverIcon } from "@hopper-ui/icons";
import { Content, Flex, Header, Heading, Item, Tabs } from "@workleap/orbiter-ui";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useOverlayTriggerState } from "react-stately";
import { v4 as uuid } from "uuid";

interface Props {
  ratingsDescription?: RatingsDescription;
  onChangeRatingDescription: (ratingsDescription: RatingsDescription) => void;
  skillName: string;
  errorMessage?: string;
  ratingsWithErrors: SkillRating[];
  isMarketSkill?: boolean;
}

export const SkillFlyoutFormDescriptions = ({ ratingsDescription, onChangeRatingDescription, skillName, errorMessage, ratingsWithErrors, isMarketSkill }: Props) => {
  const { t } = useTranslation();
  const { getSkillRatingTranslation } = useSkillTranslations();
  const workingOnMagicOverlayState = useOverlayTriggerState({});
  const [descriptionsEditorKey, setDescriptionsEditorKey] = useState(uuid());
  const [defaultDescriptions, setDefaultDescriptions] = useState<RatingsDescription>();
  const { note } = useSkillFlyoutFormDescriptionsNote();

  const { generateDescription } = useGenerateRatingsDescriptions({
    workingOnMagicOverlayState,
    onDescriptionsGenerated: descriptions => {
      onChangeRatingDescription({ ...ratingsDescription, ...descriptions });
      setDescriptionsEditorKey(uuid());
    }
  });

  const { generateDescription: generateDefaultDescriptions } = useGenerateRatingsDescriptions({
    onDescriptionsGenerated: descriptions => {
      setDefaultDescriptions(descriptions);
    }
  });

  useEffect(() => {
    if (isMarketSkill) {
      generateDefaultDescriptions({ skillName });
    }
  }, [skillName]);

  const onClickButton = (rating: SkillRating) => {
    generateDescription({ skillName, rating });
  };

  const isDisabled = (rating: SkillRating) => {
    if (!isMarketSkill) {
      return isEmpty(skillName);
    }

    return !defaultDescriptions || sameRichContent(defaultDescriptions?.[rating] as RichContent, ratingsDescription?.[rating] as RichContent);
  };

  const tabItems = allSkillRatings.map(rating => ({
    key: rating,
    header: <Text className={classNames({ "text-danger-text-weak": ratingsWithErrors.includes(rating) })} color="inherit">{getSkillRatingTranslation(rating)}</Text>,
    content: (
      <Flex direction="column" gap="inline-lg">
        <ContentEditor
          key={descriptionsEditorKey}
          className="px-2 max-h-[174px] overflow-y-auto"
          editorConfig={{
            editable: true,
            content: ratingsDescription?.[rating] as RichContent,
            onChange: content => onChangeRatingDescription({ ...ratingsDescription, [rating]: content }),
            className: "!p-0 !border-none"
          }}
        />
        <Button variant="secondary" size="small" icon={isMarketSkill ? <StartOverIcon size="sm" /> : <SparklesIcon size="sm" />} isDisabled={isDisabled(rating)} onClick={() => onClickButton(rating)}>
          {isMarketSkill ? t("skills.form.description.revert") : t("skills.form.description.generate")}
        </Button>
      </Flex>
    ) 
  }));

  return (
    <Flex gap="12px" direction="column">
      <Heading size="md">{t("skills.form.section.descriptions")}</Heading>
      <Flex gap="4px" direction="column">
        <FormFieldWrapper errorMessage={errorMessage}>
          <Tabs variant="in-card" aria-label="Skill ratings">
            {tabItems.map(({ key, header, content }) => (
              <Item key={key}>
                <Header>{header}</Header>
                <Content>{content}</Content>
              </Item>
            ))}
          </Tabs>
        </FormFieldWrapper>
        {note && !errorMessage && <Text size="xs" color="neutral-text-weak">{note}</Text>}
      </Flex>
      <WorkingOnMagicLoading overlayState={workingOnMagicOverlayState} />
    </Flex>
  );
};