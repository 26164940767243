import { type SeniorityLevel } from "@/features/roles/types/common";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import api from "@/lib/http/api";

export const useGetSeniorityLevels = () => (
  useQuery<unknown, unknown, SeniorityLevel[]>({
    queryKey: ["SeniorityLevels"], 
    queryFn: async () => {
      const res = await api.get<SeniorityLevel[]>(
        "roles/seniority-levels");
  
      return res.data;
    },
    placeholderData: keepPreviousData 
  })
);
