import { Button, FormFieldWrapper, HStack } from "@/components";
import { useGetEmployeeIsSynced } from "@/features/employees/hooks/useGetEmployeeIsSynced";
import { UserAvatar } from "@/features/users/components/UserAvatar";
import { useFileInput } from "@/hooks/useFileInput";
import { useTranslation } from "@/hooks/useTranslation";
import { useState } from "react";
import styles from "./EmployeeFormEditableAvatar.module.css";

interface EditableAvatarProps {
  firstName: string;
  lastName?: string;
  avatarUrl?: string;
  onFileChanged: (file: File) => void;
  onAvatarRemoved: () => void;
  errorMessage?: string;
  errorStyle?: string;
  isSynced?: boolean;
}

export const EmployeeFormEditableAvatar = ({ firstName, lastName, avatarUrl, onFileChanged, onAvatarRemoved, errorMessage, errorStyle, isSynced }: EditableAvatarProps) => {
  const { t } = useTranslation();
  const { input, handleFileChanged, open, remove } = useFileInput({ onFileChanged: file => _onFileChanged(file) });
  const [imageSrc, setImageSrc] = useState(avatarUrl);
  const { isHrisConnectedAndEmployeeIsSynced } = useGetEmployeeIsSynced(isSynced);

  const _onFileChanged = (file: File) => {
    setImageSrc(URL.createObjectURL(file));
    onFileChanged(file);
  };

  const onClickRemove = () => {
    remove();
    setImageSrc(undefined);
    onAvatarRemoved();
  };

  return (
    <FormFieldWrapper
      errorMessage={errorMessage}
      errorStyle={errorStyle}
    >
      <input
        aria-label="avatar"
        ref={input}
        type="file"
        id="file"
        className={styles.input}
        onChange={handleFileChanged}
        accept="image/png, image/jpeg"
      />
      <HStack gap={4} className={styles.wrapper}>
        <UserAvatar size="xl" firstName={firstName} lastName={lastName} avatarUrl={imageSrc} editable />
        <HStack gap={0} align="center">
          <Button isDisabled={isHrisConnectedAndEmployeeIsSynced} variant="secondary" onClick={open}>{t("employee.edit.uploadNew")}</Button>
          {!!input.current?.value && <Button variant="danger" onClick={onClickRemove}>{t("employee.edit.removePhoto")}</Button>}
        </HStack>
      </HStack>
    </FormFieldWrapper>
  );
};