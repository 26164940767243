import { AtsAuthError } from "@/features/ats/components/AtsAuthError";
import { AtsConnect } from "@/features/ats/components/AtsConnect";
import { AtsConnected } from "@/features/ats/components/AtsConnected";
import { Helmet } from "react-helmet-async";
import { PageHeader, VStack, useGetCurrentPageParam } from "@/components";
import { useGetAtsSettings } from "@/features/ats/hooks/useGetAtsSettings";
import { useTranslation } from "@/hooks/useTranslation";

export const AtsPage = () => {
  const { t } = useTranslation();
  const { data } = useGetAtsSettings();
  const page = useGetCurrentPageParam();

  const renderAtsView = () => {
    if (!data) {
      return null;
    }

    if (!data.isConnected) {
      return <AtsConnect />;
    }
    
    if (data.syncStatus === "AuthError") {
      return <AtsAuthError />;
    }
      
    return <AtsConnected page={page} />;
  };

  return ( 
    <>
      <Helmet>
        <title>{t("pages.settingsAts")}</title>
      </Helmet>
      <VStack gap={4}>
        <PageHeader title={t("settings.ats.title")} />
        {renderAtsView()}
      </VStack>
    </>
  );
};