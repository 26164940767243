import { type SearchEmployeeResponse } from "@/features/reports/types/search";
import api from "@/lib/http/api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useDebounce } from "use-hooks";

export const useSearchEmployees = () => {
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce<string>(query, 300);

  const { data, fetchStatus } = useQuery({
    queryKey: ["SearchEmployees", debouncedQuery],
    queryFn: async () => {
      const res = await api.get<SearchEmployeeResponse>("search/employees", {
        params: {
          search: debouncedQuery
        }
      });

      return res.data.results;
    },
    enabled: !!debouncedQuery,
    placeholderData: keepPreviousData
  });

  return {
    data,
    setQuery,
    query,
    fetchStatus
  };
};
