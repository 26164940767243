import { type GetAtsAccountIntegrationsDto } from "@/features/ats/types/common";
import { type UseQueryOptions, keepPreviousData, useQuery } from "@tanstack/react-query";
import { atsQueryKeys } from "@/features/ats/types/atsQueryKeys";
import api from "@/lib/http/api";

const getAtsIntegrations = async () => {
  const { data } = await api.get<GetAtsAccountIntegrationsDto>("/ats/integrations");

  return data;
};

export const useGetAtsIntegrations = (options?: UseQueryOptions<GetAtsAccountIntegrationsDto>) => useQuery<GetAtsAccountIntegrationsDto>({
  queryKey: atsQueryKeys.integrations(),
  queryFn: getAtsIntegrations,
  ...options,
  placeholderData: keepPreviousData
});