import { type GetRolesManagementQuery, type GetRolesManagementQueryDto } from "@/features/roles/types/common";
import { type QueryFunctionContext, type UseQueryOptions, keepPreviousData, useQuery } from "@tanstack/react-query";
import { roleQueryKeys } from "@/features/roles/types/roleQueryKeys";
import api from "@/lib/http/api";

const getRoleMangement = async ({ queryKey: [, , params] }: QueryFunctionContext<ReturnType<typeof roleQueryKeys["rolesManagement"]>>) => {
  const { data } = await api.get("/roles/management", {
    params
  });

  return data;
};

export const useGetRolesManagement = (
  config: GetRolesManagementQuery,
  options?: UseQueryOptions<GetRolesManagementQueryDto, unknown, GetRolesManagementQueryDto, ReturnType<typeof roleQueryKeys["rolesManagement"]>>
) => (
  useQuery({
    queryKey: roleQueryKeys.rolesManagement(config), 
    queryFn: getRoleMangement,
    placeholderData: keepPreviousData,
    ...options
  })
);
