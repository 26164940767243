import { type ReactNode } from "react";
import { useAuth } from "@/lib/auth/AuthProvider";
import { useGetMarketContentTranslations } from "./hooks/useGetMarketContentTranslations";

interface Props {
  children: ReactNode;
}

export const MarketContentTranslationsProvider = ({ children }: Props) => {
  const { authenticated } = useAuth();

  const { isLoading, data } = useGetMarketContentTranslations({
    enabled: authenticated
  });

  if (isLoading || !data) {
    return null;
  }
  
  return (
    <>{children}</>
  );
};
