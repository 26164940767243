import { type Feature } from "@/lib/features/types/feature";
import { HStack, Text, VStack } from "@/components";
import { PremiumBadge } from "@/features/billing/components/PremiumBadge";

interface Props {
  title: string;
  subtitle?: string;
  feature?: {
    blocked: boolean;
    name: Feature;
  };
}

export const CardHeader = ({ title, subtitle, feature }: Props) => (
  <VStack gap={1.5}>
    <HStack align="center">
      <Text size="lg" weight={580}>
        {title}
      </Text>
      {feature?.blocked && <PremiumBadge feature={feature?.name} />}
    </HStack>
    {subtitle && <Text size="sm" color="status-neutral-text">{subtitle}</Text>}
  </VStack>
);