import { useTranslation } from "@/hooks/useTranslation";
import { Tag, Text, Tooltip, TooltipTrigger } from "@workleap/orbiter-ui";

export const ExternalChip = () => {
  const { t } = useTranslation();

  return (
    <TooltipTrigger zIndex={Number.MAX_SAFE_INTEGER}>
      <Tag 
        size="sm"
        border="none"
        style={{
          backgroundColor: "var(--hop-status-inactive-surface)",
          color: "var(--hop-status-inactive-text)",
          padding: "2px var(--hop-space-inset-sm)",
          minHeight: "0px"
        }}
      >
        <Text size="xs">{t("search.external")}</Text>
      </Tag>
      <Tooltip>
        {t("search.externalTooltip")}
      </Tooltip>
    </TooltipTrigger>
  );
};