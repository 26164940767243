import { type QueryFunctionContext, type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { apiRaw } from "@/lib/http/api";
import { employeesQueryKeys } from "@/features/employees/types/employeesQueryKeys";

const getEmployeeResumeContent = async ({ queryKey: [{ employeeId }] }: QueryFunctionContext<ReturnType<typeof employeesQueryKeys["resume"]>>) => {
  const { data } = await apiRaw.get<Blob>(`/employees/${employeeId}/resume`, { responseType: "blob" });

  return data;
};

export const useGetEmployeeResumeContent = (
  employeeId: string,
  options?: Omit<UseQueryOptions<Promise<Blob>, unknown, Blob, ReturnType<typeof employeesQueryKeys["resume"]>>, "queryKey">
) => (
  useQuery({
    queryKey: employeesQueryKeys.resume(employeeId), 
    queryFn: getEmployeeResumeContent, 
    ...options
  })
);