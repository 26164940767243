import { type PaginationProps } from "@/components";
import { useCallback, useEffect, useMemo, useState } from "react";

interface UsePaginationProps {
  totalItems: number;
  pageSize: number;
  initialPage?: number;
  onChangePage?: (pageNumber: number) => void;
}

export const usePagination = ({ totalItems, initialPage, pageSize, onChangePage }: UsePaginationProps): PaginationProps => {
  if (pageSize <= 0) {
    throw new Error("Page size must be at least 1");
  }

  const [currentPage, setCurrentPage] = useState(initialPage ?? 1);

  const totalPages = useMemo(() =>
    totalItems > 0
      ? Math.ceil(totalItems / pageSize)
      : 0
  , [totalItems, pageSize]);

  useEffect(() => {
    if (totalPages <= 1 && currentPage > 1) {
      setCurrentPage(1);
    }
  }, [totalPages, currentPage]);

  const hasNext = currentPage < totalPages;
  const hasPrev = currentPage > 1;

  const goToNext = useCallback(() => {
    if (hasNext) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      onChangePage?.(newPage);
    }
  }, [currentPage, setCurrentPage, hasNext, onChangePage]);

  const goToPrev = useCallback(() => {
    if (hasPrev) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      onChangePage?.(newPage);
    }
  }, [hasPrev, setCurrentPage, currentPage, onChangePage]);

  const goToPage = useCallback((pageNumber: number) => {
    if (pageNumber <= 0) {
      setCurrentPage(1);
    }

    if (pageNumber > totalPages) {
      setCurrentPage(totalPages);
    }

    setCurrentPage(pageNumber);
    onChangePage?.(pageNumber);
  }, [setCurrentPage, onChangePage]);

  return {
    goToNext,
    goToPage,
    goToPrev,
    hasNext,
    hasPrev,
    currentPage,
    totalPages
  };
};