import { skillQueryKeys } from "@/features/skill/types/skillQueryKeys";
import { type SkillFlyoutDto } from "@/features/skill/types/skillTypes";
import api from "@/lib/http/api";
import { useQuery, useSuspenseQuery, type UseQueryOptions } from "@tanstack/react-query";

export const getSkillFlyout = async (skillName: string) => {
  const { data } = await api.post<SkillFlyoutDto>("/skills/flyout", {
    skillName
  });

  return data;
};

export const useGetSkillFlyoutSuspenseQuery = (skillName: string, options?: Omit<UseQueryOptions<SkillFlyoutDto, unknown, SkillFlyoutDto, ReturnType<typeof skillQueryKeys.flyout>>, "queryKey">) => 
  useSuspenseQuery({
    queryKey: skillQueryKeys.flyout(skillName), 
    queryFn: () => getSkillFlyout(skillName), 
    ...options
  });

export const useGetSkillFlyoutQuery = (skillName: string, options?: Omit<UseQueryOptions<SkillFlyoutDto, unknown, SkillFlyoutDto, ReturnType<typeof skillQueryKeys.flyout>>, "queryKey">) => 
  useQuery({
    queryKey: skillQueryKeys.flyout(skillName), 
    queryFn: () => getSkillFlyout(skillName), 
    ...options
  });