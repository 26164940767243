import { Item } from "react-stately";
import { MenuItemWrapper } from "./components/MenuItemWrapper";
import { type ComponentProps } from "react";

export const DefaultMenuItem = (key: string, text: string, menuItemProps?: Omit<ComponentProps<typeof MenuItemWrapper>, "children">) => (
  <Item key={key} textValue={text}>
    <MenuItemWrapper {...menuItemProps}>
      {text}
    </MenuItemWrapper>
  </Item>
);