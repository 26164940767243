import { ConfirmationModal } from "@/components";
import { useDeleteRoleMutation } from "@/features/roles/hooks/useDeleteRoleMutation";
import { useTranslation } from "@/hooks/useTranslation";
import { type OverlayTriggerState } from "react-stately";

interface Props {
  roleId: string;
  overlayState: OverlayTriggerState;
}

export const NoImpactRoleDeletionModal = ({ roleId, overlayState }: Props) => {
  const { t } = useTranslation();
  const message = t("roles.delete.noImpact.summary");
  const { mutate: deleteRole } = useDeleteRoleMutation(roleId);

  return (
    <ConfirmationModal
      title={t("roles.delete.title")}
      onClickConfirm={deleteRole}
      overlayState={overlayState}
      cancelButtonContent={t("common.form.cancel")}
      confirmButtonContent={t("roles.delete.confirm")}
    >
      {message}
    </ConfirmationModal>
  );
};