import { type Dispatch, type SetStateAction, useState } from "react";
import { type SearchResponse, type SearchRoleSkillsRequest, searchQueryKeys } from "@/features/search/types/common";
import { type UseQueryOptions, keepPreviousData, useQuery } from "@tanstack/react-query";

import { searchRolesSkills } from "@/features/search/api";
import { useDebounce } from "use-hooks";

type SearchSkillsResponse = {
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
} & SearchResponse;

export const useSearchSkills = (
  queryParams?: Omit<SearchRoleSkillsRequest, "search">,
  options?: UseQueryOptions<SearchResponse, unknown, SearchSkillsResponse, [...typeof searchQueryKeys.searchRoleSkills, string]>
) => {
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 300);
  
  const queryResult = useQuery({
    queryKey: [...searchQueryKeys.searchRoleSkills, debouncedQuery],
    queryFn: async () => (await searchRolesSkills({ search: debouncedQuery, ...queryParams })).data,
    enabled: options?.enabled || !!debouncedQuery,
    placeholderData: keepPreviousData,
    ...options
  });

  return {
    setQuery,
    query,
    ...queryResult
  };
};
