import { type AxiosDefaults, AxiosError, type AxiosRequestConfig } from "axios";
import { type Next } from "middleware-axios";
import { redirectToLogin } from "@/utils";

export const AuthMiddleware = async <T>(config: AxiosRequestConfig, next: Next<T>, _: AxiosDefaults): Promise<void> => {
  try {
    await next(config);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      if (e.response?.status === 401) {
        redirectToLogin(window.location.href);
      }
    } else {
      throw e;
    }
  }
};