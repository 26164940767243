import { Checkbox } from "@/components";
import { type GridNode } from "@react-types/grid";
import { type TableState } from "react-stately";
import { useRef } from "react";
import { useTableCell, useTableSelectionCheckbox } from "react-aria";

interface Props {
  cell: GridNode<unknown>;
  state: TableState<unknown>;
}

export const TableCheckboxCell = ({ cell, state }: Props) => {
  const ref = useRef<HTMLTableCellElement>(null);
  const { gridCellProps } = useTableCell({ node: cell }, state, ref);
  const { checkboxProps } = useTableSelectionCheckbox(
    { key: cell.parentKey ?? "" },
    state
  );

  return (
    <td
      {...gridCellProps}
      ref={ref}
      className="w-5 p-4 pl-5 border-b border-l border-neutral-border-weak"
    >
      <Checkbox {...checkboxProps} />
    </td>
  );
};