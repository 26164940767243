import { type GetCustomSkillsQueryParams, type ListCustomSkillsDto } from "@/features/skills/types/common";
import { type QueryFunctionContext, type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { skillsQueryKeys } from "@/features/skills/types/skillsQueryKeys";
import api from "@/lib/http/api";

const getCustomSkills = async({ queryKey: [, , , params] }: QueryFunctionContext<ReturnType<typeof skillsQueryKeys.listCustomSkills>>) => {
  const { data } = await api.get<ListCustomSkillsDto>("/skills/custom-skills", { params });

  return data;
};


export const useGetCustomSkills = (
  queryParams: GetCustomSkillsQueryParams,
  options?: UseQueryOptions<ListCustomSkillsDto, unknown, ListCustomSkillsDto, ReturnType<typeof skillsQueryKeys.listCustomSkills>>) =>
  useQuery({
    queryKey: skillsQueryKeys.listCustomSkills(queryParams), 
    queryFn: getCustomSkills, 
    ...options
  }); 