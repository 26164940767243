import { DraggableType } from "@/features/skill-rating/types/common";
import { type SkillRatingForm } from "@/features/skill-rating/types/skillRatingForm";
import { useGetSkillFlyoutQuery } from "@/features/skill/hooks/useGetSkillFlyout";
import { type SkillRating } from "@/features/skills/types/Skill";
import { getSkillLevelsMetadata } from "@/features/skills/types/SkillLevels";
import { Card, Content, Div, Flex, Grid, Heading, Text } from "@workleap/orbiter-ui";
import classNames from "classnames";
import { sortBy } from "lodash";
import { type ComponentProps } from "react";
import { useDragLayer, useDrop } from "react-dnd";
import { useFormContext } from "react-hook-form";
import { DraggableSkill } from "./DraggableSkill";
import { SkillRatingLevelBucketContent } from "./SkillRatingLevelBucketContent";

type DraggedSkill = Omit<ComponentProps<typeof DraggableSkill>, "onRemove"> & {
  isUncategorized: boolean;
};

interface Props {
  rating: SkillRating;
  skillsInRating: ComponentProps<typeof DraggableSkill>[];
}

export const SkillRatingLevelBucket = ({ rating, skillsInRating }: Props) => {
  const skillLevels = getSkillLevelsMetadata();
  const { Icon, name } = skillLevels[rating];
  const { setValue, getValues } = useFormContext<SkillRatingForm>();

  const handleDrop = (item: DraggedSkill) => {
    const { skills, uncategorizedSkills } = getValues();

    const itemIsRatedAlready = skills.some(x => x.name === item.name);

    // If the item is already rated, we update the rating
    // Else we add the item to the rated skills and remove it from the uncategorized skills
    if (itemIsRatedAlready) {
      const newSkills = skills.map(x => x.name === item.name ? { ...x, rating } : x);

      setValue("skills", newSkills, { shouldDirty: true });
      return;
    }

    setValue("skills", sortBy([...skills, { ...item, rating }], "name"), { shouldDirty: true });
    setValue("uncategorizedSkills", uncategorizedSkills.filter(x => x.name !== item.name), { shouldDirty: true });
  };

  const { isDragging } = useDragLayer(
    monitor => ({
      isDragging: monitor.isDragging()
    }));

  const [{ item, isOver }, drop] = useDrop({
    accept: DraggableType.SKILL,
    drop: handleDrop,
    collect: monitor => ({
      item: monitor.getItem<DraggedSkill>(),
      isOver: monitor.isOver()
    })
  });

  const { data: draggedSkill, isLoading } = useGetSkillFlyoutQuery(item?.name || "", {
    enabled: isDragging && !!item?.name
  });

  return (
    <Div className="relative" width="100%" overflow="hidden">
      <Card 
        pointerEvents="none"
        position="absolute"
        maxWidth="100%"
        height="100%"
        // The dismiss icon in the Draggable skill would appear on top if we didn't set a high zIndex here
        zIndex="999"
        display={(isLoading || draggedSkill) ? "block" : "none"}
        border={isOver ? "information" : undefined}
        backgroundColor={isOver ? "information" : undefined}
        className={classNames({ "border-dashed": isDragging && !isOver })}
        ref={drop}
      >
        <Heading>
          <Flex gap="inline-sm" alignItems="center">
            <Icon />
            <Text fontWeight="body-md-semibold" color="neutral">{name}</Text>
          </Flex>
        </Heading>
        <Content height="100%">
          <Div backgroundColor={isOver ? "information" : "neutral"}>
            <SkillRatingLevelBucketContent rating={rating} />
          </Div>
        </Content>
      </Card>
      <Card 
        height="100%"
        maxWidth="100%"
        border={isOver ? "information" : undefined}
        backgroundColor={isOver ? "information" : undefined}
        className={classNames({ "border-dashed": isDragging && !isOver })}
        overflowY="auto"
        ref={drop}
      >
        <Heading>
          <Flex gap="inline-sm" alignItems="center">
            <Icon />
            <Text fontWeight="body-md-semibold" color="neutral">{name}</Text>
          </Flex>
        </Heading>
        <Content height="100%">
          <Grid gap="inline-sm" maxHeight="100%">
            {skillsInRating.map(skill => (
              <DraggableSkill key={skill.name} {...skill} />
            ))}
          </Grid>
        </Content>
      </Card>
    </Div>
  );
};