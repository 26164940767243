
import { useIntercom } from "./useIntercom";
import { useMixpanel } from "./useMixpanel";

export const useAnalytics = () => {
  const intercom = useIntercom();
  const mixpanel = useMixpanel();

  const trackEvent = (eventName: string, properties?: Object) => {
    intercom.trackEvent(eventName, { ...properties });
    mixpanel.trackEvent(eventName, { ...properties });
  };

  const logout = () => {
    intercom.shutdown();
    mixpanel.reset();
  };

  return {
    trackEvent,
    logout
  };
};