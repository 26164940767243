import { useProgressiveLoading } from "@/components";
import { useGetEmployee } from "@/features/employees/hooks/useGetEmployee";
import { type SkillFlyoutRating } from "@/features/skill/types/skillTypes";
import { useAuth } from "@/lib/auth/AuthProvider";
import { Flex, Grid, minmax } from "@workleap/orbiter-ui";
import { isUndefined } from "lodash";
import { Suspense, type ReactNode } from "react";
import { type OverlayTriggerState } from "react-stately";
import { SkillFlyoutAddSkillButton } from "./SkillFlyoutAddSkillButton";
import { SkillFlyoutUpdateDescriptionsButton } from "./SkillFlyoutUpdateDescriptionsButton";

interface Props {
  skillName: string;
  skillDisplayName?: string;
  activeRating: SkillFlyoutRating;
  hideDefaultFlyoutActions?: boolean;
  extraActions?: ReactNode;
  overlayState: OverlayTriggerState;
}

export const SkillFlyoutViewModeActions = (props: Props) => {
  const { hideDefaultFlyoutActions, extraActions } = props;
  const { currentUser, currentUserHasPermission } = useAuth();
  const { data: employeeQueryData, isLoading } = useGetEmployee(currentUser?.employeeId as string, { enabled: !isUndefined(currentUser?.employeeId) });
  const { showLoading } = useProgressiveLoading({ isLoading: isLoading });

  const canEditDescriptions = currentUserHasPermission("Permissions.Skills.UpdateSkill");

  const hideActions = hideDefaultFlyoutActions && !extraActions;

  if (hideActions) {
    return null;
  }

  if (!employeeQueryData || showLoading) {
    return null;
  }

  return (
    <Flex direction="column" gap="inline-md">
      {!hideDefaultFlyoutActions && (
        <Grid gap="inline-md" templateColumns={canEditDescriptions ? [minmax("140px", "min-content"), "auto"] : ["1fr"]}>
          {canEditDescriptions && <SkillFlyoutUpdateDescriptionsButton {...props} />}
          <Suspense>
            <SkillFlyoutAddSkillButton employee={employeeQueryData} {...props} />
          </Suspense>
        </Grid>
      )}
      {extraActions}
    </Flex>
  );
};
