import { useCreateUserMutation } from "@/features/users/hooks/useCreateUserMutation";
import { type CreateUserResult, type NewUserFormData } from "@/features/users/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { type OverlayTriggerState } from "react-stately";
import { toast } from "react-toastify";
import { CreateUserModal } from "./CreateUserModal";

export interface InviteUserModalProps {
  overlayState: OverlayTriggerState;
  onSuccess: () => void;
}

export const InviteNewUserModal = ({ overlayState, onSuccess }: InviteUserModalProps) => {
  const { t } = useTranslation();
  const { mutate: inviteUser, isPending, validationErrors } = useCreateUserMutation(({ firstName, lastName, workEmail }: CreateUserResult) => {
    toast.success(t("users.invite.invitationSentToUser", {
      email: workEmail,
      firstName,
      lastName
    }));

    onSuccess();
    overlayState.close();
  });
  
  const submitHandler = (formData: NewUserFormData) => {
    inviteUser({ ...formData, autoInvite: true });
  };

  if (!overlayState.isOpen) {
    return null;
  }

  return (
    <CreateUserModal
      overlayState={overlayState}
      title={t("users.invite.title")}
      isSubmitting={isPending}
      validationErrors={validationErrors}
      onSubmit={submitHandler}
      submitBtnLabel={t("users.actions.sendInvite")}
    />
  );
};