import { type Ethnicity } from "@/features/employees/types/common";
import { Select, type SelectInputProps, type SelectItem } from "@/components";
import { useTranslation } from "@/hooks/useTranslation";

interface Props extends Omit<SelectInputProps<Ethnicity>, "items" | "value"> {
  value?: Ethnicity;
}

export const SelectEthnicity = ({ value, ...otherProps }: Props) => {
  const { t } = useTranslation();
  const selectItems: SelectItem<Ethnicity>[] = [
    { id: "AMERICAN_INDIAN_OR_ALASKA_NATIVE", text: t("common.labels.ethnicity.americanIndianOrAlaskaNative") },
    { id: "ASIAN_OR_INDIAN_SUBCONTINENT", text: t("common.labels.ethnicity.asianOrIndianSubcontinent") },
    { id: "BLACK_OR_AFRICAN_AMERICAN", text: t("common.labels.ethnicity.blackOrAfricanAmerican") },
    { id: "HISPANIC_OR_LATINO", text: t("common.labels.ethnicity.hispanicOrLatino") },
    { id: "NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER", text: t("common.labels.ethnicity.nativeHawaiianOrOtherPacificIslander") },
    { id: "WHITE", text: t("common.labels.ethnicity.white") },
    { id: "TWO_OR_MORE_RACES", text: t("common.labels.ethnicity.twoOrMoreRaces") },
    { id: "PREFER_NOT_TO_DISCLOSE", text: t("common.labels.preferNotToDisclose") }
  ];

  return (
    <Select
      value={value
        ? selectItems.find(({ id }) => id === value)
        : undefined}
      items={selectItems}
      {...otherProps}
    />
  );
};