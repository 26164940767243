import { Grid, Heading, PageHeader, PageLink, VStack } from "@/components";
import { Helmet } from "react-helmet-async";
import { useSettingsSections } from "@/features/tenants/hooks/useSettingsSections";
import { useTranslation } from "@/hooks/useTranslation";

export const SettingsPage = () => {
  const { t } = useTranslation();
  const { sections } = useSettingsSections();

  return (
    <>
      <Helmet>
        <title>{t("pages.settings")}</title>
      </Helmet>
      <PageHeader title={t("settings.title")} />
      <VStack gap={6}>
        {sections.map(({ title, pages }) => (
          <VStack key={title} gap={4}>
            <Heading size="md">{title}</Heading>
            <Grid cols={2} rows="auto">
              {pages.map(link => <PageLink key={link.title} {...link} />)}
            </Grid>
          </VStack>
        ))}
      </VStack>
    </>
  );
};