import { Card, CardHeader, VStack } from "@/components";
import { type EmployeeEditModel, type GetEditEmployeeModelDto } from "@/features/employees/types/common";
import { UserRoleDropdown } from "@/features/users/components/UserRoleDropdown";
import { type UserRoleType } from "@/features/users/types/userRoles";
import { useTranslation } from "@/hooks/useTranslation";
import { useCurrentUser } from "@/lib/auth/AuthProvider";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import { type ValidationErrorResult } from "@/models/error";
import { isEmpty } from "lodash";
import { type UseFormReturn } from "react-hook-form";

interface TabProfileProps {
  commandErrors?: ValidationErrorResult;
  employee?: GetEditEmployeeModelDto;
  form: UseFormReturn<EmployeeEditModel>;
}

export const UserPermissions = (props: TabProfileProps) => {
  const {
    form: {
      setValue,
      getValues
    },
    employee,
    commandErrors
  } = props;
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const { hasFeature } = useFeatures();
  
  const setRole = (role: UserRoleType) =>
    setValue("userRole", role, { shouldDirty: true });

  // TODO: check user has edit permissions access here
  const isMe = currentUser.employeeId === employee?.id;  
  const hasUserId = !isEmpty(employee?.userId);
  const excludedOptions: UserRoleType[] = [];
  if (!hasFeature("Features.CollaboratorRole")) {
    excludedOptions.push("Collaborator");
  }

  if (!hasFeature("Features.ExecutiveRole")) {
    excludedOptions.push("Executive");
  }

  return (
    <Card>
      <VStack gap={2}>
        <CardHeader title={t("employee.edit.permissions")} />
        <UserRoleDropdown
          label={t("employee.edit.accessLevel")}
          role={!hasUserId
            ? "User"
            : getValues("userRole") as UserRoleType}
          setRole={setRole}
          isDisabled={isMe || !hasUserId}
          errorMessage={commandErrors?.field("Body.UserRole")}
          excludeOptions={excludedOptions}
        />
      </VStack>
    </Card>
  );
};