
import { Card, Heading, VStack } from "@/components";
import { Helmet } from "react-helmet-async";
import { PermissionsSettingsForm } from "@/features/tenants/components/PermissionsSettingsForm";
import { useTranslation } from "@/hooks/useTranslation";

export const PermissionsSettingsPage = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <Helmet>
        <title>{t("settings.permissions.title")}</title>
      </Helmet>
      <VStack gap={6}>
        <Heading size="xl">{t("settings.permissions.title")}</Heading>
        <Card>
          <PermissionsSettingsForm />
        </Card>
      </VStack>
    </>
  );
};
