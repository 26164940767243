import { Badge, Button, Flyout, FlyoutContent, HStack } from "@/components";
import { type InitialFilters } from "@/features/filter/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { FilterIcon } from "@hopper-ui/icons";
import { Div, Flex, Heading } from "@workleap/orbiter-ui";
import { AnimatePresence } from "framer-motion";
import { type ReactNode } from "react";
import { useOverlayTriggerState } from "react-stately";
import { FiltersForm } from "./FiltersForm";

export interface FilterProps {
  totalAppliedFilters: number;
  filters: InitialFilters;
  setFilters: (filters: Partial<InitialFilters>) => void;
  children?: ReactNode;
  onClearFilters: () => void;
  onApplyFilters: () => void;
  resetFilters: () => void;
  hideDefaultFilters?: boolean;
  hideDraftRoleStatusFilterOption?: boolean;
}

export const Filter = ({
  totalAppliedFilters,
  filters,
  setFilters,
  children,
  onClearFilters,
  onApplyFilters,
  resetFilters,
  hideDefaultFilters,
  hideDraftRoleStatusFilterOption
}: FilterProps) => {
  const { t } = useTranslation();
  const overlayState = useOverlayTriggerState({});

  const handleClearFilters = () => {
    onClearFilters();
    overlayState.close();
  };

  const handleApplyFilters = () => {
    onApplyFilters();
    overlayState.close();
  };

  const onClose = () => {
    resetFilters();
    overlayState.close();
  };

  return (
    <>
      <Button
        size="small"
        icon={<FilterIcon size="sm" />}
        variant="secondary"
        onClick={overlayState.open}
      >
        <HStack align="center" gap={2}>
          <div>{t("common.filter.heading")}</div>
          {totalAppliedFilters && <Badge number={totalAppliedFilters} />}
        </HStack>
      </Button>
      <AnimatePresence>
        {overlayState.isOpen && (
          <Flyout
            isOpen={overlayState.isOpen}
            onClose={onClose}
            isDismissable
          >
            <FlyoutContent onClose={onClose}>
              <Heading size="xl" slot="header">{t("common.filter.heading")}</Heading>
              <Div slot="content">
                <FiltersForm values={filters}
                  onChange={setFilters} 
                  hideDefaultFilters={hideDefaultFilters}
                  hideDraftRoleStatusFilterOption={hideDraftRoleStatusFilterOption}
                >
                  {children}
                </FiltersForm>
              </Div>
              <Div slot="footer">
                <Flex direction="column" gap="inline-md">
                  <Button fluid onClick={handleApplyFilters}>
                    {totalAppliedFilters > 0
                      ? t("common.filters.button.update")
                      : t("common.filters.button.apply")}
                  </Button>
                  {totalAppliedFilters > 0 && (
                    <Button
                      fluid
                      onClick={handleClearFilters}
                      variant="secondary"
                    >
                      {t("common.filters.button.clear")}
                    </Button>
                  )}
                </Flex>
              </Div>
            </FlyoutContent>
          </Flyout>
        )}
      </AnimatePresence>
    </>
  );
};