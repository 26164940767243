import { AnimatePresence, motion } from "framer-motion";
import { type ReactNode } from "react";
import classNames from "classnames";
import styles from "./AccordionDetails.module.css";

export interface AccordionDetailsProps {
  children: ReactNode;
  isCollapsed?: boolean;
  itemId?: string;
  className?: string;
}

export const AccordionDetails = ({
  children,
  isCollapsed,
  itemId,
  className
}: AccordionDetailsProps) => {
  if (isCollapsed) {
    return null;
  }

  return (
    <AnimatePresence>
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: "auto" }}
        exit={{ height: 0 }}
      >
        <div
          id={itemId}
          className={classNames(styles.accordionDetails, className)}
        >
          {children}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
