import { type Model } from "@/features/types/clientTypes";
import { translate } from "@/lib/i18n";
import { nullish, object, string, unknown, url, type Output } from "valibot";

export type SettingsDto = Model<"Settings.Queries.GetSettingsQuery.SettingsDto">;
export type UpdateSettingsCommand = Model<"Settings.Commands.UpdateSettingsCommand">;
export type ToggleManagerTeamMemberInfoAccessCommand = Model<"Settings.Commands.ToggleManagerTeamMemberInfoAccessCommand">;
export type GeneralSettingsDto = Model<"Settings.Queries.GeneralSettingsDto">;
export type UpdateGeneralSettingsCommand = Model<"Settings.Commands.UpdateGeneralSettingsCommand">;

export const updateGeneralSchema = object({
  companyWebsiteUrl: nullish(string([url(translate("errors.common.isAbsoluteUri"))]))
}, unknown());

export type GeneralFormState = Output<typeof updateGeneralSchema> & Omit<UpdateGeneralSettingsCommand, "companyWebsiteUrl">;