import { DefaultLoader, FlyoutContent } from "@/components";
import { useSkillFlyoutState } from "@/features/skill/hooks/useSkillFlyoutState";
import { Div } from "@workleap/orbiter-ui";
import { Suspense, type ReactNode } from "react";
import { type OverlayTriggerState } from "react-stately";
import { SkillFlyoutEditMode } from "./SkillFlyoutEditMode";
import { SkillFlyoutViewMode } from "./SkillFlyoutViewMode";

interface Props {
  skillName: string;
  overlayState: OverlayTriggerState;
  hideDefaultFlyoutActions?: boolean;
  extraActions?: ReactNode;
  showRecommendedMentors?: boolean;
}

export const SkillFlyoutSections = (props: Props) => {
  const { overlayState } = props;
  const { skillFlyoutMode, closeFlyout } = useSkillFlyoutState(overlayState);

  if (skillFlyoutMode === "edit") {
    return (
      <Suspense fallback={<DefaultLoader />}>
        <SkillFlyoutEditMode {...props}>
          {({ header, content, footer, modals }) => (
            <FlyoutContent onClose={closeFlyout}>
              <Div slot="header">
                {header}
              </Div>
              <Div slot="content">
                {content}
              </Div>
              <Div slot="footer">
                {footer}
              </Div>
              <Div slot="modals">
                {modals}
              </Div>
            </FlyoutContent>
          )}
        </SkillFlyoutEditMode>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<DefaultLoader />}>
      <SkillFlyoutViewMode {...props}>
        {({ header, content, footer, modals }) => (
          <FlyoutContent onClose={closeFlyout}>
            <Div slot="header">
              {header}
            </Div>
            <Div slot="content">
              {content}
            </Div>
            <Div slot="footer">
              {footer}
            </Div>
            <Div slot="modals">
              {modals}
            </Div>
          </FlyoutContent>
        )}
      </SkillFlyoutViewMode>
    </Suspense>
  );
};