import { type Node } from "@react-types/shared";
import { type TreeState } from "react-stately";
import { useMenuItem } from "react-aria";
import { useRef } from "react";

interface Props<T> {
  item: Node<T>;
  state: TreeState<T>;
}

export const MenuItem = <T extends object>({ item, state }: Props<T>) => {
  const ref = useRef(null);
  const { menuItemProps } = useMenuItem(
    { key: item.key },
    state,
    ref
  );

  return (
    <li
      {...menuItemProps}
      ref={ref}
      className="outline-none"
    >
      {item.rendered}
    </li>
  );
};