import { DEFAULT_EXTENSIONS } from "@/components/editor/hooks/useEditor";
import { getDefaultSkillRatingDescriptions } from "@/features/skills/types/SkillLevels";
import { type AddCustomMarketSkill } from "@/features/skills/types/common";
import { type CustomMarketSkillFormState, type CustomSkillFormState, type RatingsDescription } from "@/features/skills/types/customSkills";
import { type Languages } from "@/hooks/useLanguages";
import { generateJSON } from "@tiptap/react";
import { isEmpty, values } from "lodash";

export const toMetadataCustomDescriptions = (formDescriptions: RatingsDescription) => {
  const descriptionItems = Object.entries(formDescriptions);

  return descriptionItems.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {} as AddCustomMarketSkill["ratingsDescriptions"]);
};

export const hasDescriptions = (descriptions: RatingsDescription) =>
  values(descriptions).some(description => !isEmpty(description));

export const makeDefaultDescriptions = (language?: Languages) : RatingsDescription => {
  const defaultTranslations = getDefaultSkillRatingDescriptions(language);
  
  return ({
    Beginner: generateJSON(`<p>${defaultTranslations.Beginner}</p>`, DEFAULT_EXTENSIONS),
    Intermediate: generateJSON(`<p>${defaultTranslations.Intermediate}</p>`, DEFAULT_EXTENSIONS),
    Advanced: generateJSON(`<p>${defaultTranslations.Advanced}</p>`, DEFAULT_EXTENSIONS),
    Expert: generateJSON(`<p>${defaultTranslations.Expert}</p>`, DEFAULT_EXTENSIONS)
  });
};

export const isCustomMarketSkillFormValid = ({ marketId, ratingsDescriptions }: CustomMarketSkillFormState) =>
  !isEmpty(marketId) && hasDescriptions(ratingsDescriptions);

export const isCustomSkillFormValid = ({ name, ratingsDescriptions, category }: CustomSkillFormState) => 
  !isEmpty(name) && !isEmpty(category) && hasDescriptions(ratingsDescriptions);