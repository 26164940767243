import { type CheckboxGroupProps, useCheckboxGroupState } from "react-stately";
import { type ReactNode } from "react";
import { useCheckboxGroup } from "react-aria";

import { FormLabel } from "@/components/form-label";
import { VStack } from "@/components/stack";
import { type ValueBase } from "@react-types/shared";

import { CheckboxGroupContext } from "./CheckboxGroupContext";

export interface CheckboxGroupOption<TValue> {
  value: TValue;
  text: string;
}

interface Props<T = string> extends Omit<CheckboxGroupProps, keyof ValueBase<string[]>>, ValueBase<T[]> {
  children: ReactNode;
}

// eslint-disable-next-line prefer-arrow-functions/prefer-arrow-functions
export function CheckboxGroup<T = string>(props: Props<T>) {
  const {
    onChange,
    value,
    defaultValue,
    ...otherProps
  } = props;
  
  const valueProps: ValueBase<string[]> = {
    value: value
      ? value as string[]
      : undefined,
    defaultValue: defaultValue ?
      defaultValue as string[] :
      undefined,
    onChange: onChange
      ? changeValue => onChange(changeValue.map(v => v as T))
      : undefined
  };

  const {
    children,
    label,
    description,
    errorMessage,
    validationState
  } = otherProps;

  const reactAriaProps = {
    ...otherProps,
    ...valueProps
  };
  
  const state = useCheckboxGroupState(reactAriaProps);
  const { groupProps, labelProps, descriptionProps, errorMessageProps } =
    useCheckboxGroup(reactAriaProps, state);

  return (
    <div {...groupProps}>
      <VStack gap={2.5}>
        <FormLabel {...labelProps}>{label}</FormLabel>
        <CheckboxGroupContext.Provider value={state}>
          <VStack gap={2.5}>
            {children}
          </VStack>
        </CheckboxGroupContext.Provider>
      </VStack>
      {description && (
        <div {...descriptionProps} className="text-sm">{description}</div>
      )}
      {errorMessage && validationState === "invalid" &&
        (
          <div {...errorMessageProps} className="text-sm text-danger-icon-hover">
            <>{errorMessage}</>
          </div>
        )}
    </div>
  );
}
