import { type AriaCalendarProps, I18nProvider, useCalendar } from "react-aria";
import { createCalendar } from "@internationalized/date";
import { useCalendarState } from "react-stately";
import { useRef } from "react";

import { CalendarGrid } from "./CalendarGrid";
import { type DateValue } from "@react-types/calendar";
import { HStack, IconButton } from "@/components";

import { AngleLeftIcon, AngleRightIcon } from "@hopper-ui/icons";
import { MonthDropdown } from "./MonthDropdown";
import { YearDropdown } from "./YearDropdown";
import i18next from "i18next";
import styles from "./Calendar.module.css";

type Props = AriaCalendarProps<DateValue>;

export const Calendar = (props: Props) => {
  const state = useCalendarState({
    ...props,
    locale: i18next.language,
    createCalendar
  });

  const ref = useRef<HTMLDivElement>(null);
  const { calendarProps, prevButtonProps, nextButtonProps } = useCalendar(
    props,
    state
  );

  return (
    <I18nProvider locale={i18next.language}>
      <div ref={ref} {...calendarProps} className={styles.container}>
        <HStack className={styles.calendarNav} align="center" justify="between">
          <IconButton
            {...prevButtonProps}
            icon={<AngleLeftIcon />}
            variant="ghost"
            size="small"
          />
          <MonthDropdown state={state} />
          <YearDropdown state={state} />
          <IconButton
            {...nextButtonProps}
            icon={<AngleRightIcon />}
            variant="ghost"
            size="small"
          />
        </HStack>
        <CalendarGrid state={state} />
      </div>
    </I18nProvider>
  );
};
