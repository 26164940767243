import { type GetAtsOpenJobsQueryDto, type GetAtsOpenJobsQueryParams } from "@/features/ats/types/common";
import { type QueryFunctionContext, type UseQueryOptions, keepPreviousData, useQuery } from "@tanstack/react-query";
import { atsQueryKeys } from "@/features/ats/types/atsQueryKeys";
import api from "@/lib/http/api";

const getAtsOpenPositions = async ({ queryKey: [, params] }: QueryFunctionContext<ReturnType<typeof atsQueryKeys["filteredJobs"]>>) => {
  const { data } = await api.get("/ats/open-jobs", {
    params
  });

  return data;
};

export const useGetAtsOpenPositions = (
  config: GetAtsOpenJobsQueryParams,
  options?: UseQueryOptions<GetAtsOpenJobsQueryDto, unknown, GetAtsOpenJobsQueryDto, ReturnType<typeof atsQueryKeys.filteredJobs>>
) => (
  useQuery({
    queryKey: atsQueryKeys.filteredJobs(config), 
    queryFn: getAtsOpenPositions,
    placeholderData: keepPreviousData,
    ...options
  })
);