import { directoryQueryKeys } from "@/features/directory/types/directoryQueryKeys";
import { roleQueryKeys } from "@/features/roles/types/roleQueryKeys";
import { useTranslation } from "@/hooks/useTranslation";
import api from "@/lib/http/api";
import { type ValidationErrors } from "@/models/error";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { toast } from "react-toastify";

export const useDeleteRoleMutation = (roleId: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError<{ errors?: ValidationErrors }>, void, unknown>({
    mutationFn: async () => {
      await api.delete(`/roles/${roleId}`);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: roleQueryKeys.all });
      queryClient.invalidateQueries({ queryKey: directoryQueryKeys.all });

      toast.success(t("common.messages.changesSaved"));
    }
  });
};