import { type ProgressiveLoadingProps } from "./types";
import { type ReactNode } from "react";
import { useProgressiveLoading } from "./useProgressiveLoading";

interface Props extends ProgressiveLoadingProps {
  children: ReactNode;
}

export const ProgressiveLoading = ({
  isLoading,
  children,
  ...rest
}: Props) => {
  const { showLoading } = useProgressiveLoading({
    isLoading: isLoading,
    ...rest
  });

  return (
    <>
      {showLoading && children}
    </>
  );
};
