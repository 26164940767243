import { type RoleReferencesDto } from "@/features/roles/types/common";
import { roleQueryKeys } from "@/features/roles/types/roleQueryKeys";
import api from "@/lib/http/api";
import { useQuery, type QueryFunctionContext, type UseQueryOptions } from "@tanstack/react-query";

const getRoleReferences = async({ queryKey: [, { roleId }] }: QueryFunctionContext<ReturnType<typeof roleQueryKeys["roleReferences"]>>) => {
  const { data } = await api.get<RoleReferencesDto>(`/roles/${roleId}/references`);

  return data;
};


export const useGetRoleReferences = (
  roleId: string,
  options?: Omit<UseQueryOptions<RoleReferencesDto, unknown, RoleReferencesDto, ReturnType<typeof roleQueryKeys.roleReferences>>, "queryKey">) =>
  useQuery({
    queryKey: roleQueryKeys.roleReferences(roleId), 
    queryFn: getRoleReferences, 
    ...options
  });