import { translate } from "@/lib/i18n";
import { type TranslationKey } from "@/lib/i18n/types";
import { ValidationErrorResult, type ValidationErrors } from "@/models/error";
import { useMutation, type UseMutationOptions, type UseMutationResult } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { toast } from "react-toastify";

type Error = AxiosError<{ errors?: ValidationErrors }>;

export type UseMutationWithValidationResult<T, TData = unknown, TContext = unknown> = UseMutationResult<TData, Error, T, TContext> & {
  validationErrors: ValidationErrorResult;
};

interface UseMutationWithValidationResultOptions<TData, TError, TVariables, TContext> extends Omit<UseMutationOptions<TData, TError, TVariables, TContext>, "onError"> {
  /**
   * Function that overrides the default onError callback of React Query to add validationErrors.
   * 
   * This function can be used to handle validation errors specifically,
   * display error messages, or perform any other error handling logic.
   * 
   * @param validationErrors - The validation errors extracted from the error response.
   * @param error - The original error object.
   * @param variables - The variables that were passed to the mutation function.
   * @param context - The context provided to the mutation, if any.
   */
  onError?: (validationErrors: ValidationErrorResult, error: TError, variables: TVariables, context: TContext | undefined) => Promise<unknown> | unknown;
}

export const useMutationWithValidationResult = <TVariables, TData = unknown, TContext = unknown>(options: UseMutationWithValidationResultOptions<TData, Error, TVariables, TContext>): UseMutationWithValidationResult<TVariables, TData, TContext> => {
  const mutation = useMutation<TData, Error, TVariables, TContext>({
    ...options,
    onError: (error, variables, context) => {
      const validationErrors = new ValidationErrorResult(error?.response?.data?.errors);
  
      if (validationErrors.summary()) {
        toast.error(translate(validationErrors.summary() as TranslationKey));
      }

      options.onError?.(validationErrors, error, variables, context);
    }
  });

  return {
    ...mutation,
    validationErrors: new ValidationErrorResult(mutation.error?.response?.data?.errors)
  };
};