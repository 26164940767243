import { type CustomSkillDto, type SimilarSkillDto } from "@/features/skills/types/common";
import { type CustomSkillFormState } from "@/features/skills/types/customSkills";
import { EditCustomSkillForm } from "./EditCustomSkillForm";
import { FormHeader, UnsavedChangesModal, VStack, useProgressiveLoading } from "@/components";
import { Routes } from "@/types/routes";
import { isCustomSkillFormValid, toMetadataCustomDescriptions } from "@/features/skills/utils/customSkills";
import { useForm } from "react-hook-form";
import { useGetCustomSkillById } from "@/features/skills/hooks/useGetCustomSkillById";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@/hooks/useTranslation";
import { useUpdateCustomSkillMutation } from "@/features/skills/hooks/useUpdateCustomSkillMutation";

interface Props {
  id: string;
  onCancelClick: () => void;
}

interface FormProps {
  customSkill: CustomSkillDto;
  onCancelClick: () => void;
}

const Form = ({ customSkill, onCancelClick }: FormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const form = useForm<CustomSkillFormState>({
    // there is an issue on the DefaultValues<TFormValues> type from react-hook-form
    // where [x: string] : unknown is transformed into [x: string] : {} | undefined
    // while the `attrs` field from the RichContent type is not compatible with {} | undefined
    // see: https://github.com/react-hook-form/react-hook-form/issues/10519
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: { ...customSkill as any }
  });
  const { formState: { isDirty, isSubmitted }, handleSubmit, watch, setValue } = form;

  const { mutate, isPending } = useUpdateCustomSkillMutation(customSkill.id, () => navigate(Routes.CustomSkills));

  const submitHandler = (formData: CustomSkillFormState) => {
    mutate({
      ...formData,
      ratingsDescriptions: toMetadataCustomDescriptions(formData.ratingsDescriptions)
    });
  };

  const onChangeSimilarSkills = (newSkills: SimilarSkillDto[]) => setValue("similarSkills", newSkills, { shouldDirty: true });

  return (
    <>
      <form onSubmit={handleSubmit(submitHandler)} className="relative">
        <VStack gap={8}>
          <FormHeader 
            title={t("skills.customSkills.form.editTitle")}
            discardButtonLabel={t("common.form.cancel")}
            saveButtonLabel={t("common.form.update")}
            onBack={onCancelClick}
            isLoading={isPending}
            isDirty={isDirty}
            canSubmit={isCustomSkillFormValid(watch())}
          />
          <EditCustomSkillForm {...form} onChangeSimilarSkills={onChangeSimilarSkills} />
        </VStack>
      </form>
      <UnsavedChangesModal when={isDirty && !isSubmitted && !isPending} />
    </>
  );
};

export const EditCustomSkill = ({ id: skillName, onCancelClick }: Props) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetCustomSkillById(skillName);
  const { showLoading } = useProgressiveLoading({ isLoading });

  if (showLoading) {
    <VStack gap={8}>
      <FormHeader 
        title={t("skills.customSkills.form.editTitle")}
        discardButtonLabel={t("common.form.cancel")}
        saveButtonLabel={t("common.form.update")}
        onBack={onCancelClick}
        isLoading
      />
    </VStack>;
  }

  if (!data) {
    return null;
  }

  return (
    <Form customSkill={data.skill} onCancelClick={onCancelClick} />
  );
};
