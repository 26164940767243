import { AxiosError } from "axios";
import { ValidationErrorResult } from "@/models/error";
import { toast } from "react-toastify";
import { useState } from "react";
import { useTranslation } from "./useTranslation";

export interface UseCommandResult<TCommand> {
  isLoading: boolean;
  send: (command: TCommand) => Promise<void>;
  errors?: ValidationErrorResult;
  title?: string;
}

interface Options {
  hideDefaultToast?: boolean;
}

export const useCommand = <TCommand>(handler: (command: TCommand) => Promise<void>, options?: Options): UseCommandResult<TCommand> => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<ValidationErrorResult>();
  const [title, setTitle] = useState<string>();
  const { t } = useTranslation();

  const send = async (command: TCommand) => {
    try {
      setIsLoading(true);
      await handler(command);
      setErrors(undefined);
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 400) {
          const badRequestErrors = new ValidationErrorResult(e.response.data.errors);
          const summary = badRequestErrors.summary();
      
          if (!options || !options?.hideDefaultToast) {
            if (summary) {
              toast.error(summary);
            } else {
              toast.error(t("common.error"));
            }
          }

          setErrors(badRequestErrors);
          setTitle(e.response.data.title);
        }
      }
      
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    send,
    title,
    errors
  };
};