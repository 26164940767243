import { useGetEmployeeIsSynced } from "@/features/employees/hooks/useGetEmployeeIsSynced";
import { type EmployeeEditModel, type EmployeeEmploymentFormState } from "@/features/employees/types/common";
import { SearchRoleThenJob } from "@/features/search/components/search-role-then-job/SearchRoleThenJob";
import { type SearchRoleThenJobResponseItem } from "@/features/search/hooks/useSearchRoleThenJob";
import { useTranslation } from "@/hooks/useTranslation";
import { type ValidationErrorResult } from "@/models/error";
import { isEmpty, isEqual } from "lodash";
import { type UseFormReturn } from "react-hook-form";
import { mapEmploymentToSelectRoleOption } from "./utils";

interface Props extends UseFormReturn<EmployeeEditModel> {
  isSynced?: boolean;
  commandErrors?: ValidationErrorResult;
}

export const CurrentEmployeeRole = ({
  isSynced,
  commandErrors,
  setValue,
  watch
}: Props) => {
  const { t } = useTranslation();
  const currentEmployment = watch("employments")?.[0];
  const { isHrisConnectedAndEmployeeIsSynced } = useGetEmployeeIsSynced(isSynced && !!currentEmployment?.roleId);
  
  const onSelectRole = (role?: SearchRoleThenJobResponseItem) => {
    if (!role) {
      return;
    }

    const { name: roleName, marketRoleId, roleId } = role;

    const employments: EmployeeEmploymentFormState[] = watch("employments") ?? [];

    // new unsaved employment - just update the role
    if (currentEmployment?.isNew) {
      setValue("employments", employments.map(item => {
        if (isEqual(currentEmployment, item)) {
          return {
            ...item,
            roleName,
            roleId,
            marketRoleId,
            isInternal: !!role.roleId
          };
        }

        return item;
      }), { shouldDirty: true });

      return;
    }

    // terminate the current employment role and add a new one
    if (!isEmpty(employments)) {
      employments[0].endDate = new Date();
    }

    setValue("employments", [{
      effectiveDate: new Date(),
      isNew: true,
      roleName,
      roleId,
      marketRoleId,
      isInternal: !!role.roleId
    }, ...employments], { shouldDirty: true });
  };
  const initialValue = mapEmploymentToSelectRoleOption(currentEmployment);

  return (
    <SearchRoleThenJob
      key={currentEmployment?.roleId ?? ""}
      label={t("common.labels.role")}
      description={t("employee.edit.roleNamePlaceholder")}
      onRoleSelected={onSelectRole}
      errorMessage={commandErrors?.field("Body.Employments[0].RoleId")}
      initialValue={initialValue}
      isDisabled={isHrisConnectedAndEmployeeIsSynced}
      hideExternalChip
    />
  );
};