import { AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { JobOpeningFormFlyout } from "@/features/jobs/components/job-opening-form/JobOpeningFormFlyout";
import { useTrackJobAction } from "@/features/jobs/hooks/useTrackJobAction";
import { useUpdateJobOpeningFormMutation } from "@/features/jobs/hooks/useUpdateJobOpeningFormMutation";
import { type UpdateJobOpening } from "@/features/jobs/types/common";
import { type JobOpeningFormInput } from "@/features/jobs/types/jobOpeningFormTypes";
import { useTranslation } from "@/hooks/useTranslation";
import { AnimatePresence } from "framer-motion";
import { FormProvider } from "react-hook-form";
import { type OverlayTriggerState } from "react-stately";

interface Props {
  jobOpening: UpdateJobOpening;
  overlayState: OverlayTriggerState;
}

export const UpdateJobOpeningFlyout = (props: Props) => {
  const { overlayState } = props;

  return (
    <AnimatePresence>
      {overlayState.isOpen && (
        <UpdateJobOpeningFlyoutOpen {...props} />
      )}
    </AnimatePresence>
  );
};

export const UpdateJobOpeningFlyoutOpen = ({ jobOpening, overlayState }: Props) => {
  const { t } = useTranslation();
  const { trackJobAction } = useTrackJobAction();

  const { mutation: { mutate, isPending }, form } = useUpdateJobOpeningFormMutation({
    jobOpening,
    onSuccess: () => {
      const { role, status, hiringManager } = form.getValues();

      trackJobAction(AnalyticValues.actionUpdate, role!.id, status, hiringManager?.id);
      overlayState.close();
    }
  });

  const onSubmit = async ({ openDate, hiringManager, postingLink, status }: JobOpeningFormInput) => {
    mutate({
      jobId: jobOpening.id,
      openDate,
      hiringManagerId: hiringManager?.id,
      postingLink,
      status
    });
  };

  return (
    <FormProvider {...form}>
      <JobOpeningFormFlyout 
        title={t("jobs.edit.form.heading")}
        submitLabel={t("common.form.save")}
        isSaving={isPending}
        mode="update"
        isStatusDisabled={jobOpening.isSynced}
        onSubmit={onSubmit}
        onClose={() => overlayState.close()}
      />
    </FormProvider>
  );
};