import { type Model } from "@/features/types/clientTypes";
import api from "@/lib/http/api";
import { getIsCrowdinToolActive } from "@/lib/i18n/tools/CrowdinInContextTool.tools";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { type AxiosError, type AxiosRequestConfig } from "axios";
import { changeLanguage } from "i18next";
import { type ReactNode, createContext, useContext } from "react";
import { authQueryKeys } from "./authQueryKeys";
import { hasAccess } from "./hasAccess";
import { type AppPermission } from "./types";

export type CurrentUserDto = Model<"Users.Queries.GetCurrentUserQueryDto.UserDto">;

export interface AuthContextInterface {
  initializing: boolean;
  authenticated: boolean;
  currentUser?: CurrentUserDto;
  isCurrentUser: (employeeId: string) => boolean;
  currentUserHasPermission: (permission: AppPermission) => boolean;
}

export const AuthContext = createContext<AuthContextInterface | null>(null);

interface AuthProviderProps {
  children?: ReactNode;
}

export const currentUserQueryKey = "current-user";

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { isLoading, data: currentUser } = useQuery({
    queryKey: authQueryKeys.currentUser(), 
    queryFn: async () => {
      const options: AxiosRequestConfig<CurrentUserDto> = {
        validateStatus: status => (status >= 200 && status < 300) || status === 401 || status === 403
      };

      const res = await api.get("users/me", options);

      if (res.data?.user) {
        const { data: { user } } = res;
        const { features, language } = user;

        if (features.includes("Features.FrenchTranslation")) {
          if (features.includes("Features.CrowdinInContextTool") && getIsCrowdinToolActive()) {
            return user;
          }

          changeLanguage(language);
        }

        return user;
      }

      return null;
    },
    throwOnError: (err?: AxiosError) => !!err?.response,
    placeholderData: keepPreviousData
  });

  const authenticated = !!currentUser;

  const authContext: AuthContextInterface = {
    initializing: isLoading,
    authenticated: authenticated,
    currentUser,
    isCurrentUser: employeeId => currentUser?.employeeId === employeeId,
    currentUserHasPermission: (permission: AppPermission) => hasAccess(permission, currentUser)
  };

  if (isLoading) {
    return null;
  }

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const auth = useContext(AuthContext);

  if (!auth) {
    throw new Error("useAuth must be used within AuthProvider");
  }

  return auth;
};

export const useCurrentUser = () => {
  const { currentUser } = useAuth();

  if (!currentUser) {
    throw new Error("user must be set");
  }

  return currentUser;
};