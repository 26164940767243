import { DatePicker, FormField } from "@/components";
import { type JobOpeningFormInput } from "@/features/jobs/types/jobOpeningFormTypes";
import { SearchEmployees } from "@/features/search/components/search-employees/SearchEmployees";
import { SearchInternalRoles } from "@/features/search/components/search-roles/SearchRoles";
import { useTranslation } from "@/hooks/useTranslation";
import { Grid, TextInput } from "@workleap/orbiter-ui";
import { useFormContext } from "react-hook-form";
import { type JobOpeningFormMode } from "./JobOpeningFormFlyout";
import { JobOpeningFormStatus } from "./JobOpeningFormStatus";

interface Props {
  mode: JobOpeningFormMode;
  isStatusDisabled?: boolean;
}

export const JobOpeningFormContent = ({ mode, isStatusDisabled }: Props) => {
  const { control } = useFormContext<JobOpeningFormInput>();
  const { t } = useTranslation();

  return (
    <Grid templateColumns="1fr">
      <FormField 
        control={control}
        name="openDate"
        label={t("jobs.form.openDate.label")}
        required
        render={({ field: { onChange, name, value, id } }) => (
          <DatePicker
            name={name}
            id={id}
            aria-label={t("jobs.form.openDate.label")}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <FormField 
        control={control}
        name="role"
        label={t("jobs.form.role.label")}
        required
        render={({ field: { value, onChange, ...rest } }) => (
          <SearchInternalRoles
            {...rest}
            value={value}
            onSelectionChange={onChange}
            enforceCollaboratorAccess
            fluid
            placeholder={t("jobs.form.role.placeholder")}
            disabled={mode === "update"}
          />
        )}
      />
      <FormField 
        control={control}
        name="hiringManager"
        label={t("jobs.form.hiringManager.label")}
        render={({ field: { value, onChange, ...rest } }) => (
          <SearchEmployees
            {...rest}
            value={value}
            onSelectionChange={onChange}
            fluid
            placeholder={t("jobs.form.hiringManager.placeholder")}
          />
        )}
      />
      <FormField
        control={control}
        name="postingLink"
        label={t("jobs.form.postingLink.label")}
        required
        render={({ field }) => (<TextInput {...field} fluid placeholder={t("jobs.form.postingLink.placeholder")} />)}
      />
      <JobOpeningFormStatus mode={mode} isStatusDisabled={isStatusDisabled} />
    </Grid>
  );
};