import { AnalyticEvents, AnalyticProperties, AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { progressionPlanQueryKeys } from "@/features/progression-plan/types/progressionPlanQueryKeys";
import { toast } from "react-toastify";
import { useAnalytics } from "@/features/analytics/hooks/useAnalytics";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "@/hooks/useTranslation";
import api from "@/lib/http/api";

export const useRemoveProgressionPlanMentorMutation = (planId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();
  
  return useMutationWithValidationResult({
    mutationFn: async (mentorEmployeeId: string) => await api.delete(`progression-plans/${planId}/mentors/${mentorEmployeeId}`, {
      data: {
        id: planId,
        mentorEmployeeId
      }
    }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: progressionPlanQueryKeys.all });
      toast.success(t("collaborate.notification.mentorRemoved"));

      trackEvent(AnalyticEvents.mentorAction, {
        [AnalyticProperties.action]: AnalyticValues.delete
      });
    }
  });
};
