import { type DeleteTenantCommand } from "@/features/tenants/types/common";
import { redirectToHome } from "@/utils";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import api from "@/lib/http/api";

export const useDeleteTenantMutation = () => useMutationWithValidationResult({
  mutationFn: async (payload: DeleteTenantCommand) => await api.post<DeleteTenantCommand>("/settings/delete-tenant", payload),
  onSuccess: async () => {
    redirectToHome();
  }
});