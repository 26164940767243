import { DismissIcon, EditIcon } from "@hopper-ui/icons";
import { EditEmploymentModal } from "./EditEmploymentModal";
import { type EmployeeEditModel, type EmployeeEmploymentFormState } from "@/features/employees/types/common";
import { HStack, IconButton, Text, VStack } from "@/components";
import { HrisLock } from "@/features/hris/components/lock/HrisLock";
import { type UseFormReturn } from "react-hook-form";
import { type ValidationErrorResult } from "@/models/error";
import { WorkHistoryEmployment } from "@/features/employees/components/work-history-employment/WorkHistoryEmployment";
import { isEqual, orderBy } from "lodash";
import { useGetEmployeeIsSynced } from "@/features/employees/hooks/useGetEmployeeIsSynced";
import { useOverlayTriggerState } from "react-stately";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  commandErrors?: ValidationErrorResult;
  employment: EmployeeEmploymentFormState;
  form: UseFormReturn<EmployeeEditModel>;
  isSynced?: boolean;
}

export const EditEmployment = ({
  employment,
  commandErrors,
  form: { setValue, watch },
  isSynced
}: Props) => {
  const overlayState = useOverlayTriggerState({});
  const { t } = useTranslation();
  
  const { roleId, isNew } = employment;
  const { isHrisConnectedAndEmployeeIsSynced } = useGetEmployeeIsSynced(isSynced && !!roleId);

  const onDelete = () => {
    setValue("employments", [...watch("employments").filter(empl => !isEqual(employment, empl))], { shouldDirty: true });
  };

  const onEditEmployment = (newEmployment: EmployeeEmploymentFormState) => {
    const updatedEmployment = {
      ...employment,
      ...newEmployment
    };

    const updatedEmployments = orderBy([...watch("employments").map(empl => {
      if (isEqual(employment, empl)) {
        return updatedEmployment;
      }

      return empl;
    })], ["endDate", "effectiveDate"], ["desc", "desc"]);

    setValue("employments", updatedEmployments, { shouldDirty: true });
  };

  return (
    <>
      <VStack gap={1}>
        <HStack justify="between" align="center">
          <WorkHistoryEmployment {...employment} />
          {isHrisConnectedAndEmployeeIsSynced
            ? (
              <HrisLock />
            )
            : (
              <HStack gap={0} align="center">
                {isNew && !roleId && <Text color="neutral-text-weak">{t("employee.employment.roleCreatedOnSave")}</Text>}
                <IconButton
                  onPress={overlayState.open}
                  icon={<EditIcon />}
                  variant="ghost"
                />
                <IconButton
                  onPress={overlayState.open}
                  icon={<DismissIcon />}
                  className="!text-danger-surface-strong"
                  variant="ghost"
                />
              </HStack>
            )}
        </HStack>
      </VStack>
      <EditEmploymentModal
        employment={employment}
        overlayState={overlayState}
        commandErrors={commandErrors}
        onEditEmployment={onEditEmployment}
        onDelete={onDelete}
      />
    </>
  );
};
