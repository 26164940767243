import { Card, VStack } from "@/components";
import { PricingTable } from "@/features/billing/components/PricingTable";
import { SubscriptionDetails } from "@/features/billing/components/SubscriptionDetails";
import { useGetSubscription } from "@/features/billing/api/useGetSubscription";
import { useSearchParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export const BillingPageContent = () => {
  const [searchParams] = useSearchParams();
  
  const success = searchParams.get("success") === "true" || false;

  const { data, isLoading } = useGetSubscription(success);
  

  if (isLoading || (success && !data?.subscription?.isActive)) {
    return (
      <VStack gap={6}>
        <Skeleton height={84} />
        <Skeleton height={400} />
      </VStack>
    );
  }

  if (data?.subscription?.isActive) {
    return <SubscriptionDetails subscription={data.subscription} />;
  }

  return (
    <VStack gap={6}>
      <Card>
        <PricingTable />
      </Card>
    </VStack>
  );
};