import { Filter, type FilterProps } from "@/features/filter";
import {
  FormLabel,
  Grid,
  NumberField,
  Radio,
  RadioGroup,
  Text,
  VStack
} from "@/components";
import { type JobStatus } from "@/features/jobs/types/common";
import { type ListFilterItem } from "@/features/filter/types/common";
import { type RoleFilterQueryParamKeys, type RoleFilters, type RoleStatus } from "@/features/roles/types/common";
import { useMarketContentTranslation, useTranslation } from "@/hooks/useTranslation";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import { FilterSelector } from "@/features/filter/components/FilterSelector";
import { type SearchResultItem } from "@/features/search/types/common";
import { searchEmployees } from "@/features/filter/components/FiltersForm";

interface Props extends FilterProps {
  filters: RoleFilters;
  showSkillGapFilters?: boolean;
}

const DEFAULT = "";
const itemKeyGetter = ({ id }: SearchResultItem) => id;

export const RoleFilter = (props: Props) => {
  const { t } = useTranslation();
  const { setFilters, filters, showSkillGapFilters } = props;
  const { hasFeature } = useFeatures();
  const { translateMarketContent } = useMarketContentTranslation();
  const itemNameGetter = ({ name }: SearchResultItem) => translateMarketContent(name);

  const onChange = (field: RoleFilterQueryParamKeys, value: number | JobStatus | ListFilterItem<RoleStatus>[] | ListFilterItem<string>[]) => {
    setFilters({
      [field]: value
    });
  };

  const onChangeRadioValue = (field: RoleFilterQueryParamKeys, value: string) => {
    const fieldValue = value === DEFAULT
      ? undefined
      : value;
      
    setFilters({
      [field]: fieldValue
    });
  };

  const onCollaboratorChange = ({ collaboratorIds }: Partial<RoleFilters>) => {
    onChange("collaboratorIds", collaboratorIds ?? []);
  };

  return (
    <Filter {...props}>
      <VStack gap={8}>
        {showSkillGapFilters && (
          <Grid cols={2} className="items-end">
            <NumberField
              minValue={0}
              maxValue={100}
              label={t("roles.filters.skillGap.from.label")}
              defaultValue={filters["skillGapFrom"] || undefined}
              onChange={value => onChange("skillGapFrom", value)}
            />
            <NumberField
              aria-label={t("roles.filters.skillGap.to.label")}
              minValue={0}
              maxValue={100}
              defaultValue={filters["skillGapTo"] || undefined}
              onChange={value => onChange("skillGapTo", value)}
            />
          </Grid>
        )}
        <RadioGroup 
          onChange={value => onChangeRadioValue("jobStatus", value)}
          label={t("roles.filters.roleOpenings.label")}
          defaultValue={filters["jobStatus"] || ""}
        >
          <Radio value={DEFAULT}>
            <Text size="xs">{t("roles.filters.roleOpenings.all")}</Text>
          </Radio>
          <Radio value="Open">
            <Text size="xs">{t("roles.filters.roleOpenings.open")}</Text>
          </Radio>
        </RadioGroup>
        {hasFeature("Features.RoleCollaboratorFilters") && (
          <VStack gap={2.5}>
            <FormLabel>{t("roles.filters.collaborators.label")}</FormLabel>
            <FilterSelector<SearchResultItem, RoleFilters>
              key="collaboratorIds"
              field="collaboratorIds"
              autoCompleteProps={{
                fetchItemsQuery: searchEmployees,
                selectedItems: filters["collaboratorIds"],
                itemKeyGetter,
                itemNameGetter
              }}
              onChange={onCollaboratorChange}
            />
          </VStack>
        )}
      </VStack>
    </Filter>
  );
};