import { Helmet } from "react-helmet-async";
import { RoleManagementList } from "@/features/roles/components/role-management/RoleManagementList";
import { useTranslation } from "@/hooks/useTranslation";

export const RolesManagementPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("pages.settingsRoles")}</title>
      </Helmet>
      <RoleManagementList />
    </>
  );
};
