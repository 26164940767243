import { ConfirmationModal } from "@/components";
import { useDeleteSkillMutation } from "@/features/skill/hooks/useDeleteSkillMutation";
import { useTranslation } from "@/hooks/useTranslation";
import { type OverlayTriggerState } from "react-stately";

interface Props {
  skillName: string;
  onDeleteSuccess?: () => void;
  overlayState: OverlayTriggerState;
}

export const NoImpactSkillDeletionModal = ({ skillName, overlayState, onDeleteSuccess }: Props) => {
  const { t } = useTranslation();
  const message = t("skills.delete.noImpact.summary");
  const { mutate: deleteSkill, isPending } = useDeleteSkillMutation(() => {
    overlayState.close();
    onDeleteSuccess?.();
  });
  const onClickConfirm = () => deleteSkill(skillName);

  return (
    <ConfirmationModal
      title={t("skills.delete.title")}
      onClickConfirm={onClickConfirm}
      overlayState={overlayState}
      cancelButtonContent={t("common.form.cancel")}
      confirmButtonContent={t("skills.actions.delete")}
      confirmButtonProps={{ loading: isPending }}
      cancelButtonProps={{ isDisabled: isPending }}
      dontCloseOnConfirm
    >
      {message}
    </ConfirmationModal>
  );
};