import { AtsIntegrationList } from "./AtsIntegrationList";
import { ModalDialog } from "@/components";
import { OverlayContainer } from "react-aria";
import { type OverlayTriggerState } from "react-stately";
import { useConnectAts } from "@/features/ats/hooks/useConnectAts";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  overlayState: OverlayTriggerState;
}

export const AtsConnectModal = ({ overlayState }: Props) => {
  const { t } = useTranslation();
  const { openMergeLink } = useConnectAts();

  const onConfirm = (integration: string, token: string) => {
    overlayState.close();
    
    openMergeLink(integration, token);
  };

  if (!overlayState.isOpen) {
    return null;
  }

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("ats.modal.title")}
        isOpen
        onClose={overlayState.close}
        isDismissable
      >
        <AtsIntegrationList
          onClose={overlayState.close}
          onConfirm={onConfirm}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};