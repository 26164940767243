import { SearchSkills } from "@/features/search/components/search-skills/SearchSkills";
import { type SkillRatingForm } from "@/features/skill-rating/types/skillRatingForm";
import { type UncategorizedSkillDto } from "@/features/skills/types/Skill";
import { Flex } from "@workleap/orbiter-ui";
import { sortBy } from "lodash";
import { useFormContext, useWatch } from "react-hook-form";
import { DraggableSkill } from "./DraggableSkill";

interface Props {
  jobTitle?: string;
}

export const SkillRatingUncategorizedSkills = ({ jobTitle }: Props) => {
  const { setValue } = useFormContext<SkillRatingForm>();
  const skills = useWatch<SkillRatingForm, "skills">({ name: "skills" });
  const uncategorizedSkills = useWatch<SkillRatingForm, "uncategorizedSkills">({ name: "uncategorizedSkills" });

  const handleSelect = (skill?: UncategorizedSkillDto) => {
    if (skill) {
      setValue("uncategorizedSkills", sortBy([...uncategorizedSkills, { ...skill, isUncategorized: true }], "name"), { shouldDirty: true });
    }
  };

  const alreadySelectedSkills = [...skills.map(x => x.name), ...uncategorizedSkills.map(x => x.name)];

  return (
    <Flex gap="inline-sm" wrap="wrap" className="max-h-[132px] overflow-auto min-h-[36px]">
      <SearchSkills
        width={{
          base: "100%",
          md: "280px"
        }}
        size="sm"
        disabledItems={alreadySelectedSkills}
        jobTitle={jobTitle}
        onSkillSelect={handleSelect}
      />
      {uncategorizedSkills.map(skill => (
        <DraggableSkill key={skill.name} {...skill} />
      ))}
    </Flex>
  );
};