import { useAnalytics } from "@/features/analytics/hooks/useAnalytics";
import { useDirectoryRoutes } from "@/features/directory/hooks/useDirectoryRoutes";
import { useGetSetupQuery } from "@/features/setup/hooks/useGetSetupQuery";
import { UserAvatar } from "@/features/users/components/UserAvatar";
import { useTranslation } from "@/hooks/useTranslation";
import { Authorize } from "@/lib/auth";
import { useCurrentUser } from "@/lib/auth/AuthProvider";
import { Routes } from "@/types";
import classNames from "classnames";
import { motion } from "framer-motion";
import { type MouseEventHandler, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useClickAway } from "react-use";

const showMenu = {
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.1
    },
    display: "block"
  },
  exit: {
    y: -5,
    opacity: 0,
    transition: {
      duration: 0.1
    },
    transitionEnd: {
      display: "none"
    }
  }
};

const sharedLinkClasses = "transition-colors";
const defaultLinkClasses = classNames(sharedLinkClasses, "hover:text-neutral-text-weak");
const listItemClasses = "py-2 px-5";
const menuClasses = "bg-neutral-surface rounded-xl border-neutral-border-weak border-[1px] shadow-elevation-raised z-50 absolute top-11 py-[10px] right-0 w-60";

export const UserMenu = () => {
  const { person, editPersonSkills } = useDirectoryRoutes();
  const currentUser = useCurrentUser();
  const { isCompleted } = useGetSetupQuery();
  const [shown, setShown] = useState(false);
  const ref = useRef(null);
  useClickAway(ref, () => setShown(false));
  const { t } = useTranslation();
  const { logout } = useAnalytics();
  const location = useLocation();

  const { firstName, lastName, avatar, employeeId } = currentUser;

  const onLogoutClick: MouseEventHandler<HTMLAnchorElement> = e => {
    e.stopPropagation();
    logout();
    location.pathname = e.currentTarget.href;
  };

  if (!isCompleted) {
    return null;
  }

  return (
    <div className="relative" ref={ref}>
      <UserAvatar
        onClick={() => setShown(!shown)}
        firstName={firstName}
        lastName={lastName}
        avatarUrl={avatar.avatarUrl}
      />
      <motion.ul
        variants={showMenu}
        initial="exit"
        animate={shown
          ? "enter"
          : "exit"}
        className={menuClasses}
      >
        <li className={listItemClasses}>
          <Authorize permission="Permissions.UserProfile.Edit">
            <Link to={person(employeeId)} className={defaultLinkClasses}>
              {t("userMenu.myProfile")}
            </Link>
          </Authorize>
        </li>
        <li className={listItemClasses}>
          <Authorize permission="Permissions.UserProfile.Edit">
            <Link to={editPersonSkills} className={defaultLinkClasses}>
              {t("employee.edit.updateSkills")}
            </Link>
          </Authorize>
        </li>
        <li className={listItemClasses}>
          <Link to={Routes.Grow} className={defaultLinkClasses}>
            {t("employee.tabs.progressionPlan")}
          </Link>
        </li>
        <li className={listItemClasses}>
          <a
            href="/logout"
            title={t("common.actions.logout")}
            onClick={onLogoutClick}
            className={classNames(sharedLinkClasses, "text-danger-icon hover:text-danger-icon-hover")}
          >
            {t("common.actions.logout")}
          </a>
        </li>
      </motion.ul>
    </div>
  );
};
