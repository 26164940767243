import { AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { Button, Card, Grid, Text, VStack } from "@/components";
import { ReactComponent as StripeIcon } from "@/features/billing/assets/stripe.svg";
import { type SubscriptionDto } from "@/features/billing/types/common";
import { useCreateCustomerPortalSessionMutation } from "@/features/billing/api/useCreateCustomerPortalSessionMutation";
import { useFormatDate } from "@/hooks/useFormatDate";
import { useTrackBillingAction } from "@/features/billing/hooks/useTrackBillingAction";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  subscription: SubscriptionDto;
}

export const SubscriptionDetails = ({ subscription }: Props) => {
  const { t } = useTranslation();
  const { trackBillingAction } = useTrackBillingAction();

  const { mutate, isPending } = useCreateCustomerPortalSessionMutation();

  const onCustomerPortalClick = () => {
    trackBillingAction(AnalyticValues.actionCustomerPortal);
    mutate();
  };

  return (
    <Grid cols={2}>
      <Card>
        <VStack gap={6}>
          <SubscriptionDate cancelAt={subscription.cancelAt} renewAt={subscription.renewAt} />
          <Button onClick={onCustomerPortalClick} loading={isPending} icon={<StripeIcon />}>{t("billing.subscription.customerPortal")}</Button>
        </VStack>
      </Card>
    </Grid>
  );
};

const SubscriptionDate = ({ cancelAt, renewAt }: SubscriptionDto) => {
  const { t } = useTranslation();
  const format = useFormatDate();
  
  if (cancelAt) {
    return (
      <VStack gap={1}>    
        <Text>{t("billing.subscription.cancelAt")}</Text>
        <Text>{format(cancelAt, t("common.formats.dates.monthDayYear"))}</Text>
      </VStack>
    );
  }

  if (renewAt) {
    return (
      <VStack gap={1}>    
        <Text>{t("billing.subscription.renewAt")}</Text>
        <Text size="md">{format(renewAt, t("common.formats.dates.monthDayYear"))}</Text>
      </VStack>
    );
  }

  return null;
};