import { Button, ButtonGroup, HStack, Heading } from "@/components";
import { Routes } from "@/types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  reset: () => void;
  isDirty: boolean;
  isLoading: boolean;
}

export const UserProfileHeader = ({ reset, isDirty, isLoading }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClickCancel = async() => {
    if (isDirty) {
      await reset();
    }
    
    navigate(Routes.Home);
  };

  return (
    <HStack justify="between" align="center">
      <Heading size="3xl">{t("profile.title")}</Heading>
      <ButtonGroup>
        <Button variant="secondary" onClick={onClickCancel}>
          {t("common.form.cancel")}
        </Button>
        <Button type="submit" isDisabled={!isDirty} loading={isLoading}>
          {t("common.form.save")}
        </Button>
      </ButtonGroup>
    </HStack>
  );
};