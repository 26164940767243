import { ChangeAuditContent } from "@/features/app/components/ChangeAuditContent";
import { RecommendedSkillMentors } from "@/features/employees/components/skills-match/RecommendedSkillMentors";
import { useGetSkillFlyoutSuspenseQuery } from "@/features/skill/hooks/useGetSkillFlyout";
import { useSkillTranslations } from "@/features/skill/hooks/useSkillTranslations";
import { allSkillRatings, type SkillFlyoutRating } from "@/features/skill/types/skillTypes";
import { UserAvatarList } from "@/features/users/components/UserAvatarList";
import { useTranslation } from "@/hooks/useTranslation";
import { Content, Flex, Header, Heading, Item, Tabs, Text } from "@workleap/orbiter-ui";
import { isEmpty } from "lodash";
import { useCallback, type SyntheticEvent } from "react";
import { SimilarSkill } from "./SimilarSkill";
import { SkillRatingDescription } from "./SkillRatingDescription";

interface Props {
  onChangeRating: (rating: SkillFlyoutRating) => void;
  activeRating: SkillFlyoutRating;
  skillName: string;
  showRecommendedMentors?: boolean;
}

const NUMBER_OF_EMPLOYEES_TO_SHOW = 6;

export const SkillFlyoutViewModeContent = (props: Props) => {
  const { activeRating, skillName, showRecommendedMentors, onChangeRating } = props;
  
  const { t } = useTranslation();
  const { data: { possessedBy, ratingsDescriptions, creationSource, similarSkills, changeAudit } } = useGetSkillFlyoutSuspenseQuery(skillName);
  const { getSkillRatingTranslation } = useSkillTranslations();

  const handleSelectionChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, rating: string) => {
      onChangeRating(rating as SkillFlyoutRating);
    },
    [onChangeRating]
  );

  const isGeneratedByAI = creationSource?.sourceName === "Autopilot";

  const tabItems = allSkillRatings.map(rating => ({
    key: rating,
    header: getSkillRatingTranslation(rating),
    content: () => {
      const employeesWithSkill = possessedBy[rating] || [];

      return (
        <Flex direction="column" gap="32px" paddingX="16px" paddingY="8px">
          <Text size="sm" className="overflow-y-auto h-[40vh] min-h-[174px]">
            <SkillRatingDescription 
              description={ratingsDescriptions[rating]}
              rating={rating}
            />
          </Text>
          {!isEmpty(employeesWithSkill) && (
            <Flex gap="inline-sm" alignItems="center">
              <UserAvatarList
                userAvatars={(possessedBy[rating] || []).map(x => x.avatar)}
                count={NUMBER_OF_EMPLOYEES_TO_SHOW}
                emptyState={<Text>{t("customSkills.description.noEmployees")}</Text>}
              />
              <Text size="sm" color="neutral-weak">
                {t("customSkills.description.employees", { count: employeesWithSkill.length })}
              </Text>
            </Flex>
          )}
        </Flex>
      );
    }   
  }));

  return (
    <Flex direction="column" gap="inline-lg">
      <Flex direction="column" gap="6px">
        <Tabs variant="in-card" aria-label="Skill ratings" selectedKey={activeRating} onSelectionChange={handleSelectionChange}>
          {tabItems.map(({ key, header, content }) => (
            <Item key={key}>
              <Header>{header}</Header>
              <Content>{content()}</Content>
            </Item>
          ))}
        </Tabs>

        <ChangeAuditContent isGeneratedByAI={isGeneratedByAI} changeAudit={changeAudit} textAlign="right" />
      </Flex>

      {!isEmpty(similarSkills) && (
        <Flex direction="column" gap="inline-md">
          <Flex direction="column" gap="4px">
            <Heading size="md">{t("customSkills.description.similarSkills")}</Heading>
            <Text size="sm" color="neutral-weak">{t("customSkills.description.similarSkillsDescription")}</Text>
          </Flex>
          <Flex gap="8px" wrap="wrap">
            {similarSkills.map(({ name }) => <SimilarSkill key={name} skillName={name} />)}
          </Flex>
        </Flex>
      )}

      {showRecommendedMentors && <RecommendedSkillMentors skillName={skillName} targetRating={activeRating} />}
    </Flex>
  );
};
