import { type SearchRequest } from "@/features/search/types/common";
import { type GetSegmentNamesByIdsQueryDto, type WorkleapSegmentDto, type WorkleapTeamDto } from "@/features/workleap/types/common";
import api from "@/lib/http/api";

export const searchTeams = async ({ search: searchTerm }: SearchRequest) =>
  await api.get<WorkleapTeamDto[]>("/workleap/teams/search", {
    params: {
      searchTerm
    }
  });

export const searchSegments = async ({ search: searchTerm }: SearchRequest) =>
  await api.get<WorkleapSegmentDto[]>("/workleap/segments/search", {
    params: { searchTerm }
  });

export const getTeamNamesByIds = async (teamIds: string[]) =>
  await api.get<GetSegmentNamesByIdsQueryDto>("/workleap/teams/names", {
    params: { teamIds }
  });

export const getSegmentNamesByIds = async (segmentIds: string[]) =>
  await api.get<GetSegmentNamesByIdsQueryDto>("/workleap/segments/names", {
    params: { segmentIds }
  });