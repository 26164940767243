import { type Dispatch, type SetStateAction } from "react";
import { type EmployeeEmploymentFormState } from "@/features/employees/types/common";
import { type JobTitleOptionItem, SearchJobTitles } from "@/features/search/components/search-job-titles/SearchJobTitles";
import { SearchRoleThenJob } from "@/features/search/components/search-role-then-job/SearchRoleThenJob";
import { type SearchRoleThenJobResponseItem } from "@/features/search/hooks/useSearchRoleThenJob";
import { type ValidationErrorResult } from "@/models/error";
import { mapEmploymentToSelectRoleOption } from "@/features/employees/components/employee-form/utils";
import { useGetHrisSettingsQuery } from "@/features/hris/hooks/useGetHrisSettings";
import { useTranslation } from "@/hooks/useTranslation";

interface Props {
  commandErrors?: ValidationErrorResult;
  employment?: EmployeeEmploymentFormState;
  onChange: Dispatch<SetStateAction<EmployeeEmploymentFormState | undefined>>;
}

export const EmploymentModalSearch = ({ commandErrors, employment, onChange }: Props) => {
  const { t } = useTranslation();
  const { data: hrisSettings } = useGetHrisSettingsQuery();

  const onItemSelectedCurrentRole = (role?: SearchRoleThenJobResponseItem) => {
    if (!role) {
      return;
    }

    onChange({
      ...employment,
      roleName: role.name,
      roleId: role.roleId,
      marketRoleId: role.marketRoleId,
      isInternal: !!role.roleId
    });
  };

  const onSelectJobTitle = ({ value: marketRoleId }: JobTitleOptionItem) => {
    if (!marketRoleId) { return; }

    onChange({
      ...employment,
      roleName: marketRoleId,
      roleId: undefined,
      marketRoleId
    });
  };

  if (hrisSettings?.isConnected) {
    return (
      <SearchJobTitles 
        label={t("employee.edit.roleName")}
        description={t("employee.edit.roleNamePlaceholder")}
        onSelect={onSelectJobTitle}
        errorMessage={commandErrors?.field("Body.Employments[0].RoleId")}
        showExternalChip
        initialValue={employment?.marketRoleId}
      />
    );
  }

  const initialValue = mapEmploymentToSelectRoleOption(employment);

  return (
    <SearchRoleThenJob 
      label={t("employee.edit.roleName")}
      description={t("employee.edit.roleNamePlaceholder")}
      onRoleSelected={onItemSelectedCurrentRole}
      errorMessage={commandErrors?.field("Body.Employments[0].RoleId")}
      initialValue={initialValue}
    />
  );
};
