/* eslint-disable-next-line no-undef */
module.exports = {
  // Used as a secondary brand accent colour
  "accent-light": "var(--hop-status-option6-surface)",
  "accent-dark": "var(--hop-status-option6-surface-strong)",
  
  // Used for the avatars
  "avatar": "var(--hop-decorative-option5-surface-strong)",
  "avatar-dark": "var(--hop-decorative-option1-surface-strong)",

  // Typical colors
  transparent: "transparent",

  "premium-from": "#FFE500",
  "premium-to": "#F0C000",

  "overlay": "#00000040",
  "flyout-overlay": "#00000026",

  // Chart colors (they cannot use the tokens)
  "chart-tooltip-background": "#FFFFFF",
  "chart-data": "#4767FE",
  "chart-body": "#3C3C3C",
  "chart-title": "#3C3C3C",
  "chart-border": "#F2F2F2",

  "participation-chart-draft": "#4767FE",
  "participation-chart-published": "#ACDCFF",

  "objective-chart-growth": "#ACDCFF",
  "objective-chart-nextstep": "#4767FE",

  "timeframe-chart-asap": "#ACDCFF",
  "timeframe-chart-short": "#4767FE",
  "timeframe-chart-medium": "#1C34BF",
  "timeframe-chart-long": "#132380",

  "activity-type-chart-training": "#ACDCFF",
  "activity-type-chart-coaching": "#4767FE",
  "activity-type-chart-applied": "#1C34BF",

  "skills-variation-chart-lost": "#1DAC92",
  "skills-variation-chart-added": "#D2EEE9",

  /**************************************
   * NEW HOPPER COLORS
   **************************************/
  "neutral-text-hover": "var(--hop-neutral-text-hover)",
  "neutral-surface-weakest-press": "var(--hop-neutral-surface-weakest-press)",
  "neutral-surface-weakest-selected": "var(--hop-neutral-surface-weakest-selected)",
  "neutral-surface-weakest-hover": "var(--hop-neutral-surface-weakest-hover)",
  "neutral-text-weak": "var(--hop-neutral-text-weak)",
  "neutral-surface-disabled": "var(--hop-neutral-surface-disabled)",
  "neutral-surface-press": "var(--hop-neutral-surface-press)",
  "neutral-surface-weak": "var(--hop-neutral-surface-weak)",
  "neutral-surface": "var(--hop-neutral-surface)",
  "neutral-text-disabled": "var(--hop-neutral-text-disabled)",
  "neutral-text-press": "var(--hop-neutral-text-press)",
  "neutral-text-strong": "var(--hop-neutral-text-strong)",
  "neutral-text": "var(--hop-neutral-text)",
  "neutral-text-weakest": "var(--hop-neutral-text-weakest)",
  "neutral-border-disabled": "var(--hop-neutral-border-disabled)",
  "neutral-border-strong-hover": "var(--hop-neutral-border-strong-hover)",
  "neutral-border-strong": "var(--hop-neutral-border-strong)",
  "neutral-surface-strong": "var(--hop-neutral-surface-strong)",
  "neutral-surface-hover": "var(--hop-neutral-surface-hover)",
  "neutral-icon-disabled": "var(--hop-neutral-icon-disabled)",
  "neutral-icon": "var(--hop-neutral-icon)",
  "neutral-icon-weak": "var(--hop-neutral-icon-weak)",
  "neutral-icon-weak-hover": "var(--hop-neutral-icon-weak-hover)",
  "neutral-border": "var(--hop-neutral-border)",
  "neutral-border-weak": "var(--hop-neutral-border-weak)",
  "neutral-icon-weakest": "var(--hop-neutral-icon-weakest)",
  "neutral-icon-strong": "var(--hop-neutral-icon-strong)",
  "neutral-icon-hover": "var(--hop-neutral-icon-hover)",
  "neutral-icon-press": "var(--hop-neutral-icon-press)",
  "neutral-border-hover": "var(--hop-neutral-border-hover)",
  "neutral-border-press": "var(--hop-neutral-border-press)",
  "neutral-surface-weak-hover": "var(--hop-neutral-surface-weak-hover)",
  "neutral-border-weakest": "var(--hop-neutral-border-weakest)",
  "neutral-surface-weakest": "var(--hop-neutral-surface-weakest)",
  "neutral-surface-selected": "var(--hop-neutral-surface-selected)",
  "neutral-border-selected": "var(--hop-neutral-border-selected)",
  
  "samoyed": "var(--hop-samoyed)",

  "primary-icon-press": "var(--hop-primary-icon-press)",
  "primary-border-press": "var(--hop-primary-border-press)",
  "primary-text-press": "var(--hop-primary-text-press)",
  "primary-icon-strong": "var(--hop-primary-icon-strong)",
  "primary-icon-strong-hover": "var(--hop-primary-icon-strong-hover)",
  "primary-icon": "var(--hop-primary-icon)",
  "primary-border-focus": "var(--hop-primary-border-focus)",
  "primary-border": "var(--hop-primary-border)",
  "primary-icon-hover": "var(--hop-primary-icon-hover)",
  "primary-icon-disabled": "var(--hop-primary-icon-disabled)",
  "primary-surface-disabled": "var(--hop-primary-surface-disabled)",
  "primary-surface-focus": "var(--hop-primary-surface-focus)",
  "primary-text-disabled": "var(--hop-primary-text-disabled)",
  "primary-surface": "var(--hop-primary-surface)",
  "primary-surface-weak": "var(--hop-primary-surface-weak)",
  "primary-surface-strong": "var(--hop-primary-surface-strong)",
  "primary-surface-strong-press": "var(--hop-primary-surface-strong-press)",
  "primary-surface-strong-hover": "var(--hop-primary-surface-strong-hover)",
  "primary-text": "var(--hop-primary-text)",
  "primary-surface-hover": "var(--hop-primary-surface-hover)",
  "primary-text-strong": "var(--hop-primary-text-strong)",
  "primary-text-strong-hover": "var(--hop-primary-text-strong-hover)",
  "primary-text-hover": "var(--hop-primary-text-hover)",
  "primary-surface-press": "var(--hop-primary-surface-press)",

  "success-border": "var(--hop-success-border)",
  "success-icon-weak": "var(--hop-success-icon-weak)",
  "success-icon-weakest": "var(--hop-success-icon-weakest)",
  "success-text-weak": "var(--hop-success-text-weak)",
  "success-surface": "var(--hop-success-surface)",
  "success-surface-strong": "var(--hop-success-surface-strong)",
  "success-text-hover": "var(--hop-success-text-hover)",
  "success-text": "var(--hop-success-text)",
  "success-icon": "var(--hop-success-icon)",
  "success-surface-weak": "var(--hop-success-surface-weak)",

  "warning-icon-weakest": "var(--hop-warning-icon-weakest)",
  "warning-icon-weak": "var(--hop-warning-icon-weak)",
  "warning-text-weak": "var(--hop-warning-text-weak)",
  "warning-surface": "var(--hop-warning-surface)",
  "warning-surface-strong": "var(--hop-warning-surface-strong)",
  "warning-surface-weak": "var(--hop-warning-surface-weak)",
  "warning-border": "var(--hop-warning-border)",
  "warning-icon": "var(--hop-warning-icon)",
  "warning-text": "var(--hop-warning-text)",
  "warning-text-strong": "var(--hop-status-caution-text)",

  "danger-border-press": "var(--hop-danger-border-press)",
  "danger-icon-press": "var(--hop-danger-icon-press)",
  "danger-text-press": "var(--hop-danger-text-press)",
  "danger-surface": "var(--hop-danger-surface)",
  "danger-text-hover": "var(--hop-danger-text-hover)",
  "danger-surface-disabled": "var(--hop-danger-surface-disabled)",
  "danger-surface-hover": "var(--hop-danger-surface-hover)",
  "danger-surface-strong": "var(--hop-danger-surface-strong)",
  "danger-surface-strong-hover": "var(--hop-danger-surface-strong-hover)",
  "danger-surface-weak": "var(--hop-danger-surface-weak)",
  "danger-border-strong": "var(--hop-danger-border-strong)",
  "danger-icon": "var(--hop-danger-icon)",
  "danger-icon-weak": "var(--hop-danger-icon-weak)",
  "danger-text": "var(--hop-danger-text)",
  "danger-surface-press": "var(--hop-danger-surface-press)",
  "danger-text-weak": "var(--hop-danger-text-weak)",
  "danger-text-strong": "var(--hop-danger-text-strong)",
  "danger-text-strong-hover": "var(--hop-danger-text-strong-hover)",
  "danger-icon-strong": "var(--hop-danger-icon-strong)",
  "danger-icon-strong-hover": "var(--hop-danger-icon-strong-hover)",
  "danger-icon-hover": "var(--hop-danger-icon-hover)",
  "danger-text-disabled": "var(--hop-danger-text-disabled)",
  "danger-icon-disabled": "var(--hop-danger-icon-disabled)",
  "danger-border": "var(--hop-danger-border)",
  
  "information-icon-weakest": "var(--hop-information-icon-weakest)",
  "information-surface-strong": "var(--hop-information-surface-strong)",
  "information-icon-weak": "var(--hop-information-icon-weak)",
  "information-text": "var(--hop-information-text)",
  "information-surface": "var(--hop-information-surface)",
  "information-surface-weak": "var(--hop-information-surface-weak)",
  "information-text-weak": "var(--hop-information-text-weak)",
  "information-icon": "var(--hop-information-icon)",
  "information-border": "var(--hop-information-border)",
  "upsell-border-press": "var(--hop-upsell-border-press)",
  "upsell-text-press": "var(--hop-upsell-text-press)",
  "upsell-icon-weakest": "var(--hop-upsell-icon-weakest)",
  "upsell-icon-weak": "var(--hop-upsell-icon-weak)",
  "upsell-border": "var(--hop-upsell-border)",
  "upsell-surface-hover": "var(--hop-upsell-surface-hover)",
  "upsell-border-disabled": "var(--hop-upsell-border-disabled)",
  "upsell-icon-press": "var(--hop-upsell-icon-press)",
  "upsell-surface-weak": "var(--hop-upsell-surface-weak)",
  "upsell-surface-disabled": "var(--hop-upsell-surface-disabled)",
  "upsell-text": "var(--hop-upsell-text)",
  "upsell-icon": "var(--hop-upsell-icon)",
  "upsell-text-hover": "var(--hop-upsell-text-hover)",
  "upsell-text-disabled": "var(--hop-upsell-text-disabled)",
  "upsell-surface-press": "var(--hop-upsell-surface-press)",
  "upsell-surface": "var(--hop-upsell-surface)",

  // Decorative option 1
  "decorative-option1-icon": "var(--hop-decorative-option1-icon)",
  "decorative-option1-text": "var(--hop-decorative-option1-text)",
  "decorative-option1-surface-strong": "var(--hop-decorative-option1-surface-strong)",

  // Decorative option 2
  "decorative-option2-surface": "var(--hop-decorative-option2-surface)",

  // Decorative option 5
  "decorative-option5-surface-weakest": "var(--hop-decorative-option5-surface-weakest)",
  "decorative-option5-surface": "var(--hop-decorative-option5-surface)",

  // Decorative option 8
  "decorative-option8-surface-weakest": "var(--hop-decorative-option8-surface-weakest)",

  // Status neutral
  "status-neutral-surface": "var(--hop-status-neutral-surface)",
  "status-neutral-text": "var(--hop-status-neutral-text)",

  // Status progress
  "status-progress-text": "var(--hop-status-progress-text)",
  "status-progress-icon": "var(--hop-status-progress-icon)",

  // Status positive
  "status-positive-icon": "var(--hop-status-positive-icon)",
  "status-positive-text": "var(--hop-status-positive-text)",
  "status-position-border": "var(--hop-status-position-border)",
  
  // Status inactive
  "status-inactive-surface": "var(--hop-status-inactive-surface)",

  // Status option 3
  "status-option3-surface": "var(--hop-status-option3-surface)"
};