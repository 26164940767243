import { type AxiosResponse } from "axios";
import { type ListFilterItem } from "@/features/filter/types/common";
import { type SearchResponse } from "@/features/search/types/common";

export const paramsToArray = (params: string | null) => {
  if (!params) {
    return [];
  }
  
  return params.split(",").map(value => ({ name: value, id: value }));
};

export const setNamesFromIds = async (func: (ids: string[]) => Promise<AxiosResponse<SearchResponse>>, listFilterItems: ListFilterItem[]) => {
  const ids = listFilterItems.map(x => x.id);
  const result = await func(ids);

  if (result.status === 200) {
    listFilterItems.forEach(item => {
      item.name = result.data.results.find(x => x.id === item.id)?.name ?? item.name;
    });
  }
};

export const setNamesForKnownIds = <TKey = string>(listFilterItems: ListFilterItem[], idToNameMap: Map<TKey, string>) => {
  listFilterItems.forEach(item => {
    item.name = idToNameMap.get(item.id as TKey) ?? item.name;
  });
};
