import { type RoleSentiment } from "@/features/roles/types/common";
import { type EmployeesQueryParameters } from "./common";

export const employeesQueryKeys = {
  all: [{ scope: "employees" }] as const,
  lists: () => [{ ...employeesQueryKeys.all[0], entity: "list" }] as const,
  list: (params: EmployeesQueryParameters) => [{ ...employeesQueryKeys.lists(), ...params }] as const,
  single: (employeeId: string) => [{ ...employeesQueryKeys.all[0], entity: "single", employeeId }] as const,
  editModel: (employeeId: string) => [{ ...employeesQueryKeys.all[0], entity: "editModel", employeeId }] as const,
  resume: (employeeId: string) => [{ ...employeesQueryKeys.all[0], entity: "resume", employeeId }] as const,
  topMatchingRoles: (employeeId: string) => [{ ...employeesQueryKeys.all[0], entity: "topMatchingRoles", employeeId }] as const,
  roleInterests: (employeeId: string, sentimentFilter?: RoleSentiment) => [{ ...employeesQueryKeys.all[0], entity: "roleInterests", employeeId, sentimentFilter }] as const,
  roleRecommendations: (employeeId: string) => [{ ...employeesQueryKeys.all[0], entity: "roleInterests", employeeId }] as const,
  roleSkillDiff: (employeeId: string, roleId: string) => [{ ...employeesQueryKeys.all[0], entity: "roleSkillDiff", employeeId, roleId }] as const,
  searchEmployees: (query: string) => [{ ...employeesQueryKeys.all[0], entity: "searchEmployees", query }] as const,
  getEmployeeBio: (employeeId: string) => [{ ...employeesQueryKeys.all[0], entity: "getEmployeeBio", employeeId }] as const
};
