import { type UpdateTrainingCommand } from "@/features/training/types/common";
import { toast } from "react-toastify";
import { trainingQueryKeys } from "@/features/training/types/trainingQueryKeys";
import { translate } from "@/lib/i18n";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import { useQueryClient } from "@tanstack/react-query";
import api from "@/lib/http/api";

interface Payload extends UpdateTrainingCommand {
  id: string;
}

export const useUpdateTrainingMutation = () => {
  const queryClient = useQueryClient();

  return useMutationWithValidationResult({
    mutationFn: async ({ id, ...rest }: Payload) => {
      const response = await api.post<UpdateTrainingCommand>(`/trainings/${id}`, rest);

      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: trainingQueryKeys.all });
      toast.success(translate("common.messages.changesSaved"));
    }
  });
};