import { type BulkPublishRolesCommand } from "@/features/roles/types/common";
import { roleQueryKeys } from "@/features/roles/types/roleQueryKeys";
import { toast } from "react-toastify";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "@/hooks/useTranslation";
import api from "@/lib/http/api";

export const useBulkPublishRolesMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  
  return useMutationWithValidationResult<BulkPublishRolesCommand>({
    mutationFn: async ({ ids } : BulkPublishRolesCommand) => {
      await api.post("roles/bulk/publish", { ids });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: roleQueryKeys.all });
      
      toast.success(t("common.messages.changesSaved"));
    }
  });
};
