import { AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { Button } from "@/components";
import { useGetHrisSettingsQuery } from "@/features/hris/hooks/useGetHrisSettings";
import { useScheduleSyncMutation } from "@/features/hris/hooks/useScheduleSyncMutation";
import { useTrackHrisAction } from "@/features/hris/hooks/useTrackHrisAction";
import { useTranslation } from "@/hooks/useTranslation";

const POLLING_INTERVAL_MS = 5000;

export const StartHrisSyncButton = () => {
  const { t } = useTranslation();
  const { data } = useGetHrisSettingsQuery({
    refetchInterval: query => {
      if (query.state.data?.isScheduledOrRunning) {
        return POLLING_INTERVAL_MS;
      }
      
      return false;
    }
  });
  const { mutate: scheduleSync, isPending } = useScheduleSyncMutation();
  const { trackHrisAction } = useTrackHrisAction();

  const onStartHrisSyncClick = () => {
    trackHrisAction(AnalyticValues.actionStartSync);
    scheduleSync();
  };
  
  return (
    <Button
      variant="primary"
      onClick={onStartHrisSyncClick}
      isDisabled={data?.isScheduledOrRunning}
      loading={isPending}
    >
      {t("hrisSettings.activate.button")}
    </Button>
  );
};