import { useParams } from "@/hooks/useParams";

export interface EmployeeRouteParams {
  employeeId: string;
}

export const useEmployeeParams = () => {
  const { employeeId } = useParams<EmployeeRouteParams>();

  return employeeId;
};