import { EmployeeForm } from "@/features/employees/components/employee-form/EmployeeForm";
import { type SubmitData } from "@/features/employees/components/employee-form/types";
import { useEditEmployeeMutation } from "@/features/employees/hooks/useEditEmployeeMutation";
import { useGetEditEmployeeModel } from "@/features/employees/hooks/useGetEditEmployeeModel";
import { useAuth } from "@/lib/auth/AuthProvider";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import { changeLanguage } from "i18next";

interface Props {
  employeeId: string;
  title: string;
  showPermissions?: boolean;
  onBack: () => void;
}

export const EmployeeEdit = ({
  employeeId,
  ...props
}: Props) => {
  const { data } = useGetEditEmployeeModel(employeeId);
  const { onBack } = props;
  const { isCurrentUser } = useAuth();
  const { hasFeature } = useFeatures();

  const isMe = isCurrentUser(employeeId);
  const { isPending, validationErrors, mutate } = useEditEmployeeMutation(employeeId, submitData => onSuccess(submitData));

  const onSubmitForm = (submitData: SubmitData) => {
    mutate(submitData);
  };

  const onSuccess = ({ formData }: SubmitData) => {
    if (hasFeature("Features.FrenchTranslation") && isMe) {
      changeLanguage(formData.language).then(() => window.location.reload());

      return;
    }

    onBack();
  };

  if (!data) {
    return null;
  }

  return (
    <EmployeeForm
      {...props}
      employee={data}
      isSaving={isPending}
      onSubmit={onSubmitForm}
      saveErrors={validationErrors}
      variant="edit"
    />
  );
};
