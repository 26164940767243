import { DefaultLoader, type FlyoutContentChildren } from "@/components";
import { useGetSkillFlyoutSuspenseQuery } from "@/features/skill/hooks/useGetSkillFlyout";
import { Suspense } from "react";
import { type OverlayTriggerState } from "react-stately";
import { SkillFlyoutEditModeCustom } from "./SkillFlyoutEditModeCustom";
import { SkillFlyoutEditModeMarket } from "./SkillFlyoutEditModeMarket";

interface Props {
  skillName: string;
  overlayState: OverlayTriggerState;
  children: FlyoutContentChildren;
}

export const SkillFlyoutEditMode = ({ overlayState, skillName, children }: Props) => {
  const { data: skill } = useGetSkillFlyoutSuspenseQuery(skillName);
  
  const { customSkillId } = skill;

  if (customSkillId) {
    return (
      <Suspense fallback={<DefaultLoader />}>
        <SkillFlyoutEditModeCustom customSkillId={customSkillId} skill={skill} overlayState={overlayState}>
          {({ header, content, footer, modals }) => (
            children({
              header, content, footer, modals
            })
          )}
        </SkillFlyoutEditModeCustom>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<DefaultLoader />}>
      <SkillFlyoutEditModeMarket overlayState={overlayState} skillName={skillName}>
        {({ header, content, footer, modals }) => (
          children({
            header, 
            content, 
            footer, 
            modals
          })
        )}
      </SkillFlyoutEditModeMarket>
    </Suspense>
  );
};