import { type ErrorCode } from "@/lib/i18n/types";
import { exists } from "i18next";
import { translate } from "@/lib/i18n";

export interface ValidationErrors {
  [errors: string]: string[] | ErrorCode[];
}

export class ValidationErrorResult {
  private errors!: ValidationErrors;

  constructor(err?: ValidationErrors) {
    this.errors = err || {};
  }

  field(key: string): string {
    const error = (this.errors && this.errors[key] && this.errors[key][0]);

    if (error && exists(error)) {
      return translate(error as ErrorCode);
    }

    return error;
  }

  fieldErrors(key: string): string[] | undefined {
    return (this.errors && this.errors[key] && this.errors[key]);
  }

  summary(): string {
    return this.field("");
  }
}
