import { Helmet } from "react-helmet-async";
import { SettingsGeneralForm } from "@/features/tenants/components/SettingsGeneralForm";
import { Spinner } from "@workleap/orbiter-ui";
import { useGetGeneralSettings } from "@/features/tenants/hooks/useGetGeneralSettings";
import { useProgressiveLoading } from "@/components";
import { useTranslation } from "@/hooks/useTranslation";

export const SettingsGeneralPage = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetGeneralSettings();
  const { showLoading } = useProgressiveLoading({ isLoading });

  if (showLoading) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Spinner aria-label="settings-loading" size="lg" color="decorative-option1-weak" />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{t("pages.settingsGeneral")}</title>
      </Helmet>
      <SettingsGeneralForm defaultValues={data} />
    </>
  );
};