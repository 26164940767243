import { type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { type UserProfileDto } from "@/features/profile/types/common";
import { userProfileQueryKeys } from "./userProfileQueryKeys";
import api from "@/lib/http/api";

export const getUserProfile = async () => {
  const { data } = await api.get<UserProfileDto>("/user-profile");

  return data;
};
  

export const useGetUserProfile = (
  options?: UseQueryOptions<UserProfileDto, unknown, UserProfileDto, string[]>
) => (
  useQuery({
    queryKey: userProfileQueryKeys.single, 
    queryFn: getUserProfile, 
    ...options
  })
);
