import { Flyout, FlyoutContent } from "@/components";
import { type JobOpeningFormInput } from "@/features/jobs/types/jobOpeningFormTypes";
import { useTranslation } from "@/hooks/useTranslation";
import { Button, ButtonGroup, Div, Flex, Form, Heading } from "@workleap/orbiter-ui";
import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useOverlayTriggerState } from "react-stately";
import { JobOpeningFormConfirmationModal } from "./JobOpeningFormConfirmationModal";
import { JobOpeningFormContent } from "./JobOpeningFormContent";
import { JobOpeningFormWatchers } from "./JobOpeningFormWatchers";

export type JobOpeningFormMode = "create" | "update" | "import";

interface Props {
  title: string;
  submitLabel: string;
  isSaving: boolean;
  mode: JobOpeningFormMode;
  isStatusDisabled?: boolean;
  onSubmit: (data: JobOpeningFormInput) => void;
  onClose: () => void;
}

export const JobOpeningFormFlyout = ({ title, submitLabel, isSaving, mode, isStatusDisabled, onSubmit, onClose }: Props) => {
  const { t } = useTranslation();
  const confirmationOverlayState = useOverlayTriggerState({});
  
  const { handleSubmit, formState: { isDirty, isSubmitted } } = useFormContext<JobOpeningFormInput>();
  const role = useWatch<JobOpeningFormInput, "role">({ name: "role" });
  const status = useWatch<JobOpeningFormInput, "status">({ name: "status" });

  const _onClose = () => {
    if (isDirty && !isSubmitted) {
      confirmationOverlayState.open();
    } else {
      onClose();
    }
  };

  const showWatchers = useMemo(() => role && status === "Open", [role, status]);
  
  return (
    <Flyout
      isOpen
      isDismissable
      onClose={_onClose}
    >
      <Form onSubmit={handleSubmit(onSubmit)} fluid height="100%">
        <FlyoutContent onClose={_onClose}>
          <Heading size="xl" slot="header">{title}</Heading>
          <Div slot="content">
            <JobOpeningFormContent mode={mode} isStatusDisabled={isStatusDisabled} />
          </Div>
          <Flex slot="footer" direction="column" gap="inline-md">
            {showWatchers && <JobOpeningFormWatchers roleId={role!.id} />}
            <ButtonGroup orientation="horizontal">
              <Button variant="secondary" onClick={_onClose} fluid>{t("common.form.cancel")}</Button>
              <Button type="submit" loading={isSaving} fluid>{submitLabel}</Button>
            </ButtonGroup>
          </Flex>
          <Div slot="modals">
            <JobOpeningFormConfirmationModal overlayState={confirmationOverlayState} onClickConfirm={() => onClose()} />
          </Div>
        </FlyoutContent>
      </Form>
    </Flyout>
  );
};