/* eslint-disable no-restricted-imports */
import { useTranslation as i18nUseTranslation, type UseTranslationResponse } from "react-i18next";

import { useCurrentUser } from "@/lib/auth/AuthProvider";
import { useGetMarketContentTranslations } from "@/lib/i18n/hooks/useGetMarketContentTranslations";
import { type TranslationKey } from "@/lib/i18n/types";
import { type Namespace, type TOptions } from "i18next";
import { get } from "lodash";

type BaseResponse = Omit<UseTranslationResponse<Namespace<string>>, "t">;
interface UseTranslationResult extends BaseResponse {
  t: (key: TranslationKey, options?: TOptions) => string;
}

/**
 * Wraps i18n useTranslation hook with the added restriction of
 * preventing generic translation keys to be used with the returned `t` function.
 * The translation keys are restricted to only those defined by our app at @/lib/i18n/types.
 * @param args Parameters<typeof i18nUseTranslation>
 * @returns UseTranslationResult
 */
export const useTranslation = (...args: Parameters<typeof i18nUseTranslation>): UseTranslationResult => {
  const { t: ti18n, ...rest } = i18nUseTranslation(...args);

  return {
    t: (key: TranslationKey, options?: TOptions) => ti18n(key, options),
    ...rest
  };
};

export const useMarketContentTranslation = () => {
  const { language } = useCurrentUser();
  
  const { data } = useGetMarketContentTranslations();

  const getTranslation = (content: string, section: Record<string, string | undefined>) =>
    section[content.toLocaleLowerCase()] || section[content];

  const translateMarketContent = (content: string) => {
    if (!content || !data) { return content; }

    const { translations } = data;

    const languageData = translations[language];
    if (!languageData) { return content; }

    for (const key of Object.keys(languageData)) {
      const translation = getTranslation(content, get(languageData, key));
      if (translation) {
        return translation;
      }
    }

    return content;
  };

  return { translateMarketContent };
};

