import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useDebounce } from "use-hooks";
import { useState } from "react";
import api from "@/lib/http/api";

interface IndustriesSearchResponse {
  industries: string[];
}

export const useIndustriesSearch = () => {
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce<string>(query, 300);

  const { data, fetchStatus } = useQuery({
    queryKey: ["industries", debouncedQuery],
    queryFn: async () => {
      const res = await api.get<IndustriesSearchResponse>(
        "search/industries", {
          params: {
            search: debouncedQuery
          }
        });

      return res.data.industries;
    },
    enabled: !!debouncedQuery,
    placeholderData: keepPreviousData
  });

  return {
    data,
    setQuery,
    query,
    fetchStatus
  };
};
