import { type PageLinkProps } from "@/components";
import { useTranslation } from "@/hooks/useTranslation";
import { useAuth, useCurrentUser } from "@/lib/auth/AuthProvider";
import { type AppPermission } from "@/lib/auth/types";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import { Routes } from "@/types";

interface PageItem extends PageLinkProps {
  requiredPermission?: AppPermission;
}

interface SettingSection {
  id: string;
  title?: string;
  pages: PageItem[];
}

export const useSettingsSections = () => {
  const { t } = useTranslation();
  const { tenant: { isWorkleapTenant } } = useCurrentUser();
  const { getBlockedFeature } = useFeatures();
  const { currentUserHasPermission } = useAuth();

  const userManagement: PageItem = {
    to: `${Routes.Settings}/users`,
    title: t("users.heading.title"),
    description: t("settings.users.description"),
    requiredPermission: "Permissions.UserManagement.Read"
  };

  const roleManagement: PageItem = {
    to: Routes.SettingsRoles,
    title: t("settings.roles.title"),
    description: t("settings.roles.description"),
    requiredPermission: "Permissions.RoleManagement.Read"
  };

  const general: PageItem = {
    to: `${Routes.Settings}/general`,
    title: t("settings.general.title"),
    description: t("settings.general.description"),
    requiredPermission: "Permissions.Settings.Write"
  };

  const organizationDetails: PageItem = {
    to: `${Routes.Settings}/company`,
    title: t("accountSettings.heading.title"),
    description: t("settings.company.description"),
    requiredPermission: "Permissions.Settings.Write"
  };

  const departments: PageItem = {
    to: `${Routes.Settings}/departments`,
    title: t("settings.departments.title"),
    description: t("settings.departments.description"),
    blockedFeature: getBlockedFeature("Features.DepartmentManagement"),
    requiredPermission: "Permissions.Departments.Read"
  };

  const billing: PageItem = {
    to: `${Routes.SettingsBilling}`,
    title: t("settings.billing.title"),
    description: t("settings.billing.description"),
    requiredPermission: "Permissions.Billing.Read"
  };

  const permissions: PageItem = {
    to: `${Routes.SettingsPermissions}`,
    title: t("settings.permissions.title"),
    description: t("settings.permissions.description"),
    blockedFeature: getBlockedFeature("Features.ManagerAccessManagement"),
    requiredPermission: "Permissions.Settings.Write"
  };

  const training: PageItem = {
    to: Routes.SettingsTraining,
    title: t("settings.training.title"),
    description: t("settings.training.description"),
    requiredPermission: "Permissions.Trainings.Edit"
  };

  const skillsSection: SettingSection = {
    id: "skills-settings",
    title: t("settings.skills"),
    pages: [
      {
        to: `${Routes.Settings}/skills/custom-descriptions`,
        title: t("settings.customDescriptions.title"),
        description: t("settings.customDescriptions.description"),
        blockedFeature: getBlockedFeature("Features.CustomizeSkills"),
        requiredPermission: "Permissions.Skills.UpdateSkill"
      },
      {
        to: `${Routes.Settings}/skills/custom-skills`,
        title: t("settings.customSkills.title"),
        description: t("settings.customSkills.description"),
        blockedFeature: getBlockedFeature("Features.CustomizeSkills"),
        requiredPermission: "Permissions.Skills.UpdateSkill"
      }
    ]
  };

  const ats: PageItem = {
    to: Routes.SettingsAts,
    title: t("settings.ats.title"),
    description: t("settings.ats.description"),
    requiredPermission: "Permissions.Settings.Ats.Write"
  };
  
  const hris: PageItem = {
    to: `${Routes.Settings}/hris`,
    title: t("settings.hris.title"),
    description: t("settings.hris.description"),
    requiredPermission: "Permissions.Settings.Hris.Write"
  };

  const integrations: SettingSection = {
    id: "integrations",
    title: t("settings.integrations"),
    pages: [ats, hris]
  };
  
  const oldSections: SettingSection[] = [
    {
      id: "account-settings",
      title: t("settings.account"),
      pages: [
        userManagement,
        roleManagement,
        organizationDetails,
        departments,
        billing,
        permissions,
        training
      ]
    },
    skillsSection,
    integrations
  ];

  const newSections: SettingSection[] = [
    {
      id: "account-settings",
      pages: [
        general,
        roleManagement,
        training,
        ats
      ]
    }
  ];

  const sections = isWorkleapTenant ? newSections : oldSections;
  // filter out pages that the user doesn't have permission to view
  sections.forEach(section => {
    section.pages = section.pages.filter(page => {
      if (page.requiredPermission) {
        return currentUserHasPermission(page.requiredPermission);
      }

      return true;
    });
  });

  // filter out sections that have no pages
  return {
    sections: sections.filter(section => section.pages.length > 0)
  };
};