import { Editor, type EditorProps } from "./Editor";
import { useEditor, type UseEditorProps } from "./hooks/useEditor";

interface Props extends Omit<EditorProps, "editor"> {
  editorConfig: UseEditorProps;
}

export const ContentEditor = ({ editorConfig, ...rest }: Props) => {
  const editor = useEditor(editorConfig);

  return <Editor {...rest} editor={editor} />;
};
