import { AtsConnectedIntegration } from "./AtsConnectedIntegration";
import { AtsDisconnectButton } from "./AtsDisconnectButton";
import { Card, CardHeader, VStack } from "@/components";
import { useTranslation } from "@/hooks/useTranslation";

export const AtsConnectedIntegrationCard = () => {
  const { t } = useTranslation();
  
  return (
    <Card>
      <VStack gap={4}>
        <CardHeader title={t("ats.connect.title")} />
        <AtsConnectedIntegration />
        <AtsDisconnectButton />
      </VStack>
    </Card>
  );
};