import classNames from "classnames";
import { type MouseEvent, type ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
  onClick?: (() => void) | ((e: MouseEvent) => void);
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  clickable?: boolean;
}

export const Card = ({ children, className, onClick, onMouseOver, onMouseOut, clickable }: Props) => (
  <div
    tabIndex={clickable || !!onClick ? 0 : undefined}
    role={clickable || !!onClick ? "button" : "none"}
    className={
      classNames(
        "bg-neutral-surface rounded-shape-rounded-md border-[1.5px] border-neutral-border-weak p-space-inset-lg relative", 
        {
          "hover:cursor-pointer hover:shadow-elevation-raised hover:border-primary-border": clickable || !!onClick,
          "focus:border-primary-border focus:bg-primary-surface-weak": clickable || !!onClick
        }, 
        className
      )
    }
    onClick={onClick}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
  >
    {children}
  </div>
);
