import { Button, ButtonGroup, Grid, TextField, VStack } from "@/components";
import { SearchRoleThenJob } from "@/features/search/components/search-role-then-job/SearchRoleThenJob";
import { type SearchRoleThenJobResponseItem } from "@/features/search/hooks/useSearchRoleThenJob";
import { type CreateUserRequestPayload, type InviteUserCurrentRole } from "@/features/users/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { type ValidationErrorResult } from "@/models/error";
import { nameOf } from "@/utils/types";
import { useForm } from "react-hook-form";

interface NewUserFormData {
  firstName: string;
  lastName: string;
  email: string;
  currentRole?: InviteUserCurrentRole;
}

interface Props {
  onCancel: () => void;
  onSubmit: (formData: NewUserFormData) => void;
  validationErrors?: ValidationErrorResult;
  submitBtnLabel?: string;
  isSubmitting?: boolean;
}


export const InviteNewUserForm = ({ onCancel, onSubmit, isSubmitting, validationErrors, submitBtnLabel }: Props) => {
  const { watch, handleSubmit, setValue } = useForm<NewUserFormData>({ defaultValues: {} });
  const { t } = useTranslation();

  const onSelectRole = (role?: SearchRoleThenJobResponseItem) => {
    if (!role) {
      return;
    }

    const { marketRoleId: marketId, roleId } = role;
    setValue("currentRole", { roleId, marketId }, { shouldDirty: true });
  };
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack className="mb-6 w-[528px]" gap={6}>
        <Grid cols={2} rows="auto">
          <TextField
            label={t("common.labels.firstName")}
            description={t("users.invite.form.firstNamePlaceholder")}
            errorMessage={validationErrors?.field("Body.FirstName")}
            value={watch("firstName")}
            onChange={value => setValue("firstName", value, { shouldDirty: true })}
            isRequired
          />
          <TextField
            name={nameOf<CreateUserRequestPayload>("lastName")}
            label={t("common.labels.lastName")}
            description={t("users.invite.form.lastNamePlaceholder")}
            errorMessage={validationErrors?.field("Body.LastName")}
            value={watch("lastName")}
            onChange={value => setValue("lastName", value, { shouldDirty: true })}
          />
        </Grid>
        <TextField
          name={nameOf<CreateUserRequestPayload>("email")}
          label={t("users.invite.form.emailAddrLabel")}
          description={t("users.invite.form.emailAddrPlaceholder")}
          errorMessage={validationErrors?.field("Body.WorkEmail")}
          value={watch("email")}
          onChange={value => setValue("email", value, { shouldDirty: true })}
          isRequired
        />
        <SearchRoleThenJob 
          label={t("users.invite.form.role")}
          description={t("common.labelDesc.select")}
          onRoleSelected={onSelectRole}
          errorMessage={validationErrors?.field("Body.CurrentRole")}
          hideExternalChip
        />
      </VStack>
      <ButtonGroup align="end">
        <Button variant="secondary" isDisabled={isSubmitting} onClick={onCancel}>
          {t("common.form.cancel")}
        </Button>
        <Button type="submit" loading={isSubmitting}>
          {submitBtnLabel || t("common.form.save")}
        </Button>
      </ButtonGroup>
    </form>
  );
};