import { HStack, Text, useProgressiveLoading } from "@/components";
import { useGetHRISIntegrations } from "@/features/setup/hooks/useGetHRISIntegrations";
import { useGetHrisSettingsQuery } from "@/features/hris/hooks/useGetHrisSettings";
import { useTranslation } from "@/hooks/useTranslation";
import Skeleton from "react-loading-skeleton";

const IMAGE_SIZE = 28;

export const ConnectedHrisIntegration = () => {
  const { t } = useTranslation();
  const { data: integrations, isLoading: isIntegrationsLoading } = useGetHRISIntegrations();
  const { data: settingsQueryData, isLoading: isSettingsLoading } = useGetHrisSettingsQuery();
  const { showLoading } = useProgressiveLoading({
    isLoading: isIntegrationsLoading || isSettingsLoading
  });

  if (showLoading) {
    return (
      <HStack gap={2} align="center">
        <Skeleton height={IMAGE_SIZE} width={IMAGE_SIZE} circle />
        <Skeleton height="24" width="200" />
      </HStack>
    );
  }

  if (!integrations || !settingsQueryData) {
    return null;
  }

  const currentIntegration = integrations.find(i => i.slug === settingsQueryData.integrationId);

  if (!currentIntegration) {
    return null;
  }

  const { name, squareImage } = currentIntegration;

  return (
    <HStack gap={2} align="center">
      <img src={squareImage} alt={name} width={IMAGE_SIZE} height={IMAGE_SIZE} />
      <Text>{t("hrisSettings.connect.connectedHris", { integration: name })}</Text>
    </HStack>
  );
};