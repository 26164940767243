import { ConfirmationModal, DefaultMenuItem, DeleteConfirmationModal, DropdownMenuButton } from "@/components";
import { getFullName } from "@/features/employees/utils";
import { postActivateUser } from "@/features/users/api/postActivateUser";
import { postDeactivateUser } from "@/features/users/api/postDeactivateUser";
import { postDeleteUser } from "@/features/users/api/postDeleteUser";
import { postSendInvite } from "@/features/users/api/postSendInvite";
import { useUserActions } from "@/features/users/hooks/useUserActions";
import { useInvalidateUsers, useStatusChangeMutation } from "@/features/users/hooks/useUsers";
import { UserDropdownActionKeys, type UserDto } from "@/features/users/types/common";
import { useCommand } from "@/hooks";
import { useTranslation } from "@/hooks/useTranslation";
import { isEmpty } from "lodash";
import { type Key } from "react";
import { Section, useOverlayTriggerState } from "react-stately";
import { toast } from "react-toastify";

interface UserActionsDropdownMenuProps {
  user: UserDto;
  onEdit: (employeeId: string) => void;
}

export const UserListActionsDropdownMenu = ({ user, onEdit }: UserActionsDropdownMenuProps) => {
  const { t } = useTranslation();
  const activateUserCommand = useCommand(postActivateUser);
  const deactivateUserCommand = useCommand(postDeactivateUser);
  const deleteUserCommand = useCommand(postDeleteUser);
  const sendInviteCommand = useCommand(postSendInvite);
  const userDeletionOverlayTriggerState = useOverlayTriggerState({});
  const userDeactivationTriggerState = useOverlayTriggerState({});
  const { buildUserActions } = useUserActions();

  const { employeeId, firstName, lastName } = user;
  const fullName = getFullName(firstName, lastName);

  const { invalidate } = useInvalidateUsers();
  const { mutateAsync: sendInviteAndMutate } = useStatusChangeMutation({
    operation: x => sendInviteCommand.send(x[0]),
    successMessage: t("users.actions.inviteSuccessMessage"),
    newStatus: "Invited"
  });
  const { mutateAsync: deactivateAndMutate } = useStatusChangeMutation({
    operation: x => deactivateUserCommand.send(x[0]),
    successMessage: t("users.actions.deactivateUserSuccessMessage"),
    newStatus: "Deactivated"
  });

  const handleEditUser = () => {
    onEdit(employeeId);
  };

  const handleSendInvite = async () => {
    await sendInviteAndMutate([employeeId]);
  };

  const handleActivateUser = async () => {
    await activateUserCommand.send(employeeId);
    invalidate();

    toast.success(t("users.actions.activateUserSuccessMessage"));
  };

  const handleDeactivateUser = async () => {
    await deactivateAndMutate([employeeId]);
  };

  const handleDelete = async () => {
    await deleteUserCommand.send(employeeId);
    invalidate();
    toast.success(t("users.delete.successMessage", { fullName: fullName }));
  };

  const actionCommands = {
    [UserDropdownActionKeys.EDIT_USER]: handleEditUser,
    [UserDropdownActionKeys.ACTIVATE_USER]: handleActivateUser,
    [UserDropdownActionKeys.DEACTIVATED_USER]: userDeactivationTriggerState.open,
    [UserDropdownActionKeys.SEND_INVITE]: handleSendInvite,
    [UserDropdownActionKeys.DELETE]: userDeletionOverlayTriggerState.open
  };

  const actionMenuItems: Record<UserDropdownActionKeys, JSX.Element> = {
    [UserDropdownActionKeys.EDIT_USER]: DefaultMenuItem(UserDropdownActionKeys.EDIT_USER, t("users.actions.edit")),
    [UserDropdownActionKeys.ACTIVATE_USER]: DefaultMenuItem(UserDropdownActionKeys.ACTIVATE_USER, t("users.actions.activateUser")),
    [UserDropdownActionKeys.DEACTIVATED_USER]: DefaultMenuItem(UserDropdownActionKeys.DEACTIVATED_USER, t("users.actions.deactivateUser")),
    [UserDropdownActionKeys.SEND_INVITE]: DefaultMenuItem(UserDropdownActionKeys.SEND_INVITE, t("users.actions.sendInvite")),
    [UserDropdownActionKeys.DELETE]: DefaultMenuItem(UserDropdownActionKeys.DELETE, t("users.actions.delete"), { variant: "danger" })
  };

  const onAction = async (key: Key) => {
    const command = actionCommands[key as UserDropdownActionKeys];

    if (!command) {
      return;
    }

    await command();
  };

  const items: JSX.Element[] = buildUserActions(user).map(action => actionMenuItems[action]);

  if (isEmpty(items)) {
    return <div className="w-12 h-12" />;
  }

  return (
    <>
      <DropdownMenuButton onAction={onAction}>
        <Section>
          {items}
        </Section>
      </DropdownMenuButton>
      <DeleteConfirmationModal
        title={t("users.delete.title")}
        onDelete={handleDelete}
        overlayState={userDeletionOverlayTriggerState}
        name={fullName}
      />
      <ConfirmationModal
        title={t("users.actions.deactivateUserModalTitle")}
        onClickConfirm={handleDeactivateUser}
        overlayState={userDeactivationTriggerState}
        confirmButtonContent={t("users.actions.deactivateUser")}
        confirmButtonProps={{ variant: "primary" }}
      >
        {t("users.actions.deactivateModalMessage")}
      </ConfirmationModal>
    </>
  );
};
