import { type SelectItem } from "@/components";
import { useTranslation } from "./useTranslation";

export type Languages = "en" | "fr";

export const useLanguages = () => {
  const { t } = useTranslation();
  
  const languages: SelectItem<Languages>[] = [
    { id: "en", text: t("common.language.english") },
    { id: "fr", text: t("common.language.french") }
  ];

  const getLanguage = (language?: string) => languages.find(item => item.id === language) ?? languages[0];

  return { 
    languages,
    getLanguage
  };
};