import { AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { type Id, toast } from "react-toastify";
import { Switch } from "@/components";
import { useGetHrisSettingsQuery } from "@/features/hris/hooks/useGetHrisSettings";
import { useRef } from "react";
import { useTrackHrisAction } from "@/features/hris/hooks/useTrackHrisAction";
import { useTranslation } from "@/hooks/useTranslation";
import { useUpdateActivationMutation } from "@/features/hris/hooks/useUpdateActivationMutation";

export const HrisActivateToggle = () => {
  const { t } = useTranslation();
  const { data: settingsQueryData } = useGetHrisSettingsQuery();
  const { mutate: updateActivation, isPending } = useUpdateActivationMutation(isActive => onSuccess(isActive));
  const toastId = useRef<Id>();
  const isDisabled = settingsQueryData?.isScheduledOrRunning || isPending;
  const { trackHrisAction } = useTrackHrisAction();

  const handleChange = (isActive: boolean) => {
    updateActivation({ isActive });
  };

  const onSuccess = (isActive: boolean) => {
    toast.dismiss(toastId.current);
    toastId.current = toast.success(isActive 
      ? t("hrisSettings.sync.toggle.enabled")
      : t("hrisSettings.sync.toggle.disabled"));
  };
  
  const onHrisAutoSyncChange = (isSelected: boolean) => {
    trackHrisAction(AnalyticValues.actionAutoSync, isSelected.toString());
    handleChange(isSelected);
  };
  
  return (
    <Switch 
      isSelected={settingsQueryData?.isActive} 
      isDisabled={isDisabled} 
      onChange={onHrisAutoSyncChange}
    >
      {t("hrisSettings.sync.toggle.label")}
    </Switch>
  );
};