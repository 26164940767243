import { atsQueryKeys } from "@/features/ats/types/atsQueryKeys";
import { type AtsOpenJobDto, type ImportAtsJobOpeningCommand } from "@/features/ats/types/common";
import { getFullName } from "@/features/employees/utils";
import { JobOpeningFormSchema, type JobOpeningFormInput } from "@/features/jobs/types/jobOpeningFormTypes";
import { jobsQueryKeys } from "@/features/jobs/types/jobsQueryKeys";
import { roleQueryKeys } from "@/features/roles/types/roleQueryKeys";
import { useMutationWithValidationResult } from "@/hooks/useMutationWithValidationResult";
import api from "@/lib/http/api";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

interface UseImportAtsJobOpeningOptions {
  job: AtsOpenJobDto;
  onSuccess: () => void;
}

export const useImportAtsJobOpeningFormMutation = ({ job, onSuccess }: UseImportAtsJobOpeningOptions) => {
  const queryClient = useQueryClient();
  
  const { roleId, roleName, hiringManager, status, postingLink } = job;
  
  const form = useForm<JobOpeningFormInput>({
    defaultValues: {
      role: roleId ? { 
        id: roleId, 
        name: roleName 
      } : null,
      hiringManager: hiringManager ? { 
        id: hiringManager.id, 
        name: getFullName(hiringManager.firstName, hiringManager.lastName) 
      } : null,
      status: status,
      openDate: new Date(),
      postingLink: postingLink ?? ""
    },
    resolver: valibotResolver(JobOpeningFormSchema)
  });


  const mutation = useMutationWithValidationResult<ImportAtsJobOpeningCommand, void>({
    mutationFn: (data: ImportAtsJobOpeningCommand) => api.post("/ats/importJobOpening", data),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: jobsQueryKeys.all }),
        queryClient.invalidateQueries({ queryKey: roleQueryKeys.all }),
        queryClient.invalidateQueries({ queryKey: atsQueryKeys.all })
      ]);

      onSuccess();
    } 
  });


  return { form, mutation };
};