import { type GetCustomMarketSkillsQueryParams, type GetCustomSkillsQueryParams, type GetSkillEmployeesQuery } from "./common";

export const skillsQueryKeys = {
  all: [{ scope: "skills" }] as const,
  lists: () => [...skillsQueryKeys.all, { group: "lists" }] as const,
  listCustomMarketSkills: (params: GetCustomMarketSkillsQueryParams) => [...skillsQueryKeys.lists(), { entity: "customMarketSkill" }, params] as const,
  listCustomSkills: (params: GetCustomSkillsQueryParams) => [...skillsQueryKeys.lists(), { entity: "customSkill" }, params] as const,
  getCustomSkillById: (id: string) => [...skillsQueryKeys.all, { entity: "customSkill", id }] as const,
  getCustomMarketSkillByName: (skillName: string) => [...skillsQueryKeys.all, { entity: "customMarketSkillByName", skillName }] as const,
  customSkillSettings: () => [...skillsQueryKeys.all, { entity: "customSkillSettings" }] as const,
  newSkills: () => [...skillsQueryKeys.all, { entity: "new-skills" }] as const,
  inventory: () => [...skillsQueryKeys.all, { entity: "inventory" }] as const,
  skillEmployees: (query: GetSkillEmployeesQuery) => [...skillsQueryKeys.all, { entity: "skill-employees", query }] as const
};