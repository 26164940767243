import { useSkillFlyoutState } from "@/features/skill/hooks/useSkillFlyoutState";
import { useMarketContentTranslation } from "@/hooks/useTranslation";
import { removeTextBetweenParenthesis } from "@/lib/utils";
import { CrossButton, Div, Flex, Text } from "@workleap/orbiter-ui";
import { useOverlayTriggerState } from "react-stately";

interface Props {
  skillName: string;
  onRemoveSkill?: () => void;
}

export const SimilarSkill = ({ skillName, onRemoveSkill }: Props) => {
  const { translateMarketContent } = useMarketContentTranslation();
  const overlayState = useOverlayTriggerState({});
  const { openFlyout } = useSkillFlyoutState(overlayState);

  const onClickSkill = () => {
    if (onRemoveSkill) {
      return;
    }

    openFlyout(skillName, "Beginner");
  };

  return (
    <Div 
      paddingX="12px"
      paddingY="inset-sm"
      backgroundColor="decorative-option5-weakest"
      borderRadius="rounded-md"
      display="flex"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      onClick={onClickSkill}
    >
      <Flex gap="8px" alignItems="center">
        <Text size="sm" color="decorative-option1">
          {removeTextBetweenParenthesis(translateMarketContent(skillName))}
        </Text>
        {onRemoveSkill && <CrossButton size="xs" aria-label="Clear" onClick={onRemoveSkill} />}
      </Flex>
    </Div>
  );
};