import { type CalendarDate } from "@internationalized/date";
import { type CalendarState } from "react-stately";
import {
  mergeProps,
  useCalendarCell,
  useFocusRing
} from "react-aria";
import { useRef } from "react";
import classNames from "classnames";
import styles from "./CalendarCell.module.css";

interface Props {
  state: CalendarState;
  date: CalendarDate;
}

export const CalendarCell = ({ state, date }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    formattedDate,
    isInvalid
  } = useCalendarCell({ date }, state, ref);

  // We add rounded corners on the left for the first day of the month,
  // the first day of each week, and the start date of the selection.
  // We add rounded corners on the right for the last day of the month,
  // the last day of each week, and the end date of the selection.
  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <td
      {...cellProps}
      className={classNames(styles.container, {
        [styles["container--focus-visible"]]: isFocusVisible
      })}
    >
      <div
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        hidden={isOutsideVisibleRange}
        className={classNames(
          styles.calendarCell,
          {
            [styles["calendarCell--selected"]]: isSelected,
            [styles["calendarCell--selected--invalid"]]: isSelected && isInvalid,
            [styles["calendarCell--disabled"]]: isDisabled
          }
        )}
      >
        <div
          className={classNames(
            styles.calendarCellDateItem,
            {
              [styles["calendarCellDateItem--disabled"]]: isDisabled && !isInvalid,
              [styles["calendarCellDateItem--focus-visible"]]: isFocusVisible,
              [styles["calendarCellDateItem--selected"]]: isSelected && !isDisabled,
              [styles["calendarCellDateItem--invalid"]]: isInvalid
            }
          )}
        >
          {formattedDate}
        </div>
      </div>
    </td>
  );
};
