import { getSkillsSearch } from "@/features/setup/api/getSkillsSearch";
import { SetupQueryKeys } from "@/features/setup/types";
import { type SkillsSearchDto } from "@/features/skills/types/common";
import { type UseQueryOptions, keepPreviousData, useQuery } from "@tanstack/react-query";
import { type Dispatch, type SetStateAction, useState } from "react";
import { useDebounce } from "use-hooks";

type SkillsResponse = {
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
} & SkillsSearchDto;

export const useGetSkillSearchResults = (
  jobTitle?: string,
  options?: Omit<UseQueryOptions<SkillsSearchDto, unknown, SkillsResponse, [SetupQueryKeys, string]>, "queryKey">
) => {
  const [query, setQuery] = useState<string>("");
  const debouncedQuery = useDebounce<string>(query, 300);
  
  const queryResult = useQuery({
    queryKey: [SetupQueryKeys.SKILLS_SEARCH, debouncedQuery],
    queryFn: () => getSkillsSearch(debouncedQuery, jobTitle),
    enabled: options?.enabled || !!debouncedQuery,
    placeholderData: keepPreviousData,
    ...options
  });

  return {
    setQuery,
    query,
    ...queryResult
  };
};

export const useGetSkillsQuery = (
  search: string,
  jobTitle?: string,
  options?: Omit<UseQueryOptions<SkillsSearchDto, unknown, SkillsResponse, [SetupQueryKeys, string]>, "queryKey">
) => useQuery({
  queryKey: [SetupQueryKeys.SKILLS_SEARCH, search],
  queryFn: () => getSkillsSearch(search, jobTitle),
  enabled: options?.enabled || !!search,
  placeholderData: keepPreviousData,
  ...options
});
