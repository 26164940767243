import { ConfirmationModal, useProgressiveLoading } from "@/components";
import { useGetRoleReferences } from "@/features/roles/hooks/useRoleReferences";
import { type RoleStatus } from "@/features/roles/types/common";
import { useTranslation } from "@/hooks/useTranslation";
import { Flex } from "@workleap/orbiter-ui";
import { noop } from "lodash";
import Skeleton from "react-loading-skeleton";
import { type OverlayTriggerState } from "react-stately";
import { NoImpactRoleDeletionModal } from "./role-deletion/NoImpactRoleDeletionModal";
import { NotDeletableRoleModal } from "./role-deletion/NotDeletableRoleModal";
import { RoleDeletionWithRiskModal } from "./role-deletion/RoleDeletionWithRiskModal";

interface Props {
  role: { id: string; name: string; status: RoleStatus };
  overlayState: OverlayTriggerState;
}


const LoadingSkeleton = () => (
  <Flex direction="column" gap="stack-md">
    <Skeleton width={450} height={70} />
    <Skeleton width={450} height={120} />
  </Flex>
);

export const DeleteRoleConfirmationModal = ({ overlayState, role: { id } }: Props) => {
  const { t } = useTranslation();
  const { isFetching, data: roleReferencesData } = useGetRoleReferences(id, {
    enabled: overlayState.isOpen
  });
  const { showLoading } = useProgressiveLoading({ isLoading: isFetching, showAfterInitialLoading: true });

  if (!overlayState.isOpen) {
    return null;
  }

  if (showLoading) {
    return (
      <ConfirmationModal
        key={id}
        title={t("roles.delete.title")}
        onClickConfirm={noop}
        overlayState={overlayState}
        cancelButtonContent={t("common.form.cancel")}
        confirmButtonContent={t("roles.delete.confirm")}
        confirmButtonProps={{ isDisabled: true }}
        cancelButtonProps={{ isDisabled: true }}
      >
        <LoadingSkeleton />
      </ConfirmationModal>
    );
  }

  if (!roleReferencesData) {
    return null;
  }

  const hasActiveEmployees = roleReferencesData.workHistory.some(({ isActive }) => isActive);

  if (roleReferencesData.totalActiveReferences === 0) {
    return (
      <NoImpactRoleDeletionModal 
        overlayState={overlayState}
        roleId={id}
      />
    );
  }

  if (hasActiveEmployees) {
    return (
      <NotDeletableRoleModal
        overlayState={overlayState}
        roleId={id}
        roleReferencesData={roleReferencesData}
      />
    );
  }

  return (
    <RoleDeletionWithRiskModal overlayState={overlayState}
      roleId={id} 
      roleReferencesData={roleReferencesData}
    />
  );
};