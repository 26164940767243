import { AnalyticEvents, AnalyticProperties, AnalyticValues } from "@/features/analytics/types/analyticEvents";
import { apiPost } from "@/lib/http/api";
import { progressionPlanQueryKeys } from "@/features/progression-plan/types/progressionPlanQueryKeys";
import { toast } from "react-toastify";
import { useAnalytics } from "@/features/analytics/hooks/useAnalytics";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "@/hooks/useTranslation";

export const useAddProgressionPlanMentorMutation = (planId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();
  
  return useMutation({
    mutationFn: async (mentorEmployeeId: string) => await apiPost(`progression-plans/${planId}/mentors`, { mentorEmployeeId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: progressionPlanQueryKeys.all });
      toast.success(t("collaborate.notification.mentorAdded"));

      trackEvent(AnalyticEvents.mentorAction, {
        [AnalyticProperties.action]: AnalyticValues.actionAdd
      });
    }
  });
};